import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { setBreadcrumb, setLoader, setTitle } from 'store/actions';
import { Helmet } from 'react-helmet';
import { Table } from 'components';
import { notification } from 'antd';

import { API, Endpoints } from 'utils/api';
import { Props, State } from './types';
import { translate } from 'utils/utils';
import Strings from 'utils/strings';

export class HighlightedSections extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			highlightedSections: [],
			columnSearch: null,
			dragIndex: -1,
			hoverIndex: -1,
		}
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setBreadcrumb(null));
		dispatch(setTitle(''));

		this.getData();
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriHighlightedSections(),
			});

			if (response.ok) {
				this.setState({ highlightedSections: response.data.results.highlightedSections || [] });
			} else {
				notification.error({
					message: Strings.sidebar.highlightedSections,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string || Strings.serverErrors.wentWrong,
				placement: 'bottomRight',
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async toggleHighlightedSection(value: any) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriHighlightedSections(`${value._id}/status`),
				data: { isActive: !value.isActive }
			});

			if (response?.ok) {
				await this.getData();
				notification.success({
					message: Strings.sidebar.highlightedSections,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.sidebar.highlightedSections,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string || Strings.serverErrors.wentWrong,
				placement: 'bottomRight',
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	onDrag = async () => {
		const { highlightedSections, hoverIndex } = this.state;
		const { dispatch } = this.props;

		const item = highlightedSections[hoverIndex];
		const itemId = item?._id;

		if (!itemId || item.pos === hoverIndex) return;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriHighlightedSections(`${itemId}/position`),
				data: { pos: hoverIndex },
			});

			if (response?.ok) {
				await this.getData();

				notification.success({
					message: Strings.sidebar.highlightedSections,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response.data?.message || '',
					placement: 'bottomRight',
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	};

	async deleteHighlightedSection(value: any) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriHighlightedSections(value),
			});

			if (response.ok) {
				this.setState({ highlightedSections: response.data.results.HighlightedSections || [] });

				notification.success({
					message: Strings.sidebar.highlightedSections,
					description: Strings.highlightedSections.deleted,
					placement: 'bottomRight',
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response.data?.message || '',
					placement: 'bottomRight',
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string || Strings.serverErrors.wentWrong,
				placement: 'bottomRight',
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	render() {
		const { highlightedSections } = this.state;
		const { dispatch } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.highlightedSections}</title>
					<meta name='description' content='Set Highlighted Sections to your web homepage' />
				</Helmet>
				<Table
					title={{
						icon: "frame",
						title: Strings.sidebar.highlightedSections
					}}
					data={highlightedSections}
					columns={[
						{
							Header: Strings.fields.image,
							id: 'image',
							accessor: (row: any) => translate(row.web?.image),
							type: 'image',
							maxWidth: 65,
						},
						{
							Header: Strings.fields.name,
							id: 'name',
							accessor: (row: any) => row.name,
						},
						{
							Header: Strings.highlightedSections.title,
							id: 'title',
							accessor: (row: any) => row?.web?.title?.pt || "-",
						},
					]}
					isSinglePage
					fullPage
					paginated={false}
					add={{
						tooltip: Strings.highlightedSections.addHighlightedSection,
						onClick: () => dispatch(push('/highlighted-sections/new'))
					}}
					actions={{
						edit: (obj: any) => ({
							onClick: () => dispatch(push(`/highlighted-sections/${obj._id}`)),
						}),
						remove: (obj: any) => ({
							onClick: () => this.deleteHighlightedSection(obj._id),
						}),
						toggle: (obj: any) => ({
							value: obj.isActive,
							onChange: () => this.toggleHighlightedSection(obj),
						})
					}}
					draggable
					onDrag={async (list: any, dragIndex: any, hoverIndex: any) => {
						this.setState({ highlightedSections: list, dragIndex, hoverIndex })
					}}
					onDragEnd={this.onDrag}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
});

export default connect(mapStateToProps)(HighlightedSections);