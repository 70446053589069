/*
 *
 * Bonuses
 *
 */

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { setTitle, setBreadcrumb, delayedDispatch, updateCrumb, setLoader } from "store/actions";
import Strings from "utils/strings";
import { ContentWrapper, Icon } from "components";
import { Col, Row, Input, Table, notification, Select, DatePicker } from "antd";
import { API, Endpoints } from "utils/api";
import "./styles.scss";
import { Props, State } from "./types";
import moment from "moment";
import strings from "utils/strings";

class Bonuses extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		if (this.props.language === "pt") {
			moment.updateLocale("pt", {
				week: {
					dow: 1 /// Date offset
				}
			});
		} else {
			moment.updateLocale("en", {
				week: {
					dow: 1 /// Date offset
				}
			});
		}

		this.state = {
			bonuses: {
				products: [],
				recruitmentBasic: [],
				recruitmentSuperior: [],
				repass: [],
				performance: [],
				binary: {
					left: 0,
					right: 0,
					value: 0
				},
				depth: [
					{ level: 1, value: 20 },
					{ level: 2, value: 15 },
					{ level: 3, value: 10 },
					{ level: 4, value: 5 },
					{ level: 5, value: 3 },
					{ level: 6, value: 2 },
					{ level: 7, value: 1 }
				],
				diamond: [],
				diamondMinimumPoints: 0,
				special: []
			},
			hasUnsavedFields: false
		};
	}

	componentDidMount() {
		this.breadCrumb();
		this.getData();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	breadCrumb = () => {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.sidebar.bonuses));

		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							icon: "preferences",
							text: Strings.sidebar.settings,
							route: "/settings"
						},
						{
							icon: "tax-settings",
							text: Strings.settings.bonuses
						}
					],
					actions: [
						{
							type: "button",
							text: Strings.generic.save,
							disabled: !this.state.hasUnsavedFields,
							className: "BreadcrumbSaveButton",
							isSave: true,
							hasIcon: true,
							onClick: () => this.onSave()
						}
					]
				};
			})
		);
	};

	getData = async () => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriSettings()
			});

			if (response.ok) {
				const bonuses = response.data.results.settings.bonuses;
				const { products, recruitment, repass, performance, binary, depth, diamond, special } = bonuses;

				const productsArray = Object.keys(products).map((key) => {
					return {
						key,
						position: this.keyToString(key),
						discount: products[key]
					};
				});

				recruitment.basic.key = "kit-basico";
				recruitment.superior.key = "kit-superior";

				const recruitmentBasic = [recruitment.basic];
				const recruitmentSuperior = [recruitment.superior];

				const repassArray = Object.keys(repass).map((key) => {
					return {
						key,
						position: this.keyToString(key),
						percentage: repass[key]
					};
				});

				const depthArray = depth.map((item: any) => {
					return {
						key: item.level,
						level: item.level,
						value: item.value
					};
				});

				const diamondMinimumPoints = diamond.minimumPoints;
				const diamondArray = Object.keys(diamond)
					.map((key) => {
						if (key !== "minimumPoints") {
							return {
								key,
								qualification: key,
								points: diamond[key]
							};
						}
					})
					.filter((item: any) => item !== undefined);

				const specialArray = Object.keys(special).map((key) => {
					return {
						key,
						qualification: key,
						gain: special[key]
					};
				});

				const newBonuses = {
					...bonuses,
					products: productsArray,
					recruitmentBasic: recruitmentBasic,
					recruitmentSuperior: recruitmentSuperior,
					repass: repassArray,
					performance: performance,
					binary,
					depth: depthArray,
					diamond: diamondArray,
					diamondMinimumPoints,
					special: specialArray
				};

				this.setState({ bonuses: newBonuses });
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	};

	onSave = async () => {
		const { bonuses } = this.state;
		const { dispatch } = this.props;
		const {
			products,
			recruitmentBasic,
			recruitmentSuperior,
			repass,
			performance,
			binary,
			depth,
			diamond,
			diamondMinimumPoints,
			special
		} = bonuses;

		const newProducts = products.reduce((acc: any, item: any) => {
			acc[item.key] = item.discount;
			return acc;
		}, {});

		const newRecruitmentBasic = { ...recruitmentBasic[0] };
		const newRecruitmentSuperior = { ...recruitmentSuperior[0] };
		delete newRecruitmentBasic.key;
		delete newRecruitmentSuperior.key;

		const newRecruitment = {
			basic: newRecruitmentBasic,
			superior: newRecruitmentSuperior
		};

		const newRepass = repass.reduce((acc: any, item: any) => {
			acc[item.key] = item.percentage;
			return acc;
		}, {});

		const newDepth = depth.map((item: any) => {
			return {
				level: item.level,
				value: item.value
			};
		});

		const diamondReduced = diamond.reduce((acc: any, item: any) => {
			acc[item.key] = item.points;
			return acc;
		}, {});

		const newDiamond = {
			...diamondReduced,
			minimumPoints: diamondMinimumPoints
		};

		const newSpecial = special.reduce((acc: any, item: any) => {
			acc[item.key] = item.gain;
			return acc;
		}, {});

		const bonusesToSend = {
			...bonuses,
			products: newProducts,
			recruitment: newRecruitment,
			repass: newRepass,
			performance,
			binary,
			depth: newDepth,
			diamond: newDiamond,
			special: newSpecial
		};

		dispatch(setLoader(true));

		try {
			const response = await API.put({
				url: Endpoints.uriSettings(),
				data: {
					bonuses: bonusesToSend
				}
			});

			if (response.ok) {
				this.setState({ hasUnsavedFields: false });

				notification.success({
					message: Strings.generic.success,
					description: Strings.bonuses.bonusesNotificationMessageSuccess,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.generic.error,
					description: Strings.bonuses.bonusesNotificationMessageError,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		} finally {
			dispatch(setLoader(false));
		}
	};

	keyToString = (key: any) => {
		switch (key) {
			case "client":
				return Strings.bonuses.keyClient;
			case "junior":
				return Strings.bonuses.keyJunior;
			case "executive":
				return Strings.bonuses.keyExecutive;
			case "specialExecutive":
				return Strings.bonuses.keySpecialExecutive;
			case "director":
				return Strings.bonuses.keyDirector;
			case "cycles":
				return Strings.bonuses.keyCycles;
			case "trimester":
				return Strings.bonuses.keyTrimester;
			case "underDiamond2":
				return Strings.bonuses.keyUnderDiamond2;
			case "diamond4":
				return Strings.users.badges.diamond4;
			case "diamond6":
				return Strings.users.badges.diamond6;
			case "diamond8":
				return Strings.users.badges.diamond8;
			case "special":
				return Strings.users.badges.specialDiamond;
			default:
				return key;
		}
	};

	renderRangeBonus4 = () => {
		const { bonuses } = this.state;
		const { performance } = bonuses;

		return performance?.map((interval: any, index: any) => {
			return (
				<React.Fragment key={index}>
					<Col xs={24} md={14}>
						<Row gutter={5} justify="start">
							<Col xs={11}>
								<Input
									type="number"
									value={interval.average}
									placeholder="0"
									addonAfter="pts"
									min={index === 0 ? 0 : performance[index - 1].average + 1}
									className="bonus-input-range"
									onChange={(val: any) => {
										if (isNaN(val.target.value)) return;

										const number = Number(val.target.value);

										interval.average = Number(number);
										this.setState({ bonuses: { ...bonuses, performance }, hasUnsavedFields: true });
									}}
									onBlur={() => {
										if (performance[index - 1] && interval.average < performance[index - 1].average) {
											interval.average = performance[index - 1].average + 1;
											this.setState({ bonuses: { ...bonuses, performance }, hasUnsavedFields: true });
										}
									}}
								/>
							</Col>
							<Col xs={2}>
								<div className="bonus-input-separator">
									<span>ou</span>
								</div>
							</Col>
							<Col xs={11}>
								<Input
									type="number"
									value={interval.annual}
									placeholder="0"
									addonAfter="pts"
									min={index === 0 ? 0 : performance[index - 1].annual + 1}
									className="bonus-input-range"
									onChange={(val: any) => {
										if (isNaN(val.target.value)) return;

										const number = Number(val.target.value);

										interval.annual = Number(number);
										this.setState({ bonuses: { ...bonuses, performance }, hasUnsavedFields: true });
									}}
									onBlur={() => {
										if (performance[index - 1] && interval.annual < performance[index - 1].annual) {
											interval.annual = performance[index - 1].annual + 1;
											this.setState({ bonuses: { ...bonuses, performance }, hasUnsavedFields: true });
										}
									}}
								/>
							</Col>
						</Row>
					</Col>
					<Col xs={24} md={10}>
						<div className="bonus-range-wrapper">
							<Input
								type="number"
								value={interval.bonus}
								placeholder="0"
								addonAfter="€"
								min={index === 0 ? 0 : performance[index - 1].bonus + 1}
								className="bonus-input-range"
								onChange={(val: any) => {
									if (isNaN(val.target.value)) return;

									const number = Number(val.target.value);

									interval.bonus = Number(number);
									this.setState({ bonuses: { ...bonuses, performance }, hasUnsavedFields: true });
								}}
								onBlur={() => {
									if (performance[index - 1] && interval.bonus < performance[index - 1].bonus) {
										interval.bonus = performance[index - 1].bonus + 1;
										this.setState({ bonuses: { ...bonuses, performance }, hasUnsavedFields: true });
									}
								}}
							/>
							{performance.length === index + 1 && (
								<button
									className="bonus-remove-button"
									onClick={() => {
										performance.splice(index, 1);
										this.setState({ bonuses: { ...bonuses, performance }, hasUnsavedFields: true });
									}}
									disabled={!performance.length === index + 1}
								>
									<Icon name="trash" />
								</button>
							)}
						</div>
					</Col>
				</React.Fragment>
			);
		});
	};

	renderIncentives = () => {
		const { bonuses } = this.state;
		const { incentive } = bonuses;

		return incentive?.incentives?.map((item: any, index: number) => {
			if (item.type === "money") {
				return (
					<Col xs={24} key={item._id}>
						<Row gutter={[20, 10]}>
							<Col xs={24}>
								<Row gutter={20}>
									<Col xs={24} md={12}>
										<label className="InputLabel" htmlFor={`bonus_type${item._id}`}>
											{strings.settings.type}
										</label>
										<Select
											id={`bonus_type${item._id}`}
											value={item.type}
											onChange={(elem: any) => {
												item.type = elem;
												item.average = 0;
												item.annual = 0;
												item.prize = "";
												item.value = 0;
												item.amount = 0;

												this.setState({ bonuses: { ...bonuses, incentive }, hasUnsavedFields: true });
											}}
											style={{ width: "100%" }}
										>
											<Select.Option value="money">{Strings.bonuses.bonusExtraIncentiveMoney}</Select.Option>
											<Select.Option value="prize">{Strings.bonuses.bonusExtraIncentivePrize}</Select.Option>
										</Select>
									</Col>
								</Row>
							</Col>
							<Col xs={12}>
								<label className="InputLabel" htmlFor={`value_${item._id}`}>
									{Strings.bonuses.bonusExtraIncentiveHeader1}
								</label>
								<Input
									id={`value_${item._id}`}
									type="number"
									value={item.value}
									placeholder="0"
									addonAfter="pts"
									min={0}
									className="bonus-input-range"
									onChange={(val: any) => {
										if (val.target.value === "") {
											item.value = null;
											this.setState({ bonuses: { ...bonuses, incentive }, hasUnsavedFields: true });

											return;
										}

										if (isNaN(val.target.value)) return;

										const number = Number(val.target.value);

										item.value = Number(number);
										this.setState({ bonuses: { ...bonuses, incentive }, hasUnsavedFields: true });
									}}
									onBlur={() => {
										this.setState({ bonuses: { ...bonuses, incentive }, hasUnsavedFields: true });
									}}
								/>
							</Col>
							<Col xs={12}>
								<div style={{ display: "flex", gap: "1rem", alignItems: "flex-end" }}>
									<div className="bonus-incentive-wrapper">
										<label className="InputLabel" htmlFor={`amount_${item._id}`}>
											{Strings.bonuses.bonusExtraIncentiveHeader2}
										</label>
										<Input
											id={`amount_${item._id}`}
											type="number"
											value={item.amount}
											placeholder="0"
											addonAfter="€"
											min={0}
											className="bonus-input-range"
											onChange={(val: any) => {
												if (val.target.value === "") {
													item.amount = null;
													this.setState({ bonuses: { ...bonuses, incentive }, hasUnsavedFields: true });

													return;
												}

												if (val.target.value === "") {
													item.value = null;
													this.setState({ bonuses: { ...bonuses, incentive }, hasUnsavedFields: true });

													return;
												}

												if (isNaN(val.target.value)) return;

												const number = Number(val.target.value);

												item.amount = Number(number);
												this.setState((prevState: any) => ({
													bonuses: {
														...bonuses,
														incentive: { ...prevState.bonuses.incentive, incentives: incentive.incentives }
													},
													hasUnsavedFields: true
												}));
											}}
											onBlur={() => {
												this.setState({ bonuses: { ...bonuses, incentive }, hasUnsavedFields: true });
											}}
										/>
									</div>
									<button
										className="bonus-remove-button"
										onClick={() => {
											incentive.incentives.splice(index, 1);
											this.setState((prevState: any) => ({
												bonuses: {
													...bonuses,
													incentive: { ...prevState.bonuses.incentive, incentives: incentive.incentives }
												},
												hasUnsavedFields: true
											}));
										}}
									>
										<Icon name="trash" />
									</button>
								</div>
							</Col>
						</Row>
					</Col>
				);
			} else {
				return (
					<Col xs={24} key={item._id}>
						<Row gutter={[20, 10]}>
							<Col xs={24}>
								<Row>
									<Col xs={24}>
										<label className="InputLabel" htmlFor={`bonus_type${item._id}`}>
											{strings.settings.type}
										</label>
									</Col>
									<Col xs={24} md={12}>
										<Select
											id={`bonus_type${item._id}`}
											value={item.type}
											onChange={(elem: any) => {
												item.type = elem;
												item.average = 0;
												item.annual = 0;
												item.prize = "";
												item.value = 0;
												item.amount = 0;

												this.setState({ bonuses: { ...bonuses, incentive }, hasUnsavedFields: true });
											}}
											style={{ width: "100%" }}
										>
											<Select.Option value="money">{Strings.bonuses.bonusExtraIncentiveMoney}</Select.Option>
											<Select.Option value="prize">{Strings.bonuses.bonusExtraIncentivePrize}</Select.Option>
										</Select>
									</Col>
								</Row>
							</Col>

							<Col xs={24}>
								<Row gutter={[20, 10]}>
									<Col xs={24} md={14}>
										<Row>
											<Col xs={11}>
												<b>{Strings.bonuses.bonusTripGroupHeader1}</b>
											</Col>
											<Col xs={2}></Col>
											<Col xs={11}>
												<b>{Strings.bonuses.bonusTripGroupHeader2}</b>
											</Col>
										</Row>
									</Col>
									<Col xs={24} md={10}>
										<label>
											<b>{Strings.bonuses.bonusTripGroupHeader3}</b>
										</label>
									</Col>
									<Col xs={24} md={14}>
										<Row gutter={5} justify="start">
											<Col xs={11}>
												<Input
													type="number"
													value={item.average}
													placeholder="0"
													addonAfter="pts"
													min={0}
													className="bonus-input-range"
													onChange={(val: any) => {
														if (val.target.value === "") {
															item.average = null;
															this.setState({
																bonuses: { ...bonuses, incentive },
																hasUnsavedFields: true
															});

															return;
														}

														if (isNaN(val.target.value)) return;

														const number = Number(val.target.value);

														item.average = Number(number);
														this.setState((prevState: any) => ({
															bonuses: {
																...bonuses,
																incentive: {
																	...prevState.bonuses.incentive,
																	incentives: incentive.incentives
																}
															},
															hasUnsavedFields: true
														}));
													}}
													onBlur={() => {
														this.setState((prevState: any) => ({
															bonuses: {
																...bonuses,
																incentive: {
																	...prevState.bonuses.incentive,
																	incentives: incentive.incentives
																}
															},
															hasUnsavedFields: true
														}));
													}}
												/>
											</Col>
											<Col xs={2}>
												<div className="bonus-input-separator">
													<span>ou</span>
												</div>
											</Col>
											<Col xs={11}>
												<Input
													type="number"
													value={item.annual}
													placeholder="0"
													addonAfter="pts"
													min={0}
													className="bonus-input-range"
													onChange={(val: any) => {
														if (val.target.value === "") {
															item.annual = null;
															this.setState({
																bonuses: { ...bonuses, incentive },
																hasUnsavedFields: true
															});

															return;
														}

														if (isNaN(val.target.value)) return;

														const number = Number(val.target.value);

														item.annual = Number(number);
														this.setState((prevState: any) => ({
															bonuses: {
																...bonuses,
																incentive: {
																	...prevState.bonuses.incentive,
																	incentives: incentive.incentives
																}
															},
															hasUnsavedFields: true
														}));
													}}
													onBlur={() => {
														this.setState((prevState: any) => ({
															bonuses: {
																...bonuses,
																incentive: {
																	...prevState.bonuses.incentive,
																	incentives: incentive.incentives
																}
															},
															hasUnsavedFields: true
														}));
													}}
												/>
											</Col>
										</Row>
									</Col>
									<Col xs={24} md={10}>
										<div className="bonus-range-wrapper">
											<Input.TextArea
												rows={3}
												autoSize={{ maxRows: 3 }}
												value={item.prize}
												onChange={(e) =>
													this.setState((prevState: any) => {
														item.prize = e.target.value;

														return {
															bonuses: {
																...prevState.bonuses,
																incentive: {
																	...prevState.bonuses.incentive,
																	incentives: incentive.incentives
																}
															},
															hasUnsavedFields: true
														};
													})
												}
											/>
											<button
												className="bonus-remove-button"
												onClick={() => {
													incentive.incentives.splice(index, 1);
													this.setState((prevState: any) => ({
														bonuses: {
															...bonuses,
															incentive: {
																...prevState.bonuses.incentive,
																incentives: incentive.incentives
															}
														},
														hasUnsavedFields: true
													}));
												}}
											>
												<Icon name="trash" />
											</button>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				);
			}
		});
	};

	renderBonus1 = () => {
		const { bonuses } = this.state;
		const { products } = bonuses;

		return (
			<Col xs={24} md={12} style={{ height: "unset" }}>
				<ContentWrapper extraClass="bonus">
					<div className="bonus-title">{Strings.bonuses.bonusProductsTitle}</div>

					<Table
						className="table"
						dataSource={products}
						columns={[
							{
								title: Strings.bonuses.bonusProductsHeader1,
								dataIndex: "position",
								key: "position"
							},
							{
								title: Strings.bonuses.bonusProductsHeader2,
								dataIndex: "discount",
								key: "discount",
								render: (text: any, _, idx: number) => (
									<div className="table-input-wrapper">
										<Input
											addonAfter="%"
											className="table-input"
											value={products[idx].discount}
											onChange={(val: any) => {
												if (isNaN(val.target.value)) return;

												const number = Number(val.target.value);

												if (number > 100) {
													products[idx].discount = 100;
												} else if (number < 0) {
													products[idx].discount = 0;
												} else {
													products[idx].discount = Number(val.target.value);
												}

												this.setState((prevState: any) => ({
													bonuses: { ...prevState.bonuses, products },
													hasUnsavedFields: true
												}));
											}}
										/>
									</div>
								),
								align: "right"
							}
						]}
						pagination={false}
					/>
				</ContentWrapper>
			</Col>
		);
	};

	renderBonus2 = () => {
		const { bonuses } = this.state;
		const { recruitmentBasic, recruitmentSuperior } = bonuses;

		return (
			<Col xs={24} md={12} style={{ height: "unset" }}>
				<ContentWrapper extraClass="bonus">
					<div className="bonus-title">{Strings.bonuses.bonusRecruitmentTitle}</div>

					<div>
						<b>{Strings.bonuses.bonusRecruitmentBasic}</b>
					</div>
					<Table
						className="table"
						dataSource={recruitmentBasic}
						columns={[
							{
								title: Strings.bonuses.bonusRecruitmentHeader1,
								dataIndex: "value",
								key: "value",
								render: (text: any, _, idx: number) => (
									<Input
										addonAfter="€"
										className="table-input"
										value={recruitmentBasic[idx].value}
										onChange={(val: any) => {
											if (isNaN(val.target.value)) return;

											recruitmentBasic[idx].value = Number(val.target.value);
											this.setState((prevState: any) => ({
												bonuses: { ...prevState.bonuses, recruitmentBasic },
												hasUnsavedFields: true
											}));
										}}
									/>
								)
							},
							{
								title: Strings.bonuses.bonusRecruitmentHeader2,
								dataIndex: "points",
								key: "points",
								render: (text: any, _, idx: number) => (
									<div className="table-input-wrapper">
										<Input
											addonAfter="pts"
											className="table-input"
											value={recruitmentBasic[idx].points}
											onChange={(val: any) => {
												if (isNaN(val.target.value)) return;

												recruitmentBasic[idx].points = Number(val.target.value);
												this.setState((prevState: any) => ({
													bonuses: { ...prevState.bonuses, recruitmentBasic },
													hasUnsavedFields: true
												}));
											}}
										/>
									</div>
								),
								align: "right"
							}
						]}
						pagination={false}
					/>

					<div>
						<b>{Strings.bonuses.bonusRecruitmentSuperior}</b>
					</div>
					<Table
						className="table"
						dataSource={recruitmentSuperior}
						columns={[
							{
								title: Strings.bonuses.bonusRecruitmentHeader1,
								dataIndex: "value",
								key: "value",
								render: (text: any, _, idx: number) => (
									<Input
										addonAfter="€"
										className="table-input"
										defaultValue={text}
										onChange={(val: any) => {
											if (isNaN(val.target.value)) return;

											recruitmentSuperior[idx].value = Number(val.target.value);
											this.setState((prevState: any) => ({
												bonuses: { ...prevState.bonuses, recruitmentSuperior },
												hasUnsavedFields: true
											}));
										}}
									/>
								)
							},
							{
								title: Strings.bonuses.bonusRecruitmentHeader2,
								dataIndex: "points",
								key: "points",
								render: (text: any, _, idx: number) => (
									<div className="table-input-wrapper">
										<Input
											addonAfter="pts"
											className="table-input"
											defaultValue={text}
											onChange={(val: any) => {
												if (isNaN(val.target.value)) return;

												recruitmentSuperior[idx].points = Number(val.target.value);
												this.setState((prevState: any) => ({
													bonuses: { ...prevState.bonuses, recruitmentSuperior },
													hasUnsavedFields: true
												}));
											}}
										/>
									</div>
								),
								align: "right"
							}
						]}
						pagination={false}
					/>
				</ContentWrapper>
			</Col>
		);
	};

	renderBonus3 = () => {
		const { bonuses } = this.state;
		const { repass } = bonuses;

		return (
			<Col xs={24} md={12} style={{ height: "unset" }}>
				<ContentWrapper extraClass="bonus">
					<div className="bonus-title">{Strings.bonuses.bonusRepassTitle}</div>

					<Table
						className="table"
						dataSource={repass}
						columns={[
							{
								title: Strings.bonuses.bonusRepassHeader1,
								dataIndex: "position",
								key: "position"
							},
							{
								title: Strings.bonuses.bonusRepassHeader2,
								dataIndex: "percentage",
								key: "percentage",
								render: (text: any, _, idx: number) => (
									<div className="table-input-wrapper">
										<Input
											addonAfter="%"
											className="table-input"
											value={repass[idx].percentage}
											onChange={(val: any) => {
												if (isNaN(val.target.value)) return;

												const number = Number(val.target.value);

												if (number > 100) {
													repass[idx].percentage = 100;
												} else if (number < 0) {
													repass[idx].percentage = 0;
												} else {
													repass[idx].percentage = number;
												}
												this.setState((prevState: any) => ({
													bonuses: { ...prevState.bonuses, repass },
													hasUnsavedFields: true
												}));
											}}
										/>
									</div>
								),
								align: "right"
							}
						]}
						pagination={false}
					/>
				</ContentWrapper>
			</Col>
		);
	};

	renderBonus4 = () => {
		const { bonuses } = this.state;
		const { performance } = bonuses;

		let buttonDisabled = false;
		let last: any = {};

		if (performance && performance.length > 0) {
			last = performance[performance.length - 1];
			const lastFilled =
				(last?.average !== void 0 &&
					last?.average >= 0 &&
					last?.annual !== void 0 &&
					last?.annual >= 0 &&
					last?.bonus !== void 0 &&
					last?.bonus >= 0) ||
				false;

			buttonDisabled = !lastFilled;
		} else {
			buttonDisabled = false;
		}

		return (
			<Col xs={24} md={12} style={{ height: "unset" }}>
				<ContentWrapper extraClass="bonus">
					<div className="bonus-title">{Strings.bonuses.bonusPerformanceTitle}</div>

					<Row gutter={[20, 10]}>
						<Col xs={24} md={14}>
							<Row>
								<Col xs={11}>
									<b>{Strings.bonuses.bonusPerformanceHeader1}</b>
								</Col>
								<Col xs={2}></Col>
								<Col xs={11}>
									<b>{Strings.bonuses.bonusPerformanceHeader2}</b>
								</Col>
							</Row>
						</Col>
						<Col xs={24} md={10}>
							<label>
								<b>{Strings.bonuses.bonusPerformanceHeader3}</b>
							</label>
						</Col>
						{this.renderRangeBonus4()}
						<Col xs={24}>
							<div className="button-add-range">
								<button
									onClick={() => {
										performance.push({
											average: last?.average + 1,
											annual: last?.annual + 1,
											bonus: last?.bonus + 1
										});

										this.setState({ bonuses: { ...bonuses, performance }, hasUnsavedFields: true });
									}}
									disabled={buttonDisabled}
								>
									{Strings.bonuses.bonusPerformanceButton}
								</button>
							</div>
						</Col>
					</Row>
				</ContentWrapper>
			</Col>
		);
	};

	renderBonus5 = () => {
		const { bonuses } = this.state;
		const { binary } = bonuses;

		return (
			<Col xs={24} md={12} style={{ height: "unset" }}>
				<ContentWrapper extraClass="bonus">
					<div className="bonus-title">5º Bónus de Produção do Binário</div>

					<div className="bonus-description">
						<p style={{ lineHeight: "2.5" }}>
							{Strings.bonuses.bonusBinaryString1}{" "}
							<span style={{ display: "inline-block" }}>
								<Input
									value={binary.right}
									className="bonus-input"
									addonAfter="pts"
									onChange={(val: any) => {
										if (isNaN(val.target.value)) return;

										if (val.target.value < 0) return;

										binary.right = Number(val.target.value);
										this.setState((prevState: any) => ({
											bonuses: { ...prevState.bonuses, binary },
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>{" "}
							{Strings.bonuses.bonusBinaryString2}{" "}
							<span style={{ display: "inline-block" }}>
								<Input
									value={binary.left}
									className="bonus-input"
									addonAfter="pts"
									onChange={(val: any) => {
										if (isNaN(val.target.value)) return;

										if (val.target.value < 0) return;

										binary.left = Number(val.target.value);
										this.setState((prevState: any) => ({
											bonuses: { ...prevState.bonuses, binary },
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>{" "}
							{Strings.bonuses.bonusBinaryString3}{" "}
							<span style={{ display: "inline-block" }}>
								<Input
									value={binary.value}
									className="bonus-input"
									addonAfter="€"
									onChange={(val: any) => {
										if (isNaN(val.target.value)) return;

										if (val.target.value < 0) return;

										binary.value = Number(val.target.value);
										this.setState((prevState: any) => ({
											bonuses: { ...prevState.bonuses, binary },
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>{" "}
							{Strings.bonuses.bonusBinaryString4}
						</p>
					</div>
				</ContentWrapper>
			</Col>
		);
	};

	renderBonus6 = () => {
		const { bonuses } = this.state;
		const { depth } = bonuses;

		return (
			<Col xs={24} md={12} style={{ height: "unset" }}>
				<ContentWrapper extraClass="bonus">
					<div className="bonus-title">{Strings.bonuses.bonusDepthTitle}</div>
					<Table
						className="table"
						dataSource={[...this.state.bonuses.depth]}
						columns={[
							{
								title: Strings.bonuses.bonusDepthHeader1,
								dataIndex: "level",
								key: "level",
								render: (text: any) => (
									<span>
										{Strings.bonuses.level} {text}
									</span>
								)
							},
							{
								title: Strings.bonuses.bonusDepthHeader2,
								dataIndex: "value",
								key: "value",
								render: (text: any, _, idx: number) => (
									<div style={{}} className="table-input-wrapper">
										<Input
											addonAfter="%"
											className="table-input"
											value={depth[idx].value}
											style={{ flex: 1 }}
											onChange={(val: any) => {
												if (isNaN(val.target.value)) return;

												const number = Number(val.target.value);

												if (number > 100) {
													depth[idx].value = 100;
												} else if (number < 0) {
													depth[idx].value = 0;
												} else {
													depth[idx].value = number;
												}
												this.setState((prevState: any) => ({
													bonuses: { ...prevState.bonuses, depth },
													hasUnsavedFields: true
												}));
											}}
										/>
										{/* {depth.length === idx + 1 && (
											<button
												className="bonus-remove-button"
												onClick={() => {
													depth.splice(idx, 1);
													this.setState((prevState: any) => ({
														bonuses: { ...prevState.bonuses, depth },
														hasUnsavedFields: true
													}));
												}}
											>
												<Icon name="trash" />
											</button>
										)} */}
									</div>
								),
								align: "right",
								width: 150
							}
						]}
						pagination={false}
					/>
					{/* <div className="button-add-range">
						<button
							onClick={() => {
								depth.push({
									key: (depth[depth.length - 1]?.key || 0) + 1,
									level: (depth[depth.length - 1]?.level || 0) + 1,
									value: (depth[depth.length - 1]?.value || 0) + 1
								});

								this.setState((prevState: any) => ({ bonuses: { ...prevState.bonuses, depth }, hasUnsavedFields: true }));
							}}
						>
							{Strings.bonuses.bonusDepthButton}
						</button>
					</div> */}
				</ContentWrapper>
			</Col>
		);
	};

	renderBonus7 = () => {
		const { bonuses } = this.state;
		const { diamond, diamondMinimumPoints } = bonuses;

		return (
			<Col xs={24} md={12} style={{ height: "unset" }}>
				<ContentWrapper extraClass="bonus">
					<div className="bonus-title">{Strings.bonuses.bonusDiamondTitle}</div>
					<Row>
						<Col xs={24} md={12}>
							<div style={{ marginBottom: "2rem" }}>
								<label htmlFor="diamond_min_points">{Strings.bonuses.bonusDiamondMinimumPoints}</label>
								<Input
									id="diamond_min_points"
									value={diamondMinimumPoints}
									className="bonus-input"
									addonAfter="pts"
									onChange={(val: any) => {
										if (isNaN(val.target.value)) return;

										if (val.target.value < 0) return;

										const number = Number(val.target.value);
										this.setState((prevState: any) => ({
											bonuses: { ...prevState.bonuses, diamondMinimumPoints: number },
											hasUnsavedFields: true
										}));
									}}
								/>
							</div>
						</Col>
					</Row>
					<Table
						className="table"
						dataSource={diamond}
						columns={[
							{
								title: Strings.bonuses.bonusDiamondHeader1,
								dataIndex: "qualification",
								key: "qualification",
								render: (text: any) => <p>{this.keyToString(text)}</p>
							},
							{
								title: Strings.bonuses.bonusDiamondHeader2,
								dataIndex: "points",
								key: "points",
								render: (text: any, _, idx: number) => (
									<div className="table-input-wrapper">
										<Input
											addonAfter="pts"
											className="table-input"
											value={diamond[idx].points}
											style={{ width: "110px" }}
											onChange={(val: any) => {
												if (isNaN(val.target.value)) return;

												if (val.target.value < 0) return;

												diamond[idx].points = Number(val.target.value);
												this.setState((prevState: any) => ({
													bonuses: { ...prevState.bonuses, diamond },
													hasUnsavedFields: true
												}));
											}}
										/>
									</div>
								),
								align: "right"
							}
						]}
						pagination={false}
					/>
				</ContentWrapper>
			</Col>
		);
	};

	renderBonus8 = () => {
		const { bonuses } = this.state;
		const { special } = bonuses;

		return (
			<Col xs={24} md={12} style={{ height: "unset" }}>
				<ContentWrapper extraClass="bonus">
					<div className="bonus-title">{Strings.bonuses.bonusSpecialTitle}</div>

					<Table
						className="table"
						dataSource={special}
						columns={[
							{
								title: Strings.bonuses.bonusSpecialHeader1,
								dataIndex: "qualification",
								key: "qualification",
								render: (text: any) => <p>{this.keyToString(text)}</p>
							},
							{
								title: Strings.bonuses.bonusSpecialHeader2,
								dataIndex: "gain",
								key: "gain",
								render: (text: any, _, idx: number) => (
									<div className="table-input-wrapper">
										<Input
											addonAfter="€"
											style={{ width: "110px" }}
											value={text}
											onChange={(val: any) => {
												if (isNaN(val.target.value)) return;

												if (val.target.value < 0) return;

												special[idx].gain = Number(val.target.value);
												this.setState((prevState: any) => ({
													bonuses: { ...prevState.bonuses, special },
													hasUnsavedFields: true
												}));
											}}
										/>
									</div>
								),
								align: "right"
							}
						]}
						pagination={false}
					/>
				</ContentWrapper>
			</Col>
		);
	};

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.bonuses}</title>
					<meta name="description" content="Description of Bonuses" />
				</Helmet>
				<Row gutter={[20, 20]} className="bonus-wrapper">
					{this.renderBonus1()}
					{this.renderBonus2()}
					{this.renderBonus3()}
					{this.renderBonus4()}
					{this.renderBonus5()}
					{this.renderBonus6()}
					{this.renderBonus7()}
					{this.renderBonus8()}
				</Row>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(Bonuses);
