import React, { Component } from "react";
import { Col, Row, notification, Table } from "antd";
import { Area } from "@ant-design/charts";
import { push } from "connected-react-router";
import { ContentWrapper, Icon } from "components";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import strings from "utils/strings";
import { formatPrice } from "utils/utils";
import moment from "moment";
import "./styles.scss";

class Wallet extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			data: [],
			startDate: moment().add({ months: -5 }).startOf("month"),
			endDate: moment().endOf("month")
		};
	}

	async componentDidMount(): Promise<void> {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		await this.getData();
		this.breadcrumb();
	}

	componentDidUpdate(): void {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { startDate, endDate } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		const body = {
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString()
		};

		try {
			const response = await API.post({
				url: Endpoints.uriCommissions("wallet/search"),
				data: body
			});

			if (response.ok) {
				const { movements = [], cards, commissionsChart } = response.data.results;

				this.setState({ data: movements, cards, commissionsChart });
			} else {
				notification.error({
					message: strings.sidebar.wallet,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				const { startDate, endDate } = this.state;

				return {
					locations: [
						{
							text: strings.sidebar.wallet,
							route: "/partner-wallet",
							icon: "wallet"
						}
					],
					actions: [
						{
							type: "datePicker",
							text: strings.dashboard.period,
							dates: [startDate, endDate],
							monthly: true,
							onChange: this.handleSelect,
							className: "fixedPicker"
						}
					]
				};
			})
		);
	}

	handleSelect = (dates: any) => {
		const newDates = [];
		if (dates && dates[0]) {
			newDates.push(moment(dates[0]).startOf("day"));
		}

		if (dates && dates[1]) {
			newDates.push(moment(dates[1]).endOf("day"));
		}

		this.setState(
			{
				startDate: newDates[0],
				endDate: newDates[1]
			},
			() => this.getData()
		);
	};

	getType(movement: any) {
		const { dispatch } = this.props;

		switch (movement.movementType) {
			case "spentCommission":
				return (
					<span>
						<span>{strings.wallet.balanceUsed}</span>
						&nbsp;
						<a
							href={`/partner-orders/${movement.order?._id}`}
							onClick={(e) => {
								e.preventDefault();
								dispatch(push(`/partner-orders/${movement.order?._id}`));
							}}
						>
							#{movement.order?.orderNumber}
						</a>
					</span>
				);
			case "expiredCommission":
				return <span>{strings.wallet.expiredCommission}</span>;
			case "adjustment":
				return <span>{strings.wallet.adjustment}</span>;
			case "redeem":
				if (movement.order) {
					return (
						<span>
							{strings.formatString(
								strings.wallet.redeemFrom,
								movement.type === "sales"
									? (strings.orders.sales as string)?.toLowerCase()
									: (strings.generic.bonuses as string)?.toLowerCase()
							)}
							&nbsp;
							<span>
								({movement.notes} -{" "}
								<a
									href={`/orders/${movement.order?._id}`}
									onClick={(e) => {
										e.preventDefault();
										dispatch(push(`/partner-orders/${movement.order?._id}`));
									}}
								>
									#{movement.order?.orderNumber}
								</a>
								)
							</span>
						</span>
					);
				}

				return (
					<span>
						{strings.formatString(
							strings.wallet.redeemFrom,
							movement.type === "sales"
								? (strings.orders.sales as string)?.toLowerCase()
								: (strings.generic.bonuses as string)?.toLowerCase()
						)}
						{movement.notes ? ` (${movement.notes})` : ""}
					</span>
				);
			default:
				return movement.movementType;
		}
	}

	renderNumber(value: number, isExpired?: boolean) {
		if (value > 0) {
			return <span className="WalletValueGreen">{formatPrice(value)}</span>;
		} else if (value < 0) {
			return <span className="WalletValueRed">{isExpired ? formatPrice(Math.abs(value)) : formatPrice(value)}</span>;
		}

		return <span>{formatPrice(value)}</span>;
	}

	renderMovements() {
		const { data = [] } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader --multi">
					<div className="ScreenHeaderLeft">
						<Icon name="diagram" />
						<h2>{strings.wallet.movements}</h2>
					</div>
					<div className="ScreenHeaderRight">
						<p>
							{strings.formatString(
								strings.wallet.earnedPeriod,
								formatPrice(
									data.reduce(
										(acc: number, entry: any) => acc + (entry.movementType === "redeem" ? entry.value || 0 : 0),
										0
									)
								)
							)}
						</p>
					</div>
				</div>
				<Table
					style={{ marginTop: 20 }}
					columns={[
						{
							title: strings.fields.date,
							dataIndex: "_created",
							key: "_created",
							render: (_created: string) => DateTime.fromISO(_created).toFormat("dd/MM/yyyy HH:mm"),
							defaultSortOrder: "descend",
							sorter: (a, b) => DateTime.fromISO(a._created).toMillis() - DateTime.fromISO(b._created).toMillis(),
							width: 150
						},
						{
							title: strings.fields.description,
							dataIndex: "description",
							key: "description",
							render: (description: string, record: any) => this.getType(record)
						},
						{
							title: strings.settings.value,
							dataIndex: "value",
							key: "value",
							render: (value: number, record: any) => {
								let val =
									record.movementType === "expiredCommission" || record.movementType === "spentCommission"
										? -record.balance
										: value;

								if (record.movementType === "adjustment") {
									val = record.balance;
								}

								return this.renderNumber(val, record.movementType === "expiredCommission");
							},
							align: "right",
							sorter: (a, b) => a.value - b.value,
							width: 150
						}
					]}
					dataSource={data}
				/>
			</ContentWrapper>
		);
	}

	renderChart() {
		const { commissionsChart = [] } = this.state;

		const config = {
			data: commissionsChart,
			xField: "month",
			yField: "total",
			appendPadding: 10,
			smooth: true,
			animation: {
				appear: {
					animation: "path-in",
					duration: 2500
				}
			},
			meta: {
				total: {
					alias: strings.wallet.totalBilled
				}
			},
			tooltip: {
				formatter: (datum: any) => {
					return { name: strings.wallet.totalBilled, value: formatPrice(datum.total) };
				}
			}
		};

		return (
			<ContentWrapper extraStyle={{ marginBottom: 0 }}>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="diagram" />
						<h2>{strings.wallet.monthlyControl}</h2>
					</div>
				</div>
				<Area style={{ marginTop: 20 }} {...config} />
			</ContentWrapper>
		);
	}

	render() {
		const { cards } = this.state;
		const { total = 0, available = 0, spent = 0, expired = 0 } = cards || {};

		const CARDS = [
			{
				icon: "wallet",
				value: total,
				description: strings.wallet.earned,
				type: "currency"
			},
			{
				icon: "wallet",
				value: available,
				description: strings.wallet.available,
				type: "currency"
			},
			{
				icon: "wallet",
				value: spent,
				description: strings.wallet.spent,
				type: "currency"
			},
			{
				icon: "wallet",
				value: expired,
				description: strings.wallet.expired,
				type: "currency"
			}
		];

		return (
			<div className="PartnersWallet">
				<Helmet>
					<title>{strings.sidebar.wallet}</title>
					<meta name="description" content="Description of Wallet" />
				</Helmet>
				<div className="IconCards">
					<Row gutter={[20, 20]}>
						{CARDS.map((card, index) => (
							<Col xs={24} md={6} key={index}>
								<div className="IconCardOuterContainer">
									<div className="IconCard">
										<div className="IconCardHeader">
											<div className="IconCardHeaderMask" />
											<div className="IconCardType">
												<Icon name={card.icon} />
											</div>
										</div>
										<div className="IconCardBody">
											<span className="IconCardValue">
												{card.type === "currency" ? formatPrice(card.value) : card.value}
											</span>
											<h3 className="IconCardDescription">{card.description}</h3>
										</div>
									</div>
								</div>
							</Col>
						))}
						<Col xs={24}>{this.renderChart()}</Col>
						<Col xs={24}>{this.renderMovements()}</Col>
					</Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(Wallet);
