import { Table } from "components";
import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Strings from "utils/strings";
import { push } from "connected-react-router";
import { setBreadcrumb, setLoader } from "store/actions";
import { notification } from "antd";
import { API, Endpoints } from "utils/api";
import { DateTime } from "luxon";

class Crm extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			crm: []
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setBreadcrumb(null));

		this.getData();
	}

	async deleteCrm(value: any) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriCrm(value)
			});

			if (response.ok) {
				this.setState({ crm: response.data.results.crm || [] });

				notification.success({
					message: Strings.sidebar.crm,
					description: Strings.crm.deleted,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.sidebar.crm,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriCrm()
			});

			if (response?.ok) {
				this.setState({ crm: response.data.results.crms || [] });
			} else {
				notification.error({
					message: Strings.sidebar.crm,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	renderStatus = (status: boolean) => {
		if (status) {
			return (
				<div className="TableTag --tag-success">
					<span>{Strings.generic.closed}</span>
				</div>
			);
		}

		return (
			<div className="TableTag --tag-warning">
				<span>{Strings.generic.opened}</span>
			</div>
		);
	};

	render() {
		const { crm } = this.state;
		const { dispatch, partners } = this.props;

		const columns = [
			{
				Header: Strings.fields.date,
				id: "_created",
				accessor: (row: any) => DateTime.fromISO(row._created).toFormat("dd/MM/yyyy HH:mm")
			},
			{
				Header: Strings.fields.name,
				id: "name",
				accessor: (row: any) => row.name
			},
			{
				Header: Strings.fields.phone,
				id: "phone",
				accessor: (row: any) => row.phone
			},
			{
				Header: Strings.fields.email,
				id: "email",
				accessor: (row: any) => row.email
			},
			{
				Header: Strings.users.status,
				id: "closed",
				Cell: (row: any) => this.renderStatus(row.cell.row.original?.closed)
			}
		];

		if (!partners) {
			columns.splice(3, 0, {
				Header: Strings.fields.partner,
				id: "partner",
				accessor: (row: any) => row.partner
			});
		}

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.crm}</title>
					<meta name="description" content="Set crm" />
				</Helmet>
				<Table
					title={{
						icon: "support-tickets",
						title: Strings.sidebar.crm
					}}
					data={crm}
					columns={columns}
					isSinglePage
					fullPage
					add={{
						tooltip: Strings.crm.addCrm,
						onClick: () => dispatch(push("/crm/new"))
					}}
					actions={{
						edit: (obj: any) => ({
							onClick: () => dispatch(push(`/crm/${obj._id}`)),
							location: `/crm/${obj._id}`
						}),
						remove: (obj: any) => ({
							onClick: () => this.deleteCrm(obj._id)
						})
					}}
				/>
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state: any) => ({
	language: state.language
});
export default connect(mapStateToProps)(Crm);
