import { Button, Col, Drawer, Input, Row, Select, Switch, notification } from "antd";
import { Icon, Table } from "components";
import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader } from "store/actions";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import "./styles.scss";
import { LANGUAGES, translate } from "utils/utils";

class Taxes extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			taxes: [],
			tempTax: {
				name: {
					"pt": "",
					"en": "",
				},
				zones: [],
				value: null,
			},
			language: "pt",
		};
	}

	componentDidMount(): void {
		this.getData();

		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.settings,
							route: "/settings",
							icon: "preferences",
						},
						{
							text: Strings.settings.taxes,
							icon: "tax-settings",
						},
					],
				};
			})
		);
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const [response, responseZones] = await Promise.all([
				API.get({
					url: Endpoints.uriTaxes(),
				}),
				API.get({
					url: Endpoints.uriZones(),
				}),
			]);

			if (response?.ok && responseZones?.ok) {
				const { taxes = [] } = response?.data?.results || {};
				const { zones = [] } = responseZones?.data?.results || {};
				this.setState({ taxes, defaultZones: zones, ...taxes });
			} else {
				notification.error({
					message: Strings.settings.taxes,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (error: unknown) {
			console.error(error);
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async saveTax() {
		const { tempTax } = this.state;
		const { dispatch } = this.props;

		if (!this.isValidTax()) {
			return;
		}

		dispatch(setLoader(true));

		try {
			const request = tempTax?._id ? API.put : API.post;
			const response = await request({
				url: Endpoints.uriTaxes(tempTax?._id || ""),
				data: tempTax,
			});

			if (response?.ok) {
				this.setState({ showTaxDrawer: false });
				await this.getData();

				notification.success({
					message: Strings.settings.taxes,
					description: response?.data?.message || (tempTax?._id ? Strings.settings.taxEdited : Strings.settings.taxAdded),
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.settings.taxes,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (error: unknown) {
			console.error(error);
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async toggleTax(id: string) { }

	async toggleDefaultTax(id: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriTaxes(id),
				data: {
					default: true,
				},
			});

			if (response?.ok) {
				await this.getData();

				notification.success({
					message: Strings.settings.taxes,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.settings.taxes,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (error: unknown) {
			console.error(error);
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async deleteTax(id: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriTaxes(id),
			});

			if (response?.ok) {
				await this.getData();

				notification.success({
					message: Strings.settings.taxes,
					description: response?.data?.message || Strings.settings.taxDeleted,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.settings.taxes,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (error: unknown) {
			console.error(error);
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	isValidTax() {
		const { tempTax } = this.state;
		const { name, value, zones } = tempTax || {};

		if (!translate(name)) {
			notification.error({
				message: Strings.settings.taxes,
				description: Strings.settings.taxNameRequired,
				placement: "bottomRight",
				duration: 5,
			});
			return false;
		}

		if (value == null) {
			notification.error({
				message: Strings.settings.taxes,
				description: Strings.settings.taxValueRequired,
				placement: "bottomRight",
				duration: 5,
			});
			return false;
		}

		if (value < 0 || value > 100) {
			notification.error({
				message: Strings.settings.taxes,
				description: Strings.settings.taxValueInvalid,
				placement: "bottomRight",
				duration: 5,
			});
			return false;
		}

		for (let zone of zones) {
			if (!zone.zone || zone.value == null) {
				notification.error({
					message: Strings.settings.taxes,
					description: Strings.settings.taxZoneRequired,
					placement: "bottomRight",
					duration: 5,
				});
				return false;
			}
		}

		return true;
	}

	deleteTaxZone(index: number) {
		const { tempTax } = this.state;
		tempTax?.zones?.splice(index, 1);
		this.setState({ tempTax });
	}

	addTaxZone() {
		const { tempTax } = this.state;

		tempTax?.zones?.push({ zone: null, value: null });
		this.setState({ tempTax });
	}

	isLastPairValid() {
		const { tempTax } = this.state;
		const lastPair = tempTax?.zones?.[tempTax?.zones?.length - 1];
		return lastPair?.zone && lastPair?.value !== null;
	}

	renderModal() {
		const { tempTax, showTaxDrawer } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="tax-settings" />
						<p>{tempTax?._id ? Strings.settings.editTax : Strings.settings.addTax}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							onClick={() => this.saveTax()}
						>
							{Strings.generic.confirm}
						</button>
						<button type="button" className="SidebarFooterButton --button-cancel" onClick={() => this.setState({ showTaxDrawer: false, tempTax: null })}>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				closable={false}
				onClose={() => this.setState({ showTaxDrawer: false, tempTax: null })}
				visible={showTaxDrawer}
				width={mobile ? "100%" : 600}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	renderDrawerContent() {
		const { language, tempTax, defaultZones = [] } = this.state;

		const bottonIsAvailable = (tempTax?.zones?.length > 0 && tempTax?.zones?.length < defaultZones.length && this.isLastPairValid()) || tempTax?.zones?.length === 0;
		const copyZones = JSON.parse(JSON.stringify(defaultZones));

		return (
			<Row gutter={[20, 10]}>
				<Col xs={24}>
					<div className="DrawerLanguageWrapper">
						<Select
							style={{ minWidth: 50 }}
							value={language || null}
							onChange={(elem: any) => {
								this.setState({ language: elem });
							}}>
							{LANGUAGES.map((lang: any, index: number) => {
								return (
									<Select.Option key={`select_option_${lang}_${index}`} value={lang.value}>
										{lang.label}
									</Select.Option>
								);
							})}
						</Select>
					</div>
				</Col>
				<Col xs={24}>
					<label htmlFor="name" className="InputLabel --label-required">
						{Strings.fields.name}
					</label>
					<Input
						id="name"
						placeholder={Strings.fields.name}
						value={tempTax?.name?.[language] || ""}
						pattern="[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{7}([A-Z0-9]?){0,16}"
						onChange={(e) => {
							const value = e.target.value;
							this.setState((prevState: any) => ({ tempTax: { ...prevState.tempTax, name: { ...prevState.tempTax?.name, [language]: value } } }))
						}}
					/>
				</Col>
				<Col xs={24}>
					<div className="TaxesCenteredContainer">
						<p><strong>{Strings.settings.taxesTitle}</strong></p>
					</div>
				</Col>
				<Col xs={24}>
					<Row gutter={[20, 10]}>
						<Col xs={14}>
							<p><strong>{Strings.zones.zone}</strong></p>
						</Col>
						<Col xs={{ span: 8, offset: 2 }}>
							<p><strong>{Strings.fields.tax} %</strong></p>
						</Col>
					</Row>
				</Col>
				{tempTax?.zones?.map((zone: any, index: number) => (
					<Col xs={24} key={index}>
						<Row gutter={[20, 10]}>
							<Col xs={14}>
								<Select
									style={{ width: "100%" }}
									placeholder={Strings.zones.zone}
									value={zone?.zone || null}
									onChange={(elem: any) => {
										this.setState((prevState: any) => ({
											tempTax: {
												...prevState.tempTax,
												zones: prevState.tempTax.zones.map((zone: any, i: number) => {
													if (i === index) {
														return { ...zone, zone: elem };
													}
													return zone;
												}),
											},
										}));
									}}
								>
									{copyZones.filter((z: any) => !tempTax?.zones?.find((zone: any, i: number) => zone.zone === z._id && index !== i)).map((z: any) => (
										<Select.Option key={z._id} value={z._id}>
											{z?.name}
										</Select.Option>
									))}
								</Select>
							</Col>
							<Col xs={{ span: 8, offset: 2 }}>
								<div className="TaxesInputContainer">
									<div className="TaxesInput">
										<Input
											placeholder="0"
											value={zone?.value || ""}
											type="number"
											min={0}
											max={100}
											suffix="%"
											onChange={(e) => {
												const value = e.target.value;
												this.setState((prevState: any) => ({
													tempTax: {
														...prevState.tempTax,
														zones: prevState.tempTax.zones.map((zone: any, i: number) => {
															if (i === index) {
																return { ...zone, value: value };
															}
															return zone;
														}),
													},
												}));
											}}
										/>
									</div>
									<Button className="TaxesDeleteButton" onClick={() => this.deleteTaxZone(index)}>
										<Icon name="trash" />
									</Button>
								</div>
							</Col>
						</Row>
					</Col>
				))}
				<Col xs={24}>
					<Row gutter={[20, 10]}>
						<Col xs={14}>
							<div className="TaxesCenteredContainerVertical">
								<p style={{ margin: 0 }}>{Strings.zones.other}</p>
							</div>
						</Col>
						<Col xs={{ span: 8, offset: 2 }}>
							<div className="TaxesInputContainer">
								<div className="TaxesInput">
									<Input
										id="other_zone_tax"
										placeholder="0"
										value={tempTax?.value || ""}
										type="number"
										min={0}
										max={100}
										suffix="%"
										onChange={(e) => {
											const value = e.target.value;
											this.setState((prevState: any) => ({ tempTax: { ...prevState.tempTax, value } }))
										}}
									/>
								</div>
								<Button style={{ visibility: "hidden" }} className="TaxesDeleteButton">
									<Icon name="trash" />
								</Button>
							</div>
						</Col>
					</Row>
				</Col>
				<Col xs={24}>
					<Button
						className="TaxesAddButton"
						disabled={!bottonIsAvailable}
						onClick={() => this.addTaxZone()}
					>
						<Icon name="plus" />
						{Strings.settings.addZoneTax}
					</Button>
				</Col>
			</Row>
		)
	}

	renderDefaultSwitch = (cell: any) => {
		const { row } = cell;

		return (
			<span className="TaxesSwitch">
				<Switch
					className={`Switch${row.original?.default ? " __active" : ""}`}
					checked={row.original?.default}
					disabled={row.original?.default}
					onChange={() => {
						this.toggleDefaultTax(row.original._id);
					}}
				/>
			</span>
		);
	}

	render() {
		const { taxes = [] } = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.taxes}</title>
					<meta name="description" content="Setup the taxes that will be applied to clients" />
				</Helmet>
				<Table
					title={{
						icon: "tax-settings",
						title: Strings.settings.taxesTitle,
					}}
					data={taxes}
					columns={[
						{
							Header: Strings.fields.name,
							id: "name",
							accessor: (row: any) => translate(row.name) || "-",
						},
						{
							Header: Strings.generic.default,
							id: "default",
							Cell: this.renderDefaultSwitch,
							Filter: () => null,
						},
					]}
					filterable
					fullPage
					isSinglePage
					add={{
						tooltip: Strings.settings.addTax,
						onClick: () => this.setState({ showTaxDrawer: true, tempTax: { zones: [] } }),
					}}
					actions={{
						edit: (obj: any) => ({
							onClick: () => this.setState({ tempTax: JSON.parse(JSON.stringify(obj)), showTaxDrawer: true }),
						}),
						toggle: (obj: any) => ({
							value: obj.isActive,
							onChange: () => this.toggleTax(obj),
						}),
						remove: (obj: any) => ({
							onClick: () => this.deleteTax(obj._id),
						})
					}}
				/>
				{this.renderModal()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	mobile: state.mobile,
});

export default connect(mapStateToProps)(Taxes);