import React, { Component } from "react";
import { Helmet } from "react-helmet";
import strings from "utils/strings";

class Suspended extends Component<any, any> {
	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{strings.users.suspended}</title>
					<meta name="description" content="Account suspended" />
				</Helmet>
				<div className="Suspended">
					<h1>{strings.users.suspended}</h1>
					<p>{strings.users.suspendedMessage1}</p>
					<p>{strings.users.suspendedMessage2}</p>
					<p>
						{strings.formatString(strings.users.suspendedMessage3, {
							tel: <a href="tel:+351 919 144 919">+351 919 144 919</a>,
							email: <a href="mailto: apoio@essenciadavida.pt">apoio@essenciadavida.pt</a>
						})}
					</p>
				</div>
			</React.Fragment>
		);
	}
}

export default Suspended;
