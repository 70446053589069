/*
 *
 * Pages
 *
 */

import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { setLoader, setTitle } from "store/actions";
import { Helmet } from "react-helmet";
import { Table } from "components";
import { API, Endpoints } from "utils/api";
import { formatPrice } from "utils/utils";

import Strings from "utils/strings";
import { Props } from "./types";
import "./styles.scss";
import { Select, notification } from "antd";
import { DateTime } from "luxon";

export class Orders extends React.Component<Props, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			orders: [],
			page: 0,
			pageSize: 100,
			total: 0,
			columnSearch: null,
			globalSearch: ""
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		this.getData();
	}

	async getData(pause?: boolean) {
		const { page, pageSize, columnSearch, globalSearch } = this.state;
		const { dispatch } = this.props;

		setTimeout(
			async () => {
				if (columnSearch !== this.state.columnSearch || globalSearch !== this.state.globalSearch) return;
				dispatch(setLoader(true));

				const body = {
					filters: columnSearch
						? Object.keys(columnSearch).map((elem) =>
								columnSearch[elem] !== ""
									? {
											field: elem,
											query: columnSearch[elem]
									  }
									: {}
						  )
						: [],
					page: page,
					perPage: pageSize,
					search: globalSearch
				};

				try {
					const response = await API.post({
						url: Endpoints.uriOrders("search"),
						data: body
					});

					if (response.ok) {
						const { orders = [], total = 0 } = response.data.results;
						this.setState({ orders, total });
					} else {
						notification.error({
							message: Strings.sidebar.orders,
							description: response?.data?.message || Strings.serverErrors.wentWrong,
							placement: "bottomRight",
							duration: 5
						});
					}
				} catch (err) {
					notification.error({
						message: Strings.serverErrors.title,
						description: err as string,
						placement: "bottomRight",
						duration: 5
					});
				}

				dispatch(setLoader(false));
			},
			pause ? 1000 : 0
		);
	}

	get pagination() {
		return {
			total: this.state.total,
			pageIndex: this.state.page,
			pageSize: this.state.pageSize,
			setPage: (page: number, size: number) => {
				this.setState({ page, pageSize: size }, () => this.getData());
			}
		};
	}

	get filtration() {
		const { globalSearch, columnSearch } = this.state;
		return {
			showGlobalSearch: true,
			showColumnSearch: true,
			defaultValues: { globalSearch, columnSearch },
			onGlobalSearch: (globalSearch: string) => {
				this.setState({ globalSearch, page: 0 }, () => this.getData());
			},
			onColumnSearch: (columnSearch: string) => {
				this.setState({ columnSearch, page: 0 }, () => this.getData());
			}
		};
	}

	getPaymentStatus(status: string) {
		const statusString = status as keyof typeof Strings.orders.paymentStatuses;
		return Strings.orders.paymentStatuses[statusString] || "-";
	}

	getShippingStatus(status: string) {
		const statusString = status as keyof typeof Strings.orders.shippingStatuses;
		return Strings.orders.shippingStatuses[statusString] || "-";
	}

	getOrderStatus = (closed: boolean) => {
		if (closed) {
			return (
				<div className="TableTag --tag-success">
					<span>{Strings.orders.closed}</span>
				</div>
			);
		}

		return (
			<div className="TableTag --tag-warning">
				<span>{Strings.orders.pending}</span>
			</div>
		);
	};

	render() {
		const { orders = [] } = this.state;
		const { dispatch, partners } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.orders}</title>
					<meta name="description" content="Description of Orders" />
				</Helmet>
				<Table
					title={{
						icon: "box",
						title: Strings.sidebar.orders
					}}
					data={orders}
					columns={[
						{
							Header: Strings.fields.date,
							id: "_created",
							accessor: (row: any) => (row._created ? DateTime.fromISO(row._created).toFormat("dd/MM/yyyy") : "-"),
							maxWidth: 100,
							Filter: () => null
						},
						{
							Header: Strings.orders.orderNumber,
							id: "orderNumber",
							accessor: (row: any) => (row.orderNumber !== null ? row.orderNumber : "-"),
							maxWidth: 150
						},
						{
							Header: Strings.fields.email,
							id: "email",
							accessor: (row: any) => row.user?.email || "-"
						},
						{
							Header: Strings.fields.name,
							id: "name",
							accessor: (row: any) => row.user?.name || "-"
						},
						{
							Header: Strings.orders.paymentStatus,
							id: "paymentStatus",
							accessor: (row: any) => this.getPaymentStatus(row.paymentStatus),
							Filter: () => null
						},
						{
							Header: Strings.orders.shippingStatus,
							id: "shippingStatus",
							accessor: (row: any) => this.getShippingStatus(row.shippingStatus),
							Filter: () => null
						},
						{
							Header: Strings.orders.status,
							id: "isClosed",
							Cell: (row: any) => this.getOrderStatus(row.cell.row.original.isClosed),
							maxWidth: 150
						},
						{
							Header: Strings.fields.total,
							id: "total",
							accessor: (row: any) => (row.total != null ? formatPrice(row.total) : "-"),
							align: "right",
							maxWidth: 100
						}
					]}
					filterable
					fullPage
					isSinglePage
					paginated
					paginationApi={this.pagination}
					filtrationApi={this.filtration}
					add={{
						tooltip: Strings.orders.addOrder,
						onClick: () => {
							partners ? dispatch(push("/partner-orders/new")) : dispatch(push("/orders/new"));
						}
					}}
					actions={{
						view: (obj: any) => ({
							onClick: () => {
								partners ? dispatch(push(`/partner-orders/${obj._id}`)) : dispatch(push(`/orders/${obj._id}`));
							},
							location: `/orders/${obj._id}`
						})
					}}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(Orders);
