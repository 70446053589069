import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { setBreadcrumb, setLoader, setTitle } from 'store/actions';
import { Helmet } from 'react-helmet';
import { Table } from 'components';
import { notification, Select } from 'antd';

import { API, Endpoints } from 'utils/api';
import { Props, State } from './types';
import { translate } from 'utils/utils';
import Strings from 'utils/strings';

export class Banners extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			banners: [],
			columnSearch: null,
			dragIndex: -1,
			hoverIndex: -1,
		}
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setBreadcrumb(null));
		dispatch(setTitle(''));

		this.getData();
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriBanners(),
			});

			if (response.ok) {
				this.setState({ banners: response.data.results.banners || [] });
			} else {
				notification.error({
					message: Strings.sidebar.banners,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: 'bottomRight',
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string || Strings.serverErrors.wentWrong,
				placement: 'bottomRight',
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async toggleBanner(value: any) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriBanners(`${value._id}/status`),
				data: { isActive: !value.isActive },
			});

			if (response?.ok) {
				await this.getData();

				notification.success({
					message: Strings.sidebar.banners,
					description: response?.data?.message || Strings.banners.updated,
					placement: 'bottomRight',
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.sidebar.banners,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: 'bottomRight',
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string || Strings.serverErrors.wentWrong,
				placement: 'bottomRight',
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	onDrag = async () => {
		const { banners, hoverIndex } = this.state;
		const { dispatch } = this.props;

		const item = banners[hoverIndex];
		const itemId = item?._id;

		if (!itemId || item.pos === hoverIndex) return;

		dispatch(setLoader(true));

		await API.patch({
			url: Endpoints.uriBanners(`${itemId}/position`),
			data: { pos: hoverIndex },
		})
			.then((response) => {
				if (response.ok && response.data.results) {
					this.getData();
				} else
					notification.error({
						message: Strings.serverErrors.title,
						description: response.data?.message || '',
						placement: 'bottomRight',
						duration: 5,
					});
			})
			.catch((err) => {
				notification.error({
					message: Strings.serverErrors.title,
					description: err as string || Strings.serverErrors.wentWrong,
					placement: 'bottomRight',
					duration: 5,
				});
			});

		dispatch(setLoader(false));
	};

	async deleteBanner(value: any) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriBanners(value),
			});

			if (response.ok) {
				this.setState({ banners: response.data.results.banners || [] });

				notification.success({
					message: Strings.sidebar.banners,
					description: Strings.banners.deleted,
					placement: 'bottomRight',
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string || Strings.serverErrors.wentWrong,
				placement: 'bottomRight',
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	shownWebFilter = (props: any) => {
		return (
			<Select
				optionFilterProp="children"
				defaultValue={""}
				className="TableSelectFilter"
				onChange={(value: string) => {
					this.setState((prevState: any) => ({
						columnSearch: {
							...prevState.columnSearch,
							shownWeb: value
						}
					}));
				}}
			>
				<Select.Option key="all" value="">{Strings.generic.every}</Select.Option>
				<Select.Option key="yes" value="true">{Strings.generic.yes}</Select.Option>
				<Select.Option key="no" value="false">{Strings.generic.no}</Select.Option>
			</Select>
		);
	}

	shownAppFilter = (props: any) => {
		return (
			<Select
				optionFilterProp="children"
				defaultValue={""}
				className="TableSelectFilter"
				onChange={(value: string) => {
					this.setState((prevState: any) => ({
						columnSearch: {
							...prevState.columnSearch,
							shownApp: value
						}
					}));
				}}
			>
				<Select.Option key="all" value="">{Strings.generic.every}</Select.Option>
				<Select.Option key="yes" value="true">{Strings.generic.yes}</Select.Option>
				<Select.Option key="no" value="false">{Strings.generic.no}</Select.Option>
			</Select>
		);
	}

	render() {
		const { banners } = this.state;
		const { dispatch } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.banners}</title>
					<meta name='description' content='Set banners to your web homepage' />
				</Helmet>
				<Table
					title={{
						icon: "frame",
						title: Strings.sidebar.banners
					}}
					data={banners}
					columns={[
						{
							Header: Strings.fields.image,
							id: 'image',
							accessor: (row: any) => translate(row.web?.image),
							type: 'image',
							maxWidth: 65,
						},
						{
							Header: Strings.fields.name,
							id: 'name',
							accessor: (row: any) => row.name,
						},
						{
							Header: Strings.banners.title,
							id: 'title',
							accessor: (row: any) => row?.web?.title?.pt || "-",
						},
						{
							Header: Strings.banners.subTitle,
							id: 'subtitle',
							accessor: (row: any) => row?.web?.subTitle?.pt || "-",
						},
					]}
					isSinglePage
					fullPage
					paginated={false}
					add={{
						tooltip: Strings.banners.addBanner,
						onClick: () => dispatch(push('/banners/new'))
					}}
					actions={{
						edit: (obj: any) => ({
							onClick: () => dispatch(push(`/banners/${obj._id}`)),
							location: `/banners/${obj._id}`
						}),
						remove: (obj: any) => ({
							onClick: () => this.deleteBanner(obj._id),
						}),
						toggle: (obj: any) => ({
							value: obj.isActive,
							onChange: () => this.toggleBanner(obj),
						})
					}}
					draggable
					onDrag={async (list: any, dragIndex: any, hoverIndex: any) => {
						this.setState({ banners: list, dragIndex, hoverIndex })
					}}
					onDragEnd={this.onDrag}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
});

export default connect(mapStateToProps)(Banners);