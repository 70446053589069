import React from "react";
import { Col, Input, Row, Select, notification } from "antd";
import { ContentWrapper, Icon, Table } from "components";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import Dropzone from "react-dropzone";
import Compressor from "compressorjs";
import { translate } from "utils/utils";
import Strings from "utils/strings";
import "./styles.scss";
import { push, replace } from "connected-react-router";

class ShippingDetail extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			shipping: {},
			isNew: props.match.params.id === "new",
			hasUnsavedFields: false,
			isActive: false,
			methods: [],
			link: ""
		};
	}

	componentDidMount() {
		this.getData();
		this.breadcrumb();
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				const { shipping, isActive, hasUnsavedFields, isNew } = this.state;
				return {
					locations: [
						{
							icon: "preferences",
							text: Strings.sidebar.settings,
							route: "/settings"
						},
						{
							icon: "delivery-truck",
							text: Strings.settings.shipping,
							route: "/settings/shippings"
						},
						{
							icon: "delivery-truck",
							text: isNew ? Strings.settings.newShipping : translate(shipping.name) || Strings.generic.loading
						}
					],
					actions: [
						{
							type: "switch",
							text: Strings.generic.active,
							value: isActive,
							small: {
								switch: true,
								text: true
							},
							separator: "right",
							onClick: (value: boolean) => this.setState({ isActive: value, hasUnsavedFields: true })
						},
						{
							type: "button",
							text: Strings.generic.save,
							disabled: !hasUnsavedFields,
							className: "BreadcrumbSaveButton",
							isSave: true,
							hasIcon: true,
							onClick: () => this.saveShipping()
						}
					]
				};
			})
		);
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { isNew } = this.state;
		const { dispatch } = this.props;

		const promises = [
			API.get({
				url: Endpoints.uriTaxes()
			})
		];

		dispatch(setLoader(true));

		try {
			if (!isNew) {
				promises.push(
					API.get({
						url: Endpoints.uriShipping(this.props.match.params.id)
					})
				);
			}

			const [responseTaxes, responseShipping] = await Promise.all(promises);

			let taxes = [],
				tax = null,
				shipping = {};

			if (responseTaxes?.ok) {
				taxes = responseTaxes.data.results?.taxes || [];
				tax = taxes.find((t: any) => t.default)?._id || null;
			} else {
				notification.error({
					message: Strings.settings.shipping,
					description: responseTaxes?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}

			if (isNew) {
				this.setState({ defaultTaxes: taxes, tax });
				return dispatch(setLoader(false));
			}

			if (responseShipping?.ok) {
				shipping = responseShipping.data.results?.shipping || {};
			} else {
				notification.error({
					message: Strings.settings.shipping,
					description: responseShipping?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}

			this.setState({ defaultTaxes: taxes, tax, shipping, ...shipping });
		} catch (err: unknown) {
			console.log("Error: ", err as string);
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async toggleShippingMethod(method: any) {
		const { dispatch, match } = this.props;
		const { id } = match?.params || {};

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriShipping(`${id}/methods/${method._id}`),
				data: {
					isActive: !method.isActive
				}
			});

			if (response?.ok) {
				await this.getData();
				notification.success({
					message: Strings.settings.shipping,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.settings.shipping,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			console.log("Error: ", err as string);
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async deleteShippingMethod(method: any) {
		const { dispatch, match } = this.props;
		const { id } = match?.params || {};

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriShipping(`${id}/methods/${method._id}`)
			});

			if (response?.ok) {
				await this.getData();
				notification.success({
					message: Strings.settings.shipping,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.settings.shipping,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			console.log("Error: ", err as string);
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async saveShipping() {
		const { dispatch, match } = this.props;
		const { id } = match?.params || {};
		const { isNew, image, name, isActive, tax, methods, link } = this.state;

		dispatch(setLoader(true));

		try {
			const body = new FormData();

			body.append("name", name);
			body.append("isActive", String(isActive));
			body.append("tax", tax);
			body.append("methods", JSON.stringify(methods));
			body.append("link", link);

			if (image && Object.keys(image).length > 0) {
				if (image.file) {
					body.append("image", image.file);
				} else {
					body.append("image", image);
				}
			}

			const request = isNew ? API.post : API.put;
			const response = await request({
				url: Endpoints.uriShipping(isNew ? "" : id),
				data: body
			});

			if (response?.ok) {
				if (isNew) {
					dispatch(replace(`/settings/shippings/${response?.data?.results?.shipping?._id}`));
					dispatch(setBreadcrumb(null));
					this.breadcrumb();
					this.setState({ isNew: false, hasUnsavedFields: false }, async () => await this.getData());
				} else {
					await this.getData();
				}

				notification.success({
					message: Strings.settings.shipping,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.settings.shipping,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			console.log("Error: ", err as string);
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	getBase64(file: any) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	onDrop(files: any, type: string) {
		try {
			const file = files[files.length - 1];

			new Compressor(file, {
				quality: 0.9,
				maxWidth: 400,
				mimeType: "image/jpeg",
				success: (result: any) => {
					this.getBase64(result).then((res) => {
						this.setState({
							image: {
								file: result,
								preview: res
							},
							hasUnsavedFields: true
						});
					});
				}
			});
		} catch (err) {
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5
			});
		}
	}

	renderShippingInformation() {
		const { name, tax, link, image, defaultTaxes = [] } = this.state;

		return (
			<ContentWrapper>
				<Row gutter={[20, 10]}>
					<Col xs={24}>
						<div className="ScreenHeader">
							<div className="ScreenHeaderLeft">
								<Icon name="delivery-truck" />
								<h2>{Strings.settings.shipping}</h2>
							</div>
						</div>
					</Col>
					<Col xs={24} md={8}>
						<div className="ShippingImageContainer">
							<Dropzone
								accept="image/jpg, image/jpeg, image/png"
								className="GenericImageUpload"
								onDrop={(files: any) => this.onDrop(files, "web")}
								style={{ height: 200 }}
							>
								{image ? (
									<div
										className="ShppingImage"
										style={{
											backgroundImage: `url('${image?.preview || image}')` || "none",
											backgroundSize: "contain"
										}}
									/>
								) : (
									<div className={`GenericImageUploadOverlay${!image ? " --visible" : ""}`}>
										<Icon name="frame" />
										<span>{Strings.generic.changeImage}</span>
									</div>
								)}
							</Dropzone>
						</div>
					</Col>
					<Col xs={24} md={16}>
						<Row gutter={[20, 10]}>
							<Col xs={24} md={12}>
								<label htmlFor="name" className="InputLabel --label-required">
									{Strings.fields.name}
								</label>
								<Input
									id="name"
									style={{ height: 40 }}
									placeholder={Strings.fields.name}
									value={name || ""}
									pattern="[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{7}([A-Z0-9]?){0,16}"
									onChange={(e) => {
										this.setState({ name: e.target.value, hasUnsavedFields: true });
									}}
								/>
							</Col>
							<Col xs={24} md={12}>
								<label htmlFor="tax" className="InputLabel --label-required">
									{Strings.fields.tax}
								</label>
								<Select
									style={{ width: "100%" }}
									value={tax}
									onChange={(value: string) => {
										this.setState({ tax: value, hasUnsavedFields: true });
									}}
									showSearch
									filterOption={(input: any, option: any) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
								>
									{defaultTaxes.map((tax: any) => (
										<Select.Option key={tax._id} value={tax._id}>
											{translate(tax.name)}
										</Select.Option>
									))}
								</Select>
							</Col>
							<Col xs={24}>
								<label htmlFor="link" className="InputLabel">
									{Strings.fields.link}
								</label>
								<Input
									id="link"
									style={{ height: 40 }}
									placeholder={Strings.fields.link}
									value={link || ""}
									onChange={(e) => {
										const value = e.target.value;
										this.setState({ link: value, hasUnsavedFields: true });
									}}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	renderShippingMethods() {
		const { isNew, methods = [] } = this.state;
		const {
			dispatch,
			match: { params }
		} = this.props;
		const { id } = params || {};

		if (isNew) return null;

		return (
			<Table
				title={{
					icon: "delivery-truck",
					title: Strings.settings.shippingMethods
				}}
				data={methods}
				columns={[
					{
						Header: Strings.fields.name,
						id: "name",
						accessor: (row: any) => translate(row.name) || "-"
					},
					{
						Header: Strings.fields.description,
						id: "description",
						accessor: (row: any) => translate(row.description) || "-"
					},
					{
						Header: Strings.zones.zone,
						id: "zone",
						accessor: (row: any) => row.zone?.name || "-"
					},
					{
						Header: Strings.fields.exclusiveClients,
						id: "exclusiveClient",
						accessor: (row: any) => row.exclusiveClient || false,
						maxWidth: 150,
						align: "center"
					},
					{
						Header: Strings.fields.exclusivePartners,
						id: "exclusivePartner",
						accessor: (row: any) => row.exclusivePartner || false,
						maxWidth: 150,
						align: "center"
					}
				]}
				filterable
				add={{
					tooltip: Strings.settings.addShipping,
					onClick: () => dispatch(push(`/settings/shippings/${id}/methods/new`))
				}}
				actions={{
					edit: (obj: any) => ({
						onClick: () => dispatch(push(`/settings/shippings/${id}/methods/${obj._id}`))
					}),
					toggle: (obj: any) => ({
						value: obj.isActive,
						onChange: () => this.toggleShippingMethod(obj)
					}),
					remove: (obj: any) => ({
						onClick: () => this.deleteShippingMethod(obj)
					})
				}}
			/>
		);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.shipping}</title>
					<meta name="description" content="Shipping Detail" />
				</Helmet>
				<div className="ShippingDetailScreen">
					{this.renderShippingInformation()}
					{this.renderShippingMethods()}
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(ShippingDetail);
