import React from 'react';
import { connect } from 'react-redux';
import type { Props, State } from './types';
import { push, replace } from 'connected-react-router';
import { delayedDispatch, setBreadcrumb, setLoader, updateCrumb } from 'store/actions';
import { Helmet } from 'react-helmet';
import { ContentWrapper } from 'components';
import { Col, Input, notification, Row } from 'antd';
import { removeDiacritics, translate } from 'utils/utils';
import { API, Endpoints } from 'utils/api';
import Strings from 'utils/strings';
import './styles.scss';

class SegmentDetail extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			segment: null,
			isActive: false,
			showOnHeader: false,
			name: null,
			pos: 0,
			seo: '',
			seoUrlLoading: false,
			language: 'pt',
			hasUnsavedFields: false,
			isNew: props.match.params.id === "new",
		}
	}

	componentDidMount() {
		this.getData();
		this.breadcrumb();
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				const { segment, isNew } = this.state;

				return {
					locations: [
						{
							text: Strings.sidebar.segments,
							route: "/segments",
							icon: "preferences",
						},
						{
							icon: segment?._id ? 'pencil-outline' : 'plus',
							text: translate(segment?.name) || (isNew ? Strings.products.new : Strings.generic.loading),
						}
					],
					actions: [
						{
							type: 'switch',
							text: Strings.segments.showOnHeader,
							value: this.state.showOnHeader,
							small: {
								switch: true,
								text: true,
							},
							separator: 'right',
							onClick: (value: boolean) => this.setState({ showOnHeader: value, hasUnsavedFields: true }),
						},
						{
							type: 'switch',
							text: Strings.generic.active,
							value: this.state.isActive,
							small: {
								switch: true,
								text: true,
							},
							separator: 'right',
							onClick: (value: boolean) => this.setState({ isActive: value, hasUnsavedFields: true }),
						},
						{
							type: 'language',
							value: this.state.language,
							showArrow: true,
							showSearch: false,
							separator: 'right',
							onChange: (value: string) => this.setState({ language: value }),
						},
						{
							type: "button",
							text: Strings.generic.save,
							disabled: !this.state.hasUnsavedFields,
							className: "BreadcrumbSaveButton",
							isSave: true,
							hasIcon: true,
							onClick: () => this.saveSegment(),
						},
					],
				};
			})
		);
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { isNew } = this.state;
		const { dispatch, match } = this.props;

		if (!match?.params.id || isNew) return;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriSegments(match?.params.id),
			});

			if (response.ok) {
				const { segments } = response.data.results || {};
				this.setState({ segment: segments, ...segments });
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string || Strings.serverErrors.wentWrong,
				placement: 'bottomRight',
				duration: 5,
			});

			dispatch(push('/segments'));
		}

		dispatch(setLoader(false));
	}

	async getSeoUrl(name?: string) {
		if (!name) return;

		this.setState({ seoUrlLoading: true });

		try {
			const response = await API.post({
				url: Endpoints.uriSeoUrl(),
				data: {
					seo: removeDiacritics(name),
					table: "segments",
				},
			});

			if (response?.ok) {
				const { seo } = response.data.results || {};
				this.setState({ seoUrlLoading: false, seo });
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string || Strings.serverErrors.wentWrong,
				placement: 'bottomRight',
				duration: 5,
			});
		}

		this.setState({ seoUrlLoading: false });
	}

	async saveSegment() {
		const { isActive, name, seo, showOnHeader, isNew } = this.state;
		const { dispatch, match } = this.props;

		if (!this.isSegmentValid()) return;

		dispatch(setLoader(true));

		try {
			const request = isNew ? API.post : API.put;
			const response = await request({
				url: Endpoints.uriSegments(isNew ? '' : match.params?.id),
				data: { isActive, name, showOnHeader, seo }
			})

			if (response.ok) {
				const { segment } = response.data.results || {};
				if (isNew) {
					dispatch(replace(`/segments/${segment._id}`));
					dispatch(setBreadcrumb(null));
					this.breadcrumb();
				}

				this.setState({ ...segment, segment, isNew: false });

				notification.success({
					message: Strings.sidebar.segments,
					description: response?.data?.message || (isNew ? Strings.segments.created : Strings.segments.updated),
					placement: 'bottomRight',
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	isSegmentValid() {
		const { name, seo } = this.state;

		if (!translate(name)) {
			notification.warning({
				message: Strings.sidebar.segments,
				description: Strings.segments.nameMissing,
				placement: 'bottomRight',
				duration: 5,
			});

			return false;
		}

		if (!seo?.trim()) {
			notification.warning({
				message: Strings.sidebar.segments,
				description: Strings.generic.seoMissing,
				placement: 'bottomRight',
				duration: 5,
			});

			return false;
		}

		return true;
	}

	render() {
		const { name, seo, seoUrlLoading, language } = this.state;

		return (
			<div className="Screen-Segment">
				<Helmet>
					<title>{Strings.segments.single}</title>
					<meta name='description' content='Edit your segment' />
				</Helmet>
				<ContentWrapper extraStyle={{ padding: 20 }}>
					<Row gutter={[20, 20]}>
						<Col xs={24} md={12}>
							<label htmlFor="segment_name" className="InputLabel --label-required">
								{Strings.fields.name}
							</label>
							<Input
								id="segment_name"
								value={name?.[language] || ''}
								placeholder={Strings.fields.name}
								onChange={(event: any) => {
									const value = event.target.value;

									this.setState((prevState: State) => ({
										name: {
											...prevState.name,
											[language]: value,
										},
										hasUnsavedFields: true,
									}));
								}}
								onBlur={() => {
									if (!seo && translate(name)) {
										const seo = removeDiacritics(name?.["pt"] || translate(name));
										this.getSeoUrl(seo);
									}
								}}
							/>
						</Col>
						<Col xs={24} md={12}>
							<label htmlFor="segment_seo" className="InputLabel --label-required">
								{Strings.fields.seoUrl}
							</label>
							<Input
								id="segment_seo"
								value={seo || ''}
								readOnly={seoUrlLoading}
								placeholder={Strings.fields.seoUrl}
								onChange={(event: any) => {
									const value = event.target.value;
									this.setState({ seo: value, hasUnsavedFields: true });
								}}
								onBlur={() => {
									if (seo) {
										this.getSeoUrl(seo)
									}
								}}
							/>
						</Col>
					</Row>
				</ContentWrapper>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
});

export default connect(mapStateToProps)(SegmentDetail);
