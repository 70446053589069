import React from "react";
import { connect } from "react-redux";
import type { Props, ShippingMethodType, State, ZoneType } from "./types";
import { delayedDispatch, setBreadcrumb, setLoader, updateCrumb } from "store/actions";
import { Col, Input, InputNumber, Row, Select, Switch, Tooltip, notification } from "antd";
import { API, Endpoints } from "utils/api";
import { translate } from "utils/utils";
import { replace } from "connected-react-router";
import { Helmet } from "react-helmet";
import { LanguageSchema } from "utils/types";
import { ContentWrapper, Icon } from "components";
import Strings from "utils/strings";
import "./styles.scss";

class ShippingMethod extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);

		this.state = {
			isNew: props.match.params.methodId === "new",
			hasUnsavedFields: false,
			isActive: false,
			language: "pt",
			name: {},
			exclusivePartner: false,
			exclusiveClient: false,
			pickOnStore: false,
			description: {},
			zone: "",
			value: null,
			methodType: "free",
			type: "subTotal",
			intervals: [{ minRange: 0 }],
			provider: {},
			defaultZones: []
		};
	}

	componentDidMount() {
		this.getData();
		this.breadcrumb();
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				const { name, isActive, hasUnsavedFields, isNew, provider, language } = this.state;
				return {
					locations: [
						{
							icon: "preferences",
							text: Strings.sidebar.settings,
							route: "/settings"
						},
						{
							icon: "delivery-truck",
							text: Strings.settings.shipping,
							route: "/settings/shippings"
						},
						{
							icon: "delivery-truck",
							text: provider?.name ? provider.name : Strings.generic.loading,
							route: `/settings/shippings/${provider?._id}`
						},
						{
							icon: "delivery-truck",
							text: isNew ? Strings.settings.newShippingMethod : translate(name) || Strings.generic.loading
						}
					],
					actions: [
						{
							type: "switch",
							text: Strings.generic.active,
							value: isActive,
							small: {
								switch: true,
								text: true
							},
							separator: "right",
							onClick: (value: boolean) => this.setState({ isActive: value, hasUnsavedFields: true })
						},
						{
							type: "language",
							value: language,
							onChange: (value: string) => this.setState({ language: value as keyof LanguageSchema }),
							className: "BreadcrumbLanguage",
							separator: "right"
						},
						{
							type: "button",
							text: Strings.generic.save,
							disabled: !hasUnsavedFields,
							className: "BreadcrumbSaveButton",
							isSave: true,
							hasIcon: true,
							onClick: () => this.saveShippingMethod()
						}
					]
				};
			})
		);
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { isNew } = this.state;
		const { dispatch, match } = this.props;
		const {
			params: { id, methodId }
		} = match || {};

		dispatch(setLoader(true));

		try {
			const [responseZones, responseMethod] = await Promise.all([
				API.get({
					url: Endpoints.uriZones()
				}),
				API.get({
					url: Endpoints.uriShipping(`${id}/methods/${methodId}`)
				})
			]);

			let zones = [],
				method = {} as ShippingMethodType,
				provider = {};

			if (responseZones?.ok) {
				zones = responseZones.data.results?.zones || [];
			} else {
				notification.error({
					message: Strings.settings.shippingMethod,
					description: responseZones?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}

			if (responseMethod?.ok) {
				if (isNew) {
					provider = responseMethod.data.results?.method?.provider || {};
				} else {
					method = responseMethod.data.results?.method || {};
				}
			} else {
				notification.error({
					message: Strings.settings.shippingMethod,
					description: responseMethod?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}

			this.setState({ defaultZones: zones, provider, method, ...method });
		} catch (err: unknown) {
			console.log("Error: ", err as string);
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async saveShippingMethod() {
		const { dispatch, match } = this.props;
		const { id, methodId } = match?.params || {};
		const { isNew, name, isActive, description, exclusivePartner, zone, value, methodType, type, intervals, pickOnStore } = this.state;

		if (!this.isValidMethod()) return;

		dispatch(setLoader(true));

		try {
			const body = {
				name,
				description,
				exclusivePartner,
				isActive,
				zone,
				value,
				methodType,
				type,
				intervals,
				pickOnStore
			};

			const request = isNew ? API.post : API.put;
			const response = await request({
				url: Endpoints.uriShipping(`${id}/methods/${methodId === "new" ? "" : methodId}`),
				data: body
			});

			if (response?.ok) {
				if (isNew) {
					dispatch(replace(`/settings/shippings/${id}/methods/${response?.data?.results?.method?._id}`));
					dispatch(setBreadcrumb(null));
					this.breadcrumb();
					this.setState({ isNew: false, hasUnsavedFields: false }, async () => await this.getData());
				} else {
					await this.getData();
				}

				notification.success({
					message: Strings.settings.shipping,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.settings.shipping,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			console.log("Error: ", err as string);
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	isValidMethod() {
		const { name, zone, value, methodType, intervals } = this.state;

		if (!translate(name) || !zone || (value == null && methodType !== "custom") || !methodType) {
			notification.error({
				message: Strings.settings.shipping,
				description: Strings.errors.invalidFields,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (methodType === "free" || methodType === "fixed") {
			if (value! <= 0) {
				notification.error({
					message: Strings.settings.shipping,
					description: Strings.settings.invalidValue,
					placement: "bottomRight",
					duration: 5
				});
				return false;
			}
		} else if (methodType === "custom") {
			let valid = true;

			for (let i = 0; i < intervals.length; i++) {
				const interval = intervals[i];

				if (i > 0 && interval.maxRange != null && interval.maxRange <= interval.minRange) {
					valid = false;
					break;
				}

				if (!i) {
					if (
						(interval.minRange != null && interval.minRange < 0) ||
						(interval.maxRange != null && interval.maxRange <= 0) ||
						(interval.maxRange != null && interval.maxRange <= interval.minRange)
					) {
						valid = false;
						break;
					}
				}

				if ((interval.value && interval.value < 0) || interval.value == null) {
					notification.error({
						message: Strings.settings.shipping,
						description: Strings.settings.invalidCostValue,
						placement: "bottomRight",
						duration: 5
					});
					return false;
				}
			}

			if (!valid) {
				notification.error({
					message: Strings.settings.shipping,
					description: Strings.settings.invalidIntervalValues,
					placement: "bottomRight",
					duration: 5
				});
				return false;
			}
		}

		return true;
	}

	renderMethods() {
		const { methodType } = this.state;

		return (
			<ContentWrapper>
				<Row gutter={[20, 10]}>
					<Col xs={24}>
						<div className="ScreenHeader">
							<div className="ScreenHeaderLeft">
								<Icon name="delivery-truck" />
								<h2>{Strings.settings.pickMethod}</h2>
							</div>
						</div>
					</Col>
					<Col xs={24}>
						<Row gutter={[20, 20]}>
							<Col xs={24} lg={12} xl={8}>
								<button
									onClick={() =>
										this.setState({
											methodType: "free",
											hasUnsavedFields: true
										})
									}
									className={`ShippingMethodCard${methodType === "free" ? " --selected" : ""}`}
								>
									<Icon name="free-delivery" />
									<div className="ShippingMethodCardDetail">
										<h3>{Strings.settings.freeShipping}</h3>
										<p>{Strings.settings.freeShippingDescription}</p>
									</div>
								</button>
							</Col>
							<Col xs={24} lg={12} xl={8}>
								<button
									onClick={() =>
										this.setState({
											methodType: "fixed",
											hasUnsavedFields: true
										})
									}
									className={`ShippingMethodCard${methodType === "fixed" ? " --selected" : ""}`}
								>
									<Icon name="shipped" />
									<div className="ShippingMethodCardDetail">
										<h3>{Strings.settings.fixedShipping}</h3>
										<p>{Strings.settings.fixedShippingDescription}</p>
									</div>
								</button>
							</Col>
							<Col xs={24} lg={12} xl={8}>
								<button
									onClick={() =>
										this.setState({
											methodType: "custom",
											hasUnsavedFields: true
										})
									}
									className={`ShippingMethodCard${methodType === "custom" ? " --selected" : ""}`}
								>
									<Icon name="fast-delivery" />
									<div className="ShippingMethodCardDetail">
										<h3>{Strings.settings.customShipping}</h3>
										<p>{Strings.settings.customShippingDescription}</p>
									</div>
								</button>
							</Col>
						</Row>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	renderMethodDetail() {
		return (
			<ContentWrapper>
				<Row gutter={[20, 10]}>
					<Col xs={24}>
						<div className="ScreenHeader">
							<div className="ScreenHeaderLeft">
								<Icon name="delivery-truck" />
								<h2>{Strings.settings.methodSettings}</h2>
							</div>
						</div>
					</Col>
					<Col xs={24}>
						<Row gutter={[20, 10]}>
							{this.renderMethodName()}
							{this.renderMethodSettings()}
						</Row>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	renderMethodName() {
		const { name, description, exclusivePartner, exclusiveClient, language } = this.state;

		return (
			<Col xs={24} xl={8}>
				<Row gutter={[20, 10]}>
					<Col xs={24}>
						<label htmlFor="name" className="InputLabel --label-required">
							{Strings.fields.name}
						</label>
						<Input
							id="name"
							placeholder={Strings.fields.name}
							value={name?.[language] || ""}
							onChange={(e) => {
								const value = e.target.value;
								this.setState((prevState: State) => ({
									name: { ...prevState.name, [language]: value },
									hasUnsavedFields: true
								}));
							}}
						/>
						<small>{Strings.settings.nameShownClient}</small>
					</Col>
					<Col xs={24}>
						<div className={`General_ColorFul_Switch ${exclusivePartner ? "__active" : ""}`}>
							<span>{Strings.fields.exclusivePartners}</span>
							<Switch
								className={`Switch ${exclusivePartner ? "__active" : ""}`}
								checked={exclusivePartner || false}
								size="small"
								onChange={(value: any) =>
									this.setState({
										exclusivePartner: value,
										exclusiveClient: value ? false : exclusiveClient,
										hasUnsavedFields: true
									})
								}
							/>
						</div>
					</Col>
					<Col xs={24}>
						<div className={`General_ColorFul_Switch ${exclusiveClient ? "__active" : ""}`}>
							<span>{Strings.fields.exclusiveClients}</span>
							<Switch
								className={`Switch ${exclusiveClient ? "__active" : ""}`}
								checked={exclusiveClient || false}
								size="small"
								onChange={(value: any) =>
									this.setState({
										exclusiveClient: value,
										exclusivePartner: value ? false : exclusivePartner,
										hasUnsavedFields: true
									})
								}
							/>
						</div>
					</Col>
					<Col xs={24}>
						<label htmlFor="description" className="InputLabel">
							{Strings.fields.description}
						</label>
						<Input.TextArea
							id="description"
							placeholder={Strings.fields.description}
							value={description?.[language] || ""}
							rows={4}
							maxLength={200}
							showCount
							onChange={(e) => {
								const value = e.target.value;
								this.setState((prevState: State) => ({
									description: { ...prevState.description, [language]: value },
									hasUnsavedFields: true
								}));
							}}
						/>
						<small className="TipAdjustTop">
							{Strings.settings.descriptionShownClient} <strong>{Strings.settings.descriptionShownClientBold}</strong>
						</small>
					</Col>
				</Row>
			</Col>
		);
	}

	renderMethodSettings() {
		const { zone, defaultZones } = this.state;

		return (
			<Col xs={24} xl={16}>
				<Row gutter={[20, 10]}>
					<Col xs={24} lg={12}>
						{this.renderMethodInput()}
					</Col>
					<Col xs={24} lg={12}>
						<label htmlFor="zone" className="InputLabel --label-required">
							{Strings.zones.zone}
						</label>
						<Select
							style={{ width: "100%" }}
							placeholder={Strings.zones.zone}
							value={zone || undefined}
							onChange={(elem: any) => {
								this.setState({ zone: elem, hasUnsavedFields: true });
							}}
						>
							{defaultZones.map((z: ZoneType) => (
								<Select.Option key={z._id} value={z._id!}>
									{z?.name}
								</Select.Option>
							))}
						</Select>
						<small>{Strings.settings.pickZone}</small>
						<div className="ShippingLink">
							<a href="/settings/zones">{Strings.settings.manageZone}</a>
						</div>
					</Col>
				</Row>
			</Col>
		);
	}

	renderMethodInput() {
		const { methodType, type, value, intervals, pickOnStore } = this.state;

		if (methodType === "free" || methodType === "fixed") {
			return (
				<Row gutter={[20, 10]}>
					<Col xs={24}>
						<label htmlFor="value" className="InputLabel --label-required">
							{methodType === "free" ? Strings.settings.minimumValue : Strings.settings.value}
						</label>
						<InputNumber
							className="ShippingMethodNumberInput"
							id="value"
							placeholder="0.00"
							prefix="€"
							type="number"
							min={0}
							step={0.01}
							value={value || undefined}
							onChange={(value) => {
								this.setState({ value, hasUnsavedFields: true });
							}}
						/>
						<small>
							<strong>
								{methodType === "free" ? Strings.settings.freeMethodValueTip : Strings.settings.fixedMethodValueTip}
							</strong>
						</small>
					</Col>
					{methodType === "free" && (
						<Col xs={24}>
							<div className={`General_ColorFul_Switch ${pickOnStore ? "__active" : ""}`}>
								<span>{Strings.fields.pickOnStore}</span>
								<Switch
									className={`Switch ${pickOnStore ? "__active" : ""}`}
									checked={pickOnStore || false}
									size="small"
									onChange={(value: any) =>
										this.setState({
											pickOnStore: value,
											hasUnsavedFields: true
										})
									}
								/>
							</div>
						</Col>
					)}
				</Row>
			);
		} else {
			const last = intervals[intervals.length - 1];
			const lastFilled =
				(last?.value !== void 0 &&
					last?.value >= 0 &&
					last?.maxRange !== void 0 &&
					last?.maxRange >= 0 &&
					last?.maxRange > last?.minRange) ||
				false;

			return (
				<Row gutter={[20, 10]}>
					<Col xs={24}>
						<label htmlFor="zone" className="InputLabel --label-required">
							{Strings.settings.type}
						</label>
						<Select
							style={{ width: "100%" }}
							placeholder={Strings.settings.type}
							value={type || undefined}
							onChange={(elem: any) => {
								this.setState({ type: elem, hasUnsavedFields: true });
							}}
						>
							<Select.Option value="subTotal">{Strings.settings.subTotal}</Select.Option>
							<Select.Option value="weight">{Strings.fields.weight}</Select.Option>
						</Select>
					</Col>
					<Col xs={24}>
						<Row gutter={[20, 0]}>
							<Col xs={24} md={14}>
								<label className="InputLabel">{Strings.settings.subTotalInterval}</label>
							</Col>
							<Col xs={24} md={10}>
								<label className="InputLabel">{Strings.settings.cost}</label>
							</Col>
							{this.renderRanges()}
							<Col xs={16}>
								<div className="ShippingMethodRangeAddButton">
									<button
										onClick={() => {
											if (lastFilled) {
												intervals.push({
													minRange: Number((last?.maxRange! + 0.01).toFixed(2))
												});
												this.setState({ intervals, hasUnsavedFields: true });
											}
										}}
										disabled={!lastFilled}
									>
										{Strings.settings.addInterval}
									</button>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			);
		}
	}

	renderRanges() {
		const { type, intervals } = this.state;

		return intervals.map((interval, index) => {
			const isLast = index === intervals.length - 1;

			return (
				<React.Fragment key={`interval_${index}`}>
					<Col xs={24} md={14}>
						<div className={`ShippingMethodRange${index > 0 ? " --adjust" : ""}`}>
							<Row gutter={5}>
								<Col xs={11}>
									<InputNumber
										className={`ShippingMethodNumberInput${type === "weight" ? " --weight" : ""}`}
										id="value"
										placeholder="0.00"
										type="number"
										min={0}
										step={type === "subTotal" ? 0.01 : 0.001}
										disabled={index > 0}
										value={interval.minRange != null ? interval.minRange : undefined}
										onChange={(value) => {
											interval.minRange = value;
											this.setState({ intervals, hasUnsavedFields: true });
										}}
									/>
								</Col>
								<Col xs={2}>
									<span className="ShippingMethodInputSeparator">-</span>
								</Col>
								<Col xs={11}>
									{interval.maxRange !== void 0 ? (
										<InputNumber
											className={`ShippingMethodNumberInput${type === "weight" ? " --weight" : ""}`}
											id="value"
											placeholder="0.00"
											type="number"
											min={0}
											step={type === "subTotal" ? 0.01 : 0.001}
											value={interval.maxRange != null ? interval.maxRange : undefined}
											onChange={(value) => {
												interval.maxRange = value;
												if (intervals[index + 1]) {
													intervals[index + 1].minRange = Number((value + 0.01).toFixed(2));
												}
												this.setState({ intervals, hasUnsavedFields: true });
											}}
										/>
									) : (
										<div className="ShippingMethodRangeAbove">
											<Tooltip placement="top" title={Strings.settings.andAboveInfo}>
												<input
													className="ShippingMethodFakeNumberInput"
													value={Strings.settings.andAbove}
													onClick={() => {
														if (interval.minRange == null) return;
														interval.maxRange =
															interval.minRange >= 0 ? Number((interval.minRange + 1).toFixed(2)) : 0;
														this.setState({
															intervals,
															hasUnsavedFields: true
														});
													}}
													readOnly
												/>
											</Tooltip>
										</div>
									)}
								</Col>
							</Row>
						</div>
					</Col>
					<Col xs={24} md={10}>
						<div className={`ShippingMethodRangeWithOption${index > 0 ? " --adjust" : ""}`}>
							<InputNumber
								className="ShippingMethodNumberInput"
								id="value"
								placeholder="0.00"
								prefix="€"
								type="number"
								min={0}
								step={0.01}
								value={interval.value || undefined}
								onChange={(value) => {
									interval.value = value;
									this.setState({ intervals, hasUnsavedFields: true });
								}}
							/>
							<button
								disabled={!isLast}
								className={isLast && index > 0 ? "" : `ButtonHidden`}
								onClick={() => {
									intervals.splice(index, 1);
									this.setState({ intervals, hasUnsavedFields: true });
								}}
							>
								<Icon name="trash" />
							</button>
						</div>
					</Col>
				</React.Fragment>
			);
		});
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.shippingMethod}</title>
					<meta name="description" content="Shipping method detail" />
				</Helmet>
				<div className="ShippingMethodDetailScreen">
					{this.renderMethods()}
					{this.renderMethodDetail()}
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(ShippingMethod);
