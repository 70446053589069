import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { Col, Input, InputNumber, Row, notification, Select, Tabs, Switch, Drawer, Tooltip, Tag } from "antd";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { Helmet } from "react-helmet";
import { LANGUAGES, removeDiacritics, translate } from "utils/utils";
import { ContentWrapper, Icon, QuillEditor, SelectTags, Table } from "components";
import { API, Endpoints } from "utils/api";
import type { LanguageSchema } from "utils/types";
import { push, replace } from "connected-react-router";
import type { Props, State } from "./types";
import type { Category } from "screens/Admin/CategoryDetail/types";
import type { Segment } from "screens/Admin/SegmentDetail/types";
import type { Component, Product, Review } from "../Products/types";
import { Tax } from "../Taxes/types";
import Dropzone from "react-dropzone";
import Compressor from "compressorjs";
import Strings from "utils/strings";
import placeholder from "assets/images/placeholders/image.jpg";
import "./styles.scss";

import blood from "assets/images/labels/blood.png";
import bones from "assets/images/labels/bones.png";
import brain from "assets/images/labels/brain.png";
import celular from "assets/images/labels/celular.png";
import energy from "assets/images/labels/energy.png";
import fertility from "assets/images/labels/fertility.png";
import glucose from "assets/images/labels/glucose.png";
import hair from "assets/images/labels/hair.png";
import heart from "assets/images/labels/heart.png";
import hepatic from "assets/images/labels/hepatic.png";
import immunity from "assets/images/labels/immunity.png";
import mental from "assets/images/labels/mental.png";
import metabolism from "assets/images/labels/metabolism.png";
import muscles from "assets/images/labels/muscles.png";
import nails from "assets/images/labels/nails.png";
import nervous from "assets/images/labels/nervous.png";
import skin from "assets/images/labels/skin.png";
import sleep from "assets/images/labels/sleep.png";
import stomach from "assets/images/labels/stomach.png";
import teeth from "assets/images/labels/teeth.png";
import testosterone from "assets/images/labels/testosterone.png";
import thyroid from "assets/images/labels/thyroid.png";
import vision from "assets/images/labels/vision.png";

const { Search } = Input;

class ProductDetail extends React.Component<Props, State> {
	productTimeout?: NodeJS.Timeout;

	constructor(props: Props) {
		super(props);

		this.state = {
			product: undefined,
			isNew: props.match.params.id === "new",
			defaultCategories: [],
			defaultSegments: [],
			language: "pt",
			drawerLanguage: "pt",
			hasUnsavedFields: false,
			tempComponent: undefined,
			showCompositionDrawer: false,
			showProductDrawer: false,
			productSearch: "",
			tempProducts: [],
			searchingProducts: false,
			defaultProducts: [],
			defaultTaxes: [],
			seoUrlLoading: false,
			defaultLabels: [
				{ value: "blood", image: blood, label: Strings.products.labelBlood },
				{ value: "bones", image: bones, label: Strings.products.labelBones },
				{ value: "brain", image: brain, label: Strings.products.labelBrain },
				{
					value: "celular",
					image: celular,
					label: Strings.products.labelCelular
				},
				{ value: "energy", image: energy, label: Strings.products.labelEnergy },
				{
					value: "fertility",
					image: fertility,
					label: Strings.products.labelFertility
				},
				{
					value: "glucose",
					image: glucose,
					label: Strings.products.labelGlucose
				},
				{ value: "hair", image: hair, label: Strings.products.labelHair },
				{ value: "heart", image: heart, label: Strings.products.labelHeart },
				{
					value: "hepatic",
					image: hepatic,
					label: Strings.products.labelHepatic
				},
				{
					value: "immunity",
					image: immunity,
					label: Strings.products.labelImmunity
				},
				{ value: "mental", image: mental, label: Strings.products.labelMental },
				{
					value: "metabolism",
					image: metabolism,
					label: Strings.products.labelMetabolism
				},
				{
					value: "muscles",
					image: muscles,
					label: Strings.products.labelMuscles
				},
				{ value: "nails", image: nails, label: Strings.products.labelNails },
				{
					value: "nervous",
					image: nervous,
					label: Strings.products.labelNervous
				},
				{ value: "skin", image: skin, label: Strings.products.labelSkin },
				{ value: "sleep", image: sleep, label: Strings.products.labelSleep },
				{
					value: "stomach",
					image: stomach,
					label: Strings.products.labelStomach
				},
				{ value: "teeth", image: teeth, label: Strings.products.labelTeeth },
				{
					value: "testosterone",
					image: testosterone,
					label: Strings.products.labelTestosterone
				},
				{
					value: "thyroid",
					image: thyroid,
					label: Strings.products.labelThyroid
				},
				{ value: "vision", image: vision, label: Strings.products.labelVision }
			],

			// Product
			name: {},
			description: {},
			images: [],
			segments: [],
			categories: [],
			reviews: [],
			howToStore: {},
			howToUse: {},
			activePrinciples: {},
			ingredients: {},
			composition: [],
			provenBy: {},
			presentation: {},
			criticalWarnings: {},
			bibliographicReferences: "",
			seo: "",
			stock: 0,
			isActive: false,
			partnerOnly: false,
			notes: {},
			price: null,
			specialPrice: null,
			points: null,
			weight: null,
			reference: "",
			ean: "",
			suggestedProducts: [],
			labels: [],
			selectedTab: "ingredients",
			tax: undefined,
			showReviewDrawer: false,
			tempReview: undefined,
			isKit: false,
			kitLevel: "junior",
			merchandising: false
		};
	}

	componentDidMount(): void {
		const { dispatch } = this.props;
		this.getData();
		this.breadcrumb();

		dispatch(setTitle(""));
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				const { product, isNew, hasUnsavedFields } = this.state;

				return {
					locations: [
						{
							text: Strings.products.title,
							route: "/products",
							icon: "box"
						},
						{
							icon: product?._id ? "pencil-outline" : "plus",
							text: translate(product?.name) || (isNew ? Strings.products.new : Strings.generic.loading)
						}
					],
					actions: [
						{
							type: "switch",
							text: Strings.products.isKit,
							value: this.state.isKit,
							small: {
								switch: true,
								text: true
							},
							separator: "right",
							onClick: (value: boolean) =>
								this.setState({
									isKit: value,
									specialPrice: null,
									seo: "",
									reference: "",
									ean: "",
									stock: 0,
									tax: undefined,
									categories: [],
									segments: [],
									points: null,
									weight: null,
									labels: [],
									ingredients: {},
									composition: [],
									activePrinciples: {},
									provenBy: {},
									howToStore: {},
									howToUse: {},
									notes: {},
									presentation: {},
									criticalWarnings: {},
									bibliographicReferences: "",
									hasUnsavedFields: true,
									merchandising: false
								})
						},
						{
							type: "switch",
							text: Strings.products.merchandising,
							value: this.state.merchandising,
							small: {
								switch: true,
								text: true
							},
							separator: "right",
							onClick: (value: boolean) =>
								this.setState({
									merchandising: value,
									hasUnsavedFields: true,
									selectedTab: value ? "notes" : "ingredients",
									isKit: false
								})
						},
						{
							type: "switch",
							text: Strings.generic.active,
							value: this.state.isActive,
							small: {
								switch: true,
								text: true
							},
							separator: "right",
							onClick: (value: boolean) => this.setState({ isActive: value, hasUnsavedFields: true })
						},
						{
							type: "language",
							value: this.state.language,
							showArrow: true,
							showSearch: false,
							separator: "right",
							onChange: (value: string) => this.setState({ language: value })
						},
						{
							type: "button",
							text: Strings.generic.save,
							disabled: !hasUnsavedFields,
							className: "BreadcrumbSaveButton",
							isSave: true,
							hasIcon: true,
							onClick: () => this.saveProduct()
						}
					]
				};
			})
		);
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { isNew } = this.state;
		const { dispatch, match } = this.props;

		dispatch(setLoader(true));

		try {
			const promises = [
				API.get({ url: Endpoints.uriCategories() }),
				API.get({ url: Endpoints.uriSegments() }),
				API.get({ url: Endpoints.uriTaxes() })
			];

			let categories: Category[] = [],
				segments: Segment[] = [],
				product = {} as Product,
				taxes: Tax[] = [];
			const [categoriesResponse, segmentsResponse, responseTaxes] = await Promise.all(promises);

			if (categoriesResponse?.ok) {
				categories = categoriesResponse.data.results?.categories || [];
			}

			if (segmentsResponse?.ok) {
				segments = segmentsResponse.data.results?.segments || [];
			}

			if (responseTaxes?.ok) {
				taxes = responseTaxes.data.results?.taxes || [];
			}

			if (!isNew) {
				const response = await API.get({
					url: Endpoints.uriProducts(match.params.id)
				});
				if (response?.ok) {
					product = response.data.results?.product || {};
				} else {
					return notification.error({
						message: Strings.products.title,
						description: response?.data?.message,
						placement: "bottomRight",
						duration: 5
					});
				}
			}

			const tax = product?.tax || taxes.find((tax) => tax.default)?._id;

			this.setState({
				product,
				...product,
				tax,
				defaultCategories: categories,
				defaultSegments: segments,
				defaultTaxes: taxes,
				hasUnsavedFields: false,
				selectedTab: product.merchandising ? "notes" : "ingredients"
			});
		} catch (err: unknown) {
			console.log("error", err as string);
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});

			dispatch(push("/products"));
		}

		dispatch(setLoader(false));
	}

	async getSeoUrl(name?: string) {
		if (!name) return;

		this.setState({ seoUrlLoading: true });

		try {
			const response = await API.post({
				url: Endpoints.uriSeoUrl(),
				data: {
					seo: removeDiacritics(name),
					table: "products"
				}
			});

			if (response?.ok) {
				const { seo } = response.data.results || {};
				this.setState({ seoUrlLoading: false, seo });
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		this.setState({ seoUrlLoading: false });
	}

	async saveProduct() {
		const {
			isNew,
			name,
			seo,
			description,
			reference,
			ean,
			price,
			specialPrice,
			points,
			categories = [],
			segments,
			ingredients,
			activePrinciples,
			presentation,
			criticalWarnings,
			bibliographicReferences,
			provenBy,
			howToStore,
			howToUse,
			notes,
			images,
			isActive,
			isKit,
			stock,
			suggestedProducts,
			labels,
			tax,
			weight,
			reviews,
			kitLevel,
			merchandising
		} = this.state;
		const { dispatch, match } = this.props;
		let { composition } = this.state;

		if (composition == null) {
			composition = [];
		}

		if (!this.validProduct()) {
			return;
		}

		dispatch(setLoader(true));

		try {
			const filteredImages = JSON.parse(JSON.stringify(images));
			for (let image of filteredImages) {
				delete image.file;
				delete image.preview;
			}

			for (const component of composition) {
				if (typeof component?.nrv === "string") {
					component.nrv = component.nrv.replace(",", ".");
				}
				if (typeof component?.nrvChildren === "string") {
					component.nrvChildren = component.nrvChildren.replace(",", ".");
				}
				if (typeof component?.dailyValue === "string") {
					component.dailyValue = component.dailyValue.replace(",", ".");
				}
				if (typeof component?.dailyValueChildren === "string") {
					component.dailyValueChildren = component.dailyValueChildren.replace(",", ".");
				}
			}

			const body = new FormData();
			body.append("name", JSON.stringify(name));
			body.append("description", JSON.stringify(description));
			body.append("reference", reference);
			body.append("price", String(price));
			body.append("images", JSON.stringify(filteredImages));
			body.append("isActive", String(isActive));
			body.append("isKit", String(isKit));
			body.append("ean", ean);
			body.append("stock", String(stock));
			body.append("weight", String(weight));

			if (isKit) {
				body.append("kitLevel", String(kitLevel));
			} else {
				body.append("seo", JSON.stringify(seo));
				body.append("specialPrice", String(specialPrice));
				body.append("points", String(points));
				body.append("labels", JSON.stringify(labels));
				body.append("categories", JSON.stringify(categories));
				body.append("segments", JSON.stringify(segments));
				body.append("ingredients", JSON.stringify(ingredients));
				body.append("composition", JSON.stringify(composition));
				body.append("activePrinciples", JSON.stringify(activePrinciples));
				body.append("provenBy", JSON.stringify(provenBy));
				body.append("howToStore", JSON.stringify(howToStore));
				body.append("howToUse", JSON.stringify(howToUse));
				body.append("notes", JSON.stringify(notes));
				body.append("presentation", JSON.stringify(presentation));
				body.append("criticalWarnings", JSON.stringify(criticalWarnings));
				body.append("bibliographicReferences", bibliographicReferences);
				body.append("isActive", String(isActive));
				body.append("suggestedProducts", JSON.stringify(suggestedProducts.map((product) => product._id)));
				body.append("tax", String(tax));
				body.append("reviews", JSON.stringify(reviews));
				body.append("merchandising", String(merchandising));
			}

			for (let i = 0; i < images.length; i++) {
				const image = images[i];

				if (image && Object.keys(image).length > 0) {
					if (image.file) {
						body.append(`image_${i}`, image.file);
					}
				}
			}

			const request = isNew ? API.post : API.put;
			const response = await request({
				url: Endpoints.uriProducts(isNew ? "" : match?.params?.id),
				data: body
			});

			if (response?.ok) {
				const { product = {} } = response.data.results || {};

				if (isNew) {
					dispatch(replace(`/products/${product._id}`));
					this.setState({ isNew: false });
				}

				await this.getData();
				dispatch(setBreadcrumb(null));
				this.breadcrumb();

				notification.success({
					message: Strings.products.title,
					description: response?.data?.message || Strings.products.saved,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.products.title,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			console.log("error", err as string);
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async deleteComment(reviewId: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriProducts(`reviews/${reviewId}`)
			});

			if (response?.ok) {
				await this.getData();

				notification.success({
					message: Strings.products.title,
					description: response?.data?.message || Strings.products.commentDeleted,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.products.title,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async saveReview() {
		const { tempReview } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.put({
				url: Endpoints.uriProducts(`reviews/${tempReview!._id}`),
				data: { ...tempReview }
			});

			if (response?.ok) {
				this.setState({ tempReview: undefined, showReviewDrawer: false });
				await this.getData();

				notification.success({
					message: Strings.products.title,
					description: response?.data?.message || Strings.products.commentUpdated,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.products.title,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	validProduct() {
		const { name, seo, description, reference, ean, price, categories, images, stock, tax, isKit, kitLevel } = this.state;

		if (!translate(name)) {
			notification.error({
				message: Strings.products.title,
				description: Strings.products.nameRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!seo?.trim() && !isKit) {
			notification.error({
				message: Strings.products.title,
				description: Strings.generic.seoMissing,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		if (!translate(description)) {
			notification.error({
				message: Strings.products.title,
				description: Strings.products.descriptionRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!reference) {
			notification.error({
				message: Strings.products.title,
				description: Strings.products.referenceRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!ean) {
			notification.error({
				message: Strings.products.title,
				description: Strings.products.eanRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (price == null || price < 0) {
			notification.error({
				message: Strings.products.title,
				description: Strings.products.priceInvalid,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!categories.length && !isKit) {
			notification.error({
				message: Strings.products.title,
				description: Strings.products.categoryRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		const favoriteAndDeleteImage = images.find((image: any) => image.favorite && image.toDelete);
		if (favoriteAndDeleteImage && images.length > 1) {
			notification.error({
				message: Strings.products.title,
				description: Strings.products.cannotDeleteFavoriteImage,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (stock == null || (Number.isInteger(stock) && stock < 0)) {
			notification.error({
				message: Strings.products.title,
				description: Strings.products.stockInvalid,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!tax && !isKit) {
			notification.error({
				message: Strings.products.title,
				description: Strings.products.taxRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (isKit && !kitLevel) {
			notification.error({
				message: Strings.products.title,
				description: Strings.kits.kitLevelRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		return true;
	}

	getBase64(file: any) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	onDrop(files: any) {
		const { images } = this.state;

		try {
			for (let file of files) {
				new Compressor(file, {
					quality: 0.9,
					maxWidth: 800,
					mimeType: "image/png",
					success: (result: any) => {
						this.getBase64(result).then((res) => {
							images.push({
								file: result,
								preview: res,
								favorite: images.length === 0 ? true : false,
								url: "",
								toDelete: false
							});
							this.setState({ images, hasUnsavedFields: true });
						});
					}
				});
			}
		} catch (err) {
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5
			});
		}
	}

	saveComponent() {
		const { tempComponent } = this.state;
		let { composition } = this.state;

		if (composition == null) {
			composition = [];
		}

		if (!tempComponent) {
			this.setState({ showCompositionDrawer: false, tempComponent: undefined });
			return;
		}

		if (!translate(tempComponent?.component)) {
			return notification.warn({
				message: Strings.products.composition,
				description: Strings.products.componentNameMandatory,
				placement: "bottomRight",
				duration: 5
			});
		}

		if (tempComponent?.dailyValue == null) {
			return notification.warn({
				message: Strings.products.composition,
				description: Strings.products.componentDailyValueMandatory,
				placement: "bottomRight",
				duration: 5
			});
		}

		if (tempComponent?._id) {
			const index = composition.findIndex((c: any) => c._id === tempComponent._id);
			composition[index] = tempComponent;
		} else {
			composition.push(tempComponent);
		}

		this.setState({
			showCompositionDrawer: false,
			tempComponent: undefined,
			composition,
			hasUnsavedFields: true
		});
	}

	async getProducts() {
		const { productSearch, isNew, product } = this.state;
		const { dispatch } = this.props;

		if (!productSearch) {
			this.setState({ defaultProducts: [] });
			return;
		}

		dispatch(setLoader(true));

		try {
			const response = await API.post({
				url: Endpoints.uriProducts("search"),
				data: {
					search: productSearch,
					perPage: 100,
					page: 0
				}
			});

			if (response?.ok) {
				let products = response.data.results?.products || [];
				if (!isNew) {
					products = products.filter((prod: any) => prod._id !== product?._id);
				}

				this.setState({ defaultProducts: products, searchingProducts: false });
			} else {
				notification.error({
					message: Strings.products.title,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	deleteProduct(product: string) {
		this.setState((prevState) => ({
			suggestedProducts: (prevState.suggestedProducts || []).filter((prod) => prod._id !== product),
			hasUnsavedFields: true
		}));
	}

	addProducts() {
		const { tempProducts } = this.state;

		this.setState((prevState) => ({
			suggestedProducts: [...(prevState.suggestedProducts || []), ...tempProducts],
			hasUnsavedFields: true,
			showProductDrawer: false,
			tempProducts: [],
			defaultProducts: []
		}));
	}

	getLabel(value: string) {
		const { defaultLabels } = this.state;

		const label = defaultLabels.find((label) => label.value === value);
		return (
			<span className="ProductLabelImage">
				{label?.label}
				<img src={label?.image} alt={value} />;
			</span>
		);
	}

	renderImageItem = (image: any, index: number) => {
		const { images } = this.state;

		return (
			<div key={`image_${index}`} className="ImageItem">
				<img src={image.file ? image.preview : image.url} alt={`product img ${index}`} />
				<div className="ImageOptions">
					<div className="ImageTags">
						{image?.preview ? <div className="ImageTag">{Strings.generic.newF}</div> : null}
						{image?.toDelete ? <div className="ImageTag --delete">{Strings.products.toDelete}</div> : null}
					</div>
					<div className="ImageActions">
						<Tooltip title={Strings.products.favoriteDescription}>
							<button
								className={image?.favorite ? "--selected" : ""}
								onClick={() => {
									for (let tempImage of images) {
										tempImage.favorite = false;
									}

									image.favorite = true;
									this.setState({ images, hasUnsavedFields: true });
								}}
							>
								<Icon name={image?.favorite ? "star-full" : "star-empty"} />
							</button>
						</Tooltip>
						<Tooltip title={Strings.products.deleteDescription}>
							<button
								className={image?.toDelete ? "--selected" : ""}
								onClick={() => {
									if (image.file) {
										images.splice(index, 1);
										if (image.favorite && images[0]) images[0].favorite = true;
									} else {
										image.toDelete = !image.toDelete;
									}
									this.setState({ images, hasUnsavedFields: true });
								}}
							>
								<Icon name="trash" />
							</button>
						</Tooltip>
					</div>
				</div>
			</div>
		);
	};

	renderProductInformation() {
		const {
			name,
			seo,
			seoUrlLoading,
			ean,
			description,
			price,
			specialPrice,
			points,
			weight,
			reference,
			stock,
			language,
			defaultLabels,
			labels,
			tax,
			defaultTaxes = [],
			isKit
		} = this.state;

		return (
			<ContentWrapper>
				<Row gutter={[20, 10]}>
					<Col xs={24}>
						<div className="ScreenHeader">
							<div className="ScreenHeaderLeft">
								<Icon name="box" />
								<h2>{Strings.products.details}</h2>
							</div>
						</div>
					</Col>
					<Col xs={24} md={12}>
						<Row gutter={[20, 19]}>
							<Col xs={24}>
								<label htmlFor="product_name" className="InputLabel --label-required">
									{Strings.fields.name}
								</label>
								<Input
									id="product_name"
									value={name?.[language as keyof LanguageSchema] || ""}
									placeholder={Strings.fields.name}
									onChange={(event: any) => {
										const value = event.target.value;

										this.setState((prevState: State) => ({
											name: {
												...prevState.name,
												[language]: value as keyof LanguageSchema
											},
											hasUnsavedFields: true
										}));
									}}
									onBlur={() => {
										if (!seo && translate(name) && !isKit) {
											const seo = removeDiacritics(name?.["pt"] || translate(name));
											this.getSeoUrl(seo);
										}
									}}
								/>
							</Col>
							<Col xs={24}>
								<label htmlFor="product_seo" className="InputLabel --label-required">
									{Strings.fields.seoUrl}
								</label>
								<Input
									id="product_seo"
									value={seo || ""}
									readOnly={seoUrlLoading}
									placeholder={Strings.fields.seoUrl}
									onChange={(event: any) => {
										const value = event.target.value;
										this.setState({ seo: value, hasUnsavedFields: true });
									}}
									onBlur={() => {
										if (seo) {
											this.getSeoUrl(seo);
										}
									}}
								/>
							</Col>
							<Col xs={24} md={6}>
								<label htmlFor="product_reference" className="InputLabel --label-required">
									{Strings.products.reference}
								</label>
								<Input
									id="product_reference"
									style={{ height: 40 }}
									value={reference || ""}
									placeholder={Strings.products.reference}
									onChange={(event: any) => {
										const value = event.target.value;

										this.setState({
											reference: value,
											hasUnsavedFields: true
										});
									}}
								/>
							</Col>
							<Col xs={24} md={6}>
								<label htmlFor="product_ean" className="InputLabel --label-required">
									{Strings.products.ean}
								</label>
								<Input
									id="product_ean"
									style={{ height: 40 }}
									value={ean || ""}
									placeholder={Strings.products.ean}
									onChange={(event: any) => {
										const value = event.target.value;

										this.setState({
											ean: value,
											hasUnsavedFields: true
										});
									}}
								/>
							</Col>
							<Col xs={24} md={6}>
								<label htmlFor="product_weight" className="InputLabel">
									{Strings.fields.weight}
								</label>
								<InputNumber
									className="NumberInput --input-weight"
									id="product_weight"
									placeholder="1.000"
									prefix="kg"
									min={0}
									step={0.001}
									value={weight || undefined}
									onChange={(value) => {
										this.setState({ weight: value, hasUnsavedFields: true });
									}}
								/>
							</Col>
							<Col xs={24} md={6}>
								<label htmlFor="product_tax" className="InputLabel --label-required">
									{Strings.fields.tax}
								</label>
								<Select
									style={{ width: "100%" }}
									value={tax}
									placeholder={Strings.fields.tax}
									onChange={(value: string) => {
										this.setState({ tax: value, hasUnsavedFields: true });
									}}
									showSearch
									showArrow
									filterOption={(input: any, option: any) => option?.toLowerCase().indexOf(input.toLowerCase())}
								>
									{defaultTaxes!.map((tax) => (
										<Select.Option key={tax.value} value={tax._id!}>
											{translate(tax?.name)}
										</Select.Option>
									))}
								</Select>
							</Col>
							<Col xs={24} md={6}>
								<label htmlFor="product_stock" className="InputLabel --label-required">
									{Strings.products.stock}
								</label>
								<InputNumber
									className="NumberInput"
									id="product_stock"
									value={stock}
									placeholder={Strings.products.stock}
									min={0}
									step={1}
									onChange={(value: number) => {
										this.setState({
											stock: value,
											hasUnsavedFields: true
										});
									}}
								/>
							</Col>
							<Col xs={24} md={6}>
								<label htmlFor="product_price" className="InputLabel --label-required">
									{Strings.products.price}
								</label>
								<InputNumber
									className="NumberInput --input-currency"
									id="product_price"
									placeholder="0.00"
									prefix="€"
									min={0}
									step={0.01}
									value={price || undefined}
									onChange={(value) => {
										this.setState({ price: value, hasUnsavedFields: true });
									}}
								/>
							</Col>
							<Col xs={24} md={6}>
								<label htmlFor="product_special_price" className="InputLabel">
									{Strings.products.specialPrice}
								</label>
								<InputNumber
									className="NumberInput --input-currency"
									id="product_special_price"
									placeholder="0.00"
									prefix="€"
									min={0}
									step={0.01}
									value={specialPrice || undefined}
									onChange={(value) => {
										this.setState({
											specialPrice: value,
											hasUnsavedFields: true
										});
									}}
								/>
							</Col>
							<Col xs={24} md={6}>
								<label htmlFor="product_points" className="InputLabel">
									{Strings.products.points}
								</label>
								<InputNumber
									className="NumberInput"
									id="product_points"
									placeholder="0"
									min={0}
									step={1}
									value={points || undefined}
									onChange={(value) => {
										this.setState({ points: value, hasUnsavedFields: true });
									}}
								/>
							</Col>
							<Col xs={24}>
								<label htmlFor="product_labels" className="InputLabel">
									{Strings.products.labels}
								</label>
								<Select
									style={{ width: "100%" }}
									value={(labels as string[]) || []}
									placeholder={Strings.products.labels}
									onChange={(value: string[]) => {
										this.setState({ labels: value, hasUnsavedFields: true });
									}}
									mode="multiple"
									showSearch
									showArrow
									filterOption={(input: any, option: any) => {
										const finalOption = defaultLabels.find((l) => l.value === option.value)?.label;
										return (finalOption?.toLowerCase().indexOf(input.toLowerCase()) || 0) >= 0;
									}}
									tagRender={(props: any) => {
										const { value, closable, onClose } = props;
										const label = defaultLabels.find((l) => l.value === value)?.label;

										return (
											<Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
												{label}
											</Tag>
										);
									}}
								>
									{defaultLabels.map((label) => (
										<Select.Option key={label.value} value={label.value}>
											<span className="ProductLabelImage">
												{label?.label}
												<img src={label?.image} alt={label.value} />;
											</span>
										</Select.Option>
									))}
								</Select>
							</Col>
						</Row>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="product_description" className="InputLabel --label-required">
							{Strings.fields.description}
						</label>
						<QuillEditor
							key={`quill_description_${language}`}
							value={description?.[language as keyof LanguageSchema] || ""}
							onChange={(value: any) => {
								this.setState((prevState: State) => ({
									description: {
										...prevState.description,
										[language]: value
									},
									hasUnsavedFields: true
								}));
							}}
							style={{ height: 390 }}
						/>
					</Col>
					{this.renderSegmentation()}
				</Row>
			</ContentWrapper>
		);
	}

	renderSegmentation() {
		const { defaultCategories, categories, defaultSegments, segments } = this.state;

		return (
			<React.Fragment>
				<Col xs={24}>
					<div className="ScreenHeader --inverted --mt20">
						<div className="ScreenHeaderLeft">
							<Icon name="diagram" />
							<h2>{Strings.products.segmentation}</h2>
						</div>
					</div>
				</Col>
				<Col xs={24}>
					<SelectTags
						id="categories"
						label={Strings.products.categories}
						placeholder={Strings.products.categories}
						required
						value={categories}
						options={defaultCategories?.map((cat: any) => ({
							title: translate(cat.name),
							value: cat._id,
							key: cat._id,
							children: cat.subCategories?.map((child: Category) => ({
								title: translate(child.name),
								value: child._id,
								key: child._id,
								parent: cat._id
							}))
						}))}
						onChange={(categories: string[]) => this.setState({ categories, hasUnsavedFields: true })}
					/>
				</Col>
				<Col xs={24}>
					<SelectTags
						id="segments"
						label={Strings.products.segments}
						placeholder={Strings.products.segments}
						value={segments}
						options={defaultSegments?.map((seg: any) => ({
							title: translate(seg.name),
							value: seg._id,
							key: seg._id,
							children: []
						}))}
						onChange={(segments: string[]) => this.setState({ segments, hasUnsavedFields: true })}
					/>
				</Col>
			</React.Fragment>
		);
	}

	renderIngredients() {
		const { ingredients, language } = this.state;

		return (
			<QuillEditor
				key={`quill_ingredients_${language}`}
				value={ingredients?.[language as keyof LanguageSchema] || ""}
				onChange={(value: any) => {
					this.setState((prevState: State) => ({
						ingredients: {
							...prevState.ingredients,
							[language]: value
						},
						hasUnsavedFields: true
					}));
				}}
				style={{ height: 500 }}
			/>
		);
	}

	renderComposition() {
		const { composition = [], tempComponent } = this.state;

		return (
			<Table
				title={{
					icon: "composition",
					title: Strings.products.composition
				}}
				data={composition}
				columns={[
					{
						Header: Strings.products.component,
						id: "component",
						accessor: (row) => translate((row as Component).component) || "-"
					},
					{
						Header: Strings.products.dailyValueChildren,
						id: "dailyValueChildren",
						accessor: (row) =>
							(row as Component).dailyValueChildren != null
								? `${(row as Component).dailyValueChildren} ${(row as Component).dailyValueUnitChildren}`
								: "-",
						maxWidth: 200
					},
					{
						Header: Strings.products.nrvChildren,
						id: "vrnChildren",
						accessor: (row) =>
							(row as Component).nrvChildren != null && (row as Component).nrvChildren !== ""
								? `${(row as Component).nrvChildren} %`
								: "*",
						maxWidth: 200
					},
					{
						Header: Strings.products.dailyValue,
						id: "dailyValue",
						accessor: (row) =>
							(row as Component).dailyValue != null
								? `${(row as Component).dailyValue} ${(row as Component).dailyValueUnit}`
								: "-",
						maxWidth: 200
					},
					{
						Header: Strings.products.nrv,
						id: "vrn",
						accessor: (row) => ((row as Component).nrv != null ? `${(row as Component).nrv} %` : "*"),
						maxWidth: 200
					}
				]}
				filterable
				paginated={false}
				draggable
				onDrag={async (list: any) => {
					this.setState({
						composition: list,
						hasUnsavedFields: true
					});
				}}
				add={{
					tooltip: Strings.products.addComponent,
					onClick: () =>
						this.setState({
							showCompositionDrawer: true,
							tempComponent: {
								...(tempComponent as Component),
								dailyValueUnit: "mg",
								dailyValueUnitChildren: "mg"
							}
						})
				}}
				actions={{
					edit: (obj: any) => ({
						onClick: () =>
							this.setState({
								showCompositionDrawer: true,
								tempComponent: {
									...(JSON.parse(JSON.stringify(obj)) as Component),
									dailyValueUnit: obj?.dailyValueUnit || "mg",
									dailyValueUnitChildren: obj?.dailyValueUnitChildren || "mg"
								}
							})
					}),
					remove: (obj: Product) => ({
						onClick: () => {
							const newComposition = composition.filter((item) => item._id !== obj._id);
							this.setState({
								composition: newComposition,
								hasUnsavedFields: true
							});
						}
					})
				}}
			/>
		);
	}

	renderActivePrinciples() {
		const { activePrinciples, language } = this.state;

		return (
			<QuillEditor
				key={`quill_principles_${language}`}
				value={activePrinciples?.[language as keyof LanguageSchema] || ""}
				onChange={(value: any) => {
					this.setState((prevState: State) => ({
						activePrinciples: {
							...prevState.activePrinciples,
							[language]: value
						},
						hasUnsavedFields: true
					}));
				}}
				style={{ height: 500 }}
			/>
		);
	}

	renderHowToUse() {
		const { howToUse, language } = this.state;

		return (
			<QuillEditor
				key={`quill_howtouse_${language}`}
				value={howToUse?.[language as keyof LanguageSchema] || ""}
				onChange={(value: any) => {
					this.setState((prevState: State) => ({
						howToUse: {
							...prevState.howToUse,
							[language]: value
						},
						hasUnsavedFields: true
					}));
				}}
				style={{ height: 500 }}
			/>
		);
	}

	renderHowToStore() {
		const { howToStore, language } = this.state;

		return (
			<QuillEditor
				key={`quill_howtostore_${language}`}
				value={howToStore?.[language as keyof LanguageSchema] || ""}
				onChange={(value: any) => {
					this.setState((prevState: State) => ({
						howToStore: {
							...prevState.howToStore,
							[language]: value
						},
						hasUnsavedFields: true
					}));
				}}
				style={{ height: 500 }}
			/>
		);
	}

	renderNotes() {
		const { notes, language } = this.state;

		return (
			<QuillEditor
				key={`quill_notes_${language}`}
				value={notes?.[language as keyof LanguageSchema] || ""}
				onChange={(value: any) => {
					this.setState((prevState: State) => ({
						notes: {
							...prevState.notes,
							[language]: value
						},
						hasUnsavedFields: true
					}));
				}}
				style={{ height: 500 }}
			/>
		);
	}

	renderProvenBy() {
		const { provenBy, language } = this.state;

		return (
			<QuillEditor
				key={`quill_provenby_${language}`}
				value={provenBy?.[language as keyof LanguageSchema] || ""}
				onChange={(value: any) => {
					this.setState((prevState: State) => ({
						provenBy: {
							...prevState.provenBy,
							[language]: value
						},
						hasUnsavedFields: true
					}));
				}}
				style={{ height: 500 }}
			/>
		);
	}

	renderPresentation() {
		const { presentation, language } = this.state;

		return (
			<QuillEditor
				key={`quill_presentation_${language}`}
				value={presentation?.[language as keyof LanguageSchema] || ""}
				onChange={(value: any) => {
					this.setState((prevState: State) => ({
						presentation: {
							...prevState.presentation,
							[language]: value
						},
						hasUnsavedFields: true
					}));
				}}
				style={{ height: 500 }}
			/>
		);
	}

	renderBibliographicReferences() {
		const { bibliographicReferences } = this.state;

		return (
			<QuillEditor
				value={bibliographicReferences || ""}
				onChange={(value: any) => {
					this.setState({
						bibliographicReferences: value,
						hasUnsavedFields: true
					});
				}}
				style={{ height: 500 }}
			/>
		);
	}

	renderSpecifics() {
		const { selectedTab, merchandising } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="warning" />
						<h2>{Strings.products.information}</h2>
					</div>
				</div>
				<Tabs onChange={(key: string) => this.setState({ selectedTab: key })} defaultActiveKey="ingredients">
					{!merchandising && (
						<React.Fragment>
							<Tabs.TabPane tab={Strings.products.ingredients} key="ingredients" />
							<Tabs.TabPane tab={Strings.products.composition} key="composition" />
							<Tabs.TabPane tab={Strings.products.activePrinciples} key="principles" />
							<Tabs.TabPane tab={Strings.products.presentation} key="presentation" />
							<Tabs.TabPane tab={Strings.products.provenBy} key="proven-by" />
							<Tabs.TabPane tab={Strings.products.howToUse} key="how-to-use" />
							<Tabs.TabPane tab={Strings.products.howToStore} key="how-to-store" />
						</React.Fragment>
					)}
					<Tabs.TabPane tab={Strings.products.notes} key="notes" />
					{!merchandising && <Tabs.TabPane tab={Strings.products.bibliographic} key="bibliographic" />}
				</Tabs>
				{!merchandising && selectedTab === "ingredients" && this.renderIngredients()}
				{!merchandising && selectedTab === "composition" && this.renderComposition()}
				{!merchandising && selectedTab === "principles" && this.renderActivePrinciples()}
				{!merchandising && selectedTab === "presentation" && this.renderPresentation()}
				{!merchandising && selectedTab === "proven-by" && this.renderProvenBy()}
				{!merchandising && selectedTab === "how-to-use" && this.renderHowToUse()}
				{!merchandising && selectedTab === "how-to-store" && this.renderHowToStore()}
				{selectedTab === "notes" && this.renderNotes()}
				{!merchandising && selectedTab === "bibliographic" && this.renderBibliographicReferences()}
			</ContentWrapper>
		);
	}

	renderShowComment = (comment: any) => {
		const { reviews } = this.state;

		return (
			<Switch
				checked={comment.show}
				onChange={(value: boolean) => {
					comment.show = value;
					this.setState({ hasUnsavedFields: true, reviews });
				}}
			/>
		);
	};

	renderGallery() {
		const { images } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="frame" />
						<h2>{Strings.fields.images}</h2>
					</div>
				</div>
				<div className="Gallery">{images.map((image: any, index: number) => this.renderImageItem(image, index))}</div>
				<Dropzone
					accept="image/jpg, image/jpeg, image/png"
					className="GalleryDropzone"
					multiple
					onDrop={(files: any) => this.onDrop(files)}
					style={{ height: 100, width: "100%", marginTop: 10 }}
				>
					<div className="ImageUploadOverlay">
						<Icon name="frame" />
						<span>{Strings.generic.changeImages}</span>
					</div>
				</Dropzone>
			</ContentWrapper>
		);
	}

	renderComment(comment: string) {
		return (
			<Tooltip title={comment} placement="topLeft" overlayClassName="ProductReviewTooltip">
				<p className="ln-table-tooltip-text">{comment}</p>
			</Tooltip>
		);
	}

	renderReviews() {
		const { reviews = [] } = this.state;

		return (
			<Table
				title={{
					icon: "testimonial",
					title: Strings.products.reviews
				}}
				style={{ marginBottom: 20 }}
				data={reviews}
				columns={[
					{
						Header: Strings.fields.photo,
						id: "photo",
						accessor: (row: any) => row.user?.photo,
						type: "image",
						maxWidth: 65
					},
					{
						Header: Strings.fields.name,
						id: "user",
						accessor: (row: any) => row.user?.name || "-",
						maxWidth: 250
					},
					{
						Header: Strings.fields.message,
						id: "text",
						Cell: (elem: any) => this.renderComment(elem.cell.row.original?.text)
					},
					{
						Header: Strings.products.review,
						id: "review",
						Cell: (elem: any) => {
							const rateElem: ReactNode[] = [];
							const rating = elem.cell.row.original?.rating;

							for (let i = 0; i < 5; i++) {
								if (i < Math.floor(rating || 0)) {
									rateElem.push(<Icon name="star-full" key={i} />);
									continue;
								}
								rateElem.push(<Icon name="star-empty" key={i} />);
							}

							return rateElem;
						},
						maxWidth: 100
					},
					{
						Header: Strings.products.showComment,
						id: "show",
						accessor: (row: any) => row.show || false,
						Filter: () => null,
						maxWidth: 150
					},
					{
						Header: Strings.products.showCommentInHome,
						id: "showInHome",
						accessor: (row: any) => row.showInHome || false,
						Filter: () => null,
						maxWidth: 150
					}
				]}
				filterable
				paginated
				actions={{
					edit: (obj: Review) => ({
						onClick: () => {
							this.setState({ showReviewDrawer: true, tempReview: JSON.parse(JSON.stringify(obj)) });
						}
					}),
					remove: (obj: Review) => ({
						onClick: () => this.deleteComment(obj._id!)
					})
				}}
			/>
		);
	}

	renderReviewDrawer() {
		const { showReviewDrawer } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="composition" />
						<p>{Strings.products.editReview}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button type="button" className="SidebarFooterButton --button-confirm" onClick={() => this.saveReview()}>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() =>
								this.setState({
									showReviewDrawer: false,
									tempReview: undefined
								})
							}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				visible={showReviewDrawer}
				onClose={() =>
					this.setState({
						showReviewDrawer: false,
						tempReview: undefined
					})
				}
				width={mobile ? "100%" : 500}
			>
				{this.renderReviewDrawerContent()}
			</Drawer>
		);
	}

	renderReviewDrawerContent() {
		const { tempReview } = this.state;

		return (
			<Row gutter={[20, 20]}>
				<Col xs={24}>
					<label htmlFor="drawer_review_name" className="InputLabel">
						{Strings.fields.name}
					</label>
					<Input id="drawer_review_name" placeholder={Strings.fields.name} value={tempReview?.user?.name} disabled />
				</Col>
				<Col xs={24}>
					<label htmlFor="drawer_review_comment" className="InputLabel">
						{Strings.fields.message}
					</label>
					<Input.TextArea
						id="drawer_review_comment"
						placeholder={Strings.fields.message}
						value={tempReview?.text || ""}
						rows={6}
						onChange={(e) => {
							const value = e.target.value;
							this.setState((prevState: any) => ({ tempReview: { ...prevState.tempReview, text: value } }));
						}}
					/>
				</Col>
				<Col xs={24}>
					<div className={`General_ColorFul_Switch ${tempReview?.show ? "__active" : ""}`}>
						<span>{Strings.products.showComment}</span>
						<Switch
							className={`Switch ${tempReview?.show ? "__active" : ""}`}
							checked={tempReview?.show || false}
							size="small"
							onChange={(value: any) =>
								this.setState((prevState: any) => ({ tempReview: { ...prevState.tempReview, show: value } }))
							}
						/>
					</div>
				</Col>
				<Col xs={24}>
					<div className={`General_ColorFul_Switch ${tempReview?.showInHome ? "__active" : ""}`}>
						<span>{Strings.products.showCommentInHome}</span>
						<Switch
							className={`Switch ${tempReview?.showInHome ? "__active" : ""}`}
							checked={tempReview?.showInHome || false}
							size="small"
							onChange={(value: any) =>
								this.setState((prevState: any) => ({ tempReview: { ...prevState.tempReview, showInHome: value } }))
							}
						/>
					</div>
				</Col>
			</Row>
		);
	}

	renderComponentDrawer() {
		const { tempComponent, showCompositionDrawer } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="composition" />
						<p>{tempComponent?._id ? Strings.products.editComponent : Strings.products.addComponent}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button type="button" className="SidebarFooterButton --button-confirm" onClick={() => this.saveComponent()}>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() =>
								this.setState({
									showCompositionDrawer: false,
									tempComponent: undefined
								})
							}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				visible={showCompositionDrawer}
				onClose={() =>
					this.setState({
						showCompositionDrawer: false,
						tempComponent: undefined
					})
				}
				width={mobile ? "100%" : 500}
			>
				{this.renderCompositionDrawerContent()}
			</Drawer>
		);
	}

	renderCompositionDrawerContent() {
		const { tempComponent, drawerLanguage } = this.state;

		const dailyValuesUnitOptions = (
			<Select
				value={tempComponent?.dailyValueUnit}
				onChange={(e) => {
					this.setState((prevState) => ({
						tempComponent: {
							...(prevState.tempComponent as Component),
							dailyValueUnit: e
						}
					}));
				}}
			>
				<Select.Option value="g">g</Select.Option>
				<Select.Option value="mg">mg</Select.Option>
				<Select.Option value="µg">µg</Select.Option>
				<Select.Option value="l">l</Select.Option>
				<Select.Option value="ml">ml</Select.Option>
			</Select>
		);

		const dailyValuesUnitChildrenOptions = (
			<Select
				value={tempComponent?.dailyValueUnitChildren}
				onChange={(e) => {
					this.setState((prevState) => ({
						tempComponent: {
							...(prevState.tempComponent as Component),
							dailyValueUnitChildren: e
						}
					}));
				}}
			>
				<Select.Option value="g">g</Select.Option>
				<Select.Option value="mg">mg</Select.Option>
				<Select.Option value="µg">µg</Select.Option>
				<Select.Option value="l">l</Select.Option>
				<Select.Option value="ml">ml</Select.Option>
			</Select>
		);

		return (
			<Row gutter={[20, 10]}>
				<Col xs={24}>
					<div className="DrawerLanguageWrapper">
						<Select
							style={{ minWidth: 50 }}
							value={drawerLanguage}
							onChange={(elem: any) => {
								this.setState({ language: elem });
							}}
						>
							{LANGUAGES.map((lang: any, index: number) => {
								return (
									<Select.Option key={`select_option_${lang}_${index}`} value={lang.value}>
										{lang.label}
									</Select.Option>
								);
							})}
						</Select>
					</div>
				</Col>
				<Col xs={24}>
					<label htmlFor="drawer_component" className="InputLabel --label-required">
						{Strings.products.component}
					</label>
					<Input
						id="drawer_component"
						placeholder={Strings.products.component}
						value={tempComponent?.component?.[drawerLanguage as keyof LanguageSchema] || ""}
						onChange={(e) => {
							const value = e.target.value;
							this.setState((prevState: State) => ({
								tempComponent: {
									...prevState.tempComponent,
									component: {
										...prevState.tempComponent?.component,
										[drawerLanguage]: value
									}
								} as Component
							}));
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="drawer_component" className="InputLabel --label-required">
						{Strings.products.dailyValue}
					</label>
					<Input
						addonAfter={dailyValuesUnitOptions}
						value={tempComponent?.dailyValue}
						placeholder={Strings.products.dailyValue}
						onChange={(e) => {
							const regex = /^[0-9.,*]*$/;
							if (!regex.test(e.target.value)) return;

							const value = e.target.value;

							this.setState((prevState: State) => ({
								tempComponent: {
									...prevState.tempComponent,
									dailyValue: value
								} as Component
							}));
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="nrv" className="InputLabel">
						{Strings.products.nrv}
					</label>
					<Input
						id="nrv"
						placeholder={Strings.products.nrv}
						value={tempComponent?.nrv || ""}
						suffix="%"
						onChange={(e) => {
							const regex = /^[0-9.,*]*$/;
							if (!regex.test(e.target.value)) return;

							const value = e.target.value;
							this.setState((prevState: State) => ({
								tempComponent: {
									...prevState.tempComponent,
									nrv: value
								} as Component
							}));
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="drawer_component" className="InputLabel">
						{Strings.products.dailyValueChildren}
					</label>
					<Input
						addonAfter={dailyValuesUnitChildrenOptions}
						value={tempComponent?.dailyValueChildren}
						placeholder={Strings.products.dailyValueChildren}
						onChange={(e) => {
							const regex = /^[0-9.,*]*$/;
							if (!regex.test(e.target.value)) return;

							const value = e.target.value;

							this.setState((prevState: State) => ({
								tempComponent: {
									...prevState.tempComponent,
									dailyValueChildren: value
								} as Component
							}));
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="nrvChildren" className="InputLabel">
						{Strings.products.nrvChildren}
					</label>
					<Input
						id="nrvChildren"
						placeholder={Strings.products.nrvChildren}
						value={tempComponent?.nrvChildren || ""}
						suffix="%"
						onChange={(e) => {
							const regex = /^[0-9.,*]*$/;
							if (!regex.test(e.target.value)) return;

							const value = e.target.value;
							this.setState((prevState: State) => ({
								tempComponent: {
									...prevState.tempComponent,
									nrvChildren: value
								} as Component
							}));
						}}
					/>
				</Col>
			</Row>
		);
	}

	renderProducts() {
		const { suggestedProducts = [], categories = [], segments = [] } = this.state;

		const tableProps = {} as any;
		if (categories?.length === 0 && segments?.length === 0) {
			tableProps.add = {
				tooltip: Strings.banners.addBanner,
				onClick: () =>
					this.setState({
						showProductDrawer: true,
						productSearch: "",
						defaultProducts: []
					})
			};
		}

		return (
			<Table
				title={{
					icon: "box",
					title: Strings.products.suggestedProducts
				}}
				style={{ margin: "20px 0" }}
				data={suggestedProducts}
				columns={[
					{
						Header: Strings.fields.image,
						id: "images",
						accessor: (row: any) => row.images?.find((img: { url: string; favorite: boolean }) => img.favorite)?.url,
						type: "image",
						maxWidth: 65
					},
					{
						Header: Strings.fields.name,
						id: "name",
						accessor: (row: any) => translate(row.name)
					},
					{
						Header: Strings.products.ean,
						id: "ean",
						accessor: (row: any) => row?.ean || "-"
					}
				]}
				paginated
				add={{
					tooltip: Strings.banners.addBanner,
					onClick: () =>
						this.setState({
							showProductDrawer: true,
							productSearch: "",
							defaultProducts: []
						})
				}}
				actions={{
					remove: (obj: any) => ({
						onClick: () => this.deleteProduct(obj._id)
					})
				}}
			/>
		);
	}

	renderProductsDrawer() {
		const { showProductDrawer } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="tax-settings" />
						<p>{Strings.products.addProduct}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button type="button" className="SidebarFooterButton --button-confirm" onClick={() => this.addProducts()}>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() =>
								this.setState({
									showProductDrawer: false,
									productSearch: "",
									defaultProducts: []
								})
							}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				closable={false}
				onClose={() =>
					this.setState({
						showProductDrawer: false,
						productSearch: "",
						defaultProducts: []
					})
				}
				visible={showProductDrawer}
				width={mobile ? "100%" : 600}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	renderDrawerContent() {
		const { defaultProducts, tempProducts, productSearch, suggestedProducts = [], searchingProducts } = this.state;

		const filteredProducts = defaultProducts.filter((product) => !suggestedProducts.some((prod) => prod._id === product._id));

		return (
			<Row gutter={[20, 10]}>
				<Col xs={24}>
					<label htmlFor="products_search" className="InputLabel">
						{Strings.generic.search}
					</label>
					<Search
						id="products_search"
						placeholder={Strings.generic.search}
						value={productSearch}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							this.setState({ productSearch: e.target.value, searchingProducts: true }, () => {
								if (this.productTimeout) clearInterval(this.productTimeout);
								this.productTimeout = setTimeout(() => {
									this.getProducts();
								}, 500);
							});
						}}
						allowClear
					/>
				</Col>
				{filteredProducts.length === 0 && !searchingProducts && productSearch.length > 0 && (
					<Col xs={24}>
						<p>{Strings.products.noResults}</p>
					</Col>
				)}
				{filteredProducts.length === 0 && searchingProducts && productSearch.length > 0 && (
					<Col xs={24}>
						<p>{Strings.products.searching}</p>
					</Col>
				)}
				{filteredProducts.length === 0 && productSearch.length === 0 && (
					<Col xs={24}>
						<p>{Strings.products.waitingForInput}</p>
					</Col>
				)}
				<Col xs={24}>
					<Row gutter={[20, 20]}>
						{filteredProducts.length > 0 &&
							productSearch.length > 0 &&
							filteredProducts.map((product, index: number) => {
								const checked = tempProducts.some((prod: Product) => prod._id === product._id);

								return (
									<React.Fragment key={product._id}>
										{index === 0 && <hr />}
										<Col xs={24}>
											<button
												className="DrawerProductBlock"
												onClick={() => {
													if (checked) {
														const index = tempProducts.findIndex((prod: Product) => prod._id === product._id);
														if (index > -1) {
															tempProducts.splice(index, 1);
														}
													} else {
														tempProducts.push(JSON.parse(JSON.stringify(product)));
													}

													this.setState({ tempProducts });
												}}
											>
												<div className="DrawerProduct">
													<div
														className="DrawerProductImage"
														style={{
															backgroundImage: `url(${
																product.images?.find(
																	(img: { url: string; favorite: boolean }) => img.favorite
																)?.url ||
																product.images?.[0]?.url ||
																placeholder
															})`
														}}
													/>
													<div className="DrawerProductInfo">
														<p>{translate(product.name)}</p>
														<p>EAN: {product.ean}</p>
													</div>
												</div>
												<span className={`DrawerProductCheckbox${checked ? " --checked" : ""}`}>
													<Icon name="correct-symbol" />
												</span>
											</button>
										</Col>
									</React.Fragment>
								);
							})}
					</Row>
				</Col>
			</Row>
		);
	}

	renderKitProduct() {
		const { isKit, name, price, kitLevel, description, reference, ean, weight, stock, language } = this.state;

		if (!isKit) return null;

		return (
			<ContentWrapper>
				<Row gutter={[20, 10]}>
					<Col xs={24}>
						<div className="ScreenHeader">
							<div className="ScreenHeaderLeft">
								<Icon name="box" />
								<h2>{Strings.products.details}</h2>
							</div>
						</div>
					</Col>
					<Col xs={24}>
						<Row gutter={[20, 10]}>
							<Col xs={24} md={12}>
								<label htmlFor="product_name" className="InputLabel --label-required">
									{Strings.fields.name}
								</label>
								<Input
									id="product_name"
									style={{ height: 40 }}
									value={name?.[language as keyof LanguageSchema] || ""}
									placeholder={Strings.fields.name}
									onChange={(event: any) => {
										const value = event.target.value;

										this.setState((prevState: State) => ({
											name: {
												...prevState.name,
												[language]: value as keyof LanguageSchema
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</Col>
						</Row>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="product_price" className="InputLabel --label-required">
							{Strings.products.price}
						</label>
						<InputNumber
							className="NumberInput --input-currency"
							id="product_price"
							placeholder="0.00"
							prefix="€"
							min={0}
							step={0.01}
							value={price || undefined}
							onChange={(value) => {
								this.setState({ price: value, hasUnsavedFields: true });
							}}
						/>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="product_kit_level" className="InputLabel --label-required">
							{Strings.settings.successLevel}
						</label>
						<Select
							style={{ width: "100%" }}
							value={kitLevel}
							placeholder={Strings.settings.successLevel}
							onChange={(value: string) => {
								this.setState({ kitLevel: value, hasUnsavedFields: true });
							}}
							allowClear={false}
							showArrow
							filterOption={(input: any, option: any) => option?.toLowerCase().indexOf(input.toLowerCase())}
						>
							<Select.Option value="junior">{Strings.products.junior}</Select.Option>
							<Select.Option value="executive">{Strings.products.executive}</Select.Option>
						</Select>
					</Col>
					<Col xs={24} md={6}>
						<label htmlFor="product_reference" className="InputLabel --label-required">
							{Strings.products.reference}
						</label>
						<Input
							id="product_reference"
							style={{ height: 40 }}
							value={reference || ""}
							placeholder={Strings.products.reference}
							onChange={(event: any) => {
								const value = event.target.value;

								this.setState({
									reference: value,
									hasUnsavedFields: true
								});
							}}
						/>
					</Col>
					<Col xs={24} md={6}>
						<label htmlFor="product_ean" className="InputLabel --label-required">
							{Strings.products.ean}
						</label>
						<Input
							id="product_ean"
							style={{ height: 40 }}
							value={ean || ""}
							placeholder={Strings.products.ean}
							onChange={(event: any) => {
								const value = event.target.value;

								this.setState({
									ean: value,
									hasUnsavedFields: true
								});
							}}
						/>
					</Col>
					<Col xs={24} md={6}>
						<label htmlFor="product_stock" className="InputLabel --label-required">
							{Strings.products.stock}
						</label>
						<InputNumber
							className="NumberInput"
							id="product_stock"
							value={stock}
							placeholder={Strings.products.stock}
							min={0}
							step={1}
							onChange={(value: number) => {
								this.setState({
									stock: value,
									hasUnsavedFields: true
								});
							}}
						/>
					</Col>
					<Col xs={24} md={6}>
						<label htmlFor="product_weight" className="InputLabel">
							{Strings.fields.weight}
						</label>
						<InputNumber
							className="NumberInput --input-weight"
							id="product_weight"
							placeholder="1.000"
							prefix="kg"
							min={0}
							step={0.001}
							value={weight || undefined}
							onChange={(value) => {
								this.setState({ weight: value, hasUnsavedFields: true });
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="product_description" className="InputLabel --label-required">
							{Strings.fields.description}
						</label>
						<QuillEditor
							key={`quill_kit_description_${language}`}
							style={{ height: 390 }}
							value={description?.[language as keyof LanguageSchema] || ""}
							onChange={(value: any) => {
								this.setState((prevState: State) => ({
									description: {
										...prevState.description,
										[language]: value
									},
									hasUnsavedFields: true
								}));
							}}
						/>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	render() {
		const { isKit } = this.state;

		return (
			<div className="ProductDetailScreen">
				<Helmet>
					<title>{Strings.products.title}</title>
					<meta name="description" content="Edit your product's information" />
				</Helmet>
				{!isKit ? (
					<React.Fragment>
						{this.renderProductInformation()}
						{this.renderSpecifics()}
						{this.renderGallery()}
						{this.renderProducts()}
						{this.renderReviews()}
						{this.renderComponentDrawer()}
						{this.renderProductsDrawer()}
						{this.renderReviewDrawer()}
					</React.Fragment>
				) : (
					<React.Fragment>
						{this.renderKitProduct()}
						{this.renderGallery()}
					</React.Fragment>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state: { language: keyof LanguageSchema }) => ({
	language: state.language
});

export default connect(mapStateToProps)(ProductDetail);
