import React from "react";
import { connect } from "react-redux";
import type { Props, State } from "./types";
import { push, replace } from "connected-react-router";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { Helmet } from "react-helmet";
import { ContentWrapper, QuillEditor } from "components";
import { Col, Input, notification, Row } from "antd";
import { translate } from "utils/utils";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import "./styles.scss";

class FaqDetail extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			faq: null,
			isActive: false,
			question: null,
			answer: null,
			pos: 0,
			language: "pt",
			hasUnsavedFields: false,
			isNew: props.match.params.id === "new"
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.faqs.single));

		this.getData();
		this.breadcrumb();
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				const { faq } = this.state;

				return {
					locations: [
						{
							text: Strings.sidebar.settings,
							route: "/settings",
							icon: "preferences"
						},
						{
							icon: "support-tickets",
							text: Strings.settings.faqs,
							route: "/settings/faqs"
						},
						{
							icon: faq?._id ? "pencil-outline" : "plus",
							text: translate(faq?.question) || "new"
						}
					],
					actions: [
						{
							type: "switch",
							text: Strings.generic.active,
							value: this.state.isActive,
							small: {
								switch: true,
								text: true
							},
							separator: "right",
							onClick: (value: boolean) => this.setState({ isActive: value, hasUnsavedFields: true })
						},
						{
							type: "language",
							value: this.state.language,
							showArrow: true,
							showSearch: false,
							separator: "right",
							onChange: (value: string) => this.setState({ language: value })
						},
						{
							type: "button",
							text: Strings.generic.save,
							disabled: !this.state.hasUnsavedFields,
							className: "BreadcrumbSaveButton",
							isSave: true,
							hasIcon: true,
							onClick: () => this.saveFaq()
						}
					]
				};
			})
		);
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { dispatch, match } = this.props;

		if (!match?.params.id || match?.params.id === "new") {
			dispatch(setTitle(`${Strings.faqs.single} - ${Strings.generic.newM}`));
			return;
		}

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriFaqs(match?.params.id)
			});

			if (response.ok) {
				const { faqs } = response.data.results || {};

				dispatch(setTitle(`${Strings.faqs.single} - ${translate(faqs.question)}`));

				this.setState({ faq: faqs, ...faqs });
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});

			dispatch(push("/settings/faqs"));
		}

		dispatch(setLoader(false));
	}

	async saveFaq() {
		const { isActive, question, answer, isNew } = this.state;
		const { dispatch, match } = this.props;

		if (!this.isFaqValid()) return;

		dispatch(setLoader(true));

		try {
			const request = isNew ? API.post : API.put;
			const response = await request({
				url: Endpoints.uriFaqs(isNew ? "" : match?.params.id),
				data: { isActive, question, answer }
			});

			if (response.ok) {
				const { faq } = response.data.results || {};
				if (isNew) {
					dispatch(replace(`/settings/faqs/${faq._id}`));
					dispatch(setBreadcrumb(null));
					this.breadcrumb();
				}

				this.setState({ ...faq, faq, isNew: false });

				notification.success({
					message: Strings.sidebar.faqs,
					description: response?.data?.message || (isNew ? Strings.faqs.created : Strings.faqs.updated),
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	isFaqValid() {
		const { answer, question } = this.state;

		if (!translate(question)) {
			notification.warning({
				message: Strings.errors.invalidFields,
				description: Strings.faqs.questionMissing,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		if (!translate(answer)) {
			notification.warning({
				message: Strings.errors.invalidFields,
				description: Strings.faqs.answerMissing,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		return true;
	}

	render() {
		const { question, answer, language } = this.state;

		return (
			<div className="Screen-Faq">
				<Helmet>
					<title>{Strings.faqs.single}</title>
					<meta name="description" content="Edit your faq" />
				</Helmet>
				<ContentWrapper extraStyle={{ padding: 20 }}>
					<Row gutter={[20, 20]}>
						<Col xs={24}>
							<label htmlFor="faq_web_title" className="InputLabel --label-required">
								{Strings.faqs.question}
							</label>
							<Input
								id="faq_question"
								value={question?.[language] || ""}
								placeholder={Strings.fields.question}
								onChange={(event: any) => {
									const value = event.target.value;

									this.setState((prevState: State) => ({
										question: {
											...prevState.question,
											[language]: value
										},
										hasUnsavedFields: true
									}));
								}}
							/>
						</Col>
						<Col xs={24}>
							<label htmlFor="faq_web_sub_title" className="InputLabel --label-required">
								{Strings.faqs.answer}
							</label>
							<QuillEditor
								key={`quill_${language}`}
								value={answer?.[language]}
								onChange={(value: any) => {
									this.setState((prevState: State) => ({
										answer: {
											...prevState.answer,
											[language]: value
										},
										hasUnsavedFields: true
									}));
								}}
							/>
						</Col>
					</Row>
				</ContentWrapper>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(FaqDetail);
