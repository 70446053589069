import React from "react";
import { connect } from "react-redux";
import type { HelpFile } from "screens/Admin/HelpFiles/types";
import { push } from "connected-react-router";
import { store } from "store";
import { Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { translate } from "utils/utils";
import Strings from "utils/strings";
import YoutubeThumbnail from "assets/images/youtube.png";
import pdfThumbnail from "assets/images/pdf.png";
import "./styles.scss";

type State = { clicked: boolean };
type Props = HelpFile & { dispatch: typeof store.dispatch, language: string };

class File extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			clicked: false,
		}
	}

	renderOptions() {
		const { _id, url, fileType, dispatch, onDelete } = this.props;

		return (
			<div className="FileOptions">
				{fileType === "video" ? (
					<Tooltip title={Strings.settings.previewVideo}>
						<button className="FileOption" onClick={(e: React.MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							this.setState({ clicked: true })
						}}>
							<PlayCircleOutlined translate={null} />
						</button>
					</Tooltip>
				) : (
					<Tooltip title={Strings.settings.viewFile}>
						<button className="FileOption" onClick={(e: React.MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							window.open(translate(url), "_blank")
						}}>
							<EyeOutlined translate={null} />
						</button>
					</Tooltip>
				)}
				<Tooltip title={Strings.settings.editFile}>
					<button className="FileOption" onClick={() => dispatch(push(`/settings/files/${_id}`))}>
						<EditOutlined translate={null} />
					</button>
				</Tooltip>
				<Tooltip title={Strings.settings.deleteFile}>
					<button className="FileOption FileOptionDelete" onClick={(e: React.MouseEvent) => {
						e.preventDefault();
						e.stopPropagation();

						if (typeof onDelete === "function") {
							onDelete();
						}
					}}>
						<DeleteOutlined translate={null} />
					</button>
				</Tooltip>
			</div>
		)
	}

	renderLabel() {
		const { successLevel } = this.props;

		return (
			<div className="FileLabel">
				<p>{Strings.settings.level} {successLevel}</p>
			</div>
		);
	}

	render() {
		const { clicked } = this.state;
		const { _id, url, title, description, fileType, dispatch, readonly = false } = this.props;

		return (
			<div
				onClick={() => {
					if (readonly && fileType !== "video") {
						window.open(translate(url), "_blank")
					} else if (readonly && fileType === "video") {
						this.setState({ clicked: true });
					} else if (!readonly) {
						dispatch(push(`/settings/files/${_id}`));
					}
				}}
				className="FileWrapper"
			>
				{!readonly && this.renderLabel()}
				{!readonly && this.renderOptions()}
				{!clicked ?
					// @ts-ignore
					<img alt={fileType === "video" ? "youtube" : "pdf"} src={fileType === "video" ? YoutubeThumbnail : pdfThumbnail} loading="lazy" />
					:
					<iframe
						width="100%"
						height="210px"
						src={`${translate(url)}?modestbranding=1`}
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowFullScreen
					/>}
				<div className="FileDescription">
					<h2>{translate(title).length > 100 ? `${translate(title).substring(0, 100)}...` : translate(title)}</h2>
					{translate(description)?.length > 0 ? <small>{translate(description).length > 100 ? `${translate(description).substring(0, 100)}...` : translate(description)}</small> : null}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: { language: string }) => ({
	language: state.language,
})

export default connect(mapStateToProps)(File);