import React, { Component } from "react";
import { connect } from "react-redux";
import { Manual } from "../Manuals/types";
import { Props } from "./types";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import { Col, Input, Row, notification } from "antd";
import Strings from "utils/strings";
import { push } from "connected-react-router";
import { LanguageSchema } from "utils/types";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import { translate } from "utils/utils";
import { Helmet } from "react-helmet";
import { ContentWrapper } from "components";
import Dropzone from "react-dropzone";

type State = {
	isNew: boolean;
	manual?: Manual;
	hasUnsavedChanges: boolean;
	language: keyof LanguageSchema;
	uploadFile?: {
		[key in keyof LanguageSchema]?: File;
	};
} & Manual;

class ManualDetail extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			title: { pt: "", en: "" },
			description: { pt: "", en: "" },
			url: { pt: "", en: "" },
			hasUnsavedChanges: false,
			isNew: props.match.params.id === "new",
			language: "pt",
			isActive: true
		};
	}

	componentDidMount(): void {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		this.breadcrumb();
		this.getData();
	}

	componentDidUpdate(): void {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { isNew } = this.state;
		const { dispatch, match } = this.props;

		if (isNew) return;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriManuals(`manuals/${match.params.id}`)
			});

			if (response.ok) {
				const manual: Manual = response.data.results.manual || {};
				this.setState({ ...manual, manual });
			} else {
				notification.error({
					message: Strings.settings.manuals,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});

				dispatch(push("/settings/manuals"));
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
			dispatch(push("/settings/manuals"));
		}

		dispatch(setLoader(false));
	}

	async saveManual() {
		const { isNew, title, description, url, isActive, uploadFile } = this.state;
		const { dispatch, match } = this.props;

		if (!this.validManual()) return;

		dispatch(setLoader(true));

		try {
			const request = isNew ? API.post : API.put;
			const body = new FormData();

			body.append("title", JSON.stringify(title));
			body.append("description", JSON.stringify(description));
			body.append("url", JSON.stringify(url));
			body.append("isActive", String(isActive));
			body.append("type", "manual");

			if (uploadFile) {
				Object.entries(uploadFile).forEach(([key, value]) => {
					if (value) {
						body.append(`manual_${key}`, value);
					}
				});
			}

			const response = await request({
				url: Endpoints.uriManuals(isNew ? "" : match.params.id),
				data: body
			});

			if (response?.ok) {
				const manual: Manual = response.data.results.manual || {};
				this.setState({ ...manual, manual, hasUnsavedChanges: false });

				if (isNew) {
					dispatch(push("/settings/manuals"));
				}

				notification.success({
					message: Strings.settings.manuals,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.settings.manuals,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				const { title, isNew, _id } = this.state;

				return {
					locations: [
						{
							text: Strings.sidebar.settings,
							route: "/settings",
							icon: "preferences"
						},
						{
							text: Strings.settings.manuals,
							route: "/settings/manuals",
							icon: "duplicate"
						},
						{
							text: isNew ? Strings.settings.newManual : translate(title) || Strings.generic.loading,
							icon: _id ? "pencil-outline" : "plus"
						}
					],
					actions: [
						{
							type: "switch",
							text: Strings.generic.active,
							value: this.state.isActive,
							small: {
								switch: true,
								text: true
							},
							separator: "right",
							onClick: (value: boolean) => this.setState({ isActive: value, hasUnsavedChanges: true })
						},
						{
							type: "language",
							value: this.state.language,
							showArrow: true,
							showSearch: false,
							separator: "right",
							onChange: (value: keyof LanguageSchema) => this.setState({ language: value })
						},
						{
							type: "button",
							text: Strings.generic.save,
							disabled: !this.state.hasUnsavedChanges,
							className: "BreadcrumbSaveButton",
							isSave: true,
							hasIcon: true,
							onClick: () => this.saveManual()
						}
					]
				};
			})
		);
	}

	onDrop(files: any) {
		const { language } = this.state;

		try {
			const file = files[files.length - 1];

			this.setState((prevState: State) => ({
				uploadFile: {
					...prevState.uploadFile,
					[language]: file
				},
				hasUnsavedChanges: true
			}));
		} catch (err) {
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5
			});
		}
	}

	validManual() {
		const { title } = this.state;

		if (!translate(title)) {
			notification.error({
				message: Strings.settings.manuals,
				description: Strings.settings.titleMandatory,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		return true;
	}

	render() {
		const { title, description, url, language, uploadFile } = this.state;

		const linkStartsWithHttp = url?.[language]?.startsWith("http");

		return (
			<div className="ScreenManual">
				<Helmet>
					<title>{Strings.settings.manuals}</title>
					<meta name="description" content={Strings.settings.manualsSubTitle} />
				</Helmet>
				<ContentWrapper>
					<Row gutter={[20, 10]}>
						<Col xs={24} md={12}>
							<Row gutter={[20, 10]}>
								<Col xs={24}>
									<label htmlFor="manual_title" className="InputLabel --label-required">
										{Strings.pages.title}
									</label>
									<Input
										id="manual_title"
										style={{ height: 40 }}
										value={title?.[language] || ""}
										placeholder={Strings.pages.title}
										onChange={(event: any) => {
											const value = event.target.value;

											this.setState((prevState: State) => ({
												title: {
													...prevState.title,
													[language]: value
												},
												hasUnsavedChanges: true
											}));
										}}
									/>
								</Col>
								{linkStartsWithHttp ? (
									<Col xs={24}>
										<label htmlFor="manual_url" className="InputLabel --label-required">
											{Strings.settings.url}
										</label>
										<Input
											id="manual_url"
											style={{ height: 40 }}
											readOnly={true}
											value={url?.[language] || ""}
											placeholder={Strings.settings.url}
											suffix={
												url[language]?.includes("/manuals/help_") ? (
													<span
														onClick={(e: React.MouseEvent) => {
															e.preventDefault();
															e.stopPropagation();
															this.setState((prevState: State) => ({
																url: { ...prevState.url, [language]: "" }
															}));
														}}
													>
														<CloseOutlined translate={null} />
													</span>
												) : null
											}
											onChange={(event: any) => {
												const value = event.target.value;

												this.setState((prevState: State) => ({
													url: {
														...prevState.url,
														[language]: value
													},
													hasUnsavedChanges: true
												}));
											}}
										/>
									</Col>
								) : (
									<Col xs={24}>
										<Dropzone
											accept="application/pdf"
											className="FilePdfDropzone"
											onDrop={(files: File[]) => this.onDrop(files)}
										>
											<label htmlFor="file" className="InputLabel --label-required">
												{Strings.settings.file}
											</label>
											<Input
												id="file"
												style={{ height: 40, cursor: "pointer" }}
												value={uploadFile?.[language]?.name || ""}
												readOnly
												placeholder={Strings.settings.selectPdf}
												suffix={
													uploadFile?.[language] ? (
														<span
															onClick={(e: React.MouseEvent) => {
																e.preventDefault();
																e.stopPropagation();
																this.setState((prevState: State) => ({
																	uploadFile: { ...prevState.uploadFile, [language]: null }
																}));
															}}
														>
															<CloseOutlined translate={null} />
														</span>
													) : (
														<UploadOutlined translate={null} />
													)
												}
											/>
										</Dropzone>
									</Col>
								)}
							</Row>
						</Col>
						<Col xs={24} md={12}>
							<label htmlFor="manual_description" className="InputLabel">
								{Strings.fields.description}
							</label>
							<Input.TextArea
								id="manual_description"
								rows={4}
								style={{ height: "calc(100% - 30px)", resize: "none" }}
								value={description?.[language] || ""}
								placeholder={Strings.fields.description}
								onChange={(event: any) => {
									const value = event.target.value;

									this.setState((prevState: State) => ({
										description: {
											...prevState.description,
											[language]: value
										},
										hasUnsavedChanges: true
									}));
								}}
							/>
						</Col>
					</Row>
				</ContentWrapper>
			</div>
		);
	}
}

const mapStateToProps = (state: { language: string }) => ({
	language: state.language
});

export default connect(mapStateToProps)(ManualDetail);
