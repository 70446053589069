import React from 'react';
import { connect } from 'react-redux';
import type { Props, State } from './types';
import { ContentWrapper, Icon } from 'components';
import { setLoader, setTitle } from 'store/actions';
import { notification, Collapse } from 'antd';
import { translate } from 'utils/utils';
import { API, Endpoints } from 'utils/api';
import Strings from 'utils/strings';
import './styles.scss';

const { Panel } = Collapse;

class FaqsForPartners extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			faqs: [],
		}
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		this.getData();
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriFaqs(),
			});

			if (response.ok) {
				const { faqs = [] } = response.data.results || {};
				this.setState({ faqs });
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string || Strings.serverErrors.wentWrong,
				placement: 'bottomRight',
				duration: 5,
			});

		}

		dispatch(setLoader(false));
	}

	render() {
		const { faqs } = this.state;

		return (
			<div className="Screen-Faq">
				<ContentWrapper>
					<div className="ScreenHeader --mb-20">
						<div className="ScreenHeaderLeft">
							<Icon name="warning" />
							<h2>{Strings.settings.faqs}</h2>
						</div>
					</div>
					{faqs?.length > 0 ?
						(
							<Collapse defaultActiveKey={['1']}>
								{faqs?.map((data, index: number) => (
									<Panel
										key={data._id}
										showArrow={false}
										header={(<strong>{index + 1}. {translate(data.question)}</strong>)}
									>
										<div dangerouslySetInnerHTML={{ __html: translate(data.answer) }} />
									</Panel>
								))}
							</Collapse>
						) : (
							<div className="FileLevelHeader --stripped">
								<h3>{Strings.settings.noFaqsAvailable}</h3>
							</div>
						)}
				</ContentWrapper>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
});

export default connect(mapStateToProps)(FaqsForPartners);
