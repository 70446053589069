import React from "react";
import { connect } from "react-redux";
import type { Crm, Props, State } from "./types";
import { push, replace } from "connected-react-router";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { Helmet } from "react-helmet";
import { ContentWrapper, PhoneInput } from "components";
import { Col, Input, notification, Row, Select } from "antd";
import { translate } from "utils/utils";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import { DateTime } from "luxon";
import { PieChartOutlined } from "@ant-design/icons";
import "./styles.scss";

class CrmDetail extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			crm: null,
			name: null,
			email: null,
			phone: null,
			partner: null,
			request: null,
			createdAt: null,
			hasUnsavedFields: false,
			closed: false,
			isNew: props.match.params.id === "new"
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.crm.single));

		this.getData();
		this.breadcrumb();
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				const { crm, isNew } = this.state;
				const { partners } = this.props;

				const actions: any[] = [
					{
						type: "button",
						text: Strings.generic.save,
						disabled: !this.state.hasUnsavedFields,
						className: "BreadcrumbSaveButton",
						isSave: true,
						hasIcon: true,
						onClick: () => this.saveCrm()
					}
				];

				if (!isNew) {
					actions.unshift(
						{
							type: "button",
							text: crm?.closed ? Strings.crm.open : Strings.crm.close,
							className: crm?.closed ? "BreadcrumbOpenButton" : "BreadcrumbForgetButton",
							separator: "right",
							onClick: () => this.toggleCrm()
						},
						{
							type: "button",
							text: partners ? Strings.crm.sendToAdmin : Strings.crm.sendToPartner,
							className: "BreadcrumbOpenButton",
							separator: "right",
							onClick: () => this.changeLevel()
						}
					);
				}

				return {
					locations: [
						{
							icon: <PieChartOutlined translate={null} />,
							iconType: "antd",
							text: Strings.sidebar.crm,
							route: "/crm"
						},
						{
							icon: crm?._id ? "pencil-outline" : "plus",
							text: translate(crm?.name) || "new"
						}
					],
					actions
				};
			})
		);
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { isNew } = this.state;
		const { dispatch, match } = this.props;

		const promises = [
			API.get({
				url: Endpoints.uriPartners()
			})
		];

		if (isNew) {
			dispatch(setTitle(`${Strings.crm.single} - ${Strings.generic.newM}`));
		} else {
			promises.push(
				API.get({
					url: Endpoints.uriCrm(match?.params.id)
				})
			);
		}

		dispatch(setLoader(true));

		try {
			const [responsePartners, responseCrm] = await Promise.all(promises);

			let partners = [],
				crm = {} as Crm;
			if (responsePartners?.ok) {
				partners = responsePartners.data.results.users || [];
			} else {
				notification.error({
					message: Strings.sidebar.crm,
					description: responsePartners.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}

			if (!isNew && responseCrm?.ok) {
				crm = responseCrm.data.results.crm;
				dispatch(setTitle(`${Strings.crm.single} - ${translate(crm.name)}`));
			} else if (!isNew) {
				notification.error({
					message: Strings.sidebar.crm,
					description: responseCrm.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}

			this.setState({ crm, ...crm, partners });
		} catch (err) {
			console.log("err", err as string);
			dispatch(push("/crm"));

			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async saveCrm() {
		const { name, partner, email, phone, request, isNew } = this.state;
		const { dispatch, match, partners, user } = this.props;

		if (!this.isCrmValid()) return;

		dispatch(setLoader(true));

		try {
			const apiRequest = isNew ? API.post : API.put;

			const response = await apiRequest({
				url: Endpoints.uriCrm(isNew ? "" : match.params.id),
				data: {
					name,
					email,
					phone,
					partner: partners ? user._id : partner,
					request
				}
			});

			if (response.ok) {
				const { crm } = response.data.results || {};
				if (isNew) {
					dispatch(replace(`/crm/${crm._id}`));
					dispatch(setBreadcrumb(null));
					this.breadcrumb();
				}

				this.setState({ ...crm, crm, isNew: false, hasUnsavedFields: false });

				notification.success({
					message: Strings.sidebar.crm,
					description: response?.data?.message || (isNew ? Strings.crm.created : Strings.crm.updated),
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async changeLevel() {
		const { dispatch, match, partners = false } = this.props;
		const {
			params: { id }
		} = match;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriCrm(`${id}/level`),
				data: { adminOnly: partners }
			});

			if (response.ok) {
				dispatch(push("/crm"));

				notification.success({
					message: Strings.sidebar.crm,
					description: response?.data?.message || Strings.crm.updated,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}
		dispatch(setLoader(false));
	}

	async toggleCrm() {
		const { crm } = this.state;
		const { dispatch, match } = this.props;
		const {
			params: { id }
		} = match;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriCrm(`${id}/status`),
				data: {
					closed: !crm?.closed
				}
			});

			if (response.ok) {
				await this.getData();

				notification.success({
					message: Strings.sidebar.crm,
					description: response?.data?.message || Strings.crm.updated,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}
		dispatch(setLoader(false));
	}

	isCrmValid() {
		const { request, name, email, phone, partner } = this.state;
		const { partners } = this.props;

		if (!name) {
			notification.warning({
				message: Strings.errors.invalidFields,
				description: Strings.crm.nameMissing,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		if (!email) {
			notification.warning({
				message: Strings.errors.invalidFields,
				description: Strings.crm.emailMissing,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		if (!phone) {
			notification.warning({
				message: Strings.errors.invalidFields,
				description: Strings.crm.phoneMissing,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		if (!partners && !partner) {
			notification.warning({
				message: Strings.errors.invalidFields,
				description: Strings.crm.partnerMissing,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		if (!request) {
			notification.warning({
				message: Strings.errors.invalidFields,
				description: Strings.crm.requestMissing,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		return true;
	}

	render() {
		const { name, email, phone, partner, _created, request, partners: partnersList, isNew, closed } = this.state;
		const { partners = false } = this.props;

		return (
			<div className="Screen-Crm">
				<Helmet>
					<title>{Strings.crm.single}</title>
					<meta name="description" content="Edit your CRM" />
				</Helmet>
				<ContentWrapper extraStyle={{ padding: 20 }}>
					<Row gutter={[20, 10]}>
						{(!isNew && (
							<Col xs={24}>
								<label htmlFor="crm_createdAt" className="InputLabel">
									{Strings.fields.date}
								</label>
								<Input
									id="crm_createdAt"
									value={_created ? DateTime.fromISO(_created).toFormat("dd/MM/yyyy") : "-"}
									style={{ height: 40 }}
									placeholder={Strings.fields.createdAt}
									readOnly
									disabled={closed}
								/>
							</Col>
						)) ||
							null}
						<Col xs={24} md={12}>
							<label htmlFor="crm_name" className="InputLabel --label-required">
								{Strings.crm.name}
							</label>
							<Input
								id="crm_name"
								value={name || ""}
								style={{ height: 40 }}
								placeholder={Strings.fields.name}
								disabled={closed}
								onChange={(event: any) => {
									const value = event.target.value;

									this.setState((prevState: State) => ({
										name: value,
										hasUnsavedFields: true
									}));
								}}
							/>
						</Col>
						<Col xs={24} md={12}>
							<label htmlFor="crm_email" className="InputLabel --label-required">
								{Strings.crm.email}
							</label>
							<Input
								id="crm_email"
								value={email || ""}
								style={{ height: 40 }}
								placeholder={Strings.fields.email}
								disabled={closed}
								onChange={(event: any) => {
									const value = event.target.value;

									this.setState((prevState: State) => ({
										email: value,
										hasUnsavedFields: true
									}));
								}}
							/>
						</Col>
						<Col xs={24} md={12}>
							<label htmlFor="crm_phone" className="InputLabel --label-required">
								{Strings.crm.phone}
							</label>
							<PhoneInput
								id="crm_phone"
								defaultCountry="pt"
								value={phone || ""}
								inputClass={`input-phone${closed ? " phone-disabled" : ""}`}
								style={{ height: 40 }}
								inputProps={{
									name: "phone",
									required: true,
									autoComplete: "off",
									placeholder: Strings.fields.phone
								}}
								disabled={closed}
								onChange={(phone: any) => this.setState({ phone, hasUnsavedFields: true })}
							/>
						</Col>
						{!partners ? (
							<Col xs={24} md={12}>
								<label htmlFor="partner" className="InputLabel --label-required">
									{Strings.staff.partner}
								</label>
								<Select
									id="partner"
									key={`partner_${partner}`}
									className="tagsSelector"
									style={{ width: "100%" }}
									placeholder={Strings.placeholders.partner}
									showSearch
									filterOption={(input: any, option: any) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									defaultValue={partner || null}
									disabled={closed}
									onChange={(value: any) =>
										this.setState({
											partner: value,
											hasUnsavedFields: true
										})
									}
								>
									{partnersList?.map((partner: any) => (
										<Select.Option key={`partner_${partner?._id}`} value={partner?._id}>
											{partner?.name}
										</Select.Option>
									))}
								</Select>
							</Col>
						) : null}
						<Col xs={24}>
							<label htmlFor="crm_web_sub_title" className="InputLabel --label-required">
								{Strings.crm.request}
							</label>
							<Input.TextArea
								id="crm_web_sub_title"
								value={request || ""}
								onChangeCapture={(event: any) => {
									const val = event.target.value;
									this.setState((obj) => ({
										request: val,
										hasUnsavedFields: true
									}));
								}}
								disabled={closed}
								style={{ resize: "none" }}
								rows={4}
							/>
						</Col>
					</Row>
				</ContentWrapper>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	user: state.user
});

export default connect(mapStateToProps)(CrmDetail);
