import React, { Component } from "react";
import { Col, DatePicker, Drawer, Row, Select, notification } from "antd";
import { Icon, Table } from "components";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { setLoader, setTitle } from "store/actions";
import { API, Endpoints } from "utils/api";
import strings from "utils/strings";
import { formatPrice } from "utils/utils";
import moment from "moment";
import { debounce } from "lodash";
import pt from "antd/lib/date-picker/locale/pt_PT";
import en from "antd/lib/date-picker/locale/en_US";
import "./styles.scss";

const { RangePicker } = DatePicker;

class Commissions extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			data: [],
			page: 0,
			pageSize: 100,
			total: 0,
			columnSearch: null,
			globalSearch: "",

			filters: {
				startDate: moment().startOf("month"),
				endDate: moment().endOf("month")
			},
			tempFilters: {
				startDate: moment().startOf("month"),
				endDate: moment().endOf("month")
			},
			showDrawer: false,
			origin: undefined
		};
	}

	componentDidMount(): void {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		this.getData();
	}

	async getData(pause?: boolean) {
		const { page, pageSize, columnSearch, globalSearch, filters, origin } = this.state;
		const { dispatch, countries = [] } = this.props;

		setTimeout(
			async () => {
				if (columnSearch !== this.state.columnSearch || globalSearch !== this.state.globalSearch) return;
				dispatch(setLoader(true));

				const body = {
					filters: columnSearch
						? Object.keys(columnSearch).map((elem) =>
								columnSearch[elem] !== ""
									? {
											field: elem,
											query: columnSearch[elem]
									  }
									: {}
						  )
						: [],
					page: page,
					perPage: pageSize,
					search: globalSearch,
					startDate: filters?.startDate.toISOString(),
					endDate: filters?.endDate.toISOString(),
					origin
				};

				try {
					const response = await API.post({
						url: Endpoints.uriCommissions("search"),
						data: body
					});

					if (response.ok) {
						const { commissions = [], redeemedValue, pendingValue, points, expiredValue, total = 0 } = response.data.results;

						for (const commission of commissions) {
							if (commission.origin) {
								const countryCallingCode = commission.origin?.phone?.substring(0, 4).replace("+", "");
								const country = countries.find((elem: any) => elem.callingCodes.includes(countryCallingCode))?.alpha2Code;

								if (country) {
									commission.origin.country = country.toLowerCase();
								}
							}

							if (commission.partner) {
								const countryCallingCode = commission.partner?.phone?.substring(0, 4).replace("+", "");
								const country = countries.find((elem: any) => elem.callingCodes.includes(countryCallingCode))?.alpha2Code;

								if (country) {
									commission.partner.country = country.toLowerCase();
								}
							}
						}

						this.setState({
							data: commissions,
							redeemedValue,
							pendingValue,
							points,
							expiredValue,
							total
						});
					} else {
						notification.error({
							message: strings.sidebar.commissions,
							description: response?.data?.message || strings.serverErrors.wentWrong,
							placement: "bottomRight",
							duration: 5
						});
					}
				} catch (err) {
					notification.error({
						message: strings.serverErrors.title,
						description: err as string,
						placement: "bottomRight",
						duration: 5
					});
				}

				dispatch(setLoader(false));
			},
			pause ? 1000 : 0
		);
	}

	applyFilters() {
		const { tempFilters } = this.state;

		this.setState(
			(prevState: any) => ({
				filters: { ...prevState.filters, startDate: tempFilters.startDate, endDate: tempFilters.endDate },
				showDrawer: false
			}),
			() => this.getData()
		);
	}

	debounceFetcher = debounce(async (value: string) => {
		const body = { search: value, page: 0, perPage: 10 };

		const res = await API.post({ url: Endpoints.uriPartners("search"), data: body });
		if (res?.ok) {
			const { users = [] } = res.data.results;
			this.setState({ originOptions: users });
		}
	}, 800);

	handleSearch = (value: string) => {
		if (value) {
			this.debounceFetcher(value);
			this.setState({ searchOriginParams: value });
		} else {
			this.setState({ originOptions: [], searchOriginParams: "" });
		}
	};

	async redeemCommission(id: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriCommissions(id),
				data: { redeemed: true }
			});

			if (response?.ok) {
				await this.getData();

				notification.success({
					message: strings.sidebar.commissions,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: strings.sidebar.commissions,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		} finally {
			dispatch(setLoader(false));
		}
	}

	get pagination() {
		return {
			total: this.state.total,
			pageIndex: this.state.page,
			pageSize: this.state.pageSize,
			setPage: (page: number, size: number) => {
				this.setState({ page, pageSize: size }, () => this.getData());
			}
		};
	}

	get filtration() {
		const { globalSearch, columnSearch } = this.state;
		return {
			showGlobalSearch: true,
			showColumnSearch: true,
			defaultValues: { globalSearch, columnSearch },
			onGlobalSearch: (globalSearch: string) => {
				this.setState({ globalSearch, page: 0 }, () => this.getData());
			},
			onColumnSearch: (columnSearch: string) => {
				this.setState({ columnSearch, page: 0 }, () => this.getData());
			}
		};
	}

	handleSelect = (dates: any) => {
		const newDates = [];
		if (dates && dates[0]) {
			newDates.push(moment(dates[0]).startOf("day"));
		}

		if (dates && dates[1]) {
			newDates.push(moment(dates[1]).endOf("day"));
		}

		this.setState(
			{
				startDate: newDates[0],
				endDate: newDates[1]
			},
			() => this.getData()
		);
	};

	getType(type: string) {
		switch (type) {
			case "commissions":
				return strings.sidebar.commissions;
			case "bonus":
				return strings.generic.bonus;
			case "sales":
				return strings.orders.sales;
			default:
				return type;
		}
	}

	renderNumber(value: number) {
		if (value > 0) {
			return <span className="CommissionValueGreen">{value}</span>;
		} else if (value < 0) {
			return <span className="CommissionValueRed">{value}</span>;
		}

		return <span>{value}</span>;
	}

	renderValue(value: number) {
		if (value > 0) {
			return <span className="CommissionValueGreen">{formatPrice(value)}</span>;
		} else if (value < 0) {
			return <span className="CommissionValueRed">{formatPrice(value)}</span>;
		}

		return <span>{formatPrice(value)}</span>;
	}

	renderCommissions() {
		const { data = [], filters } = this.state;

		return (
			<div className="CommissionsTable">
				<Table
					title={{
						icon: "organization",
						title: strings.sidebar.commissionsAndBonuses
					}}
					headerOptions={[
						{
							icon: "filter",
							label: strings.generic.filters,
							onClick: () =>
								this.setState({
									showDrawer: true,
									tempFilters: {
										startDate: filters?.startDate,
										endDate: filters?.endDate
									}
								})
						}
					]}
					data={data}
					columns={[
						{
							Header: strings.fields.date,
							id: "date",
							accessor: (row: any) => row.date && DateTime.fromISO(row.date).toLocaleString(DateTime.DATE_SHORT),
							maxWidth: 150,
							Filter: () => null
						},
						{
							Header: strings.settings.type,
							id: "type",
							accessor: (row: any) => this.getType(row.type) || "-",
							Filter: () => null,
							maxWidth: 150
						},
						{
							Header: strings.products.notes,
							id: "notes",
							accessor: (row: any) => row.notes || "-",
							Filter: () => null
						},
						{
							Header: strings.generic.from,
							id: "origin",
							Cell: (row: any) =>
								row.cell.row.original?.origin?.name ? (
									<div className="react-tel-input">
										{row.cell.row.original?.origin?.phone ? (
											<div className="selected-flag">
												<span className={`flag ${row.cell.row.original?.origin?.country}`} />
												<span className="partner-name partner-has-flag">
													{row.cell.row.original?.origin?.name}
													{row.cell.row.original?.origin?.userCode != null
														? ` (#${row.cell.row.original?.origin?.userCode})`
														: ""}
												</span>
											</div>
										) : (
											<span className="partner-name">
												{row.cell.row.original?.origin?.name}
												{row.cell.row.original?.origin?.userCode != null
													? ` (#${row.cell.row.original?.origin?.userCode})`
													: ""}
											</span>
										)}
									</div>
								) : (
									"-"
								),
							Filter: () => null
						},
						{
							Header: strings.products.points,
							id: "points",
							Cell: (row: any) =>
								row.cell.row.original?.points != null ? this.renderNumber(row.cell.row.original?.points) : "-",
							Filter: () => null,
							maxWidth: 100,
							align: "right"
						},
						{
							Header: strings.settings.value,
							id: "value",
							Cell: (row: any) =>
								row.cell.row.original?.value != null ? this.renderValue(row.cell.row.original?.value) : "-",
							Filter: () => null,
							maxWidth: 100,
							align: "right"
						},
						{
							Header: strings.generic.expirationDate,
							id: "expirationDate",
							accessor: (row: any) =>
								row.expirationDate ? DateTime.fromISO(row.expirationDate).toFormat("dd/MM/yyyy HH:mm") : "-",
							Filter: () => null,
							maxWidth: 150,
							align: "center"
						}
					]}
					filterable
					fullPage
					isSinglePage
					paginated
					paginationApi={this.pagination}
					filtrationApi={this.filtration}
				/>
			</div>
		);
	}

	renderDrawer() {
		const { showDrawer } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="organization" />
						<p>{strings.fields.selectFilters}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button type="button" className="SidebarFooterButton --button-confirm" onClick={() => this.applyFilters()}>
							{strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() => this.setState({ showDrawer: false, tempFilters: {} })}
						>
							{strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				closable={false}
				onClose={() => this.setState({ showDrawer: false, tempFilters: {} })}
				visible={showDrawer}
				width={mobile ? "100%" : 600}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	renderDrawerContent() {
		const { tempFilters, searchOriginParams = "", originOptions = [], origin = undefined } = this.state;
		const { language } = this.props;

		return (
			<Row gutter={[20, 20]}>
				<Col xs={24}>
					<label className="InputLabel" htmlFor="period">
						{strings.dashboard.period}
					</label>
					<RangePicker
						id="period"
						style={{ width: "100%" }}
						locale={language === "pt" ? pt : en}
						allowEmpty={[false, false]}
						ranges={{
							[strings.ranges.today]: [moment(), moment()],
							[strings.ranges.thisMonth]: [moment().startOf("month"), moment().endOf("month")],
							[strings.ranges.lastMonth]: [
								moment().subtract(1, "month").startOf("month"),
								moment().subtract(1, "month").endOf("month")
							],
							[strings.ranges.thisYear]: [moment().startOf("year"), moment().endOf("month")],
							[strings.ranges.lastYear]: [
								moment().subtract(1, "year").startOf("year"),
								moment().subtract(1, "year").endOf("year")
							]
						}}
						placeholder={[strings.fields.startDate, strings.fields.endDate]}
						separator={
							<span className="__turn90">
								<Icon name="arrow-up" />
							</span>
						}
						value={[tempFilters?.startDate, tempFilters?.endDate]}
						onChange={(value: any) =>
							this.setState({ tempFilters: { ...tempFilters, startDate: value[0], endDate: value[1] } })
						}
						allowClear={false}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="origin" className="InputLabel">
						{strings.generic.from}
					</label>
					<Select
						id="origin"
						style={{ width: "100%", height: 40 }}
						placeholder={strings.generic.from}
						filterOption={false}
						showSearch
						onSearch={(value) => this.handleSearch(value)}
						value={origin}
						onChange={(elem: any) => {
							this.setState({ origin: elem });
						}}
						onClear={() =>
							this.setState({
								origin: undefined,
								originOptions: [],
								searchOriginParams: "",
								hasUnsavedFields: true
							})
						}
						notFoundContent={
							<div>{searchOriginParams === "" ? strings.generic.searchByEmailOrName : strings.generic.noResults}</div>
						}
						allowClear={true}
					>
						{originOptions?.map((partner: any) => (
							<Select.Option value={partner._id} key={partner._id}>
								{partner.name} ({partner.email})
							</Select.Option>
						))}
					</Select>
				</Col>
			</Row>
		);
	}

	render() {
		const { redeemedValue = 0, pendingValue = 0, points = 0, expiredValue = 0 } = this.state;

		const CARDS = [
			{
				icon: "wallet",
				value: redeemedValue,
				description: strings.commissions.redeemedValue,
				type: "currency"
			},
			{
				icon: "wallet",
				value: pendingValue,
				description: strings.commissions.pendingValue,
				type: "currency"
			},
			{
				icon: "points",
				value: points,
				description: strings.commissions.redeemedPoints
			},
			{
				icon: "wallet",
				value: expiredValue,
				description: strings.commissions.expiredValue,
				type: "currency"
			}
		];

		return (
			<div className="PartnersCommissions">
				<Helmet>
					<title>{strings.sidebar.commissionsAndBonuses}</title>
					<meta name="description" content="Check all your commissions and bonuses" />
				</Helmet>
				<div className="CommissionCards">
					<Row gutter={[20, 20]}>
						{CARDS.map((card, index) => (
							<Col xs={24} md={6} key={index}>
								<div className="IconCardOuterContainer">
									<div className="IconCard">
										<div className="IconCardHeader">
											<div className="IconCardHeaderMask" />
											<div className="IconCardType">
												<Icon name={card.icon} />
											</div>
										</div>
										<div className="IconCardBody">
											<span className="IconCardValue">
												{card.type === "currency" ? formatPrice(card.value) : card.value}
											</span>
											<h3 className="IconCardDescription">{card.description}</h3>
										</div>
									</div>
								</div>
							</Col>
						))}
					</Row>
				</div>
				{this.renderCommissions()}
				{this.renderDrawer()}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	countries: state.countries
});

export default connect(mapStateToProps)(Commissions);
