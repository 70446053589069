import React from "react";
import { connect } from "react-redux";
import { notification } from "antd";
import { setLoader } from "store/actions";
import { store } from "store";
import { API, Endpoints } from "utils/api";
import { Helmet } from "react-helmet";
import { ContentWrapper, ManualItem } from "components";
import { FileUnknownOutlined } from "@ant-design/icons";
import { Manual } from "screens/Admin/Manuals/types";
import Strings from "utils/strings";
import "./styles.scss";

type State = {
	manuals?: Manual[];
};
type Props = {
	language: string;
	dispatch: typeof store.dispatch;
};

class Manuals extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			manuals: []
		};
	}

	componentDidMount(): void {
		this.getData();
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriManuals(),
				params: { type: "manual" }
			});

			if (response?.ok) {
				const manuals: Manual[] = response.data.results.manuals || [];
				this.setState({ manuals });
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	renderGallery() {
		const { manuals = [] } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<FileUnknownOutlined translate={null} />
						<h2>{Strings.settings.manuals}</h2>
					</div>
				</div>
				<div className="PartnerManualGrid">
					{manuals.map((manual, i) => {
						return <ManualItem key={i} readonly {...manual} />;
					})}
				</div>
				{manuals.length === 0 && (
					<div className="PartnerManualHeader">
						<h3>{Strings.settings.noFilesPartner}</h3>
					</div>
				)}
			</ContentWrapper>
		);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.manuals}</title>
					<meta name="description" content={Strings.settings.manuals} />
				</Helmet>
				{this.renderGallery()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: { language: string }) => ({
	language: state.language
});

export default connect(mapStateToProps)(Manuals);
