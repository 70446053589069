import React, { Component } from "react";
import { Col, Table, Row, notification } from "antd";
import { ContentWrapper, Icon, ProgressBarSteps } from "components";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { setLoader, setTitle } from "store/actions";
import { API, Endpoints } from "utils/api";
import strings from "utils/strings";
import { formatPrice, getSuccessLevelBadge } from "utils/utils";
import moment from "moment";

class Performance extends Component<any, any> {
	searchTimeout: NodeJS.Timeout | undefined;

	constructor(props: any) {
		super(props);

		this.state = {
			date: moment().endOf("year")
		};
	}

	async componentDidMount(): Promise<void> {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		await this.getData();
	}

	async getData() {
		const { date } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		const body = {
			date: date.format("yyyy"),
			type: "performance"
		};

		try {
			const response = await API.post({
				url: Endpoints.uriCommissions("search-by-type"),
				data: body
			});

			if (response.ok) {
				const { cards = {}, commissions = [], settings, currentTpv, eligible = false } = response.data.results;
				this.setState({ cards, commissions, settings, currentTpv, eligible });
			} else {
				notification.error({
					message: strings.commissions.annual,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async getUsers() {
		const { partnerSearch } = this.state;

		try {
			const body = { search: partnerSearch, page: 0, perPage: 10 };

			const response = await API.post({
				url: Endpoints.uriPartners("search"),
				data: body
			});

			if (response.ok) {
				const { users = [] } = response.data.results || {};
				this.setState({ partnerOptions: users });
			} else {
				notification.error({
					message: strings.commissions.repass,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}
	}

	renderMovements() {
		const { data = [], date } = this.state;
		const { countries } = this.props;

		return (
			<ContentWrapper>
				<div className="ScreenHeader --multi">
					<div className="ScreenHeaderLeft">
						<Icon name="diagram" />
						<h2>{strings.commissions.annual}</h2>
					</div>
				</div>
				<Table
					style={{ marginTop: 20 }}
					columns={[
						{
							title: strings.fields.date,
							dataIndex: "date",
							key: "date",
							render: () => date.format("yyyy"),
							width: 100
						},
						{
							title: strings.fields.partner,
							dataIndex: "partner",
							key: "partner",
							render: (_: any, record: any) => {
								const countryCallingCode = record.phone?.substring(0, 4).replace("+", "");
								let country;

								if (countryCallingCode) {
									country = countries
										.find((elem: any) => elem.callingCodes.includes(countryCallingCode))
										?.alpha2Code?.toLowerCase();
								}

								return (
									<span className="TableRowAligned">
										{country ? (
											<div className="react-tel-input">
												<div className="selected-flag">
													<span className={`flag ${country}`} />
												</div>
											</div>
										) : null}
										{record.name}
										{Boolean(record?.userCode) ? ` (#${record.userCode})` : ""}
									</span>
								);
							},
							sorter: (a, b) => a.name?.localeCompare(b.name)
						},
						{
							title: strings.users.partnerRanking,
							dataIndex: "actualSuccessLevel",
							key: "actualSuccessLevel",
							render: (_: any, record: any) => getSuccessLevelBadge(record.partner?.successLevel) || "-",
							sorter: (a, b) => a.partner?.successLevel?.localeCompare(b.partner?.successLevel)
						},
						{
							title: strings.bonuses.bonusExtraIncentiveHeader1,
							dataIndex: "annualTpv",
							key: "annualTpv",
							render: (annualTpv: number) => annualTpv || "-",
							sorter: (a, b) => a.annualTpv - b.annualTpv
						},
						{
							title: strings.settings.value,
							dataIndex: "value",
							key: "value",
							render: (value: number) => {
								if (date.format("yyyy") === DateTime.utc().endOf("year").toFormat("yyyy")) {
									return "-";
								}
								return formatPrice(value);
							},
							sorter: (a, b) => a.value - b.value,
							width: 150
						},
						{
							title: strings.generic.expirationDate,
							dataIndex: "expirationDate",
							key: "expirationDate",
							render: (value: string) => (value ? DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm") : "-"),
							sorter: (a, b) => DateTime.fromISO(a._created).toMillis() - DateTime.fromISO(b._created).toMillis(),
							width: 150
						}
					]}
					dataSource={data}
				/>
			</ContentWrapper>
		);
	}

	renderProgressBar() {
		const { settings, currentTpv = 0 } = this.state;

		const steps = [];
		if (settings) {
			for (let i = 0; i < settings.length; i++) {
				steps.push({
					value: settings[i].annual,
					element: <small>{formatPrice(settings[i].bonus)}</small>,
					topElement: <small>{settings[i].annual}</small>
				});
			}
		}

		if (!steps.length) return null;

		return <ProgressBarSteps min={0} steps={steps} value={currentTpv} />;
	}

	renderPartnerMovements() {
		const { commissions = [] } = this.state;
		const { countries } = this.props;

		return (
			<ContentWrapper>
				<div className="ScreenHeader --multi">
					<div className="ScreenHeaderLeft">
						<Icon name="diagram" />
						<h2>{strings.commissions.annual}</h2>
					</div>
				</div>
				<Table
					style={{ marginTop: 20 }}
					columns={[
						{
							title: strings.fields.date,
							dataIndex: "date",
							key: "date",
							render: (date: string) => DateTime.fromISO(date).toFormat("yyyy"),
							width: 100
						},
						{
							title: strings.fields.partner,
							dataIndex: "partner",
							key: "partner",
							render: (partner: any) => {
								const countryCallingCode = partner.phone?.substring(0, 4).replace("+", "");
								let country;

								if (countryCallingCode) {
									country = countries
										.find((elem: any) => elem.callingCodes.includes(countryCallingCode))
										?.alpha2Code?.toLowerCase();
								}

								return (
									<span className="TableRowAligned">
										{country ? (
											<div className="react-tel-input">
												<div className="selected-flag">
													<span className={`flag ${country}`} />
												</div>
											</div>
										) : null}
										{partner?.name}
										{Boolean(partner?.userCode) ? ` (#${partner.userCode})` : ""}
									</span>
								);
							},
							sorter: (a, b) => a.name?.localeCompare(b.name)
						},
						{
							title: strings.users.partnerRanking,
							dataIndex: "successLevel",
							key: "successLevel",
							render: (_: any, record: any) => getSuccessLevelBadge(record.partner?.successLevel) || "-",
							sorter: (a, b) => a.partner?.successLevel?.localeCompare(b.partner?.successLevel)
						},
						{
							title: strings.bonuses.bonusExtraIncentiveHeader1,
							dataIndex: "annualTpv",
							key: "annualTpv",
							render: (annualTpv: number) => annualTpv || "-",
							sorter: (a, b) => a.annualTpv - b.annualTpv
						},
						{
							title: strings.settings.value,
							dataIndex: "value",
							key: "value",
							render: (value: number) => formatPrice(value),
							sorter: (a, b) => a.value - b.value,
							width: 150
						},
						{
							title: strings.generic.expirationDate,
							dataIndex: "expirationDate",
							key: "expirationDate",
							render: (value: string) => (value ? DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm") : "-"),
							sorter: (a, b) => DateTime.fromISO(a._created).toMillis() - DateTime.fromISO(b._created).toMillis(),
							width: 150
						}
					]}
					dataSource={commissions}
					rowKey={(record) => record._id}
				/>
			</ContentWrapper>
		);
	}

	render() {
		const { cards, settings, currentTpv, eligible, partnerDetail } = this.state;
		const { total = 0, available = 0, spent = 0, expired = 0 } = cards || {};

		const CARDS = [
			{
				icon: "wallet",
				value: total,
				description: strings.commissions.withThisBonus,
				type: "currency"
			},
			{
				icon: "wallet",
				value: available,
				description: strings.wallet.available,
				type: "currency"
			},
			{
				icon: "wallet",
				value: spent,
				description: strings.wallet.spent,
				type: "currency"
			},
			{
				icon: "wallet",
				value: expired,
				description: strings.wallet.expired,
				type: "currency"
			}
		];

		return (
			<div className="CommissionPerformance">
				<Helmet>
					<title>{strings.commissions.annual}</title>
					<meta name="description" content="Description of annual commissions" />
				</Helmet>
				<div className="IconCards">
					{!eligible && (
						<Col xs={24}>
							<ContentWrapper>
								<div className="ScreenHeader">
									<div className="ScreenHeaderLeft">
										<Icon name="user" />
										<h2>{strings.commissions.eligibility}</h2>
									</div>
								</div>
								<p>
									<strong>
										{strings.commissions.actualRank}: {getSuccessLevelBadge(partnerDetail?.partner?.successLevel)}
									</strong>
								</p>
								<p className="CommissionsPartnersAttention">{strings.commissions.notEligibleJunior}</p>
							</ContentWrapper>
						</Col>
					)}
					<Row gutter={[20, 20]}>
						{CARDS.map((card, index) => (
							<Col xs={24} md={6} key={index}>
								<div className="IconCardOuterContainer">
									<div className="IconCard">
										<div className="IconCardHeader">
											<div className="IconCardHeaderMask" />
											<div className="IconCardType">
												<Icon name={card.icon} />
											</div>
										</div>
										<div className="IconCardBody">
											<span className="IconCardValue">
												{card.type === "currency" ? formatPrice(card.value) : card.value}
											</span>
											<h3 className="IconCardDescription">{card.description}</h3>
										</div>
									</div>
								</div>
							</Col>
						))}
						<Col xs={24}>
							<Row gutter={[20, 20]}>
								<Col xs={16}>
									<ContentWrapper>
										<div className="ScreenHeader">
											<div className="ScreenHeaderLeft">
												<Icon name="points" />
												<h2>{strings.commissions.progress}</h2>
											</div>
										</div>
										{this.renderProgressBar()}
										<table className="CommissionTpvTable">
											<thead>
												<tr>
													<th>{strings.commissions.accumulatedTpv}</th>
													<th>{strings.generic.bonus}</th>
												</tr>
											</thead>
											<tbody>
												{settings?.map((setting: any, index: number) => {
													const reached = currentTpv >= setting.annual;

													const style: React.CSSProperties = {};
													if (reached) {
														style.backgroundColor = "#00b5b033";
														style.fontWeight = 600;
														style.color = "#00b5b0";
													}

													return (
														<tr key={index} style={style}>
															<td>{setting.annual}</td>
															<td>{formatPrice(setting.bonus)}</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</ContentWrapper>
								</Col>
								<Col xs={8}>
									<div className="IconCardOuterContainer">
										<div className="IconCard">
											<div className="IconCardHeader">
												<div className="IconCardHeaderMask" />
												<div className="IconCardType">
													<Icon name="points" />
												</div>
											</div>
											<div className="IconCardBody">
												<span className="IconCardValue">{currentTpv}</span>
												<h3 className="IconCardDescription">{strings.bonuses.bonusExtraIncentiveHeader1}</h3>
											</div>
										</div>
									</div>
								</Col>
							</Row>
						</Col>
						<Col xs={24}>{this.renderPartnerMovements()}</Col>
					</Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	countries: state.countries
});

export default connect(mapStateToProps)(Performance);
