import React from "react";
import { connect } from "react-redux";
import { push, replace } from "connected-react-router";
import { Banner, Props, State } from "./types";
import type { LanguageSchema } from "utils/types";
import type { Category } from "screens/Admin/CategoryDetail/types";
import type { Segment } from "screens/Admin/SegmentDetail/types";
import type { Product } from "screens/Admin/Products/types";
import { delayedDispatch, setBreadcrumb, setLoader, updateCrumb } from "store/actions";
import { Helmet } from "react-helmet";
import { ContentWrapper, Icon, SelectTags, Table } from "components";
import { Col, Drawer, Input, notification, Row, Slider, Switch, TreeSelect } from "antd";
import Dropzone from "react-dropzone";
import Compressor from "compressorjs";
import { removeDiacritics, translate } from "utils/utils";

import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import placeholder from "assets/images/placeholders/image.jpg";
import "./styles.scss";

const { SHOW_PARENT } = TreeSelect;
const { Search } = Input;

class BannerDetail extends React.Component<Props, State> {
	productTimeout?: NodeJS.Timeout;

	constructor(props: Props) {
		super(props);

		this.state = {
			banner: null,
			isActive: true,
			showWeb: true,
			web: {
				showLabels: false,
				overlayOpacity: 0
			},
			language: "pt",
			hasUnsavedFields: false,
			showProductDrawer: false,
			productSearch: "",
			seoUrlLoading: false,

			categories: [],
			segments: [],
			products: [],

			tempProducts: [],

			defaultCategories: [],
			defaultProducts: [],
			defaultSegments: [],
			isNew: props.match?.params?.id === "new",
			searchingProducts: false
		};
	}

	componentDidMount() {
		this.breadcrumb();
		this.getData();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	breadcrumb() {
		const { dispatch } = this.props;

		dispatch(setBreadcrumb(null));
		delayedDispatch(
			setBreadcrumb(() => {
				const { banner } = this.state;

				return {
					locations: [
						{
							icon: "frame",
							text: Strings.sidebar.banners,
							route: "/banners"
						},
						{
							icon: banner?._id ? "pencil-outline" : "plus",
							text: banner?.name || Strings.generic.newM
						}
					],
					actions: [
						{
							type: "switch",
							text: Strings.generic.active,
							value: this.state.isActive,
							small: {
								switch: true,
								text: true
							},
							separator: "right",
							onClick: (value: boolean) => this.setState({ isActive: value, hasUnsavedFields: true })
						},
						{
							type: "language",
							value: this.state.language,
							showArrow: true,
							showSearch: false,
							separator: "right",
							onChange: (value: keyof LanguageSchema) => this.setState({ language: value })
						},
						{
							type: "button",
							text: Strings.generic.save,
							disabled: !this.state.hasUnsavedFields,
							className: "BreadcrumbSaveButton",
							isSave: true,
							hasIcon: true,
							onClick: () => this.saveBanner()
						}
					]
				};
			})
		);
	}

	async getData() {
		const { isNew } = this.state;
		const { dispatch, match } = this.props;

		const promises = [API.get({ url: Endpoints.uriCategories() }), API.get({ url: Endpoints.uriSegments() })];

		if (match?.params?.id !== "new") {
			promises.push(API.get({ url: Endpoints.uriBanners(match?.params.id) }));
		}

		dispatch(setLoader(true));

		try {
			let defaultCategories: Category[] = [],
				defaultSegments: Segment[] = [],
				banner: Banner = {};
			const [responseCategories, responseSegments, response] = await Promise.all(promises);

			if (responseCategories?.ok) {
				defaultCategories = responseCategories.data.results?.categories || [];
			}

			if (responseSegments?.ok) {
				defaultSegments = responseSegments.data.results?.segments || [];
			}

			if (!isNew && response?.ok) {
				banner = response.data.results?.banners || {};
			} else if (!isNew) {
				notification.error({
					message: Strings.sidebar.banners,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});

				dispatch(push("/banners"));
			}

			this.setState({ defaultCategories, defaultSegments, banner, ...banner });
		} catch (err) {
			dispatch(push("/banners"));

			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async getSeoUrl(name?: string) {
		if (!name) return;

		this.setState({ seoUrlLoading: true });

		try {
			const response = await API.post({
				url: Endpoints.uriSeoUrl(),
				data: {
					seo: removeDiacritics(name),
					table: "banners"
				}
			});

			if (response?.ok) {
				const { seo } = response.data.results || {};
				this.setState({ seoUrlLoading: false, seo });
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		this.setState({ seoUrlLoading: false });
	}

	async saveBanner() {
		const { isActive, name, seo, showApp, app, showWeb, web, categories, segments, products = [] } = this.state;
		const { dispatch, match } = this.props;
		const { id } = match?.params || {};

		if (!this.isBannerValid()) return;

		dispatch(setLoader(true));

		try {
			const body = new FormData();
			body.append("isActive", JSON.stringify(isActive));
			body.append("name", name!);
			body.append("seo", seo!);
			body.append("categories", JSON.stringify(categories));
			body.append("segments", JSON.stringify(segments));
			body.append("products", JSON.stringify(products.map((product) => product._id)));

			body.append("showApp", JSON.stringify(showApp));
			if (app) {
				const appCopy = JSON.parse(JSON.stringify(app));

				if (appCopy?.image && Object.keys(appCopy?.image).length > 0) {
					for (let key in appCopy?.image) {
						if (appCopy.image[key].file) {
							delete appCopy.image[key];
						}
					}
				}

				body.append("app", JSON.stringify(appCopy));
			}

			body.append("showWeb", JSON.stringify(showWeb));
			if (web) {
				const webCopy = JSON.parse(JSON.stringify(web));

				if (webCopy?.image && Object.keys(webCopy?.image).length > 0) {
					for (let key in webCopy?.image) {
						if (webCopy.image[key].file) {
							delete webCopy.image[key];
						}
					}
				}

				body.append("web", JSON.stringify(webCopy));
			}

			if (web?.image && Object.keys(web?.image).length > 0) {
				for (let key in web?.image) {
					if (web.image[key].file) {
						body.append(`webImage_${key}`, web.image[key].file);
					}
				}
			}

			if (app?.image && Object.keys(app?.image).length > 0) {
				for (let key in app?.image) {
					if (app.image[key].file) {
						body.append(`appImage_${key}`, app.image[key].file);
					}
				}
			}

			const request = id === "new" ? API.post : API.put;
			const response = await request({
				url: Endpoints.uriBanners(id === "new" ? "" : id),
				data: body
			});

			if (response?.ok) {
				const { banner } = response.data.results || {};
				if (id === "new") {
					this.breadcrumb();
					dispatch(replace(`/banners/${banner._id}`));
				}

				this.setState({ banner, ...banner, hasUnsavedFields: false }, () => {
					notification.success({
						message: Strings.sidebar.banners,
						description: id === "new" ? Strings.banners.created : Strings.banners.updated,
						placement: "bottomRight",
						duration: 5
					});
				});
			} else {
				notification.error({
					message: Strings.sidebar.banners,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async getProducts() {
		const { productSearch } = this.state;
		const { dispatch } = this.props;

		if (!productSearch) {
			this.setState({ defaultProducts: [] });
			return;
		}

		dispatch(setLoader(true));

		try {
			const response = await API.post({
				url: Endpoints.uriProducts("search"),
				data: {
					search: productSearch,
					perPage: 100,
					page: 0
				}
			});

			if (response?.ok) {
				this.setState({ defaultProducts: response.data.results?.products || [], searchingProducts: false });
			} else {
				notification.error({
					message: Strings.products.title,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	isBannerValid() {
		const { name, seo, showApp, app, showWeb, web } = this.state;

		if (!name || !name.trim()) {
			notification.warning({
				message: Strings.errors.invalidFields,
				description: Strings.banners.nameMissing,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		if (!seo || !seo.trim()) {
			notification.warning({
				message: Strings.errors.invalidFields,
				description: Strings.generic.seoMissing,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		if (showWeb) {
			if (!translate(web?.image)) {
				notification.warning({
					message: Strings.errors.invalidFields,
					description: Strings.banners.imageMissing,
					placement: "bottomRight",
					duration: 5
				});

				return false;
			}

			if (!translate(web?.title)) {
				notification.warning({
					message: Strings.errors.invalidFields,
					description: Strings.banners.titleMissing,
					placement: "bottomRight",
					duration: 5
				});

				return false;
			}
		}

		if (showApp) {
			if (!translate(app?.image)) {
				notification.warning({
					message: Strings.errors.invalidFields,
					description: Strings.banners.imageMissing,
					placement: "bottomRight",
					duration: 5
				});

				return false;
			}

			if (app?.showLabels && !translate(app?.title)) {
				notification.warning({
					message: Strings.errors.invalidFields,
					description: Strings.banners.titleMissing,
					placement: "bottomRight",
					duration: 5
				});

				return false;
			}
		}

		if (!showWeb && !showApp) {
			notification.warning({
				message: Strings.errors.invalidFields,
				description: Strings.banners.showMissing,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		return true;
	}

	getBase64(file: any) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	onDrop(files: any, type: string) {
		try {
			const file = files[files.length - 1];

			new Compressor(file, {
				quality: 0.9,
				maxWidth: 400,
				mimeType: "image/jpeg",
				success: (result: any) => {
					this.getBase64(result).then((res) => {
						if (type === "web") {
							this.setState((prevState: State) => ({
								web: {
									...prevState?.web,
									image: {
										...prevState.web?.image,
										[prevState.language]: {
											file: result,
											preview: res
										}
									}
								},
								hasUnsavedFields: true
							}));
						} else if (type === "app") {
							this.setState((prevState: State) => ({
								app: {
									...prevState?.app,
									image: {
										...prevState.app?.image,
										[prevState.language]: {
											file: result,
											preview: res
										}
									}
								},
								hasUnsavedFields: true
							}));
						}
					});
				}
			});
		} catch (err) {
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5
			});
		}
	}

	deleteProduct(product: string) {
		this.setState((prevState: State) => ({
			products: (prevState.products || []).filter((prod) => prod._id !== product),
			hasUnsavedFields: true
		}));
	}

	addProducts() {
		const { tempProducts } = this.state;

		this.setState((prevState: State) => ({
			products: [...(prevState.products || []), ...tempProducts],
			hasUnsavedFields: true,
			showProductDrawer: false,
			tempProducts: [],
			defaultProducts: []
		}));
	}

	renderBannerDetails() {
		const { name, seo, seoUrlLoading, web, language } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader" style={{ marginBottom: 20 }}>
					<div className="ScreenHeaderLeft">
						<Icon name="frame" />
						<h2>{Strings.banners.details}</h2>
					</div>
				</div>
				<Row gutter={[20, 10]}>
					<Col xs={24} md={12}>
						<span style={{ position: "relative" }}>
							<Dropzone
								accept="image/jpg, image/jpeg, image/png"
								className="BannerDropzone"
								onDrop={(files: any) => this.onDrop(files, "web")}
							>
								{web?.image?.[language] ? (
									<React.Fragment>
										<div
											style={{
												position: "absolute",
												inset: 0,
												backgroundColor: "#000",
												opacity: (web?.overlayOpacity || 0) / 100
											}}
										/>
										<img src={web.image[language].preview || web?.image[language]} alt="banner" />
									</React.Fragment>
								) : (
									<div className={`ImageUploadOverlay${!web?.image?.[language] ? " --visible" : ""}`}>
										<Icon name="frame" />
										<span>{Strings.generic.changeImage}</span>
									</div>
								)}
							</Dropzone>
							{web?.image?.[language] && (
								<button
									onClick={(e: React.MouseEvent<HTMLElement>) => {
										e.stopPropagation();
										delete web?.image?.[language];

										this.setState({ web, hasUnsavedFields: true });
									}}
									className="GenericImageDelete"
								>
									<Icon name="close" />
								</button>
							)}
						</span>
					</Col>
					<Col xs={24} md={12}>
						<Row gutter={[20, 10]}>
							<Col xs={24}>
								<label htmlFor="banner_name" className="InputLabel --label-required">
									{Strings.fields.name}
								</label>
								<Input
									id="banner_name"
									value={name || ""}
									placeholder={Strings.fields.name}
									onChange={(event: any) => {
										const value = event.target.value;
										this.setState({ name: value, hasUnsavedFields: true });
									}}
									onBlur={() => {
										if (!seo && name) {
											const seo = removeDiacritics(name);
											this.getSeoUrl(seo);
										}
									}}
								/>
							</Col>
							<Col xs={24}>
								<label htmlFor="banner_seo" className="InputLabel --label-required">
									{Strings.fields.seoUrl}
								</label>
								<Input
									id="banner_seo"
									value={seo || ""}
									readOnly={seoUrlLoading}
									placeholder={Strings.fields.seoUrl}
									onChange={(event: any) => {
										const value = event.target.value;
										this.setState({ seo: value, hasUnsavedFields: true });
									}}
									onBlur={() => {
										if (seo) {
											this.getSeoUrl(seo);
										}
									}}
								/>
							</Col>
							<Col xs={24}>
								<label htmlFor="banner_overlay" className="InputLabel">
									{Strings.banners.darkOverlay}
								</label>
								<Slider
									id="banner_overlay"
									marks={{ 0: "0%", 100: "100%" }}
									value={web?.overlayOpacity}
									min={0}
									max={100}
									tipFormatter={(value: any) => <span>{`${value}%`}</span>}
									onChange={(value: number) => {
										this.setState((prevState: State) => ({
											web: {
												...prevState.web,
												overlayOpacity: value
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</Col>
							<Col xs={24}>
								<div style={{ marginTop: 20 }} className={`GenericSwitch${web?.showLabels ? " __active" : ""}`}>
									<span>{Strings.banners.showLabels}</span>
									<Switch
										className={`Switch${web?.showLabels ? " __active" : ""}`}
										checked={web?.showLabels || false}
										size="small"
										onChange={(value: boolean) =>
											this.setState((prevState: State) => ({
												web: {
													...prevState.web,
													showLabels: value
												},
												hasUnsavedFields: true
											}))
										}
									/>
								</div>
							</Col>
							<Col xs={24}>
								<label htmlFor="banner_web_title" className="InputLabel --label-required">
									{Strings.banners.title}
								</label>
								<Input
									id="banner_web_title"
									value={web?.title?.[language] || ""}
									placeholder={Strings.banners.title}
									onChange={(event: any) => {
										const value = event.target.value;

										this.setState((prevState: State) => ({
											web: {
												...prevState.web,
												title: {
													...prevState.web?.title,
													[language]: value
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</Col>
							<Col xs={24}>
								<label htmlFor="banner_web_sub_title" className="InputLabel">
									{Strings.banners.subTitle}
								</label>
								<Input
									id="banner_web_sub_title"
									value={web?.subTitle?.[language] || ""}
									placeholder={Strings.banners.subTitle}
									onChange={(event: any) => {
										const value = event.target.value;

										this.setState((prevState: State) => ({
											web: {
												...prevState.web,
												subTitle: {
													...prevState.web?.subTitle,
													[language]: value
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</Col>
							<Col xs={24}>
								<div style={{ marginTop: 20 }} className={`GenericSwitch${web?.showButton ? " __active" : ""}`}>
									<span>{Strings.banners.showButton}</span>
									<Switch
										className={`Switch${web?.showButton ? " __active" : ""}`}
										checked={web?.showButton || false}
										size="small"
										onChange={(value: boolean) =>
											this.setState((prevState: State) => ({
												web: {
													...prevState.web,
													showButton: value
												},
												hasUnsavedFields: true
											}))
										}
									/>
								</div>
							</Col>
							<Col xs={24}>
								<label htmlFor="banner_button_title" className={`InputLabel${web?.showButton ? " --label-required" : ""}`}>
									{Strings.banners.buttonText}
								</label>
								<Input
									id="banner_button_title"
									value={web?.buttonText?.[language] || ""}
									placeholder={Strings.banners.buttonText}
									disabled={!web?.showButton}
									onChange={(event: any) => {
										const value = event.target.value;

										this.setState((prevState: State) => ({
											web: {
												...prevState.web,
												buttonText: {
													...prevState.web?.buttonText,
													[language]: value
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	renderBannerSegmentation() {
		const { defaultCategories, categories, defaultSegments, segments, products } = this.state;

		const categoryProps = {
			id: "product_categories",
			treeData: defaultCategories.map((category) => ({
				title: translate(category.name),
				value: category._id,
				key: category._id,
				children: category.subCategories?.map((child) => ({
					title: translate(child.name),
					value: child._id,
					key: child._id,
					parent: category._id
				}))
			})),
			value: categories as string[],
			treeCheckable: true,
			showCheckedStrategy: SHOW_PARENT,
			placeholder: Strings.products.categories,
			style: { width: "100%" },
			treeDefaultExpandAll: true,
			showArrow: true,
			onChange: (categories: string[]) => this.setState({ categories, hasUnsavedFields: true }),
			disabled: products?.length! > 0
		};

		return (
			<ContentWrapper>
				<Row gutter={[20, 10]}>
					<Col xs={24}>
						<div className="ScreenHeader">
							<div className="ScreenHeaderLeft">
								<Icon name="diagram" />
								<h2>{Strings.products.segmentation}</h2>
							</div>
						</div>
					</Col>
					<Col xs={24}>
						<SelectTags
							id="categories"
							label={Strings.products.categories}
							placeholder={Strings.products.categories}
							required
							value={categories}
							options={defaultCategories?.map((cat: any) => ({
								title: translate(cat.name),
								value: cat._id,
								key: cat._id,
								children: cat.subCategories?.map((child: Category) => ({
									title: translate(child.name),
									value: child._id,
									key: child._id,
									parent: cat._id
								}))
							}))}
							onChange={(categories: string[]) => this.setState({ categories, hasUnsavedFields: true })}
						/>
					</Col>
					<Col xs={24}>
						<SelectTags
							id="segments"
							label={Strings.products.segments}
							placeholder={Strings.products.segments}
							value={segments}
							options={defaultSegments?.map((segment: any) => ({
								title: translate(segment.name),
								value: segment._id,
								key: segment._id,
								children: []
							}))}
							onChange={(segments: string[]) => this.setState({ segments, hasUnsavedFields: true })}
						/>
					</Col>
					<Col xs={24}>{this.renderProducts()}</Col>
				</Row>
			</ContentWrapper>
		);
	}

	renderProducts() {
		const { products = [], categories = [], segments = [] } = this.state;

		const tableProps = {} as any;
		if (categories?.length === 0 && segments?.length === 0) {
			tableProps.add = {
				tooltip: Strings.banners.addBanner,
				onClick: () => this.setState({ showProductDrawer: true, productSearch: "", defaultProducts: [] })
			};
		}

		return (
			<Table
				title={{
					icon: "box",
					title: Strings.products.title
				}}
				style={{ marginTop: 20 }}
				data={products}
				columns={[
					{
						Header: Strings.fields.image,
						id: "images",
						accessor: (row: any) => row.images?.find((img: { url: string; favorite: boolean }) => img.favorite)?.url,
						type: "image",
						maxWidth: 65
					},
					{
						Header: Strings.fields.name,
						id: "name",
						accessor: (row: any) => translate(row.name)
					},
					{
						Header: Strings.products.ean,
						id: "ean",
						accessor: (row: any) => row?.ean || "-"
					}
				]}
				paginated
				{...tableProps}
				actions={{
					remove: (obj: any) => ({
						onClick: () => this.deleteProduct(obj._id)
					})
				}}
			/>
		);
	}

	renderProductsDrawer() {
		const { showProductDrawer } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="tax-settings" />
						<p>{Strings.products.addProduct}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button type="button" className="SidebarFooterButton --button-confirm" onClick={() => this.addProducts()}>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() => this.setState({ showProductDrawer: false, productSearch: "", defaultProducts: [] })}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				closable={false}
				onClose={() => this.setState({ showProductDrawer: false, productSearch: "", defaultProducts: [] })}
				visible={showProductDrawer}
				width={mobile ? "100%" : 600}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	renderDrawerContent() {
		const { defaultProducts, tempProducts, productSearch, products = [], searchingProducts } = this.state;

		const filteredProducts = defaultProducts.filter((product) => !products.some((prod) => prod._id === product._id));

		return (
			<Row gutter={[20, 10]}>
				<Col xs={24}>
					<label htmlFor="products_search" className="InputLabel">
						{Strings.generic.search}
					</label>
					<Search
						id="products_search"
						placeholder={Strings.generic.search}
						value={productSearch}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							this.setState({ productSearch: e.target.value, searchingProducts: true }, () => {
								if (this.productTimeout) clearInterval(this.productTimeout);
								this.productTimeout = setTimeout(() => {
									this.getProducts();
								}, 500);
							});
						}}
						allowClear
					/>
				</Col>
				{filteredProducts.length === 0 && !searchingProducts && productSearch.length > 0 && (
					<Col xs={24}>
						<p>{Strings.products.noResults}</p>
					</Col>
				)}
				{filteredProducts.length === 0 && searchingProducts && productSearch.length > 0 && (
					<Col xs={24}>
						<p>{Strings.products.searching}</p>
					</Col>
				)}
				{filteredProducts.length === 0 && productSearch.length === 0 && (
					<Col xs={24}>
						<p>{Strings.products.waitingForInput}</p>
					</Col>
				)}
				<Col xs={24}>
					<Row gutter={[20, 20]}>
						{filteredProducts.length > 0 &&
							productSearch.length > 0 &&
							filteredProducts.map((product, index: number) => {
								const checked = tempProducts.some((prod: Product) => prod._id === product._id);

								return (
									<React.Fragment>
										{index === 0 && <hr />}
										<Col xs={24} key={product._id}>
											<button
												className="DrawerProductBlock"
												onClick={() => {
													if (checked) {
														const index = tempProducts.findIndex((prod: Product) => prod._id === product._id);
														if (index > -1) {
															tempProducts.splice(index, 1);
														}
													} else {
														tempProducts.push(JSON.parse(JSON.stringify(product)));
													}

													this.setState({ tempProducts });
												}}
											>
												<div className="DrawerProduct">
													<div
														className="DrawerProductImage"
														style={{
															backgroundImage: `url(${
																product.images?.find(
																	(img: { url: string; favorite: boolean }) => img.favorite
																)?.url ||
																product.images?.[0]?.url ||
																placeholder
															})`
														}}
													/>
													<div className="DrawerProductInfo">
														<p>{translate(product.name)}</p>
														<p>EAN: {product.ean}</p>
													</div>
												</div>
												<button className={`DrawerProductCheckbox${checked ? " --checked" : ""}`}>
													<Icon name="correct-symbol" />
												</button>
											</button>
										</Col>
									</React.Fragment>
								);
							})}
					</Row>
				</Col>
			</Row>
		);
	}

	render() {
		return (
			<div className="Screen-Banner">
				<Helmet>
					<title>{Strings.banners.single}</title>
					<meta name="description" content="Edit your banner" />
				</Helmet>
				{this.renderBannerDetails()}
				{this.renderBannerSegmentation()}
				{this.renderProductsDrawer()}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	mobile: state.mobile
});

export default connect(mapStateToProps)(BannerDetail);
