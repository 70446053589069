import React, { Component } from "react";
import { Col, DatePicker, Drawer, Input, Row, Select, Switch, notification } from "antd";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import { ContentWrapper, Icon, Table } from "components";
import { DateTime } from "luxon";
import moment from "moment";
import pt from "antd/lib/date-picker/locale/pt_PT";
import en from "antd/lib/date-picker/locale/en_US";

import "./styles.scss";
import { LANGUAGES, translate } from "utils/utils";

const { RangePicker } = DatePicker;

class Incentives extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			bonuses: {},
			trimestral: [],
			groupTrip: {},
			language: "pt"
		};
	}

	async componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.settings.incentives));

		await this.getData();
		this.breadCrumb();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriSettings()
			});

			if (response.ok) {
				const { bonuses } = response.data.results.settings || {};
				const { trimestral, groupTrip } = bonuses;

				this.setState({ trimestral, groupTrip, bonuses });
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async saveIncentive() {
		const { trimestral, tempTrimestral } = this.state;

		if (!translate(tempTrimestral?.name)) {
			return notification.error({
				message: Strings.settings.incentives,
				description: Strings.categories.nameRequired,
				placement: "bottomRight",
				duration: 5
			});
		}

		if (typeof tempTrimestral?.startDate !== "string") {
			tempTrimestral.startDate = tempTrimestral.startDate.toISOString();
		}
		if (typeof tempTrimestral?.endDate !== "string") {
			tempTrimestral.endDate = tempTrimestral.endDate.toISOString();
		}

		if (!tempTrimestral?._id) {
			trimestral.push(tempTrimestral);
			this.setState({ trimestral, showDrawer: false, tempTrimestral: null, hasUnsavedFields: true });
		} else {
			const index = trimestral.findIndex((elem: any) => elem._id === tempTrimestral._id);
			if (index !== -1) {
				trimestral[index] = tempTrimestral;
				this.setState({ trimestral, showDrawer: false, tempTrimestral: null, hasUnsavedFields: true });
			}
		}
	}

	async onSave() {
		const { bonuses, trimestral, groupTrip } = this.state;
		const { dispatch } = this.props;

		if (!this.isValidIncentive()) {
			return;
		}

		dispatch(setLoader(true));

		try {
			const response = await API.put({
				url: Endpoints.uriSettings(),
				data: {
					bonuses: {
						...bonuses,
						trimestral,
						groupTrip
					}
				}
			});

			if (response.ok) {
				await this.getData();
				this.setState({ hasUnsavedFields: false });

				notification.success({
					message: Strings.generic.success,
					description: Strings.bonuses.bonusesNotificationMessageSuccess,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.generic.error,
					description: Strings.bonuses.bonusesNotificationMessageError,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		} finally {
			dispatch(setLoader(false));
		}
	}

	isValidIncentive() {
		const { trimestral, groupTrip } = this.state;

		for (const entry of trimestral) {
			for (const incentive of entry.incentives) {
				if (incentive.tpv === null || incentive.tpv < 0) {
					notification.error({
						message: Strings.generic.error,
						description: Strings.bonuses.bonusExtraIncentiveTpvError,
						placement: "bottomRight",
						duration: 5
					});

					return false;
				}

				if (!incentive.type) {
					notification.error({
						message: Strings.generic.error,
						description: Strings.bonuses.bonusExtraIncentiveTypeError,
						placement: "bottomRight",
						duration: 5
					});

					return false;
				}

				if (incentive.type === "money" && (!incentive.bonus || incentive.bonus < 0)) {
					notification.error({
						message: Strings.generic.error,
						description: Strings.bonuses.bonusExtraIncentivePrizeError,
						placement: "bottomRight",
						duration: 5
					});

					return false;
				}

				if (incentive.type === "prize" && (!incentive.prize || incentive.prize.trim().length === 0)) {
					notification.error({
						message: Strings.generic.error,
						description: Strings.bonuses.bonusExtraIncentivePrizeError,
						placement: "bottomRight",
						duration: 5
					});

					return false;
				}
			}
		}

		for (const bonus of groupTrip.bonuses) {
			if (bonus.average === null || bonus.average < 0) {
				notification.error({
					message: Strings.generic.error,
					description: Strings.bonuses.bonusTripGroupAverageError,
					placement: "bottomRight",
					duration: 5
				});

				return false;
			}

			if (bonus.annual === null || bonus.annual < 0) {
				notification.error({
					message: Strings.generic.error,
					description: Strings.bonuses.bonusTripGroupAnnualError,
					placement: "bottomRight",
					duration: 5
				});

				return false;
			}

			if (!bonus.prize || bonus.prize.trim().length === 0) {
				notification.error({
					message: Strings.generic.error,
					description: Strings.bonuses.bonusTripGroupPrizeError,
					placement: "bottomRight",
					duration: 5
				});

				return false;
			}
		}

		return true;
	}

	breadCrumb = () => {
		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							icon: "preferences",
							text: Strings.sidebar.settings,
							route: "/settings"
						},
						{
							icon: "points",
							text: Strings.settings.incentives
						}
					],
					actions: [
						{
							type: "button",
							text: Strings.generic.save,
							disabled: !this.state.hasUnsavedFields,
							className: "BreadcrumbSaveButton",
							isSave: true,
							hasIcon: true,
							onClick: () => this.onSave()
						}
					]
				};
			})
		);
	};

	getType(type: string) {
		switch (type) {
			case "money":
				return Strings.bonuses.bonusExtraIncentiveMoney;
			case "prize":
				return Strings.bonuses.bonusExtraIncentivePrize;
			default:
				return type;
		}
	}

	renderDrawer() {
		const { showDrawer, tempTrimestral } = this.state;
		const { mobile } = this.props;

		let title = tempTrimestral?._id ? Strings.settings.editTrimestralIncentive : Strings.settings.addTrimestralIncentive;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="points" />
						<p>{title}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button type="button" className="SidebarFooterButton --button-confirm" onClick={() => this.saveIncentive()}>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() => this.setState({ showDrawer: false, tempTrimestral: null })}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				closable
				onClose={() => this.setState({ showDrawer: false, tempTrimestral: null })}
				visible={showDrawer}
				width={mobile ? "100%" : 400}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	renderDrawerContent() {
		const { showDrawer, tempTrimestral, language: drawerLanguage } = this.state;
		const { language } = this.props;

		if (!showDrawer) return null;

		return (
			<Row gutter={[20, 20]}>
				<Col xs={24}>
					<div className="DrawerLanguageWrapper">
						<Select
							style={{ minWidth: 50 }}
							value={drawerLanguage}
							onChange={(elem: any) => {
								this.setState({ language: elem });
							}}
						>
							{LANGUAGES.map((lang: any, index: number) => {
								return (
									<Select.Option key={`select_option_${lang}_${index}`} value={lang.value}>
										{lang.label}
									</Select.Option>
								);
							})}
						</Select>
					</div>
				</Col>
				<Col xs={24}>
					<label className="InputLabel" htmlFor="trimestral_range">
						{Strings.dashboard.period}
					</label>
					<RangePicker
						id="trimestral_range"
						style={{ width: "100%" }}
						locale={language === "pt" ? pt : en}
						allowEmpty={[false, false]}
						placeholder={[Strings.fields.startDate, Strings.fields.endDate]}
						separator={
							<span className="__turn90">
								<Icon name="arrow-up" />
							</span>
						}
						picker="month"
						value={[moment.utc(tempTrimestral?.startDate), moment.utc(tempTrimestral?.endDate)]}
						onChange={(value: any) =>
							this.setState((prevState: any) => ({
								tempTrimestral: {
									...prevState.tempTrimestral,
									startDate: value[0].startOf("month"),
									endDate: value[1].endOf("month")
								},
								hasUnsavedFields: true
							}))
						}
						allowClear={false}
					/>
				</Col>
				<Col xs={24}>
					<label className="InputLabel" htmlFor="trimestral_name">
						{Strings.fields.name}
					</label>
					<Input
						id="trimestral_name"
						value={tempTrimestral?.name?.[drawerLanguage]}
						placeholder={Strings.fields.name}
						onChange={(e: any) => {
							const value = e.target.value;

							this.setState((prevState: any) => ({
								tempTrimestral: {
									...prevState.tempTrimestral,
									name: {
										...prevState.tempTrimestral?.name,
										[drawerLanguage]: value
									}
								},
								hasUnsavedFields: true
							}));
						}}
					/>
				</Col>
			</Row>
		);
	}

	renderTrimestralExpanded(row: any) {
		const { trimestral } = this.state;

		return (
			<div className="IncentivesSubTable">
				<table>
					<thead>
						<tr>
							<th>{Strings.settings.type}</th>
							<th>{Strings.commissions.tpv}</th>
							<th>{Strings.bonuses.bonusExtraIncentivePrize}</th>
							<th className="IncentiveSubTableActionHeader">
								<Icon
									name="plus"
									onClick={() => {
										row.original.incentives.push({ tpv: null, bonus: null, type: "money" });
										this.setState({ trimestral, hasUnsavedFields: true });
									}}
								/>
							</th>
						</tr>
					</thead>
					<tbody>
						{row.original.incentives?.map((incentive: any, index: number) => {
							return (
								<tr key={index}>
									<td>
										<div className="IncentiveSubTableSelect">
											<Select
												value={incentive.type}
												onChange={(elem: any) => {
													incentive.type = elem;
													incentive.tpv = 0;
													incentive.prize = "";
													incentive.bonus = 0;

													this.setState({ incentive, hasUnsavedFields: true });
												}}
												placeholder={Strings.settings.type}
												style={{ height: 32, width: "100%", maxWidth: 200 }}
											>
												<Select.Option value="money">{Strings.bonuses.bonusExtraIncentiveMoney}</Select.Option>
												<Select.Option value="prize">{Strings.bonuses.bonusExtraIncentivePrize}</Select.Option>
											</Select>
										</div>
									</td>
									<td>
										<div className="IncentivesSubTableInput">
											<Input
												type="number"
												value={incentive.tpv}
												placeholder="0"
												addonAfter="pts"
												min={0}
												style={{ maxWidth: 200 }}
												className="bonus-input-range"
												onChange={(val: any) => {
													if (val.target.value === "") {
														incentive.tpv = null;
														this.setState({
															trimestral,
															hasUnsavedFields: true
														});

														return;
													}

													if (isNaN(val.target.value)) return;

													const number = Number(val.target.value);

													incentive.tpv = Number(number);
													this.setState({ trimestral, hasUnsavedFields: true });
												}}
												onBlur={() => {
													this.setState({ trimestral, hasUnsavedFields: true });
												}}
											/>
										</div>
									</td>
									<td>
										<div className="IncentivesSubTableInput">
											{incentive.type === "money" && (
												<Input
													type="number"
													value={incentive.bonus}
													placeholder="0"
													addonAfter="€"
													min={0}
													style={{ maxWidth: 200 }}
													className="bonus-input-range"
													onChange={(val: any) => {
														if (val.target.value === "") {
															incentive.bonus = null;
															this.setState({
																trimestral,
																hasUnsavedFields: true
															});

															return;
														}

														if (isNaN(val.target.value)) return;

														const number = Number(val.target.value);

														incentive.bonus = Number(number);
														this.setState({
															trimestral,
															hasUnsavedFields: true
														});
													}}
													onBlur={() => {
														this.setState({
															trimestral,
															hasUnsavedFields: true
														});
													}}
												/>
											)}
											{incentive.type === "prize" && (
												<Input
													value={incentive.prize}
													style={{ width: "100%", height: 32 }}
													placeholder={Strings.bonuses.bonusExtraIncentivePrize}
													onChange={(e: any) => {
														incentive.prize = e.target.value;
														this.setState({ trimestral, hasUnsavedFields: true });
													}}
												/>
											)}
										</div>
									</td>
									<td>
										<div className="IncentivesSubTableActions">
											<Icon
												name="trash"
												onClick={() => {
													row.original.incentives.splice(index, 1);
													this.setState({ trimestral, hasUnsavedFields: true });
												}}
											/>
										</div>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				{(!row.original.incentives || row.original.incentives?.length === 0) && (
					<div className="IncentivesSubTableEmpty">
						<p>{Strings.settings.noIncentives}</p>
					</div>
				)}
			</div>
		);
	}

	renderTrimestral() {
		const { trimestral = [] } = this.state;

		return (
			<Table
				title={{ title: Strings.settings.trimestralBonus }}
				data={trimestral}
				columns={[
					{
						Header: Strings.dashboard.period,
						id: "period",
						accessor: (row: any) =>
							DateTime.fromISO(row.startDate).toFormat("dd/MM/yyyy") +
							" - " +
							DateTime.fromISO(row.endDate).toFormat("dd/MM/yyyy"),
						maxWidth: 200
					},
					{
						Header: Strings.fields.name,
						id: "name",
						accessor: (row: any) => translate(row.name) || "-"
					}
				]}
				renderExpanded={(row: any) => this.renderTrimestralExpanded(row)}
				expanded
				paginated
				add={{
					tooltip: Strings.settings.addTrimestralIncentive,
					onClick: () =>
						this.setState({
							showDrawer: true,
							tempTrimestral: {
								isActive: false,
								startDate: moment.utc().startOf("month"),
								endDate: moment.utc().add(2, "months").endOf("month")
							}
						})
				}}
				actions={{
					edit: (obj: any) => ({
						onClick: () => this.setState({ showDrawer: true, tempTrimestral: JSON.parse(JSON.stringify(obj)) }),
						disabled: !obj._id
					}),
					toggle: (obj: any) => ({
						value: obj.isActive,
						onChange: () => {
							const { trimestral } = this.state;
							const index = trimestral.findIndex((elem: any) => elem._id === obj._id);

							if (index !== -1) {
								trimestral[index].isActive = !trimestral[index].isActive;
								this.setState({ trimestral, hasUnsavedFields: true });
							}
						}
					}),
					remove: (obj: any) => ({
						onClick: () => {
							const { trimestral } = this.state;
							const index = trimestral.findIndex((elem: any) => elem._id === obj._id);

							if (index !== -1) {
								trimestral.splice(index, 1);
								this.setState({ trimestral, hasUnsavedFields: true });
							}
						}
					})
				}}
			/>
		);
	}

	renderGroupTripIntervals = () => {
		const { groupTrip } = this.state;
		const { bonuses: groupTripBonuses } = groupTrip;

		return groupTripBonuses?.map((interval: any, index: any) => {
			return (
				<React.Fragment key={index}>
					<Col xs={24} md={14}>
						<Row gutter={5} justify="start">
							<Col xs={11}>
								<Input
									type="number"
									value={interval.average}
									placeholder="0"
									addonAfter="pts"
									min={index === 0 ? 0 : groupTripBonuses[index - 1].average + 1}
									className="bonus-input-range"
									onChange={(val: any) => {
										if (isNaN(val.target.value)) return;

										const number = Number(val.target.value);

										interval.average = Number(number);
										this.setState({ groupTrip, hasUnsavedFields: true });
									}}
									onBlur={() => {
										if (groupTripBonuses[index - 1] && interval.average < groupTripBonuses[index - 1].average) {
											interval.average = groupTripBonuses[index - 1].average + 1;
											this.setState({ groupTrip, hasUnsavedFields: true });
										}
									}}
								/>
							</Col>
							<Col xs={2}>
								<div className="bonus-input-separator">
									<span>{Strings.successLadder.or}</span>
								</div>
							</Col>
							<Col xs={11}>
								<Input
									type="number"
									value={interval.annual}
									placeholder="0"
									addonAfter="pts"
									min={index === 0 ? 0 : groupTripBonuses[index - 1].annual + 1}
									className="bonus-input-range"
									onChange={(val: any) => {
										if (isNaN(val.target.value)) return;

										const number = Number(val.target.value);

										interval.annual = Number(number);
										this.setState({ groupTrip, hasUnsavedFields: true });
									}}
									onBlur={() => {
										if (groupTripBonuses[index - 1] && interval.annual < groupTripBonuses[index - 1].annual) {
											interval.annual = groupTripBonuses[index - 1].annual + 1;
											this.setState({ groupTrip, hasUnsavedFields: true });
										}
									}}
								/>
							</Col>
						</Row>
					</Col>
					<Col xs={24} md={10}>
						<div className="bonus-range-wrapper">
							<Input
								value={interval.prize}
								placeholder={Strings.bonuses.bonusTripGroupHeader3}
								style={{ height: 32 }}
								onChange={(e) => {
									interval.prize = e.target.value;

									this.setState({ groupTrip, hasUnsavedFields: true });
								}}
							/>
							{groupTripBonuses.length === index + 1 && (
								<button
									className="bonus-remove-button"
									onClick={() => {
										groupTripBonuses.splice(index, 1);
										this.setState({ groupTrip, hasUnsavedFields: true });
									}}
									disabled={!groupTripBonuses.length === index + 1}
								>
									<Icon name="trash" />
								</button>
							)}
						</div>
					</Col>
				</React.Fragment>
			);
		});
	};

	renderGroupTrip() {
		const { groupTrip } = this.state;
		const { bonuses: groupTripBonuses, isActive } = groupTrip;

		let buttonDisabled = false;
		let last: any = {};

		if (groupTripBonuses && groupTripBonuses.length > 0) {
			last = groupTripBonuses[groupTripBonuses.length - 1];
			const lastFilled =
				(last?.average !== void 0 &&
					last?.average >= 0 &&
					last?.annual !== void 0 &&
					last?.annual >= 0 &&
					last?.prize !== "" &&
					last?.prize?.trim().length > 0) ||
				false;

			buttonDisabled = !lastFilled;
		} else {
			buttonDisabled = false;
		}

		return (
			<ContentWrapper extraClass="GroupTripContent">
				<div className="bonus-title-wrapper">
					<div className="bonus-title" style={{ marginBottom: 0 }}>
						{Strings.bonuses.bonusTripGroupTitle}
					</div>
					<Switch
						checked={isActive}
						onChange={() => {
							groupTrip.isActive = !isActive;
							this.setState({ groupTrip, hasUnsavedFields: true });
						}}
						size="default"
					/>
				</div>
				<Row gutter={[20, 10]}>
					<Col xs={24} md={14}>
						<Row>
							<Col xs={11}>
								<b>{Strings.bonuses.bonusTripGroupHeader1}</b>
							</Col>
							<Col xs={{ span: 11, offset: 2 }}>
								<b>{Strings.bonuses.bonusTripGroupHeader2}</b>
							</Col>
						</Row>
					</Col>
					<Col xs={24} md={10}>
						<label>
							<b>{Strings.bonuses.bonusTripGroupHeader3}</b>
						</label>
					</Col>
					{this.renderGroupTripIntervals()}
					<Col xs={24}>
						<div className="button-add-range">
							<button
								onClick={() => {
									groupTripBonuses.push({
										average: last?.average + 1,
										annual: last?.annual + 1,
										bonus: ""
									});

									this.setState({ groupTrip, hasUnsavedFields: true });
								}}
								disabled={buttonDisabled}
							>
								{Strings.bonuses.bonusPerformanceButton}
							</button>
						</div>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.bonuses}</title>
					<meta name="description" content="Description of Bonuses" />
				</Helmet>
				<Row gutter={[20, 20]}>
					<Col xs={24}>{this.renderTrimestral()}</Col>
					<Col xs={24}>{this.renderGroupTrip()}</Col>
				</Row>
				{this.renderDrawer()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	user: state.user
});

export default connect(mapStateToProps)(Incentives);
