import React from "react";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { Props } from "./types";
import { Helmet } from "react-helmet";
import { push, replace } from "connected-react-router";
import { ContentWrapper, Icon, PhoneInput, Table } from "components";
import { Col, Input, notification, Row, Modal, Drawer, Select, Switch, DatePicker } from "antd";
import Compressor from "compressorjs";
import Dropzone from "react-dropzone";
import debounce from "lodash/debounce";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import "./styles.scss";
import moment from "moment";

class PartnerDetail extends React.Component<Props, any> {
	phoneValidationInput: any;
	constructor(props: Props) {
		super(props);

		this.state = {
			name: "",
			fullName: "",
			birthday: null,
			email: "",
			phone: "",
			addresses: [],
			vatNumber: "",
			iban: "",
			referredBy: null,
			suspended: false,

			partnerOptions: [],
			searchParams: "",

			spouse: {
				email: "",
				name: "",
				birthday: null,
				vatNumber: "",
				phone: ""
			},

			addressInfo: {
				address: "",
				address2: "",
				city: "",
				country: "",
				state: "",
				postalCode: ""
			},

			underBy: props?.router?.location?.state?.underBy || null,
			placementSide: props?.router?.location?.state?.placementSide || "",

			hasUnsavedFields: false,
			isNew: props.match.params.id === "new"
		};

		if (props?.router?.location?.state?.underBy && props?.router?.location?.state?.placementSide) {
			if (props?.partners) {
				props.dispatch(replace(`/partner-partners/new`, {}));
			} else {
				props.dispatch(replace(`/partners/new`, {}));
			}
		}

		this.onDrop = this.onDrop.bind(this);
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		this.getData();
		this.breadcrumb();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));
		dispatch(updateCrumb());
	}

	breadcrumb() {
		const { dispatch } = this.props;

		dispatch(setBreadcrumb(null));
		delayedDispatch(
			setBreadcrumb(() => {
				const { suspended, isNew } = this.state;
				const { partners } = this.props;

				const actions: any[] = [
					{
						type: "button",
						text: Strings.generic.save,
						disabled: !this.state.hasUnsavedFields,
						isSave: true,
						hasIcon: true,
						onClick: () => this.saveUser()
					}
				];

				if (!partners && !isNew) {
					actions.unshift({
						type: "button",
						text: suspended ? Strings.users.unSuspend : Strings.users.suspend,
						className: suspended ? "BreadcrumbOpenButton" : "BreadcrumbForgetButton",
						separator: "right",
						onClick: () => this.suspendUser()
					});
				}

				return {
					locations: [
						{
							icon: "user",
							text: Strings.sidebar.partners,
							route: partners ? "/partner-partners" : "/partners"
						},
						{
							icon: this.state.isNew ? "plus" : "user",
							text: this.state.user?.name || Strings.users.addPartner
						}
					],
					actions
				};
			})
		);
	}

	async getData() {
		const { isNew, countries = [] } = this.state;
		const { dispatch, match } = this.props;

		dispatch(setLoader(true));

		try {
			if (!isNew) {
				let defaultCountries,
					user,
					defaultStates = {} as any;

				const promises = [API.get({ url: Endpoints.uriPartners(match?.params?.id) })];

				if (countries.length === 0) {
					promises.push(API.get({ url: Endpoints.uriCountries() }));
				} else {
					defaultCountries = JSON.parse(JSON.stringify(countries));
					defaultCountries.forEach((country: any) => (defaultStates[country._id] = country?.states || []));
				}

				const [response, responseCountries] = await Promise.all(promises);

				if (response?.ok) {
					user = response?.data?.results?.user || {};
					dispatch(setTitle(user?.name || ""));
				} else {
					dispatch(push("/partners"));
					notification.error({
						message: Strings.staff.partner,
						description: response.data?.message || Strings.serverErrors.wentWrong,
						placement: "bottomRight",
						duration: 5
					});
				}

				let defaultApiCountries = [] as any;
				if (responseCountries?.ok) {
					defaultCountries = responseCountries?.data?.results?.countries || [];
					defaultApiCountries = JSON.parse(JSON.stringify(defaultCountries));
					defaultCountries.forEach((country: any) => (defaultStates[country._id] = country?.states || []));
				} else if (responseCountries) {
					notification.error({
						message: Strings.staff.partner,
						description: response.data?.message || Strings.serverErrors.wentWrong,
						placement: "bottomRight",
						duration: 5
					});
				}

				let referrer;

				if (user?.partner?.referredBy) {
					const response = await API.get({ url: Endpoints.uriPartners(user?.partner?.referredBy) });

					if (response?.ok) {
						const { user } = response?.data?.results || {};
						referrer = `${user?.name} (${user?.email})}`;
					}
				}

				this.setState({
					...user,
					user,
					spouse: user.partner.spouse,
					referredBy: user.partner.referredBy,
					referrer,
					defaultCountries,
					defaultStates,
					countries: defaultApiCountries || this.props.countries || []
				});
			} else if (countries.length === 0) {
				const response = await API.get({ url: Endpoints.uriCountries() });

				if (response?.ok) {
					const { countries } = response?.data?.results || [];
					const defaultStates = [] as any;
					countries.forEach((country: any) => (defaultStates[country._id] = country?.states || []));
					this.setState({ defaultCountries: countries, defaultStates });
				} else {
					notification.error({
						message: Strings.staff.partner,
						description: response.data?.message || Strings.serverErrors.wentWrong,
						placement: "bottomRight",
						duration: 5
					});
				}
			} else {
				const defaultCountries = JSON.parse(JSON.stringify(countries));
				const defaultStates = [] as any;
				defaultCountries.forEach((country: any) => (defaultStates[country._id] = country?.states || []));
				this.setState({ defaultCountries, defaultStates });
			}
		} catch (err) {
			dispatch(push("/partners"));
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async saveUser() {
		const {
			isNew,
			photo,
			name,
			fullName,
			birthday,
			email,
			phone,
			addresses,
			vatNumber,
			underBy,
			placementSide,
			referredBy,
			spouse,
			addressInfo,
			iban
		} = this.state;
		const { dispatch, match, partners } = this.props;

		if (!this.validUser()) return;

		if (!this.validSpouse()) return;

		if (isNew && !this.validAddressOnCreate()) return;

		dispatch(setLoader(true));

		try {
			const body = new FormData();
			body.append("name", name);
			body.append("fullName", fullName);
			if (birthday) body.append("birthday", birthday);
			body.append("email", email);
			body.append("phone", phone);
			body.append("addresses", JSON.stringify(addresses));
			if (vatNumber) body.append("vatNumber", vatNumber);
			body.append("role", "partner");
			body.append("iban", iban);
			if (referredBy) body.append("referredBy", referredBy);

			if (photo && Object.keys(photo).length > 0) {
				if (photo.file) {
					body.append("photo", photo.file);
				} else {
					body.append("photo", photo);
				}
			}

			if (spouse) body.append("spouse", JSON.stringify(spouse));
			body.append("addressInfo", JSON.stringify(addressInfo));

			if (isNew && underBy && placementSide) {
				body.append("suggestedUnderBy", underBy._id);
				body.append("placementSide", placementSide);
			}

			if (!underBy && isNew) {
				body.append("placementSide", placementSide);
			}

			const request = isNew ? API.post : API.put;
			const response = await request({
				url: Endpoints.uriPartners(isNew ? "" : match?.params?.id),
				data: body
			});

			if (response.ok) {
				const { user } = response.data.results || {};
				notification.success({
					message: Strings.staff.partner,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});

				if (isNew) {
					if (partners) {
						dispatch(replace(`/partner-partners/${user._id}`));
					} else {
						dispatch(replace(`/partners/${user._id}`));
					}
					this.breadcrumb();
				}

				this.setState({ ...user, user, isNew: false, hasUnsavedFields: false });
			} else {
				notification.error({
					message: Strings.staff.partner,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			console.log("error", err as string);
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async saveAddress() {
		const { selectedAddress } = this.state;
		const { dispatch, match } = this.props;

		if (!this.validAddress()) return;

		dispatch(setLoader(true));

		try {
			const body = { ...selectedAddress };
			delete body._id;

			const request = selectedAddress?._id ? API.put : API.post;
			const response = await request({
				url: Endpoints.uriPartners(`${match?.params?.id}/addresses/${selectedAddress?._id || ""}`),
				data: body
			});

			if (response.ok) {
				const { user } = response.data.results || {};
				this.setState({ ...user, user, selectedAddress: null, showAddressDrawer: false });

				notification.success({
					message: Strings.users.addresses,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.users.addresses,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async deleteAddress(id: string) {
		const { dispatch } = this.props;
		const { user } = this.state;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriPartners(`${user._id}/addresses/${id}`)
			});
			if (response.ok) {
				const { user } = response.data.results || {};
				this.setState({ user, ...user });

				notification.success({
					message: Strings.users.addresses,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.users.addresses,
					description: (response.data?.message as string) || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async forgetUser() {
		const { dispatch } = this.props;
		const { user } = this.state;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriPartners(`${user._id}/forget`),
				data: { forget: true }
			});

			if (response.ok) {
				const { user } = response.data.results || {};
				this.setState({ openConfirmForgetModal: false, user, name: user.name, email: user.email });
				notification.success({
					message: Strings.staff.partner,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.staff.partner,
					description: (response.data?.message as string) || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async setBillingAddress(address: any) {
		const { dispatch } = this.props;
		const { user } = this.state;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriPartners(`${user._id}/addresses/${address._id}`),
				data: { isDefaultBilling: !address.isDefaultBilling }
			});

			if (response.ok) {
				const { user } = response.data.results || {};
				this.setState({ user, ...user });

				notification.success({
					message: Strings.users.addresses,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.users.addresses,
					description: (response.data?.message as string) || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async suspendUser() {
		const { dispatch, partners } = this.props;
		const { user } = this.state;

		if (partners) return;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriPartners(`${user._id}/suspend`),
				data: { suspended: !user.suspended }
			});

			if (response.ok) {
				const { user } = response.data.results || {};
				this.setState({ ...user, user });
				notification.success({
					message: Strings.staff.partner,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.staff.partner,
					description: (response.data?.message as string) || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	validAddress() {
		const { selectedAddress } = this.state;

		if (!selectedAddress?.address) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.addressRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!selectedAddress?.city) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.cityRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!selectedAddress?.country) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.countryRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!selectedAddress?.state) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.stateRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!selectedAddress?.postalCode) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.zipRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		return true;
	}

	validAddressOnCreate() {
		const { addressInfo } = this.state;

		if (!addressInfo?.address) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.addressRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!addressInfo?.city) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.cityRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!addressInfo?.country) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.countryRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!addressInfo?.state) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.stateRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!addressInfo?.postalCode) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.zipRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		return true;
	}

	validUser() {
		const { name, fullName, phone, email, vatNumber, placementSide } = this.state;

		if (!name) {
			notification.warn({
				message: Strings.users.addresses,
				description: Strings.users.nameRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!fullName) {
			notification.warn({
				message: Strings.users.addresses,
				description: Strings.users.fullNameRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!phone) {
			notification.warn({
				message: Strings.users.addresses,
				description: Strings.users.phoneRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!email) {
			notification.warn({
				message: Strings.users.addresses,
				description: Strings.users.emailRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!this.validEmail(email)) {
			notification.warn({
				message: Strings.users.addresses,
				description: Strings.users.emailInvalid,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!vatNumber) {
			notification.warn({
				message: Strings.users.addresses,
				description: Strings.users.vatNumberRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!placementSide) {
			notification.warn({
				message: Strings.users.addresses,
				description: Strings.users.pleaseSelectSide,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		return true;
	}

	validSpouse() {
		const { spouse } = this.state;

		if (spouse.name) {
			if (!spouse?.email) {
				notification.error({
					message: Strings.users.addresses,
					description: Strings.users.emailRequired,
					placement: "bottomRight",
					duration: 5
				});
				return false;
			}

			if (!spouse.email) {
				notification.error({
					message: Strings.users.addresses,
					description: Strings.users.emailRequired,
					placement: "bottomRight",
					duration: 5
				});
				return false;
			}

			if (!this.validEmail(spouse.email)) {
				notification.error({
					message: Strings.users.addresses,
					description: Strings.users.emailInvalid,
					placement: "bottomRight",
					duration: 5
				});
				return false;
			}

			if (!spouse.birthday) {
				notification.error({
					message: Strings.users.addresses,
					description: Strings.users.birthdayRequired,
					placement: "bottomRight",
					duration: 5
				});
				return false;
			}

			if (!spouse.vatNumber) {
				notification.error({
					message: Strings.users.addresses,
					description: Strings.users.vatRequired,
					placement: "bottomRight",
					duration: 5
				});
				return false;
			}

			if (!spouse.phone) {
				notification.error({
					message: Strings.users.addresses,
					description: Strings.users.phoneRequired,
					placement: "bottomRight",
					duration: 5
				});
				return false;
			}

			return true;
		} else {
			return true;
		}
	}

	validIban(iban: string) {
		if (!iban) return false;

		const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{7}([A-Z0-9]?){0,16}$/;
		return !ibanRegex.test(iban);
	}

	validEmail(email: string) {
		if (!email) return false;

		const emailRegex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return emailRegex.test(email);
	}

	getBase64(file: File) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	onDrop(files: File[] | Blob[]) {
		try {
			const file = files.pop();

			new Compressor(file!, {
				quality: 0.9,
				maxWidth: 400,
				mimeType: "image/jpeg",
				success: (result: any) => {
					this.getBase64(result).then((res) => {
						this.setState({
							photo: { file: result, preview: res },
							hasUnsavedFields: true
						});
					});
				}
			});
		} catch (err) {
			console.log("err", err);
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5
			});
		}
	}

	getCountry(id: string) {
		const { defaultCountries } = this.state;
		return defaultCountries.find((c: any) => c._id === id)?.name;
	}

	getState(id: string, countryId: string) {
		const { defaultStates } = this.state;
		const states = defaultStates?.[countryId] || {};
		return states.find((c: any) => c._id === id)?.name;
	}

	renderConfirmForget = () => {
		const { openConfirmForgetModal } = this.state;

		return (
			<Modal
				centered
				className="confirmForget"
				style={{ textAlign: "center" }}
				visible={openConfirmForgetModal}
				cancelText={Strings.generic.close}
				okText={Strings.authentication.forgetConfirm}
				onOk={() => this.forgetUser()}
				onCancel={() => this.setState({ openConfirmForgetModal: false })}
				title={null}
				closable={false}
				bodyStyle={{ minHeight: 420 }}
			>
				<Icon name="user" style={{ fontSize: "50px" }}></Icon>
				<div className="title">
					{openConfirmForgetModal?.email
						? Strings.formatString(Strings.authentication.forgetTitle, openConfirmForgetModal?.email)
						: Strings.authentication.forgetTitle}
				</div>
			</Modal>
		);
	};

	debounceFetcher = debounce(async (value: string) => {
		const { underBy } = this.state;
		const body: { search: string; page: number; perPage: number; underBy?: string } = { search: value, page: 0, perPage: 10 };
		if (underBy) body.underBy = underBy._id;

		try {
			const res = await API.post({
				url: Endpoints.uriPartners("search-ascendants"),
				data: body
			});
			if (res?.ok) {
				const { users } = res.data.results;
				this.setState({ partnerOptions: users });
			}
		} catch (err) {
			console.log("err", err);
		}
	}, 800);

	handleSearch = (value: string) => {
		if (value) {
			this.debounceFetcher(value);
			this.setState({ searchParams: value });
		} else {
			this.setState({ partnerOptions: [], searchParams: "" });
		}
	};

	renderPartnerInformation() {
		const {
			name,
			fullName,
			birthday,
			email,
			photo,
			phone,
			vatNumber,
			userCode,
			isNew,
			partner,
			underBy,
			placementSide,
			referredBy,
			referrer,
			partnerOptions,
			searchParams,
			iban
		} = this.state;
		const { partners } = this.props;

		const hostname = window.location.hostname?.split(".")?.[0];
		const parsedHostname = window.location.hostname.split(".")?.slice(1)?.join(".");
		let link = `${window.location.protocol}//${partner?.store}.${parsedHostname}`;
		if (hostname === "localhost") link = `https://${partner?.store}.edvdev.net`;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="user" />
						<h2>{Strings.users.fullPartnerInfo}</h2>
					</div>
				</div>
				<div className="UserContainer">
					<div className="UserAvatarContainer">
						<Dropzone
							accept="image/jpg, image/jpeg, image/png"
							className="UserAvatarInnerContainer"
							onDrop={this.onDrop}
							disableClick={!isNew && partners}
						>
							<div className="AvatarWrapper">
								{photo ? (
									<img src={photo?.preview || photo} alt="partner avatar" />
								) : (
									<div className="UserAvatarPlaceholder">
										<Icon name="frame" />
										<p>{Strings.users.partnerNoImage}</p>
									</div>
								)}
							</div>
						</Dropzone>
					</div>
					<div className="UserInformationWrapper">
						<Row gutter={[20, 10]}>
							<Col xs={12}>
								<label htmlFor="name" className="InputLabel --label-required">
									{Strings.fields.name}
								</label>
								<Input
									id="name"
									placeholder={Strings.fields.name}
									value={name || ""}
									onChange={(e) => this.setState({ name: e.target.value, hasUnsavedFields: true })}
									disabled={!isNew && partners}
									style={{ height: 40 }}
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="birthday" className="InputLabel">
									{Strings.fields.birthday}
								</label>
								<DatePicker
									id="birthday"
									style={{ width: "100%", height: 40 }}
									placeholder={Strings.fields.birthday}
									value={birthday ? moment.utc(birthday) : undefined}
									disabledDate={(current) => current && current > moment().subtract(18, "years")}
									onChange={(value) => {
										this.setState({ birthday: value?.toISOString(), hasUnsavedFields: true });
									}}
									disabled={!isNew && partners}
								></DatePicker>
							</Col>
							<Col xs={24}>
								<label htmlFor="fullName" className="InputLabel --label-required">
									{Strings.fields.fullName}
								</label>
								<Input
									id="fullName"
									placeholder={Strings.fields.fullName}
									value={fullName || ""}
									onChange={(e) => this.setState({ fullName: e.target.value, hasUnsavedFields: true })}
									disabled={!isNew && partners}
									style={{ height: 40 }}
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="email" className="InputLabel --label-required">
									{Strings.fields.email}
								</label>
								<Input
									id="email"
									type="email"
									placeholder={Strings.fields.email}
									value={email || ""}
									disabled={!isNew}
									onChange={(e) => this.setState({ email: e.target.value, hasUnsavedFields: true })}
									style={{ height: 40 }}
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="phone" className="InputLabel --label-required">
									{Strings.fields.phone}
								</label>
								<PhoneInput
									ref={(ref: any) => {
										this.phoneValidationInput = ref;
									}}
									defaultCountry="pt"
									value={phone || ""}
									inputClass={!isNew && partners ? "input-phone phone-disabled" : "input-phone"}
									inputProps={{
										name: "phone",
										required: true,
										autoComplete: "off",
										placeholder: Strings.fields.phone
									}}
									disabled={!isNew && partners}
									onChange={(phone: any) => this.setState({ phone, hasUnsavedFields: true })}
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="vat_number" className="InputLabel --label-required">
									{Strings.fields.vatNumber}
								</label>
								<Input
									id="var_number"
									placeholder={Strings.fields.vatNumber}
									value={vatNumber || ""}
									onChange={(e) => this.setState({ vatNumber: e.target.value, hasUnsavedFields: true })}
									disabled={!isNew && partners}
									style={{ height: 40 }}
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="partner_code" className="InputLabel">
									{Strings.users.partnerCode}
								</label>
								<Input
									id="partner_code"
									placeholder={Strings.users.partnerCode}
									value={userCode || ""}
									disabled
									style={{ height: 40 }}
								/>
							</Col>
							{partner?.store != null && (
								<Col xs={24}>
									<label htmlFor="store_link" className="InputLabel">
										{Strings.generic.storeLink}
									</label>
									<Input
										id="store_link"
										placeholder={Strings.generic.storeLink}
										value={link}
										disabled
										style={{ height: 40 }}
									/>
								</Col>
							)}
							{Boolean(underBy) && Boolean(placementSide) && (
								<React.Fragment>
									<Col xs={24} md={12}>
										<label htmlFor="partner_under_by" className="InputLabel --label-required">
											{Strings.users.underBy}
										</label>
										<Input
											id="partner_under_by"
											placeholder={Strings.users.underBy}
											value={`${underBy?.name} (${underBy?.email})`}
											disabled
											style={{ height: 40 }}
										/>
									</Col>
									<Col xs={24} md={12}>
										<label htmlFor="partner_placement_side" className="InputLabel --label-required">
											{Strings.users.placementSide}
										</label>
										<Input
											id="partner_placement_side"
											placeholder={Strings.users.placementSide}
											value={placementSide === "left" ? Strings.generic.left : Strings.generic.right}
											disabled
											style={{ height: 40 }}
										/>
									</Col>
								</React.Fragment>
							)}
							{isNew && !Boolean(underBy) && (
								<Col xs={24} md={12}>
									<label htmlFor="partner_placement_side" className="InputLabel --label-required">
										{Strings.users.placementSide}
									</label>
									<Select
										id="partner_placement_side"
										style={{ width: "100%", height: 40 }}
										placeholder={Strings.users.placementSide}
										filterOption={(input: any, option: any) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										value={placementSide || null}
										onChange={(elem: any) => {
											this.setState({ placementSide: elem });
										}}
										allowClear={false}
									>
										<Select.Option value="left">{Strings.generic.left}</Select.Option>
										<Select.Option value="right">{Strings.generic.right}</Select.Option>
									</Select>
								</Col>
							)}
							{!partners && (
								<Col xs={24} md={12}>
									<label htmlFor="referredBy" className="InputLabel --label-required">
										{Strings.users.referredBy}
									</label>
									<Select
										id="referredBy"
										style={{ width: "100%", height: 40 }}
										placeholder={Strings.users.referredBy}
										filterOption={false}
										showSearch
										onSearch={this.handleSearch}
										value={referrer || referredBy || null}
										onChange={(elem: any) => {
											this.setState({ referredBy: elem, referrer: undefined, hasUnsavedFields: true });
										}}
										onClear={() =>
											this.setState({
												referredBy: undefined,
												referrer: undefined,
												partnerOptions: [],
												searchParams: "",
												hasUnsavedFields: true
											})
										}
										disabled={!isNew}
										notFoundContent={
											<div>
												{searchParams === "" ? Strings.generic.searchByEmailOrName : Strings.generic.noResults}
											</div>
										}
										allowClear={false}
									>
										{partnerOptions?.map((partner: any) => (
											<Select.Option value={partner._id} key={partner._id}>
												{partner.name} ({partner.email})
											</Select.Option>
										))}
									</Select>
								</Col>
							)}
							{(!partners && (
								<Col xs={24}>
									<label htmlFor="iban" className="InputLabel">
										{Strings.fields.iban}
									</label>
									<Input
										id="iban"
										placeholder={Strings.fields.iban}
										value={iban || ""}
										pattern="[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{7}([A-Z0-9]?){0,16}"
										onChange={(e) => this.setState({ iban: e.target.value?.toUpperCase(), hasUnsavedFields: true })}
										style={{ height: 40 }}
									/>
								</Col>
							)) ||
								null}
						</Row>
					</div>
				</div>
			</ContentWrapper>
		);
	}

	renderSpouseInformation() {
		const { spouse, isNew } = this.state;
		const { partners } = this.props;

		const isRequired = spouse?.name ? true : false;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="user" />
						<h2>{Strings.users.fullSpouseInfo}</h2>
					</div>
				</div>
				<div>
					<Row gutter={[20, 10]}>
						<Col xs={12}>
							<label htmlFor="spouseName" className={`InputLabel ${isRequired && "--label-required"}`}>
								{Strings.fields.name}
							</label>
							<Input
								id="spouseName"
								placeholder={Strings.fields.name}
								value={spouse?.name || ""}
								onChange={(e) => {
									let val = e.target.value;

									this.setState((prevState: any) => ({
										spouse: { ...prevState.spouse, name: val },
										hasUnsavedFields: true
									}));
								}}
								disabled={!isNew && partners}
								style={{ height: 40 }}
							/>
						</Col>
						<Col xs={12}>
							<label htmlFor="spouseEmail" className={`InputLabel ${isRequired && "--label-required"}`}>
								{Strings.fields.email}
							</label>
							<Input
								id="spouseEmail"
								placeholder={Strings.fields.email}
								value={spouse?.email || ""}
								onChange={(e) => {
									let val = e.target.value;

									this.setState((prevState: any) => ({
										spouse: { ...prevState.spouse, email: val },
										hasUnsavedFields: true
									}));
								}}
								disabled={!isNew && partners}
								style={{ height: 40 }}
							/>
						</Col>
						<Col xs={12}>
							<label htmlFor="spouseBirthday" className={`InputLabel ${isRequired && "--label-required"}`}>
								{Strings.fields.birthday}
							</label>
							<DatePicker
								id="spouseBirthday"
								style={{ width: "100%", height: 40 }}
								placeholder={Strings.fields.birthday}
								value={spouse?.birthday ? moment.utc(spouse?.birthday) : undefined}
								disabledDate={(current) => current && current > moment().subtract(18, "years")}
								onChange={(value) => {
									this.setState((prevState: any) => ({
										spouse: { ...prevState.spouse, birthday: value?.toISOString() },
										hasUnsavedFields: true
									}));
								}}
								disabled={!isNew && partners}
							></DatePicker>
						</Col>
						<Col xs={12}>
							<label htmlFor="spouseNif" className={`InputLabel ${isRequired && "--label-required"}`}>
								{Strings.fields.nif}
							</label>
							<Input
								id="spouseNif"
								placeholder={Strings.fields.nif}
								value={spouse?.vatNumber || ""}
								onChange={(e) => {
									let val = e.target.value;

									this.setState((prevState: any) => ({
										spouse: { ...prevState.spouse, vatNumber: val },
										hasUnsavedFields: true
									}));
								}}
								disabled={!isNew && partners}
								style={{ height: 40 }}
							/>
						</Col>
						<Col xs={12}>
							<label htmlFor="spousePhoneNumber" className={`InputLabel ${isRequired && "--label-required"}`}>
								{Strings.fields.phoneNumber}
							</label>
							<PhoneInput
								id="spousePhoneNumber"
								defaultCountry="pt"
								inputClass={!isNew && partners ? "input-phone phone-disabled" : "input-phone"}
								placeholder={Strings.fields.phoneNumber}
								value={spouse?.phone || ""}
								onChange={(phone: any) => {
									this.setState((prevState: any) => ({
										spouse: { ...prevState.spouse, phone },
										hasUnsavedFields: true
									}));
								}}
								disabled={!isNew && partners}
								style={{ height: 40 }}
							/>
						</Col>
					</Row>
				</div>
			</ContentWrapper>
		);
	}

	renderNewAddress() {
		const { defaultCountries = [], defaultStates = [], addressInfo } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="user" />
						<h2>{Strings.users.addresses}</h2>
					</div>
				</div>
				<div>
					<Row gutter={[20, 10]}>
						<Col xs={12}>
							<label htmlFor="address" className="InputLabel --label-required">
								{Strings.fields.address}
							</label>
							<Input
								id="address"
								placeholder={Strings.fields.address}
								value={addressInfo?.address || ""}
								onChange={(e) => {
									let val = e.target.value;

									this.setState((prevState: any) => ({
										addressInfo: {
											...prevState?.addressInfo,
											address: val
										},
										hasUnsavedFields: true
									}));
								}}
							/>
						</Col>
						<Col xs={12}>
							<label htmlFor="address2" className="InputLabel">
								{Strings.fields.address2}
							</label>
							<Input
								id="address2"
								placeholder={Strings.fields.address2}
								value={addressInfo?.address2 || ""}
								onChange={(e) => {
									let val = e.target.value;

									this.setState((prevState: any) => ({
										addressInfo: {
											...prevState.addressInfo,
											address2: val
										},
										hasUnsavedFields: true
									}));
								}}
							/>
						</Col>
						<Col xs={12} md={6}>
							<label htmlFor="country" className="InputLabel --label-required">
								{Strings.fields.country}
							</label>
							<Select
								id="country"
								style={{ width: "100%" }}
								placeholder={Strings.fields.country}
								showSearch
								filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								value={addressInfo?.country || null}
								onChange={(elem: any) => {
									this.setState((prevState: any) => ({
										addressInfo: {
											...prevState.addressInfo,
											country: elem
										},
										hasUnsavedFields: true
									}));
								}}
								allowClear={false}
							>
								{defaultCountries.map((country: any) => (
									<Select.Option key={country._id} value={country._id}>
										{country?.name}
									</Select.Option>
								))}
							</Select>
						</Col>
						<Col xs={12} md={6}>
							<label htmlFor="state" className="InputLabel --label-required">
								{Strings.fields.state}
							</label>
							<Select
								id="state"
								style={{ width: "100%" }}
								placeholder={Strings.fields.state}
								showSearch
								filterOption={(input: any, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								value={addressInfo?.state || null}
								disabled={!addressInfo?.country}
								options={
									addressInfo?.country
										? defaultStates[addressInfo?.country].map((state: any) => ({
												value: state?._id,
												label: state?.name
										  }))
										: null
								}
								onChange={(elem: any) => {
									let val = elem;

									this.setState((prevState: any) => ({
										addressInfo: {
											...prevState.addressInfo,
											state: val
										},
										hasUnsavedFields: true
									}));
								}}
								allowClear={false}
							/>
						</Col>
						<Col xs={12} md={6}>
							<label htmlFor="city" className="InputLabel --label-required">
								{Strings.fields.city}
							</label>
							<Input
								id="city"
								placeholder={Strings.fields.city}
								value={addressInfo?.city || ""}
								onChange={(e) => {
									let val = e.target.value;

									this.setState((prevState: any) => ({
										addressInfo: {
											...prevState.addressInfo,
											city: val
										},
										hasUnsavedFields: true
									}));
								}}
							/>
						</Col>
						<Col xs={12} md={6}>
							<label htmlFor="zipCode" className="InputLabel --label-required">
								{Strings.fields.zipCode}
							</label>
							<Input
								id="zipCode"
								placeholder={Strings.fields.zipCode}
								value={addressInfo?.postalCode || ""}
								onChange={(e) => {
									let val = e.target.value;

									this.setState((prevState: any) => ({
										addressInfo: {
											...prevState.addressInfo,
											postalCode: val
										},
										hasUnsavedFields: true
									}));
								}}
							/>
						</Col>
					</Row>
				</div>
			</ContentWrapper>
		);
	}

	renderPartnerAddresses() {
		const { addresses } = this.state;
		const { partners } = this.props;

		const columns = [
			{
				Header: Strings.fields.name,
				id: "name",
				accessor: (row: any) => row.name
			},
			{
				Header: Strings.fields.address,
				id: "address",
				accessor: (row: any) => row.address
			},
			{
				Header: Strings.fields.country,
				id: "country",
				accessor: (row: any) => (row.country ? this.getCountry(row.country) : "-")
			},
			{
				Header: Strings.fields.state,
				id: "state",
				accessor: (row: any) => (row.state ? this.getState(row.state, row.country) : "-")
			},
			{
				Header: Strings.users.isDefault,
				id: "isDefault",
				accessor: (user: any) => user.isDefault
			}
		] as any[];

		if (!partners) {
			columns.push({
				Header: Strings.users.isDefaultBilling,
				id: "isDefaultBilling",
				Cell: (row: any) => (
					<Switch
						checked={row.cell.row.original?.isDefaultBilling || false}
						onChange={(_, e) => {
							e.preventDefault();
							e.stopPropagation();

							this.setBillingAddress(row.cell.row.original);
						}}
						size="small"
					/>
				)
			});
		}

		return (
			<Table
				title={{
					icon: "directions",
					title: Strings.users.addresses
				}}
				data={addresses}
				columns={columns}
				style={{ marginBottom: 10 }}
				add={{
					tooltip: Strings.banners.addBanner,
					onClick: () => this.setState({ showAddressDrawer: true, selectedAddress: null })
				}}
				actions={{
					edit: (obj: any) => ({
						onClick: () => this.setState({ showAddressDrawer: true, selectedAddress: JSON.parse(JSON.stringify(obj)) })
					}),
					remove: (obj: any) => ({
						onClick: () => this.deleteAddress(obj._id)
					})
				}}
			/>
		);
	}

	renderAddressDrawer() {
		const { showAddressDrawer, selectedAddress } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="directions" />
						<p>{selectedAddress?._id ? Strings.users.editAddress : Strings.users.addAddress}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button type="button" className="SidebarFooterButton --button-confirm" onClick={() => this.saveAddress()}>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() => this.setState({ showAddressDrawer: false, tempAddress: null })}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				closable={false}
				onClose={() => this.setState({ showAddressDrawer: false, tempAddress: null })}
				visible={showAddressDrawer}
				width={mobile ? "100%" : 400}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	renderDrawerContent() {
		const { selectedAddress, defaultCountries = [], defaultStates = [] } = this.state;

		return (
			<Row gutter={[20, 10]}>
				<Col xs={24}>
					<label htmlFor="name" className="InputLabel --label-required">
						{Strings.fields.name}
					</label>
					<Input
						id="name"
						placeholder={Strings.fields.name}
						value={selectedAddress?.name || ""}
						onChange={(e) => this.setState({ selectedAddress: { ...selectedAddress, name: e.target.value } })}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="phone" className="InputLabel">
						{Strings.fields.phone}
					</label>
					<PhoneInput
						ref={(ref: any) => {
							this.phoneValidationInput = ref;
						}}
						defaultCountry="pt"
						value={selectedAddress?.phone || ""}
						inputClass="input-phone"
						inputProps={{
							name: "phone",
							required: true,
							autoComplete: "off",
							placeholder: Strings.fields.phone
						}}
						onChange={(phone: string) => this.setState({ selectedAddress: { ...selectedAddress, phone } })}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="address" className="InputLabel --label-required">
						{Strings.fields.address}
					</label>
					<Input
						id="address"
						placeholder={Strings.fields.address}
						value={selectedAddress?.address || ""}
						onChange={(e) => this.setState({ selectedAddress: { ...selectedAddress, address: e.target.value } })}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="address2" className="InputLabel">
						{Strings.fields.address2}
					</label>
					<Input
						id="address2"
						placeholder={Strings.fields.address2}
						value={selectedAddress?.address2 || ""}
						onChange={(e) => this.setState({ selectedAddress: { ...selectedAddress, address2: e.target.value } })}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="zip" className="InputLabel --label-required">
						{Strings.fields.zipCode}
					</label>
					<Input
						id="zip"
						placeholder={Strings.fields.zipCode}
						value={selectedAddress?.postalCode || ""}
						onChange={(e) => this.setState({ selectedAddress: { ...selectedAddress, postalCode: e.target.value } })}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="city" className="InputLabel --label-required">
						{Strings.fields.city}
					</label>
					<Input
						id="city"
						placeholder={Strings.fields.city}
						value={selectedAddress?.city || ""}
						onChange={(e) => this.setState({ selectedAddress: { ...selectedAddress, city: e.target.value } })}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="country" className="InputLabel --label-required">
						{Strings.fields.country}
					</label>
					<Select
						style={{ width: "100%" }}
						placeholder={Strings.fields.country}
						showSearch
						filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						value={selectedAddress?.country || null}
						onChange={(elem: any) => {
							this.setState((prevState: any) => ({
								selectedAddress: {
									...prevState.selectedAddress,
									country: elem,
									state: null,
									hasUnsavedFields: true
								}
							}));
						}}
					>
						{defaultCountries.map((country: any) => (
							<Select.Option key={country._id} value={country._id}>
								{country?.name}
							</Select.Option>
						))}
					</Select>
				</Col>
				<Col xs={24}>
					<label htmlFor="state" className="InputLabel --label-required">
						{Strings.fields.state}
					</label>
					<Select
						id="state"
						style={{ width: "100%" }}
						placeholder={selectedAddress?.country ? Strings.fields.state : Strings.fields.selectCountryFirst}
						showSearch
						filterOption={(input: any, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						value={selectedAddress?.state || null}
						disabled={!selectedAddress?.country}
						options={
							selectedAddress?.country
								? defaultStates[selectedAddress?.country].map((state: any) => ({
										value: state?._id,
										label: state?.name
								  }))
								: null
						}
						onChange={(elem: any) => {
							this.setState((prevState: any) => ({
								selectedAddress: {
									...prevState.selectedAddress,
									state: elem,
									hasUnsavedFields: true
								}
							}));
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="vat_number" className="InputLabel">
						{Strings.fields.vatNumber}
					</label>
					<Input
						id="vat_number"
						placeholder={Strings.fields.vatNumber}
						value={selectedAddress?.vatNumber || ""}
						onChange={(e) => this.setState({ selectedAddress: { ...selectedAddress, vatNumber: e.target.value } })}
					/>
				</Col>
				<Col xs={24}>
					<div className={`General_ColorFul_Switch ${selectedAddress?.isDefault ? "__active" : ""}`}>
						<span>{Strings.users.isDefault}</span>
						<Switch
							className={`Switch ${selectedAddress?.isDefault ? "__active" : ""}`}
							checked={selectedAddress?.isDefault || false}
							size="small"
							onChange={(value: any) =>
								this.setState((prevState: any) => ({
									selectedAddress: {
										...prevState.selectedAddress,
										isDefault: value,
										hasUnsavedFields: true
									}
								}))
							}
						/>
					</div>
				</Col>
			</Row>
		);
	}

	render() {
		const { isNew } = this.state;
		const { partners } = this.props;

		return (
			<div className="PartnerDetailScreen">
				<Helmet>
					<title>{Strings.staff.partner}</title>
					<meta name="description" content="Edit your partners\'s information" />
				</Helmet>
				{this.renderPartnerInformation()}
				{this.renderSpouseInformation()}
				{isNew ? this.renderNewAddress() : partners ? null : this.renderPartnerAddresses()}
				{this.renderAddressDrawer()}
				{this.renderConfirmForget()}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	mobile: state.mobile,
	router: state.router,
	countries: state.countries
});

export default connect(mapStateToProps)(PartnerDetail);
