import { Table } from "components";
import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Strings from "utils/strings";
import { push } from "connected-react-router";
import { setLoader, setTitle } from "store/actions";
import { notification, Switch } from "antd";
import { API, Endpoints } from "utils/api";
import { translate } from "utils/utils";
import { Segment } from "screens/Admin/SegmentDetail/types";

class Segments extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			segments: [],
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(setTitle(""));
		this.getData();
	}

	async deleteSegment(value: any) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriSegments(value),
			});

			if (response.ok) {
				this.setState({ segments: response.data.results.segments || [] });

				notification.success({
					message: Strings.sidebar.segments,
					description: Strings.segments.deleted,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.sidebar.segments,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriSegments(),
			});

			if (response.ok) {
				this.setState({ segments: response.data.results.segments || [] });
			} else {
				notification.error({
					message: Strings.sidebar.segments,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});

				dispatch(push("/segments"));
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	onDrag = async () => {
		const { segments, hoverIndex } = this.state;
		const { dispatch } = this.props;

		const item = segments[hoverIndex];
		const itemId = item?._id;

		if (!itemId || item.pos === hoverIndex) return;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriSegments(`${itemId}/position`),
				data: { pos: hoverIndex },
			});

			if (response.ok) {
				await this.getData();

				notification.success({
					message: Strings.sidebar.segments,
					description: response?.data?.message || Strings.segments.updated,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	};

	renderShowOnHeaderSwitch = (cell: any) => {
		const { row } = cell;

		return (
			<span className="TaxesSwitch">
				<Switch
					className={`Switch${row.original?.showOnHeader ? " __active" : ""}`}
					checked={row.original?.showOnHeader}
					onChange={() => {
						this.toggleShowOnHeader(row.original);
					}}
				/>
			</span>
		);
	};

	async toggleShowOnHeader(segment: Segment) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriSegments(`${segment._id}/header`),
				data: {
					showOnHeader: !segment.showOnHeader,
				},
			});

			if (response?.ok) {
				await this.getData();

				notification.success({
					message: Strings.sidebar.segments,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.sidebar.segments,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (error: unknown) {
			console.error(error);
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async toggleSegments(value: any) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriSegments(`${value._id}/status`),
				data: {
					isActive: !value.isActive,
				},
			});

			if (response.ok) {
				await this.getData();

				notification.success({
					message: Strings.sidebar.segments,
					description: response?.data?.message || Strings.segments.updated,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}
		dispatch(setLoader(false));
	}

	render() {
		const { segments } = this.state;
		const { dispatch } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.segments}</title>
					<meta name="description" content="Set segments" />
				</Helmet>
				<Table
					title={{
						icon: "support-tickets",
						title: Strings.sidebar.segments,
					}}
					data={segments}
					columns={[
						{
							Header: Strings.fields.name,
							id: "name",
							accessor: (row: any) => translate(row.name),
						},
						{
							Header: Strings.segments.showOnHeader,
							id: "showOnHeader",
							Cell: this.renderShowOnHeaderSwitch,
							Filter: () => null,
						},
					]}
					isSinglePage
					fullPage
					paginated={false}
					filterable
					add={{
						tooltip: Strings.segments.addSegment,
						onClick: () => dispatch(push("/segments/new")),
					}}
					actions={{
						edit: (obj: any) => ({
							onClick: () => dispatch(push(`/segments/${obj._id}`)),
							location: `/segments/${obj._id}`,
						}),
						remove: (obj: any) => ({
							onClick: () => this.deleteSegment(obj._id),
						}),
						toggle: (obj: any) => ({
							value: obj.isActive,
							onChange: () => this.toggleSegments(obj),
						}),
					}}
					draggable
					onDrag={async (list: any, dragIndex: any, hoverIndex: any) => {
						this.setState({ segments: list, dragIndex, hoverIndex });
					}}
					onDragEnd={this.onDrag}
				/>
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state: any) => ({
	language: state.language,
});
export default connect(mapStateToProps)(Segments);
