import React, { Component } from "react";
import itemPlaceholder from "assets/images/placeholders/image.jpg";
import { formatPrice, translate } from "utils/utils";
import { Button } from "antd";
import strings from "utils/strings";

export default class OrderProduct extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			items: 1
		};
	}

	render() {
		const { items } = this.state;
		const {
			product: { images, name, stock, price },
			onAddClick
		} = this.props;
		const image = images?.find((image: any) => image.favorite) || images?.[0];
		const inStock = stock > 0;

		return (
			<div className="OrderProductContainer">
				<div className="OrderProductImage">
					<img src={image?.url || itemPlaceholder} alt="Product" />
					{!inStock && <div className="OrderProductOutOfStock">{strings.orders.outOfStock}</div>}
				</div>
				<div className="OrderProductInfo">
					<h3>{translate(name)}</h3>
				</div>
				{inStock && (
					<div className="OrderProductActions">
						<div className="OrderProductQuantity">
							<Button
								size="small"
								onClick={() =>
									this.setState((prevState: any) => ({ items: prevState.items === 1 ? 1 : prevState.items - 1 }))
								}
							>
								-
							</Button>
							<span>{items}</span>
							<Button size="small" onClick={() => this.setState((prevState: any) => ({ items: prevState.items + 1 }))}>
								+
							</Button>
							<strong>{formatPrice(price)}</strong>
						</div>
						<Button
							size="small"
							onClick={() => {
								if (typeof onAddClick === "function") onAddClick(items);
							}}
						>
							{strings.orders.addProduct}
						</Button>
					</div>
				)}
			</div>
		);
	}
}
