import { Col, Input, InputNumber, Row, notification } from "antd";
import { ContentWrapper } from "components";
import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import strings from "utils/strings";
import "./styles.scss";
import { delayedDispatch, setBreadcrumb, updateCrumb, setTitle, setLoader } from "store/actions";
import { Props, State } from "./types";
import { API, Endpoints } from "utils/api";

class SuccessLadder extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			hasUnsavedFields: false,
			minimumPoints: {
				pv: 150,
				cp: 30
			},
			specialExecutive: {
				qualification: {
					executives: 2,
					executivesMinimumPerBranch: 1
				},
				maintenance: {
					executives: 2,
					executivesMinimumPerBranch: 1
				}
			},
			bronze: {
				qualification: {
					specialExecutives: 4,
					specialExecutivesMinimumPerBranch: 1,
					executives: 8,
					executivesMinimumPerBranch: 3
				},
				maintenance: {
					specialExecutives: 4,
					specialExecutivesMinimumPerBranch: 1,
					executives: 8,
					executivesMinimumPerBranch: 3
				}
			},
			silver: {
				qualification: {
					specialExecutives: 8,
					specialExecutivesMinimumPerBranch: 2,
					executives: 12,
					executivesMinimumPerBranch: 3
				},
				maintenance: {
					specialExecutives: 8,
					specialExecutivesMinimumPerBranch: 2,
					executives: 12,
					executivesMinimumPerBranch: 3
				}
			},
			gold: {
				qualification: {
					specialExecutives: 12,
					specialExecutivesMinimumPerBranch: 3
				},
				maintenance: {
					specialExecutives: 12,
					specialExecutivesMinimumPerBranch: 3
				}
			},
			gold30: {
				qualification: {
					cycles: 30
				}
			},
			gold60: {
				qualification: {
					cycles: 60
				}
			},
			gold100: {
				qualification: {
					cycles: 100
				}
			},
			ruby: {
				qualification: {
					sponsorGoldBranches: 2,
					cycles: 200
				}
			},
			emerald: {
				qualification: {
					sponsorGoldBranches: 4,
					cycles: 500
				}
			},
			diamond: {
				qualification: {
					sponsorGoldBranches: 4,
					cycles: 500
				}
			},
			diamond2: {
				qualification: {
					sponsorDiamondBranches: 4,
					cycles: 1500
				}
			},
			diamond4: {
				qualification: {
					sponsorDiamondBranches: 4,
					minimumSgv: 2000000,
					maximumSgvPerBranch: 500000,
					minimumExecutives: 10000
				}
			},
			diamond6: {
				qualification: {
					sponsorDiamondBranches: 6,
					minimumSgv: 3000000,
					maximumSgvPerBranch: 500000,
					minimumExecutives: 15000
				}
			},
			diamond8: {
				qualification: {
					sponsorDiamondBranches: 8,
					minimumSgv: 4000000,
					maximumSgvPerBranch: 500000,
					minimumExecutives: 20000
				}
			},
			special: {
				qualification: {
					sponsorDiamondBranches: 10,
					minimumSgv: 5000000,
					maximumSgvPerBranch: 500000,
					minimumExecutives: 25000
				}
			}
		};
	}

	componentDidMount(): void {
		const { dispatch } = this.props;

		dispatch(setTitle(strings.sidebar.successLadder));

		this.breadCrumb();
		this.getData();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	breadCrumb = () => {
		delayedDispatch(
			setBreadcrumb(() => {
				const isDisabled = this.state.hasUnsavedFields && this.canSave();
				return {
					locations: [
						{
							icon: "preferences",
							text: strings.sidebar.settings,
							route: "/settings"
						},
						{
							icon: "tax-settings",
							text: strings.settings.successLadder
						}
					],
					actions: [
						{
							type: "button",
							text: strings.generic.save,
							disabled: !isDisabled,
							className: "BreadcrumbSaveButton",
							isSave: true,
							hasIcon: true,
							onClick: () => this.onSave()
						}
					]
				};
			})
		);
	};

	arePropertiesFilled = () => {
		const { bronze, silver, gold, gold30, gold60, gold100, ruby, emerald, diamond, diamond2, diamond4, diamond6, diamond8, special } =
			this.state;

		const objects: Record<string, any>[] = [
			bronze,
			silver,
			gold,
			gold30,
			gold60,
			gold100,
			ruby,
			emerald,
			diamond,
			diamond2,
			diamond4,
			diamond6,
			diamond8,
			special
		];

		for (let obj of objects) {
			if (obj.qualification) {
				for (let key in obj.qualification) {
					if (obj.qualification[key] === undefined || obj.qualification[key] === null || obj.qualification[key] === "") {
						return false;
					}
				}
			}

			if (obj.maintenance) {
				for (let key in obj.maintenance) {
					if (obj.maintenance[key] === undefined || obj.maintenance[key] === null || obj.maintenance[key] === "") {
						return false;
					}
				}
			}
		}

		return true;
	};

	canSave = () => {
		if (this.state.minimumPoints?.pv === null || this.state.minimumPoints?.cp === null || !this.arePropertiesFilled()) {
			return false;
		}

		return true;
	};

	getData = async () => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriSettings()
			});

			if (response.ok) {
				const successLadder = response.data.results.settings.successLadder;

				this.setState({ ...successLadder });
			} else {
				notification.error({
					message: strings.generic.error,
					description: strings.bonuses.bonusesNotificationMessageError,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		} finally {
			dispatch(setLoader(false));
		}
	};

	onSave = async () => {
		const { dispatch } = this.props;
		const {
			minimumPoints,
			bronze,
			silver,
			gold,
			gold30,
			gold60,
			gold100,
			ruby,
			emerald,
			diamond,
			diamond2,
			diamond4,
			diamond6,
			diamond8,
			special
		} = this.state;

		const payload = {
			minimumPoints,
			bronze,
			silver,
			gold,
			gold30,
			gold60,
			gold100,
			ruby,
			emerald,
			diamond,
			diamond2,
			diamond4,
			diamond6,
			diamond8,
			special
		};

		dispatch(setLoader(true));

		try {
			const response = await API.put({
				url: Endpoints.uriSettings(),
				data: {
					successLadder: payload
				}
			});

			if (response.ok) {
				this.setState({ hasUnsavedFields: false });

				notification.success({
					message: strings.generic.success,
					description: strings.successLadder.messageSuccess,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: strings.generic.error,
					description: strings.successLadder.messageError,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		} finally {
			dispatch(setLoader(false));
		}
	};

	renderMinimumPoints = () => {
		const { minimumPoints } = this.state;

		return (
			<Col xs={24}>
				<Row gutter={20}>
					<Col xs={24} md={12}>
						<ContentWrapper extraClass="ladder">
							<div className="ladder-title-wrapper">
								<span className="ladder-title">{strings.successLadder.minimumPoints}</span>
								<small>{strings.successLadder.minimumPointsDescription}</small>
							</div>
							<div className="ladder-description">
								<div className="line">
									<span>{strings.successLadder.havePvPoints}</span>
									<span className="ladder-input-block">
										<InputNumber
											value={minimumPoints?.pv}
											placeholder="4"
											min={0}
											onChange={(val: number) => {
												this.setState((prevState) => ({
													minimumPoints: {
														...prevState.minimumPoints,
														pv: val
													},
													hasUnsavedFields: true
												}));
											}}
										/>
									</span>
									<span>{strings.successLadder.pvPoints}</span>
									<span className="ladder-input-block">
										<InputNumber
											value={minimumPoints?.cp}
											placeholder="4"
											min={0}
											onChange={(val: number) => {
												this.setState((prevState) => ({
													minimumPoints: {
														...prevState.minimumPoints,
														cp: val
													},
													hasUnsavedFields: true
												}));
											}}
										/>
									</span>
									<span>{strings.successLadder.cpPoints}</span>
								</div>
							</div>
						</ContentWrapper>
					</Col>
				</Row>
			</Col>
		);
	};

	renderSpecialExecutive = () => {
		const { specialExecutive } = this.state;

		return (
			<Col xs={24} md={12}>
				<ContentWrapper extraClass="ladder">
					<div className="ladder-title">{strings.users.badges.specialExecutive}</div>
					<div className="ladder-description">
						<div className="ladder-entry-title">{strings.successLadder.qualification}</div>
						<div className="line">
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={specialExecutive.qualification?.executives}
									placeholder="8"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											bronze: {
												...prevState.bronze,
												qualification: {
													...prevState.bronze.qualification,
													executives: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							{strings.successLadder.activeExecutives}
							<span className="ladder-input-block">
								<InputNumber
									value={specialExecutive.qualification?.executivesMinimumPerBranch}
									placeholder="3"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											bronze: {
												...prevState.bronze,
												qualification: {
													...prevState.bronze.qualification,
													executivesMinimumPerBranch: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.eachBinaryLeg}</span>
						</div>
						<div className="ladder-entry-title --mt-20">{strings.successLadder.monthlyMinimum}</div>
						<div className="line">
							<span>{strings.successLadder.keepActive}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={specialExecutive.maintenance?.executives}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											bronze: {
												...prevState.bronze,
												maintenance: {
													...prevState.bronze.maintenance,
													executives: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.activeExecutives}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={specialExecutive.maintenance?.executivesMinimumPerBranch}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											bronze: {
												...prevState.bronze,
												maintenance: {
													...prevState.bronze.maintenance,
													executivesMinimumPerBranch: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.eachBinaryLeg}</span>
						</div>
					</div>
				</ContentWrapper>
			</Col>
		);
	};

	renderBronze = () => {
		const { bronze } = this.state;

		return (
			<Col xs={24} md={12}>
				<ContentWrapper extraClass="ladder">
					<div className="ladder-title">{strings.users.badges.bronze}</div>
					<div className="ladder-description">
						<div className="ladder-entry-title">{strings.successLadder.qualification}</div>
						<div className="line">
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={bronze.qualification?.specialExecutives}
									placeholder="4"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											bronze: {
												...prevState.bronze,
												qualification: {
													...prevState.bronze.qualification,
													specialExecutives: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.activeSpecialExecutives}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={bronze.qualification?.specialExecutivesMinimumPerBranch}
									placeholder="1"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											bronze: {
												...prevState.bronze,
												qualification: {
													...prevState.bronze.qualification,
													specialExecutivesMinimumPerBranch: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.eachBinaryLeg}</span>
						</div>
						<div className="ladder-or-separator">{strings.successLadder.or}</div>
						<div className="line">
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={bronze.qualification?.executives}
									placeholder="8"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											bronze: {
												...prevState.bronze,
												qualification: {
													...prevState.bronze.qualification,
													executives: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							{strings.successLadder.activeExecutives}
							<span className="ladder-input-block">
								<InputNumber
									value={bronze.qualification?.executivesMinimumPerBranch}
									placeholder="3"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											bronze: {
												...prevState.bronze,
												qualification: {
													...prevState.bronze.qualification,
													executivesMinimumPerBranch: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.eachBinaryLeg}</span>
						</div>
						<div className="ladder-entry-title --mt-20">{strings.successLadder.monthlyMinimum}</div>
						<div className="line">
							<span>{strings.successLadder.keepActive}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={bronze.maintenance?.specialExecutives}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											bronze: {
												...prevState.bronze,
												maintenance: {
													...prevState.bronze.maintenance,
													specialExecutives: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.activeSpecialExecutives}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={bronze.maintenance?.specialExecutivesMinimumPerBranch}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											bronze: {
												...prevState.bronze,
												maintenance: {
													...prevState.bronze.maintenance,
													specialExecutivesMinimumPerBranch: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.eachBinaryLeg}</span>
						</div>
						<div className="ladder-or-separator">{strings.successLadder.or}</div>
						<div className="line">
							<span>{strings.successLadder.keepActive}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={bronze.maintenance?.executives}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											bronze: {
												...prevState.bronze,
												maintenance: {
													...prevState.bronze.maintenance,
													executives: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.activeExecutives}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={bronze.maintenance?.executivesMinimumPerBranch}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											bronze: {
												...prevState.bronze,
												maintenance: {
													...prevState.bronze.maintenance,
													executivesMinimumPerBranch: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.eachBinaryLeg}</span>
						</div>
					</div>
				</ContentWrapper>
			</Col>
		);
	};

	renderSilver = () => {
		const { silver } = this.state;

		return (
			<Col xs={24} md={12}>
				<ContentWrapper extraClass="ladder">
					<div className="ladder-title">{strings.users.badges.silver}</div>
					<div className="ladder-description">
						<div className="ladder-entry-title">{strings.successLadder.qualification}</div>
						<div className="line">
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={silver.qualification?.specialExecutives}
									placeholder="6"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											silver: {
												...prevState.silver,
												qualification: {
													...prevState.silver.qualification,
													specialExecutives: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.activeSpecialExecutives}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={silver.qualification?.specialExecutivesMinimumPerBranch}
									placeholder="2"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											silver: {
												...prevState.silver,
												qualification: {
													...prevState.silver.qualification,
													specialExecutivesMinimumPerBranch: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.eachBinaryLeg}</span>
						</div>
						<div className="ladder-or-separator">{strings.successLadder.or}</div>
						<div className="line">
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={silver.qualification?.executives}
									placeholder="8"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											silver: {
												...prevState.silver,
												qualification: {
													...prevState.silver.qualification,
													executives: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.activeExecutives}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={silver.qualification?.executivesMinimumPerBranch}
									placeholder="3"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											silver: {
												...prevState.silver,
												qualification: {
													...prevState.silver.qualification,
													executivesMinimumPerBranch: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.eachBinaryLeg}</span>
						</div>
						<div className="ladder-entry-title --mt-20">{strings.successLadder.monthlyMinimum}</div>
						<div className="line">
							<span>{strings.successLadder.keepActive}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={silver.maintenance?.specialExecutives}
									placeholder="8"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											silver: {
												...prevState.silver,
												maintenance: {
													...prevState.silver.maintenance,
													specialExecutives: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.activeSpecialExecutives}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={silver.maintenance?.specialExecutivesMinimumPerBranch}
									placeholder="2"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											silver: {
												...prevState.silver,
												maintenance: {
													...prevState.silver.maintenance,
													specialExecutivesMinimumPerBranch: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.eachBinaryLeg}</span>
							<div className="ladder-or-separator">{strings.successLadder.or}</div>
							<span>{strings.successLadder.keepActive}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={silver.maintenance?.executives}
									placeholder="12"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											silver: {
												...prevState.silver,
												maintenance: {
													...prevState.silver.maintenance,
													executives: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.activeExecutives}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={silver.maintenance?.executivesMinimumPerBranch}
									placeholder="3"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											silver: {
												...prevState.silver,
												maintenance: {
													...prevState.silver.maintenance,
													executivesMinimumPerBranch: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.eachBinaryLeg}</span>
						</div>
					</div>
				</ContentWrapper>
			</Col>
		);
	};

	renderGold = () => {
		const { gold } = this.state;

		return (
			<Col xs={24} md={12}>
				<ContentWrapper extraClass="ladder">
					<div className="ladder-title">{strings.users.badges.gold}</div>
					<div className="ladder-description">
						<div className="ladder-entry-title">{strings.successLadder.qualification}</div>
						<div className="line">
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={gold.qualification?.specialExecutives}
									placeholder="8"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											gold: {
												...prevState.gold,
												qualification: {
													...prevState.gold.qualification,
													specialExecutives: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.activeSpecialExecutives}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={gold.qualification?.specialExecutivesMinimumPerBranch}
									placeholder="3"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											gold: {
												...prevState.gold,
												qualification: {
													...prevState.gold.qualification,
													specialExecutivesMinimumPerBranch: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.eachBinaryLeg}</span>
							<div className="ladder-entry-title --mt-20">{strings.successLadder.monthlyMinimum}</div>
							<span>{strings.successLadder.keepActive}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={gold.maintenance?.specialExecutives}
									placeholder="8"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											gold: {
												...prevState.gold,
												maintenance: {
													...prevState.gold.maintenance,
													specialExecutives: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.activeSpecialExecutives}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={gold.maintenance?.specialExecutivesMinimumPerBranch}
									placeholder="3"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											gold: {
												...prevState.gold,
												maintenance: {
													...prevState.gold.maintenance,
													specialExecutivesMinimumPerBranch: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.eachBinaryLeg}</span>
						</div>
					</div>
				</ContentWrapper>
			</Col>
		);
	};

	renderGold30 = () => {
		const { gold30 } = this.state;

		return (
			<Col xs={24} md={12}>
				<ContentWrapper extraClass="ladder">
					<div className="ladder-title">{strings.users.badges.gold30}</div>
					<div className="ladder-description">
						<div className="ladder-entry-title">{strings.successLadder.qualificationAndMonthlyMinimum}</div>
						<div className="line">
							<span>{strings.successLadder.accumulate}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={gold30.qualification?.cycles}
									placeholder="30"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											gold30: {
												...prevState.gold30,
												qualification: {
													...prevState.gold30.qualification,
													cycles: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.ciclesBonusBinary}</span>
						</div>
					</div>
				</ContentWrapper>
			</Col>
		);
	};

	renderGold60 = () => {
		const { gold60 } = this.state;

		return (
			<Col xs={24} md={12}>
				<ContentWrapper extraClass="ladder">
					<div className="ladder-title">{strings.users.badges.gold60}</div>
					<div className="ladder-description">
						<div className="ladder-entry-title">{strings.successLadder.qualificationAndMonthlyMinimum}</div>
						<div className="line">
							<span>{strings.successLadder.accumulate}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={gold60.qualification?.cycles}
									placeholder="60"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											gold60: {
												...prevState.gold60,
												qualification: {
													...prevState.gold60.qualification,
													cycles: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.ciclesBonusBinary}</span>
						</div>
					</div>
				</ContentWrapper>
			</Col>
		);
	};

	renderGold100 = () => {
		const { gold100 } = this.state;

		return (
			<Col xs={24} md={12}>
				<ContentWrapper extraClass="ladder">
					<div className="ladder-title">{strings.users.badges.gold100}</div>
					<div className="ladder-description">
						<div className="ladder-entry-title">{strings.successLadder.qualificationAndMonthlyMinimum}</div>
						<div className="line">
							<span>{strings.successLadder.accumulate}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={gold100.qualification?.cycles}
									placeholder="100"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											gold100: {
												...prevState.gold100,
												qualification: {
													...prevState.gold100.qualification,
													cycles: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.ciclesBonusBinary}</span>
						</div>
					</div>
				</ContentWrapper>
			</Col>
		);
	};

	renderRuby = () => {
		const { ruby } = this.state;

		return (
			<Col xs={24} md={12}>
				<ContentWrapper extraClass="ladder">
					<div className="ladder-title">{strings.users.badges.ruby}</div>
					<div className="ladder-description">
						<div className="ladder-entry-title">{strings.successLadder.qualificationAndMonthlyMinimum}</div>
						<div className="line">
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={ruby.qualification?.sponsorGoldBranches}
									placeholder="2"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											ruby: {
												...prevState.ruby,
												qualification: {
													...prevState.ruby.qualification,
													sponsorGoldBranches: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.sponsorshipGold}</span>
							<div className="separator"></div>
							<span>{strings.successLadder.accumulate}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={ruby.qualification?.cycles}
									placeholder="200"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											ruby: {
												...prevState.ruby,
												qualification: {
													...prevState.ruby.qualification,
													cycles: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.ciclesBonusBinary}</span>
						</div>
					</div>
				</ContentWrapper>
			</Col>
		);
	};

	renderEmerald = () => {
		const { emerald } = this.state;

		return (
			<Col xs={24} md={12}>
				<ContentWrapper extraClass="ladder">
					<div className="ladder-title">{strings.users.badges.emerald}</div>
					<div className="ladder-description">
						<div className="ladder-entry-title">{strings.successLadder.qualificationAndMonthlyMinimum}</div>
						<div className="line">
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={emerald.qualification?.sponsorGoldBranches}
									placeholder="4"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											emerald: {
												...prevState.emerald,
												qualification: {
													...prevState.emerald.qualification,
													sponsorGoldBranches: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.sponsorshipGold}</span>
							<div className="separator"></div>
							<span>{strings.successLadder.accumulate}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={emerald.qualification?.cycles}
									min={0}
									placeholder="500"
									onChange={(val: number) => {
										this.setState((prevState) => ({
											emerald: {
												...prevState.emerald,
												qualification: {
													...prevState.emerald.qualification,
													cycles: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.ciclesBonusBinary}</span>
						</div>
					</div>
				</ContentWrapper>
			</Col>
		);
	};

	renderDiamond = () => {
		const { diamond } = this.state;

		return (
			<Col xs={24} md={12}>
				<ContentWrapper extraClass="ladder">
					<div className="ladder-title">{strings.users.badges.diamond}</div>
					<div className="ladder-description">
						<div className="ladder-entry-title">{strings.successLadder.qualificationAndMonthlyMinimum}</div>
						<div className="line">
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={diamond.qualification?.sponsorGoldBranches}
									placeholder="6"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											diamond: {
												...prevState.diamond,
												qualification: {
													...prevState.diamond.qualification,
													sponsorGoldBranches: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.sponsorshipGold}</span>
							<div className="separator"></div>
							<span>{strings.successLadder.accumulate}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={diamond.qualification?.cycles}
									placeholder="1000"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											diamond: {
												...prevState.diamond,
												qualification: {
													...prevState.diamond.qualification,
													cycles: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.ciclesBonusBinary}</span>
						</div>
					</div>
				</ContentWrapper>
			</Col>
		);
	};

	renderDiamond2 = () => {
		const { diamond2 } = this.state;

		return (
			<Col xs={24} md={12}>
				<ContentWrapper extraClass="ladder">
					<div className="ladder-title">{strings.users.badges.diamond2}</div>
					<div className="ladder-description">
						<div className="ladder-entry-title">{strings.successLadder.qualificationAndMonthlyMinimum}</div>
						<div className="line">
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={diamond2.qualification?.sponsorDiamondBranches}
									placeholder="2"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											diamond2: {
												...prevState.diamond2,
												qualification: {
													...prevState.diamond2.qualification,
													sponsorDiamondBranches: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.sponsorshipDiamond}</span>
							<div className="separator"></div>
							<span>{strings.successLadder.accumulate}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={diamond2.qualification?.cycles}
									placeholder="1500"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											diamond2: {
												...prevState.diamond2,
												qualification: {
													...prevState.diamond2.qualification,
													cycles: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.ciclesBonusBinary}</span>
						</div>
					</div>
				</ContentWrapper>
			</Col>
		);
	};

	renderDiamond4 = () => {
		const { diamond4 } = this.state;

		return (
			<Col xs={24} md={12}>
				<ContentWrapper extraClass="ladder">
					<div className="ladder-title">{strings.users.badges.diamond4}</div>
					<div className="ladder-description">
						<div className="ladder-entry-title">{strings.successLadder.qualificationAndMonthlyMinimum}</div>
						<div className="line">
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={diamond4.qualification?.sponsorDiamondBranches}
									placeholder="4"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											diamond4: {
												...prevState.diamond4,
												qualification: {
													...prevState.diamond4.qualification,
													sponsorDiamondBranches: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.sponsorshipDiamond}</span>
							<div className="separator"></div>
							<span>{strings.successLadder.accumulate}</span>
							<span className="ladder-input-block --medium">
								<InputNumber
									value={diamond4.qualification?.minimumSgv}
									placeholder="2000000"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											diamond4: {
												...prevState.diamond4,
												qualification: {
													...prevState.diamond4.qualification,
													minimumSgv: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.sgv}</span>
							<span className="ladder-input-block --medium">
								<InputNumber
									value={diamond4.qualification?.maximumSgvPerBranch}
									placeholder="50000"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											diamond4: {
												...prevState.diamond4,
												qualification: {
													...prevState.diamond4.qualification,
													maximumSgvPerBranch: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.sgv2}</span>
							<div className="separator"></div>
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={diamond4.qualification?.minimumExecutives}
									placeholder="10000"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											diamond4: {
												...prevState.diamond4,
												qualification: {
													...prevState.diamond4.qualification,
													minimumExecutives: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.executivesActiveLine}</span>
						</div>
					</div>
				</ContentWrapper>
			</Col>
		);
	};

	renderDiamond6 = () => {
		const { diamond6 } = this.state;

		return (
			<Col xs={24} md={12}>
				<ContentWrapper extraClass="ladder">
					<div className="ladder-title">{strings.users.badges.diamond6}</div>
					<div className="ladder-description">
						<div className="ladder-entry-title">{strings.successLadder.qualificationAndMonthlyMinimum}</div>
						<div className="line">
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={diamond6.qualification?.sponsorDiamondBranches}
									placeholder="6"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											diamond6: {
												...prevState.diamond6,
												qualification: {
													...prevState.diamond6.qualification,
													sponsorDiamondBranches: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.sponsorshipDiamond}</span>
							<div className="separator"></div>
							<span>{strings.successLadder.accumulate}</span>
							<span className="ladder-input-block --medium">
								<InputNumber
									value={diamond6.qualification?.minimumSgv}
									placeholder="3000000"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											diamond6: {
												...prevState.diamond6,
												qualification: {
													...prevState.diamond6.qualification,
													minimumSgv: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.sgv}</span>
							<span className="ladder-input-block --medium">
								<InputNumber
									value={diamond6.qualification?.maximumSgvPerBranch}
									placeholder="500000"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											diamond6: {
												...prevState.diamond6,
												qualification: {
													...prevState.diamond6.qualification,
													maximumSgvPerBranch: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.sgv2}</span>
							<div className="separator"></div>
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={diamond6.qualification?.minimumExecutives}
									placeholder="15000"
									onChange={(val: number) => {
										this.setState((prevState) => ({
											diamond6: {
												...prevState.diamond6,
												qualification: {
													...prevState.diamond6.qualification,
													minimumExecutives: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.executivesActiveLine}</span>
						</div>
					</div>
				</ContentWrapper>
			</Col>
		);
	};

	renderDiamond8 = () => {
		const { diamond8 } = this.state;

		return (
			<Col xs={24} md={12}>
				<ContentWrapper extraClass="ladder">
					<div className="ladder-title">{strings.users.badges.diamond8}</div>
					<div className="ladder-description">
						<div className="ladder-entry-title">{strings.successLadder.qualificationAndMonthlyMinimum}</div>
						<div className="line">
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={diamond8.qualification?.sponsorDiamondBranches}
									placeholder="8"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											diamond8: {
												...prevState.diamond8,
												qualification: {
													...prevState.diamond8.qualification,
													sponsorDiamondBranches: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.sponsorshipDiamond}</span>
							<div className="separator"></div>
							<span>{strings.successLadder.accumulate}</span>
							<span className="ladder-input-block --medium">
								<InputNumber
									value={diamond8.qualification?.minimumSgv}
									placeholder="4000000"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											diamond8: {
												...prevState.diamond8,
												qualification: {
													...prevState.diamond8.qualification,
													minimumSgv: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.sgv}</span>
							<span className="ladder-input-block --medium">
								<InputNumber
									value={diamond8.qualification?.maximumSgvPerBranch}
									placeholder="500000"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											diamond8: {
												...prevState.diamond8,
												qualification: {
													...prevState.diamond8.qualification,
													maximumSgvPerBranch: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.sgv2}</span>
							<div className="separator"></div>
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={diamond8.qualification?.minimumExecutives}
									placeholder="20000"
									onChange={(val: number) => {
										this.setState((prevState) => ({
											diamond8: {
												...prevState.diamond8,
												qualification: {
													...prevState.diamond8.qualification,
													minimumExecutives: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.executivesActiveLine}</span>
						</div>
					</div>
				</ContentWrapper>
			</Col>
		);
	};

	renderSpecialDiamond = () => {
		const { special } = this.state;

		return (
			<Col xs={24} md={12}>
				<ContentWrapper extraClass="ladder">
					<div className="ladder-title">{strings.users.badges.specialDiamond}</div>
					<div className="ladder-description">
						<div className="ladder-entry-title">{strings.successLadder.qualificationAndMonthlyMinimum}</div>
						<div className="line">
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={special.qualification?.sponsorDiamondBranches}
									placeholder="10"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											special: {
												...prevState.special,
												qualification: {
													...prevState.special.qualification,
													sponsorDiamondBranches: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.sponsorshipDiamond}</span>
							<div className="separator"></div>
							<span>{strings.successLadder.accumulate}</span>
							<span className="ladder-input-block --medium">
								<InputNumber
									value={special.qualification?.minimumSgv}
									placeholder="5000000"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											special: {
												...prevState.special,
												qualification: {
													...prevState.special.qualification,
													minimumSgv: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.sgv}</span>
							<span className="ladder-input-block --medium">
								<InputNumber
									value={special.qualification?.maximumSgvPerBranch}
									placeholder="500000"
									min={0}
									onChange={(val: number) => {
										this.setState((prevState) => ({
											special: {
												...prevState.special,
												qualification: {
													...prevState.special.qualification,
													maximumSgvPerBranch: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.sgv2}</span>
							<div className="separator"></div>
							<span>{strings.successLadder.have}</span>
							<span className="ladder-input-block">
								<InputNumber
									value={special.qualification?.minimumExecutives}
									placeholder="30000"
									onChange={(val: number) => {
										this.setState((prevState) => ({
											special: {
												...prevState.special,
												qualification: {
													...prevState.special.qualification,
													minimumExecutives: val
												}
											},
											hasUnsavedFields: true
										}));
									}}
								/>
							</span>
							<span>{strings.successLadder.executivesActiveLine}</span>
						</div>
					</div>
				</ContentWrapper>
			</Col>
		);
	};

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{strings.sidebar.bonuses}</title>
					<meta name="description" content="Description of Success Ladder" />
				</Helmet>
				<Row gutter={[20, 20]} className="ladder-wrapper">
					{this.renderMinimumPoints()}
					{this.renderSpecialExecutive()}
					{this.renderBronze()}
					{this.renderSilver()}
					{this.renderGold()}
					{this.renderGold30()}
					{this.renderGold60()}
					{this.renderGold100()}
					{this.renderRuby()}
					{this.renderEmerald()}
					{this.renderDiamond()}
					{this.renderDiamond2()}
					{this.renderDiamond4()}
					{this.renderDiamond6()}
					{this.renderDiamond8()}
					{this.renderSpecialDiamond()}
				</Row>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(SuccessLadder);
