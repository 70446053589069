import { FileAddOutlined, FileUnknownOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { ContentWrapper, Icon, ManualItem } from "components";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import { Manual, Props, State } from "./types";
import Strings from "utils/strings";
import "./styles.scss";
import { push } from "connected-react-router";

class Manuals extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			manuals: []
		};
	}

	componentDidMount(): void {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		this.getManuals();
		this.breadcrumb();
	}

	componentDidUpdate(): void {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getManuals() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriManuals(),
				params: { type: "manual" }
			});

			if (response?.ok) {
				const manuals: Manual[] = response.data.results.manuals || [];
				this.setState({ manuals });
			}
		} catch (err) {
			console.log(err);
		}

		dispatch(setLoader(false));
	}

	async deleteManual(id: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriManuals(id)
			});

			if (response.ok) {
				await this.getManuals();

				notification.success({
					message: Strings.settings.manuals,
					description: response?.data?.message || Strings.settings.fileDeleted,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.settings.manuals,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	breadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.settings,
							route: "/settings",
							icon: "preferences"
						},
						{
							text: Strings.settings.manuals,
							icon: <FileUnknownOutlined translate={null} />,
							iconType: "antd"
						}
					]
				};
			})
		);
	}

	renderList() {
		const { manuals = [] } = this.state;
		const { dispatch } = this.props;

		return (
			<ContentWrapper>
				<div className="ScreenHeader --multi">
					<div className="ScreenHeaderLeft">
						<Icon name="duplicate" />
						<h2>{Strings.settings.manualsFullTitle}</h2>
					</div>
					<div className="ScreenHeaderRight">
						<button className="ManualAddButton" onClick={() => dispatch(push("/settings/manuals/new"))}>
							<FileAddOutlined translate={null} />
							<p>{Strings.settings.addFile}</p>
						</button>
					</div>
				</div>
				<div className="ManualGrid">
					{manuals.map((manual) => {
						return <ManualItem {...manual} key={manual._id} onDelete={() => this.deleteManual(manual._id!)} />;
					})}
				</div>
				{manuals.length === 0 && (
					<div className="ManualHeader">
						<h3>{Strings.settings.noManuals}</h3>
					</div>
				)}
			</ContentWrapper>
		);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.manuals}</title>
					<meta name="description" content={Strings.settings.manualsSubTitle} />
				</Helmet>
				{this.renderList()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(Manuals);
