/*
 *
 * Users
 *
 */

import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Props, State } from "./types";
import { Helmet } from "react-helmet";
import { setBreadcrumb, setLoader, setTitle } from "store/actions";
import { Icon, Table } from "components";
import { Modal, Tooltip, notification } from "antd";

import Strings from "utils/strings";
import { API, Endpoints } from "utils/api";
import "./styles.scss";
import { distributorSubDomains, localType } from "screens/App/routes";

export class Users extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			users: [],
			page: 0,
			pageSize: 100,
			total: 0,
			columnSearch: null,
			globalSearch: "",
			openConfirmResendInviteModal: false,
			tempUser: null
		};
	}

	async componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));
		dispatch(setBreadcrumb(null));

		this.getData();
	}

	async getData(pause?: boolean) {
		const { page, pageSize, columnSearch, globalSearch } = this.state;
		const { dispatch, countries = [] } = this.props;

		setTimeout(
			async () => {
				if (columnSearch !== this.state.columnSearch || globalSearch !== this.state.globalSearch) return;
				dispatch(setLoader(true));

				const body = {
					filters: columnSearch
						? Object.keys(columnSearch).map((elem) =>
								columnSearch[elem] !== ""
									? {
											field: elem,
											query: columnSearch[elem]
									  }
									: {}
						  )
						: [],
					page: page,
					perPage: pageSize,
					search: globalSearch
				};

				try {
					const response = await API.post({
						url: Endpoints.uriUsers("search"),
						data: body
					});

					if (response.ok) {
						const { users = [], total = 0 } = response.data.results;

						for (const user of users) {
							if (!user.associatedPartner) continue;

							const countryCallingCode = user.associatedPartner?.phone?.substring(0, 4).replace("+", "");
							const country = countries.find((elem: any) => elem.callingCodes.includes(countryCallingCode))?.alpha2Code;

							if (country) {
								user.associatedPartner.country = country.toLowerCase();
							}
						}

						this.setState({ users, total });
					} else {
						notification.error({
							message: Strings.sidebar.users,
							description: response?.data?.message || Strings.serverErrors.wentWrong,
							placement: "bottomRight",
							duration: 5
						});
					}
				} catch (err) {
					notification.error({
						message: Strings.serverErrors.title,
						description: err as string,
						placement: "bottomRight",
						duration: 5
					});
				}

				dispatch(setLoader(false));
			},
			pause ? 1000 : 0
		);
	}

	async deleteUser(id: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriUsers(id)
			});

			if (response.ok) {
				this.getData();
				notification.success({
					message: Strings.sidebar.users,
					description: response?.data?.message || Strings.users.deleted,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.sidebar.users,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async toggleUser(id: string, active: boolean) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriUsers(`${id}/status`),
				data: {
					active: !active
				}
			});

			if (response.ok) {
				this.getData();
				notification.success({
					message: Strings.sidebar.users,
					description: response?.data?.message || Strings.users.updated,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.sidebar.users,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async resendEmail(user: any) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriUsers(`${user._id}/resend-invite`)
			});

			if (response?.ok) {
				this.setState({ openConfirmResendInviteModal: false, tempUser: null });
				notification.success({
					message: Strings.users.title,
					description: response.data.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	get pagination() {
		return {
			total: this.state.total,
			pageIndex: this.state.page,
			pageSize: this.state.pageSize,
			setPage: (page: number, size: number) => {
				this.setState({ page, pageSize: size }, () => this.getData());
			}
		};
	}

	get filtration() {
		const { globalSearch, columnSearch } = this.state;
		return {
			showGlobalSearch: true,
			showColumnSearch: true,
			defaultValues: { globalSearch, columnSearch },
			onGlobalSearch: (globalSearch: string) => {
				this.setState({ globalSearch, page: 0 }, () => this.getData());
			},
			onColumnSearch: (columnSearch: string) => {
				this.setState({ columnSearch, page: 0 }, () => this.getData());
			}
		};
	}

	getStatus(status: any) {
		if (status) {
			return Strings.staff.confirmed;
		}
		return Strings.staff.pending;
	}

	renderStatus = (elem: any) => {
		if (elem.value === "true")
			return (
				<div className="TableTag --tag-success">
					<span>{this.getStatus(true)}</span>
				</div>
			);

		return (
			<Tooltip title={Strings.authentication.resendInviteEmail}>
				<div
					className="TableTag --tag-warning --tag-clickable"
					onClick={() => this.setState({ openConfirmResendInviteModal: true, tempUser: elem.cell.row.original })}
				>
					<Icon name="refresh" className="resend" />
					<span>{this.getStatus(false)}</span>
				</div>
			</Tooltip>
		);
	};

	renderUsers() {
		const { users = [] } = this.state;
		const { dispatch, partners } = this.props;

		let actions, add;
		if (!partners) {
			actions = {
				edit: (obj: any) => ({
					onClick: () => dispatch(push(`/clients/${obj._id}`))
				}),
				toggle: (obj: any) => ({
					value: obj.active,
					onChange: () => this.toggleUser(obj._id, obj.active)
				})
			};

			add = {
				tooltip: Strings.users.addUser,
				onClick: () => dispatch(push("/clients/new"))
			};
		} else {
			actions = {
				view: (obj: any) => ({
					onClick: () => dispatch(push(`/partner-clients/${obj._id}`))
				})
			};

			add = {
				tooltip: Strings.users.addUser,
				onClick: () => dispatch(push("/partner-clients/new"))
			};
		}

		return (
			<Table
				title={{
					icon: "user",
					title: Strings.users.title
				}}
				data={users}
				columns={[
					{
						Header: Strings.fields.photo,
						id: "photo",
						accessor: (row: any) => row.photo,
						type: "image",
						maxWidth: 65
					},
					{
						Header: Strings.fields.name,
						id: "name",
						accessor: (row: any) => row.name
					},
					{
						Header: Strings.fields.email,
						id: "email",
						accessor: (row: any) => row.email,
						Cell: (row: any) =>
							row.cell.row.original?.email ? (
								<a href={`mailto:${row.cell.row.original?.email}`}>{row.cell.row.original?.email}</a>
							) : (
								"-"
							)
					},
					{
						Header: Strings.fields.phone,
						id: "phone",
						accessor: (row: any) => row.phone,
						Cell: (row: any) =>
							row.cell.row.original?.phone ? (
								<a href={`tel:${row.cell.row.original?.phone}`}>{row.cell.row.original?.phone}</a>
							) : (
								"-"
							)
					},
					{
						Header: Strings.users.associatedPartner,
						id: "associatedPartner",
						Cell: (row: any) =>
							row.cell.row.original?.associatedPartner?.name ? (
								<div className="react-tel-input">
									{row.cell.row.original?.associatedPartner?.phone ? (
										<div className="selected-flag">
											<span className={`flag ${row.cell.row.original?.associatedPartner?.country}`} />
											<span className="partner-name partner-has-flag">
												{row.cell.row.original?.associatedPartner?.name}
												{row.cell.row.original?.associatedPartner?.userCode != null
													? ` (#${row.cell.row.original?.associatedPartner?.userCode})`
													: ""}
											</span>
										</div>
									) : (
										<span className="partner-name">
											{row.cell.row.original?.associatedPartner?.name}
											{row.cell.row.original?.associatedPartner?.userCode != null
												? ` (#${row.cell.row.original?.associatedPartner?.userCode})`
												: ""}
										</span>
									)}
								</div>
							) : (
								"-"
							)
					}
				]}
				filterable
				fullPage
				isSinglePage
				paginationApi={this.pagination}
				filtrationApi={this.filtration}
				add={add}
				actions={actions}
			/>
		);
	}

	renderConfirmResendInvite = () => {
		const { openConfirmResendInviteModal, tempUser } = this.state;

		return (
			<Modal
				className="resendInvite"
				style={{ textAlign: "center" }}
				visible={openConfirmResendInviteModal}
				cancelText={Strings.generic.close}
				okText={Strings.authentication.resendInviteEmail}
				onOk={() => this.resendEmail(tempUser)}
				onCancel={() => this.setState({ openConfirmResendInviteModal: false })}
				title={null}
				closable={false}
				bodyStyle={{ minHeight: 200 }}
			>
				<Icon name="paper-plane" style={{ fontSize: "50px" }} />
				<div className="title">
					{openConfirmResendInviteModal
						? Strings.formatString(Strings.authentication.confirmResendInviteEmail, tempUser?.name)
						: Strings.authentication.resendInviteEmail}
				</div>
			</Modal>
		);
	};

	render() {
		return (
			<div className="ScreenUsers">
				<Helmet>
					<title>{Strings.sidebar.users}</title>
					<meta name="description" content="Description of Users" />
				</Helmet>
				{this.renderUsers()}
				{this.renderConfirmResendInvite()}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	countries: state.countries
});

export default connect(mapStateToProps)(Users);
