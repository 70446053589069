import React from "react";
import { Col, Row, Input, Drawer, notification, Switch, Select, Button } from "antd";
import { ContentWrapper, Icon, PhoneInput } from "components";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import strings from "utils/strings";
import { API, Endpoints } from "utils/api";

import placeholder from "assets/images/placeholders/user.jpg";
import { formatPrice, translate } from "utils/utils";
import OrderProduct from "./product";
import { push } from "connected-react-router";

const { Search } = Input;

class OrderNew extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			paymentStatus: "pending_payment",
			defaultCountries: [],
			changingAddress: false,
			products: [],
			tempProducts: [],
			showProductsDrawer: false,
			drawerSelectedImage: null
		};
	}

	async componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		await this.getData();
		this.breadcrumb();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;

		dispatch(updateCrumb());
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const [countriesResponse] = await Promise.all([API.get({ url: Endpoints.uriCountries("?states=true") })]);

			if (countriesResponse?.ok) {
				const { countries = [] } = countriesResponse.data.results;
				const defaultStates: any[] = [];

				countries.forEach((country: any) => (defaultStates[country._id] = country?.states || []));
				this.setState({ defaultCountries: countries, defaultStates });
			} else {
				notification.error({
					message: strings.sidebar.orders,
					description: countriesResponse.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.sidebar.orders,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async submitOrder() {
		const {
			products,
			user,
			selectedPaymentMethod,
			selectedShippingMethod,
			selectedShippingAddress,
			selectedPaymentAddress,
			paymentStatus,
			shippingMethods = [],
			total
		} = this.state;
		const { addresses = [] } = user || {};
		const { dispatch, partners } = this.props;

		if (!this.validOrder()) return;

		dispatch(setLoader(true));

		let method, provider;
		for (const m of shippingMethods) {
			for (const mm of m.methods) {
				if (mm._id === selectedShippingMethod) {
					provider = m;
					method = mm;
					break;
				}
			}
		}

		const body = {
			products: products?.map((prod: any) => ({ ...prod, product: prod._id })) || [],
			shipping: {
				address: addresses?.find((a: any) => a._id === selectedShippingAddress),
				method,
				provider
			},
			payment: {
				address: addresses?.find((a: any) => a._id === selectedPaymentAddress),
				paymentType: selectedPaymentMethod
			},
			paymentStatus,
			user,
			total
		} as any;

		try {
			const response = await API.post({
				url: Endpoints.uriOrders(),
				data: body
			});

			if (response?.ok) {
				const { order } = response.data.results || {};

				if (order?._id) {
					if (partners) {
						dispatch(push(`/partner-orders/${order._id}`));
					} else {
						dispatch(push(`/orders/${order._id}`));
					}

					notification.success({
						message: strings.sidebar.orders,
						description: response?.data?.message,
						placement: "bottomRight",
						duration: 5
					});
				}
			} else {
				notification.error({
					message: strings.sidebar.orders,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.sidebar.orders,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async getUsers(openDrawer = false) {
		const { userSearchText, showUsersDrawer } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.post({
				url: Endpoints.uriUsers("search"),
				data: {
					search: userSearchText,
					filters: [],
					perPage: 20,
					page: 0,
					showAll: true
				}
			});

			if (response?.ok) {
				const { users = [] } = response.data.results;
				if (users.length === 1 && !showUsersDrawer) {
					this.setState({ tempUser: JSON.parse(JSON.stringify(users[0])) }, () => this.getUser());
					return;
				}

				this.setState({
					tempUsers: users,
					showUsersDrawer: openDrawer
				});
			} else {
				notification.error({
					message: strings.sidebar.orders,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.sidebar.orders,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async getUser() {
		const { tempUser, defaultCountries } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriUsers(tempUser._id)
			});

			if (response?.ok) {
				const { user = {} } = response.data.results;

				for (const address of user.addresses) {
					const tempCountry = defaultCountries.find((c: any) => c._id === address.country);
					if (tempCountry) {
						address.country = tempCountry;
						address.state = tempCountry.states?.find((s: any) => s._id === address.state);
					}

					if (address.isDefault) {
						this.setState({
							selectedPaymentAddress: address._id,
							selectedShippingAddress: address._id
						});
					} else {
						this.setState({
							selectedPaymentAddress: null,
							selectedShippingAddress: null
						});
					}
				}

				this.setState({
					user,
					showUsersDrawer: false,
					tempUsers: [],
					userSearchText: "",
					selectedPaymentMethod: "",
					selectedShippingMethod: null
				});
			} else {
				notification.error({
					message: strings.sidebar.orders,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.sidebar.orders,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async getProducts() {
		const { productSearchText } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.post({
				url: Endpoints.uriProducts("search"),
				data: {
					search: productSearchText,
					filters: [],
					perPage: 20,
					page: 0
				}
			});

			if (response?.ok) {
				const { products = [] } = response.data.results;
				this.setState({ defaultProducts: products });
			} else {
				notification.error({
					message: strings.sidebar.orders,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.sidebar.orders,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async saveAddress() {
		const { tempAddress, user, defaultCountries } = this.state;
		const { dispatch } = this.props;

		if (!tempAddress || !this.validAddress()) return;

		dispatch(setLoader(true));

		try {
			const body = { ...tempAddress };
			delete body._id;

			const request = tempAddress?._id ? API.put : API.post;
			const response = await request({
				url: Endpoints.uriUsers(`${user?._id}/addresses/${tempAddress?._id || ""}`),
				data: body
			});

			if (response.ok) {
				const {
					user: { addresses }
				} = response.data.results || {};

				for (const address of addresses) {
					const tempCountry = defaultCountries.find((c: any) => c._id === address.country);
					if (tempCountry) {
						address.country = tempCountry;
						address.state = tempCountry.states?.find((s: any) => s._id === address.state);
					}
				}

				this.setState((prevState: any) => ({
					user: {
						...prevState.user,
						addresses
					},
					tempAddress: null,
					changingAddress: false
				}));

				notification.success({
					message: strings.users.addresses,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: strings.users.addresses,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async calculateTotal() {
		const { products, user, selectedShippingAddress, shippingMethods = [], selectedShippingMethod } = this.state;
		const { addresses = [] } = user || {};
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		let method;
		for (const m of shippingMethods) {
			for (const mm of m.methods) {
				if (mm._id === selectedShippingMethod) {
					method = mm;
					break;
				}
			}
		}

		const body = {
			products: products?.map((prod: any) => ({ ...prod, product: prod._id })) || [],
			isPickup: false,
			shipping: {
				address: addresses?.find((a: any) => a._id === selectedShippingAddress),
				method
			}
		} as any;

		try {
			const response = await API.post({
				url: Endpoints.uriOrders("calculate"),
				data: body
			});

			if (response?.ok) {
				const {
					products: newProducts = [],
					total = 0,
					shipping: shippingMethods,
					totalShipping = 0,
					totalProducts = 0,
					points = 0
				} = response.data.results || {};

				for (const product of newProducts) {
					product._id = product.product;
				}

				this.setState({
					products: JSON.parse(JSON.stringify(newProducts)),
					total,
					totalShipping,
					totalProducts,
					points,
					shippingMethods
				});
			} else {
				notification.error({
					message: strings.sidebar.orders,
					description: response.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.sidebar.orders,
				description: (err as string) || strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	validAddress() {
		const { tempAddress } = this.state;

		if (!tempAddress?.address) {
			notification.warn({
				message: strings.users.addresses,
				description: strings.users.addressRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!tempAddress?.city) {
			notification.warn({
				message: strings.users.addresses,
				description: strings.users.cityRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!tempAddress?.country) {
			notification.warn({
				message: strings.users.addresses,
				description: strings.users.countryRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!tempAddress?.state) {
			notification.warn({
				message: strings.users.addresses,
				description: strings.users.stateRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!tempAddress?.postalCode) {
			notification.warn({
				message: strings.users.addresses,
				description: strings.users.zipRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		return true;
	}

	validOrder() {
		const { user, products, selectedPaymentMethod, selectedPaymentAddress, selectedShippingAddress, selectedShippingMethod } =
			this.state;

		if (
			!user ||
			products?.length === 0 ||
			!selectedPaymentMethod ||
			!selectedPaymentAddress ||
			!selectedShippingAddress ||
			!selectedShippingMethod
		) {
			notification.warn({
				message: strings.sidebar.orders,
				description: strings.orders.invalidOrder,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		return true;
	}

	breadcrumb() {
		const { dispatch } = this.props;

		dispatch(setBreadcrumb(null));
		delayedDispatch(
			setBreadcrumb(() => {
				const { paymentStatus } = this.state;
				const { partners } = this.props;

				const defaultPaymentStatus = [
					{
						label: strings.orders.paymentStatuses.pending_payment,
						value: "pending_payment"
					},
					{
						label: strings.orders.paymentStatuses.paid,
						value: "paid"
					}
				];

				const canCreate = this.canCreateOrder();

				return {
					locations: [
						{
							text: strings.sidebar.orders,
							route: partners ? "/partner-orders" : "/orders",
							icon: "box"
						},
						{
							text: strings.orders.newOrder,
							icon: "box"
						}
					],
					actions: [
						{
							type: "select",
							text: strings.orders.paymentStatus,
							value: paymentStatus,
							onChange: (value: string) => {
								this.setState({ paymentStatus: value });
							},
							options: defaultPaymentStatus.map((s) => ({
								value: s.value,
								text: s.label
							})),
							minWidth: 150
						},
						{
							type: "select",
							text: strings.orders.shippingStatus,
							value: strings.orders.shippingStatuses.pending_processing,
							options: [],
							minWidth: 150,
							disabled: true,
							margin: "left"
						},
						{
							type: "button",
							text: strings.generic.save,
							onClick: () => this.submitOrder(),
							disabled: !canCreate,
							hasIcon: true,
							icon: <Icon name="correct-symbol" />,
							separator: "left"
						}
					]
				};
			})
		);
	}

	getPaymentStatus(status: string) {
		const statusString = status as keyof typeof strings.orders.paymentStatuses;
		return strings.orders.paymentStatuses[statusString] || "-";
	}

	getShippingStatus(status: string) {
		const statusString = status as keyof typeof strings.orders.shippingStatuses;
		return strings.orders.shippingStatuses[statusString] || "-";
	}

	getDefaultPaymentStatus(paymentStatus: string) {
		if (paymentStatus === "pending_payment") {
			return [
				{
					label: strings.orders.paymentStatuses.pending_payment,
					value: "pending_payment"
				},
				{ label: strings.orders.paymentStatuses.paid, value: "paid" }
			];
		}

		return [
			{
				label: strings.orders.paymentStatuses.pending_payment,
				value: "waiting_payment"
			},
			{
				label: strings.orders.paymentStatuses.pending_payment,
				value: "pending_payment"
			},
			{ label: strings.orders.paymentStatuses.paid, value: "paid" },
			{
				label: strings.orders.paymentStatuses.cancelled,
				value: "cancelled"
			},
			{
				label: strings.orders.paymentStatuses.partially_refunded,
				value: "partially_refunded"
			},
			{
				label: strings.orders.paymentStatuses.refunded,
				value: "refunded"
			},
			{
				label: strings.orders.paymentStatuses.failed_payment,
				value: "failed_payment"
			}
		];
	}

	getDefaultShippingStatus() {
		return [
			{
				label: strings.orders.shippingStatuses.pending_processing,
				value: "pending_processing"
			},
			{
				label: strings.orders.shippingStatuses.processing,
				value: "processing"
			},
			{
				label: strings.orders.shippingStatuses.partially_shipped,
				value: "partially_shipped"
			},
			{
				label: strings.orders.shippingStatuses.shipped,
				value: "shipped"
			},
			{
				label: strings.orders.shippingStatuses.delivered,
				value: "delivered"
			},
			{
				label: strings.orders.shippingStatuses.partially_returned,
				value: "partially_returned"
			},
			{
				label: strings.orders.shippingStatuses.returned,
				value: "returned"
			},
			{ label: strings.orders.shippingStatuses.closed, value: "closed" },
			{
				label: strings.orders.shippingStatuses.cancelled,
				value: "cancelled"
			},
			{
				label: strings.orders.shippingStatuses.awaiting_pickup,
				value: "awaiting_pickup"
			},
			{
				label: strings.orders.shippingStatuses.partially_pickedup,
				value: "partially_pickedup"
			},
			{
				label: strings.orders.shippingStatuses.partially_created,
				value: "partially_created"
			},
			{
				label: strings.orders.shippingStatuses.pickup_created,
				value: "pickup_created"
			}
		];
	}

	canCreateOrder() {
		const { user, products, selectedPaymentMethod, selectedPaymentAddress, selectedShippingAddress, selectedShippingMethod } =
			this.state;

		if (
			!user ||
			products?.length === 0 ||
			!selectedPaymentMethod ||
			!selectedPaymentAddress ||
			!selectedShippingAddress ||
			!selectedShippingMethod
		)
			return false;

		return true;
	}

	selectUser() {
		const { tempUser } = this.state;

		if (tempUser) {
			this.getUser();
		} else {
			this.setState({
				showUsersDrawer: false,
				tempUser: null,
				userSearchText: ""
			});
		}
	}

	async addProducts() {
		const { tempProducts, products } = this.state;

		if (tempProducts?.length) {
			for (const prod of tempProducts) {
				const product = products.find((p: any) => p._id === prod._id);

				if (product) {
					product.quantity += +prod.quantity;
				} else {
					products.push(prod);
				}
			}

			this.setState({
				showProductsDrawer: false,
				selectedShippingMethod: null,
				tempProducts: [],
				products
			});

			await this.calculateTotal();

			return;
		}
	}

	renderClientSearch() {
		const { user, userSearchText = "" } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="user" />
						<h2>{strings.orders.clientInformation}</h2>
					</div>
				</div>
				<Row gutter={[20, 20]}>
					<Col xs={24} md={12}>
						<label htmlFor="order_client_search" className="InputLabel">
							{strings.orders.searchClientLabel}
						</label>
						<Search
							id="order_client_search"
							placeholder={strings.orders.searchClient}
							allowClear
							enterButton={strings.generic.search}
							size="large"
							value={userSearchText}
							onChange={(e) =>
								this.setState({
									userSearchText: e.target.value
								})
							}
							onSearch={(value) => {
								if (value) {
									this.getUsers(true);
								} else {
									this.setState({ showUsersDrawer: true });
								}
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="order_user_name" className="InputLabel">
							{strings.fields.name}
						</label>
						<Input
							id="order_user_name"
							placeholder={strings.fields.name}
							value={user?.name || ""}
							style={{ height: 40 }}
							readOnly
						/>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="order_user_email" className="InputLabel">
							{strings.fields.email}
						</label>
						<Input
							id="order_user_email"
							placeholder={strings.fields.email}
							value={user?.email || ""}
							style={{ height: 40 }}
							readOnly
						/>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="order_user_phone" className="InputLabel">
							{strings.fields.phone}
						</label>
						<PhoneInput
							defaultCountry="pt"
							value={user?.phone || ""}
							disabled
							inputClass="input-phone phone-disabled"
							inputProps={{
								name: "phone",
								required: true,
								autoComplete: "off",
								placeholder: strings.fields.phone
							}}
						/>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	renderAddressForm() {
		const { changingAddress, tempAddress, defaultCountries, defaultStates = [] } = this.state;

		if (!changingAddress) return null;

		return (
			<Col xs={24} md={12}>
				<div className="OrderAddressBlock">
					<div className="OrderAddressTitle">
						<Icon name="directions" />
						<h3>{strings.orders.addAddress}</h3>
					</div>
				</div>
				<Row style={{ marginTop: 10 }} gutter={[20, 10]}>
					<Col xs={24}>
						<label htmlFor="order_address_name" className="InputLabel">
							{strings.fields.name}
						</label>
						<Input
							id="order_address_name"
							placeholder={strings.fields.name}
							value={tempAddress?.name || ""}
							onChange={(e) => {
								const value = e.target.value;

								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										name: value
									}
								}));
							}}
							style={{ height: 40 }}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="order_address_address" className="InputLabel">
							{strings.fields.address}
						</label>
						<Input
							id="order_address_address"
							placeholder={strings.fields.address}
							value={tempAddress?.address || ""}
							onChange={(e) => {
								const value = e.target.value;

								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										address: value
									}
								}));
							}}
							style={{ height: 40 }}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="order_address_address2" className="InputLabel">
							{strings.fields.address2}
						</label>
						<Input
							id="order_address_address2"
							placeholder={strings.fields.address2}
							value={tempAddress?.address2 || ""}
							onChange={(e) => {
								const value = e.target.value;

								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										address2: value
									}
								}));
							}}
							style={{ height: 40 }}
						/>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="zip" className="InputLabel --label-required">
							{strings.fields.zipCode}
						</label>
						<Input
							id="zip"
							placeholder={strings.fields.zipCode}
							value={tempAddress?.postalCode || ""}
							onChange={(e) => {
								const value = e.target.value;

								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										postalCode: value
									}
								}));
							}}
							style={{ height: 40 }}
						/>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="city" className="InputLabel --label-required">
							{strings.fields.city}
						</label>
						<Input
							id="city"
							placeholder={strings.fields.city}
							value={tempAddress?.city || ""}
							onChange={(e) => {
								const value = e.target.value;

								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										city: value
									}
								}));
							}}
							style={{ height: 40 }}
						/>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="country" className="InputLabel --label-required">
							{strings.fields.country}
						</label>
						<Select
							style={{ width: "100%" }}
							placeholder={strings.fields.country}
							showSearch
							filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							value={tempAddress?.country || null}
							onChange={(elem: any) => {
								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										country: elem,
										state: null
									}
								}));
							}}
						>
							{defaultCountries.map((country: any) => (
								<Select.Option key={country._id} value={country._id}>
									{country?.name}
								</Select.Option>
							))}
						</Select>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="state" className="InputLabel --label-required">
							{strings.fields.state}
						</label>
						<Select
							id="state"
							style={{ width: "100%" }}
							placeholder={tempAddress?.country ? strings.fields.state : strings.fields.selectCountryFirst}
							showSearch
							filterOption={(input: any, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							value={tempAddress?.state || null}
							disabled={!tempAddress?.country}
							options={
								tempAddress?.country
									? defaultStates[tempAddress?.country].map((state: any) => ({
											value: state?._id,
											label: state?.name
									  }))
									: null
							}
							onChange={(elem: any) => {
								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										state: elem
									}
								}));
							}}
						/>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="order_address_phone" className="InputLabel">
							{strings.fields.phone}
						</label>
						<PhoneInput
							id="order_address_phone"
							defaultCountry="pt"
							value={tempAddress?.phone || ""}
							onChange={(phone: string) =>
								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										phone
									}
								}))
							}
							inputClass="input-phone"
							inputProps={{
								name: "phone",
								required: false,
								autoComplete: "off",
								placeholder: strings.fields.phone
							}}
						/>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="vat_number" className="InputLabel">
							{strings.fields.vatNumber}
						</label>
						<Input
							id="vat_number"
							placeholder={strings.fields.vatNumber}
							value={tempAddress?.vatNumber || ""}
							onChange={(e) => {
								const value = e.target.value;

								this.setState((prevState: any) => ({
									tempAddress: {
										...prevState.tempAddress,
										vatNumber: value
									}
								}));
							}}
							style={{ height: 40 }}
						/>
					</Col>
					<Col xs={24}>
						<div className={`General_ColorFul_Switch ${tempAddress?.isDefault ? "__active" : ""}`}>
							<span>{strings.users.isDefault}</span>
							<Switch
								className={`Switch ${tempAddress?.isDefault ? "__active" : ""}`}
								checked={tempAddress?.isDefault || false}
								size="small"
								onChange={(value: any) =>
									this.setState((prevState: any) => ({
										tempAddress: {
											...prevState.tempAddress,
											isDefault: value,
											hasUnsavedFields: true
										}
									}))
								}
							/>
						</div>
					</Col>

					<Col xs={12}>
						<Button type="primary" style={{ width: "100%" }} onClick={() => this.saveAddress()}>
							{strings.generic.save}
						</Button>
					</Col>
					<Col xs={12}>
						<Button
							style={{ width: "100%" }}
							onClick={() =>
								this.setState({
									changingAddress: false,
									tempAddress: null
								})
							}
						>
							{strings.generic.cancel}
						</Button>
					</Col>
				</Row>
			</Col>
		);
	}

	renderClientAddresses() {
		const { user, changingAddress, selectedShippingAddress, selectedPaymentAddress } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="directions" />
						<h2>{strings.orders.orderAddresses}</h2>
					</div>
				</div>
				<Row gutter={[20, 20]}>
					{!changingAddress && (
						<React.Fragment>
							<Col xs={24} md={12}>
								<div className="OrderAddressBlock">
									<div className="OrderAddressTitle --rounded">
										<Icon name="directions" />
										<h3>{strings.orders.shippingAddress}</h3>
									</div>
									{!user && (
										<div className="OrderAddressInfo --single-entry --select-first">
											<strong>{strings.orders.selectClientFirst}</strong>
										</div>
									)}
									{user?.addresses?.map((address: any) => {
										const shippingVat = address?.vatNumber ? `${strings.fields.vatNumber}: ${address?.vatNumber}` : "";
										const shippingPhoneNumber = address?.phoneNumber
											? `${strings.fields.phoneShort}: ${address?.phoneNumber}`
											: "";

										return (
											<div className="OrderAddress" key={`address_${address._id}`}>
												<div className="OrderAddressInfo --single-entry">
													<strong>{address?.name}</strong>
													<span>
														{address?.address}
														{address?.address2 ? `, ${address?.address2}` : ""}
													</span>
													<span>
														{address?.postalCode} {address?.city}
													</span>
													<span>
														{address?.state?.name}, {address?.country?.name}
													</span>
													{Boolean(shippingVat) || Boolean(shippingPhoneNumber) ? (
														<span>
															{shippingVat}
															{shippingVat && shippingPhoneNumber
																? ` | ${shippingPhoneNumber}`
																: shippingPhoneNumber}
														</span>
													) : null}
												</div>
												<button
													className={`OrderAddressSelect${
														selectedShippingAddress === address._id ? " --selected" : ""
													}`}
													onClick={() =>
														this.setState({
															selectedShippingAddress: address._id
														})
													}
												>
													<Icon
														name="correct-symbol"
														onClick={() =>
															this.setState({
																selectedShippingAddress: address._id
															})
														}
													/>
												</button>
											</div>
										);
									})}
									{user && (
										<button
											onClick={() =>
												this.setState({
													changingAddress: true
												})
											}
											className="OrderButton"
										>
											<strong>{strings.orders.addAddress}</strong>
										</button>
									)}
								</div>
							</Col>
							<Col xs={24} md={12}>
								<div className="OrderAddressBlock">
									<div className="OrderAddressTitle --rounded">
										<Icon name="directions" />
										<h3>{strings.orders.billingAddress}</h3>
									</div>
									{!user && (
										<div className="OrderAddressInfo --single-entry --select-first">
											<strong>{strings.orders.selectClientFirst}</strong>
										</div>
									)}
									{user?.addresses?.map((address: any) => {
										const shippingVat = address?.vatNumber ? `${strings.fields.vatNumber}: ${address?.vatNumber}` : "";
										const shippingPhoneNumber = address?.phoneNumber
											? `${strings.fields.phoneShort}: ${address?.phoneNumber}`
											: "";

										return (
											<div className="OrderAddress" key={`address_${address._id}`}>
												<div className="OrderAddressInfo --single-entry">
													<strong>{address?.name}</strong>
													<span>
														{address?.address}
														{address?.address2 ? `, ${address?.address2}` : ""}
													</span>
													<span>
														{address?.postalCode} {address?.city}
													</span>
													<span>
														{address?.state?.name}, {address?.country?.name}
													</span>
													{Boolean(shippingVat) || Boolean(shippingPhoneNumber) ? (
														<span>
															{shippingVat}
															{shippingVat ? ` | ${shippingPhoneNumber}` : shippingPhoneNumber}
														</span>
													) : null}
												</div>
												<button
													className={`OrderAddressSelect${
														selectedPaymentAddress === address._id ? " --selected" : ""
													}`}
													onClick={() =>
														this.setState({
															selectedPaymentAddress: address._id
														})
													}
												>
													<Icon
														name="correct-symbol"
														onClick={() =>
															this.setState({
																selectedPaymentAddress: address._id
															})
														}
													/>
												</button>
											</div>
										);
									})}
									{user && (
										<button
											onClick={() =>
												this.setState({
													changingAddress: true
												})
											}
											className="OrderButton"
										>
											<strong>{strings.orders.addAddress}</strong>
										</button>
									)}
								</div>
							</Col>
						</React.Fragment>
					)}
					{changingAddress && this.renderAddressForm()}
				</Row>
			</ContentWrapper>
		);
	}

	renderUsersDrawer() {
		const { showUsersDrawer } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="user" />
						<p>{strings.orders.searchClientLabel}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button type="button" className="SidebarFooterButton --button-confirm" onClick={() => this.selectUser()}>
							{strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() =>
								this.setState({
									showUsersDrawer: false,
									tempUser: undefined,
									tempUsers: [],
									userSearchText: ""
								})
							}
						>
							{strings.generic.cancel}
						</button>
					</div>
				}
				visible={showUsersDrawer}
				onClose={() =>
					this.setState({
						showUsersDrawer: false,
						tempUser: undefined,
						tempUsers: []
					})
				}
				width={mobile ? "100%" : 500}
			>
				{this.renderUsersDrawerContent()}
			</Drawer>
		);
	}

	renderUsersDrawerContent() {
		const { userSearchText, tempUsers = [], tempUser } = this.state;

		return (
			<Row gutter={[20, 20]}>
				<Col xs={24}>
					<label htmlFor="order_client_search" className="InputLabel">
						{strings.orders.searchClientLabel}
					</label>
					<Search
						id="order_client_search"
						placeholder={strings.orders.searchClient}
						allowClear
						enterButton={strings.generic.search}
						size="large"
						value={userSearchText}
						onChange={(e) => this.setState({ userSearchText: e.target.value })}
						onSearch={(value) => {
							if (value) this.getUsers(true);
						}}
					/>
				</Col>
				<Col xs={24}>
					<Row gutter={[20, 20]}>
						{tempUsers.length > 0 &&
							tempUsers.map((user: any) => {
								const checked = user?._id === tempUser?._id;

								return (
									<React.Fragment key={user._id}>
										<Col xs={24}>
											<button
												className="DrawerOrderUserBlock"
												onClick={() =>
													this.setState({
														tempUser: checked ? null : JSON.parse(JSON.stringify(user))
													})
												}
											>
												<div className="DrawerOrderUser">
													<div
														className="DrawerOrderUserImage"
														style={{
															backgroundImage: `url(${placeholder})`
														}}
													/>
													<div className="DrawerOrderUserInfo">
														<p>{user.name}</p>
														<p>{user.email}</p>
													</div>
												</div>
												<span className={`DrawerOrderUserCheckbox${checked ? " --checked" : ""}`}>
													<Icon name="correct-symbol" />
												</span>
											</button>
										</Col>
									</React.Fragment>
								);
							})}
					</Row>
				</Col>
			</Row>
		);
	}

	renderProductList() {
		const { user, products = [], total = 0 } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader --multi">
					<div className="ScreenHeaderLeft">
						<Icon name="basket" />
						<h2>{strings.orders.productList}</h2>
					</div>
					<div className="ScreenHeaderRight">
						<Button
							type="primary"
							disabled={!user}
							style={{ marginBottom: 10 }}
							onClick={async () => {
								await this.getProducts();
								this.setState({ showProductsDrawer: true });
							}}
						>
							{strings.orders.addProducts}
						</Button>
					</div>
				</div>
				<div className="OrderProductsContainer">
					<div className="OrderProductsTable">
						{products?.length > 0 ? (
							<table>
								<tbody>
									{products?.map((product: any, index: number) => {
										return (
											<tr key={`product_${product._id}`}>
												<td>
													<div className="OrderProductColumn --no-padding">
														<img src={product?.image} alt={translate(product.name)} />
													</div>
												</td>
												<td>
													<div className="OrderProductColumn">
														<strong>{translate(product.name)}</strong>
													</div>
												</td>
												<td>
													<div className="OrderProductColumn">
														<strong>{strings.products.reference}</strong>
														<span>{product.reference}</span>
													</div>
												</td>
												<td>
													<div className="OrderProductColumn">
														<strong className="--align-right">{strings.fields.quantity}</strong>
														<span className="--align-right">{product.quantity}</span>
													</div>
												</td>
												<td>
													<div className="OrderProductColumn">
														<strong className="--align-right">{strings.products.price}</strong>
														<span className="--align-right">{formatPrice(product.price)}</span>
													</div>
												</td>
												<td>
													<div className="OrderProductColumn">
														<strong className="--align-right">{strings.fields.subTotal}</strong>
														<span className="--align-right">{formatPrice(product.total)}</span>
													</div>
												</td>
												<td className="--option">
													<button
														className="OrderProductColumn --centered"
														onClick={() => {
															products.splice(index, 1);
															this.setState({ products }, () => this.calculateTotal());
														}}
													>
														<Icon name="trash" />
													</button>
												</td>
											</tr>
										);
									})}
									{products?.length > 0 && (
										<tr>
											<td>&nbsp;</td>
											<td>&nbsp;</td>
											<td>&nbsp;</td>
											<td>&nbsp;</td>
											<td>
												<div className="OrderProductColumn">
													<strong className="--align-right">{strings.orders.totalQuantity}</strong>
													<span className="--align-right">
														{products?.reduce((acc: any, product: any) => acc + product.quantity, 0)}
													</span>
												</div>
											</td>
											<td>
												<div className="OrderProductColumn">
													<strong className="--align-right">{strings.fields.total}</strong>
													<span className="--align-right">{formatPrice(total)}</span>
												</div>
											</td>
											<td className="--option">&nbsp;</td>
										</tr>
									)}
								</tbody>
							</table>
						) : (
							<div className="OrderProductListEmpty">
								<p>{strings.orders.productListEmpty}</p>
							</div>
						)}
					</div>
				</div>
			</ContentWrapper>
		);
	}

	renderProductsDrawer() {
		const { showProductsDrawer } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="basket" />
						<p>{strings.orders.productList}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button type="button" className="SidebarFooterButton --button-confirm" onClick={() => this.addProducts()}>
							{strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() =>
								this.setState({
									showProductsDrawer: false,
									tempProducts: [],
									productSearchText: ""
								})
							}
						>
							{strings.generic.cancel}
						</button>
					</div>
				}
				visible={showProductsDrawer}
				onClose={() =>
					this.setState({
						showProductsDrawer: false,
						tempProducts: [],
						productSearchText: ""
					})
				}
				width={mobile ? "100%" : 1000}
			>
				{this.renderProductsDrawerContent()}
			</Drawer>
		);
	}

	renderProductsDrawerContent() {
		const { productSearchText, defaultProducts = [], tempProducts = [], drawerSelectedImage } = this.state;

		const selectedProduct = tempProducts?.find((product: any) => product._id === drawerSelectedImage);

		return (
			<div className="OrderProductDrawerContainer">
				<div className="OrderProductsDrawerHeader">
					<label htmlFor="order_product_search" className="InputLabel">
						{strings.orders.searchProduct}
					</label>
					<Search
						id="order_product_search"
						placeholder={strings.orders.searchProduct}
						allowClear
						enterButton={strings.generic.search}
						size="large"
						value={productSearchText}
						onChange={(e) => this.setState({ productSearchText: e.target.value })}
						onSearch={() => this.getProducts()}
					/>
				</div>
				<div className="OrderProductsDrawerList">
					<Row gutter={[20, 20]}>
						{defaultProducts?.map((product: any) => {
							return (
								<Col key={`product_${product?._id}`} xs={24} md={8}>
									<OrderProduct
										product={product}
										onAddClick={(quantity: number) => {
											const copy = JSON.parse(JSON.stringify(tempProducts));
											const tempProduct = copy.find((tempProduct: any) => tempProduct._id === product._id);

											if (tempProduct) {
												tempProduct.quantity = +tempProduct.quantity + quantity;
											} else {
												const newProduct = {
													...product,
													quantity,
													image: product?.images?.find((img: { url: string; favorite: boolean }) => img.favorite)
														?.url
												};

												copy.push(newProduct);
											}

											this.setState((prevState: any) => ({
												tempProducts: copy,
												drawerSelectedImage:
													prevState.drawerSelectedImage != null ? prevState.drawerSelectedImage : product?._id
											}));
										}}
									/>
								</Col>
							);
						})}
					</Row>
				</div>
				{tempProducts?.length > 0 && (
					<div className="OrderProductsDrawerSummary">
						<div className="OrderProductsDrawerImages">
							{tempProducts?.map((product: any) => {
								const image =
									product.images?.find((img: { url: string; favorite: boolean }) => img.favorite)?.url ||
									product.images?.[0]?.url ||
									placeholder;

								return (
									<button
										key={product._id}
										className={`OrderProductsDrawerImage${drawerSelectedImage === product?._id ? " --selected" : ""}`}
										onClick={() => this.setState({ drawerSelectedImage: product._id })}
									>
										<img src={image} alt={translate(product.name)} />
									</button>
								);
							})}
						</div>
						{Boolean(selectedProduct) && (
							<React.Fragment>
								<div className="OrderProductsDrawerDescription">
									<h3>{translate(selectedProduct.name)}</h3>
								</div>
								<div className="OrderProductQuantity">
									<Button
										size="small"
										onClick={() => {
											if (selectedProduct.quantity > 1) {
												selectedProduct.quantity = +selectedProduct.quantity - 1;
												this.setState({ tempProducts });
											}
										}}
									>
										-
									</Button>
									<span>{selectedProduct?.quantity || 0}</span>
									<Button
										size="small"
										onClick={() => {
											selectedProduct.quantity = +selectedProduct.quantity + 1;
											this.setState({ tempProducts });
										}}
									>
										+
									</Button>
								</div>
							</React.Fragment>
						)}
					</div>
				)}
			</div>
		);
	}

	renderOrderShippingAndPayment() {
		const { products = [], shippingMethods, selectedShippingMethod, selectedPaymentMethod } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="box" />
						<h2>{strings.orders.orderProcessing}</h2>
					</div>
				</div>
				<Row gutter={[20, 20]}>
					<Col xs={24} md={12}>
						<div className="OrderAddressBlock">
							<div className="OrderAddressTitle --rounded">
								<Icon name="directions" />
								<h3>{strings.orders.shippingMethod}</h3>
							</div>
							{products?.length === 0 && (
								<div className="OrderAddressInfo --single-entry --select-first">
									<strong>{strings.orders.selectProductsFirst}</strong>
								</div>
							)}
							{shippingMethods?.map((shippingMethod: any) => {
								if (shippingMethod?.methods?.length === 0) return null;

								return shippingMethod?.methods?.map((method: any) => {
									return (
										<div className="OrderAddress" key={`shipping_method_${method._id}`}>
											<div className="OrderAddressInfo --single-entry --lowercase">
												<strong>{translate(method?.name)}</strong>
												<span>{translate(method?.description)}</span>
											</div>
											<strong>
												{method?.methodType === "free" ? strings.orders.free : formatPrice(method?.value || 0)}
											</strong>
											<button
												className={`OrderAddressSelect${
													selectedShippingMethod === method._id ? " --selected" : ""
												}`}
												onClick={() =>
													this.setState(
														{
															selectedShippingMethod: method._id
														},
														() => this.calculateTotal()
													)
												}
											>
												<Icon name="correct-symbol" />
											</button>
										</div>
									);
								});
							})}
						</div>
					</Col>
					<Col xs={24} md={12}>
						<div className="OrderAddressBlock">
							<div className="OrderAddressTitle --rounded">
								<Icon name="directions" />
								<h3>{strings.orders.paymentMethod}</h3>
							</div>
							{products?.length === 0 ? (
								<div className="OrderAddressInfo --single-entry --select-first">
									<strong>{strings.orders.selectProductsFirst}</strong>
								</div>
							) : (
								<React.Fragment>
									<div className="OrderAddress">
										<div className="OrderAddressInfo --single-entry --lowercase">
											<strong>{strings.orders.cashOnDelivery}</strong>
											<span>{strings.orders.cashOnDeliveryDescription}</span>
										</div>
										<button
											className={`OrderAddressSelect${
												selectedPaymentMethod === "cash_on_delivery" ? " --selected" : ""
											}`}
											onClick={() => this.setState({ selectedPaymentMethod: "cash_on_delivery" })}
										>
											<Icon name="correct-symbol" />
										</button>
									</div>
									<div className="OrderAddress">
										<div className="OrderAddressInfo --single-entry --lowercase">
											<strong>{strings.orders.bankTransfer}</strong>
											<span>{strings.orders.bankTransferDescription}</span>
										</div>
										<button
											className={`OrderAddressSelect${
												selectedPaymentMethod === "bank_transfer" ? " --selected" : ""
											}`}
											onClick={() => this.setState({ selectedPaymentMethod: "bank_transfer" })}
										>
											<Icon name="correct-symbol" />
										</button>
									</div>
								</React.Fragment>
							)}
						</div>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{strings.orders.newOrder}</title>
					<meta name="description" content="Order details" />
				</Helmet>
				{this.renderClientSearch()}
				{this.renderClientAddresses()}
				{this.renderUsersDrawer()}
				{this.renderProductList()}
				{this.renderProductsDrawer()}
				{this.renderOrderShippingAndPayment()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	mobile: state.mobile
});

export default connect(mapStateToProps)(OrderNew);
