import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
	/* cSpell:enable */
	/* cSpell.language:en */
	en: {
		// Generic
		generic: {
			askToSave: "Deseja guardar as alterações antes de sair da página?",
			edit: "Edit",
			done: "Done",
			accept: "Accept",
			cancel: "Cancel",
			remove: "Remove",
			forget: "Forget customer",
			delete: "Delete",
			success: "Success",
			active: "Active",
			expand: "Expand",
			toggle: "Toggle",
			save: "Save",
			yes: "Yes",
			no: "No",
			apply: "Apply",
			item: "Item",
			items: "Items",
			submit: "Submit",
			continue: "Continue",
			confirm: "Confirm",
			finish: "Finish",
			back: "Back",
			oops: "Oops",
			error: "Error",
			retry: "Retry",
			reset: "Reset",
			search: "Search",
			noResults: "No Results",
			searchByEmailOrName: "Search by email or name",
			searchPartner: "Partner name/code...",
			clickHereTo: "Click here to {0}",
			requiredField: "{0} is a required field",
			duplicateEmail: "This email is already taken. Please use another email.",
			emptyList: "No {0} in List",
			leavePage: "Leave Page?",
			leave: "Leave",
			stay: "Stay",
			open: "Open",
			close: "Close",
			change: "Change",
			free: "Free",
			send: "Send",
			selected: "Selected",
			selecteds: "Selected",
			swalEditTitle: "Cancel Edit?",
			swalEditDescription: "Cancelling edit will erase all the unsaved information!",
			leaveDescription: "Leaving this page will erase all the unsaved information!",
			swalDeleteTitle: "Delete {0}?",
			swalDeleteDescription: "By leaving this page, you'll erase all the {0} on your list",
			genericSelect: "Select {0}",
			select: "Select",
			addGeneric: "Add {0}",
			moreOptions: "More Options",
			all: "All",
			enable: "Enable",
			newF: "New",
			newM: "New",
			addNewF: "Add new {0}",
			addNewM: "Add new {0}",
			default: "Default",
			editGeneric: "Edit {0}",
			infinite: "Infinite",
			details: "Details",
			noItemsSelected: "No Items Selected",
			clearList: "Clear List",
			loading: "Loading...",
			view: "View",
			actions: "Actions",
			invalidUrl: "Invalid URL. Please remove HTTP prefix.",
			shop: "Shop",
			noEntries: "No Entries",
			chatWalktroughText1:
				"We've added several FAQs that will help you know and understand how the Shop2Grow product works. These FAQs are available from the chat icon at the bottom right of the screen.",
			chatWalktroughText2: "We are also available through chat to help you the best way on to setting up your store.",
			skip: "Skip",
			walkTrough: "walkthrough",
			clone: "Clone",
			download: "Download",
			upload: "Upload",
			template: "Download Template",
			export: "Export",
			import: "Import",
			defaultItem: "an item",
			defaultItemRecipient: "this {0}",
			demo: "Demo",
			previewOnly: "Preview Only",
			selectPaymentMethod: "Please select a payment method before updating your subscription.",
			basic: "Basic",
			advanced: "Advanced",
			editMode: "Edit Mode",
			print: "Print",
			code: "Code",
			filters: " Filters",
			changeImage: "Click here to add an image file or drag it into this box.",
			changeImages: "Click here to add images or drag them into this box.",
			userImage: `This user don't have any image`,
			changeVideo: "Click here to add a video file or drag it into this box.",
			changeYoutubeVideo: "Insert youtube link",
			changeAudio: "Click here to add an audio file or drag it into this box.",
			everyF: "All",
			uploadImages: "Drop images here or click to select files to upload",
			uploadVideos: "Drop animations here or click to select files to upload",
			none: "None",
			clickOrDrag: "Click or drag file to this area",
			singleOrBulk: "Support for a single or bulk file upload",
			processingImages: "Processing Images",
			preview: "Preview",
			every: "All",
			seoMissing: "SEO is a mandatory field",
			visitStore: "Visit Store",
			storeLink: "Store Link",
			placeholder: "Placeholder",
			left: "Left",
			right: "Right",
			redeem: "Redeem",
			bonus: "Bonus",
			from: "Origin",
			bonuses: "Bonuses",
			destiny: "Destiny",
			expirationDate: "Expires on",
			copy: "Copy",
			opened: "Opened",
			closed: "Closed",
			searchMinimumChars: "Search requires at least 3 characters",
			searchEmail: "Search by email",
			inactive: "Inactive"
		},

		fields: {
			name: "Name",
			firstName: "First Name",
			fullName: "Full Name",
			lastName: "Last Name",
			centername: "Center Name",
			address: "Address",
			address2: "Address 2",
			birthday: "Birthday",
			nif: "NIF",
			addresses: "Addresses",
			country: "Country",
			state: "State / Province",
			city: "City",
			zipCode: "PostCode",
			description: "Description",
			phone: "Phone",
			phoneNumber: "Phone Number",
			phoneShort: "Tel.:",
			mobilePhone: "Mobile Number",
			email: "Email",
			request: "Request",
			createdAt: "Created At",
			partner: "Partner",
			seoUrl: "SEO Url",
			tags: "Tags",
			ccNumber: "CC Number",
			billingAddress: "Billing Address",
			shippingAddress: "Shipping Address",
			vat: "VAT",
			vatNumber: "VAT Number",
			password: "Password",
			message: "Message",
			image: "Image",
			images: "Images",
			imgPlaceholder: "Try dropping some images here, or click to select files to upload",
			filesPlaceholder: "Try dropping your file here, or click to select a file",
			subTotal: "Subtotal",
			total: "Total",
			quantity: "Quantity",
			ordered: "Ordered",
			totalQuantity: "Total Quantity",
			discount: "Discount",
			registerDate: "Register Date",
			invalidEmail: "Email is not Valid",
			tax: "Tax",
			addedOn: "Added On",
			startDate: "Start Date",
			endDate: "End Date",
			startHour: "Start Hour",
			endHour: "End Hour",
			filename: "Filename",
			gender: "Gender",
			waitingForResults: "Waiting for Name or SKU...",
			link: "Link",
			date: "Date",
			otherRegions: "Other Zones",
			taxValues: "Tax Values",
			previewLink: "Preview Link",
			clickToUpload: "Click to Upload",
			photo: "Photo",
			autor: "Author",
			category: "Category",
			question: "Question",
			answer: "Answer",
			exclusivePartners: "Exclusive Partners",
			exclusiveClients: "Exclusive Clients",
			pickOnStore: "Pick on Store",
			recipient: "Recipient",

			// Select
			selectCountry: "Select Country",
			selectState: "Select State / Province",
			selectFilters: "Select Filters",
			selectDiscount: "Select Discount",

			countries: "Countries",
			states: "States",
			selectCountryFirst: "Select Country First",

			// Pairs
			valuePair: "{0} / {1}",
			placeHolderPair: "{0} => {1}",

			code: "Abbreviation",

			logo: "Logo",
			gif: "GIF",
			iban: "IBAN",
			age: "Age",
			article: "Article",
			bccEmail: "BCC Email",
			weight: "Weight (kg)",
			list: "List",
			edit: "Edit",
			cardNumber: "Card Number",
			cardName: "Card Name",
			month: "Month",
			year: "Year",
			cvc: "CVC"
		},

		language: {
			header: "Language",
			defaultLanguage: "Default Language",
			languageNotActive: "Save the changes before changing the default language.",
			portuguese: "Portuguese",
			english: "English",
			german: "German",
			french: "French",
			spanish: "Spanish"
		},

		sidebar: {
			dashboard: "Dashboard",
			crm: "CRM",
			profile: "Profile",
			help: "Help",
			information: "Information",
			staff: "Staff",
			staffLogs: "Staff Logs",
			settings: "Settings",
			logs: "Logs",
			users: "Customers",
			marketing: "Marketing",
			highlightedSections: "Highlighted Sections",
			banners: "Banners",
			faqs: "Faqs",
			segments: "Segments",
			notifications: "Notifications",
			partners: "Partners",
			categories: "Categories",
			tree: "Partners Tree",
			orders: "Orders",
			commissions: "Commissions",
			partnerTrees: "Partner Trees",
			geoTree: "Geneological Tree",
			referralTree: "Sponsor Tree",
			bonuses: "Bonuses",
			successLadder: "Success Ladder",
			commissionsAndBonuses: "Commissions & Bonuses",
			wallet: "Wallet"
		},

		header: {
			daysLeft: "days left on trial",
			upgrade: "Upgrade",
			account: "Account",
			maintenance: "Maintenance",
			visitStore: "Visit Store",
			deleteSuccess: "Dummy data deleted successfully",
			extend: "Extend Trial"
		},

		// Screens
		authentication: {
			welcome: "Welcome",
			header: "Authentication",
			register: "Register",
			login: "Login",
			logout: "Logout",
			signIn: "Sign in",
			confirmAccount: "Confirm Account",
			name: "Name",
			namePlaceholder: "Your name",
			email: "Email",
			emailPlaceholder: "Your email",
			password: "Password",
			passwordPlaceholder: "Your password",
			newPassword: "New password",
			newPasswordPlaceholder: "Enter new password",
			confirmPassword: "Confirm Password",
			confirmPasswordPlaceholder: "Repeat your password",
			forgotPassword: "Forgot your password? Click here.",
			loginFooter: "No account? Register here",
			registerFooter: "Do you have an account? Login here",
			resendCode1: "Have you not received your confirmation code? ",
			resendCode2: "Resend activation code",
			sendEmail: "Send Email",
			recoverPassword: "Recover Password",
			changePassword: "Change Password",
			changeEmail: "Change Email",
			newEmail: "New Email",
			repeatEmail: "Repeat Email",
			newEmailConfirm: "Confirm New Email",
			currentEmail: "Current Email",
			currentPassword: "Current Password",
			emailSent: "Email Sent Successfully",
			repeatPassword: "Repeat Password",
			rememberedPassword: "Did you remember your password?",
			adminPassword: "Admin Password",
			notLoggedIn: "You need to be logged in to access this page",
			noMatchPassword: "Passwords don't match",
			acceptSubtitle: "Please input a password to confirm your account.",
			confirmInvite: "Please type your password to accept invite.",
			goBackToLogin: "Go to the login screen",
			resendInviteEmail: "Resend Invitation Email",
			confirmResendInviteEmail: "Resend an invitation email to {0}?",
			forgetTitle: "Are you sure you want to block the user {0}? This action will leave the user without access to the app.",
			forgetConfirm: "Block User",
			enterCode: "Please enter the authy code"
		},

		acceptInvite: {
			header: "Accept Invite",
			title: "Hi {0}, {1} has invited you to belong to {2}",
			title2: "Hi {0}, {1} has invited you to manage {2}.",
			subTitle: "Please choose a password to confirm your account",
			expired: "This invitation link has expired"
		},

		notFound: {
			header: "Not Found"
		},

		importModal: {
			fileName: "Filename",
			size: "Size",
			import: "Import"
		},

		errors: {
			invalidArgs: "Invalid or missing arguments",
			invalidFields: "Invalid or missing fields",
			emailIsInvalid: "Email format is not valid",
			fillEmail: "Please input your email",
			fillPassword: "Please input your password",
			pleaseFillFormCorrectly: "Please fill the form correctly",
			fillField: "This field is mandatory",
			notSupportedFile: "File type not supported",
			nameIsEmpty: "Please fill name field",
			passwordsDontMatch: "Fields 'New password' and 'Confirm password' don't match",
			emailIsEmpty: "Please fill email field",
			fileTooBigorInvalid: "File size is larger than 8MB or file is corrupt",
			unsupportedFile: "File not supported",
			fileNotSupported: "File you tried to upload is not supported.",
			processingError: "Processing Error",
			imageProcessingError: "{images} could not be processed.",
			partnerCodeIsInvalid: "Partner Code format is not valid",
			fillEmailOrCode: "Please input your email or partner code"
		},

		profile: {
			profilePicture: "Profile Picture",
			role: "Role",
			editedSuccessfully: "Profile edited successfully",
			recurrence: "Recurrence",
			recurrenceActive: "Enable Recurrence",
			recurrenceUntil: "Recurrent until",
			recurringEndMissing: "Recurrence end date is mandatory.",
			recurrenceSuccess: "Recurrence settings saved successfully.",
			deleteRecurrence: "Delete Recurrence",
			noThankYou: "No Thank You",
			recurrenceTooltip: "Create recurrence",
			deleteRecurrenceDescription: "Do you want to delete recurrence on {date}?",
			personalPoints: "Personal Points",
			pvPoints: "PV Points"
		},

		placeholders: {
			name: "Your name",
			email: "your@email.com",
			phoneNumber: "+351265000000",
			language: "Select a language option",
			role: "Select a role",
			partner: "Partner",
			currentPassword: "actual password",
			newPassword: "new password",
			confirmPassword: "new password",
			newEmail: "new@email.com",
			page: "page",
			titlePage: "Page Title",
			link: "https://www.google.com/"
		},

		staff: {
			owner: "Owner",
			owners: "Owners",
			admin: "Administrator",
			marketing: "Marketing",
			partner: "Partner",
			editedSuccessfully: "Staff edited successfully",
			passwordChangedSuccessfully: "Password changed successfully",
			emailChangedSuccessfully: "Email changed successfully",
			wrongPassword: "Wrong password",
			emailInUse: "Email in use",
			status: "Status",
			confirmed: "Confirmed",
			pending: "Pending",
			createStaff: "Create Staff",
			createdSuccessfully: "Staff member has been created successfully.",
			sysAdmin: "System Admin",
			addStaff: "Add a Staff Member",
			editStaff: "Edit Staff Member",
			addOwner: "Add an Owner",
			editOwner: "Edit Owner",
			user: "User",
			removeStaff: "Remove Staff",
			removed: "User has been removed as Staff sucessfully.",
			disableStaff: "Disable Staff Member",
			enableStaff: "Enable Staff Member",
			disabled: "Staff member disabled sucessfully.",
			enabled: "Staff member enabled sucessfully.",
			addNew: "Add new Staff"
		},

		staffLogs: {
			logTypes: {
				staff: "Staff",
				user: "User",
				product: "Product",
				order: "Order"
			},
			changeType: "Change Type"
		},

		crm: {
			single: "CRM",
			name: "Name",
			email: "Email",
			phone: "Phone",
			createdAt: "Created At",
			partner: "Partner",
			request: "Request",
			createCrm: "Create CRM",
			createdSuccessfully: "CRM member has been created successfully.",
			addCrm: "Add a CRM Member",
			editCrm: "Edit CRM Member",
			user: "User",
			removeCRM: "Remove CRM",
			removed: "User has been removed as CRM sucessfully.",
			disableCRM: "Disable CRM Member",
			enableCRM: "Enable CRM Member",
			disabled: "CRM member disabled sucessfully.",
			enabled: "CRM member enabled sucessfully.",
			addNew: "Add new CRM",
			created: "CRM has been created successfully.",
			updated: "CRM has been updated successfully.",
			deleted: "CRM has been deleted successfully.",
			nameMissing: "Name field is a mandatory field.",
			emailMissing: "Email field is a mandatory field.",
			phoneMissing: "Phone field is a mandatory field.",
			partnerMissing: "Partner field is a mandatory field.",
			requestMissing: "Request field is a mandatory field.",
			sendToAdmin: "Send to Admin",
			sendToPartner: "Sent to Partner",
			close: "Close CRM",
			open: "Reopen CRM"
		},

		ranges: {
			today: "Today",
			thisMonth: "This month",
			lastMonth: "Last month",
			thisYear: "This year",
			lastYear: "Last year"
		},

		dashboard: {
			period: "Period",
			active: "Active",
			homepage: "Homepage",
			inStock: "In Stock",
			daily: "Dialy",
			monthly: "Monthly",
			annually: "Annually",
			newUsers: "New Users",
			newUsersContent: "New Users Content",
			uses: "Uses",
			androidUse: "Android Usage",
			androidUseContent: "Android Usage Content",
			iOSUse: "iOS Usage",
			iOSUseContent: "iOS Usage Content",
			webUse: "Web Usage",
			webUseContent: "Web Usage Content",
			copyStoreLink: "Copy Store Link",
			storeLinkCopied: "Store Link Copied",
			yourStoreLink: "Your Store Link"
		},

		settings: {
			pages: "Pages",
			emailTemplates: "E-mail Settings",
			faqs: "FAQS",
			pagesSubTitle: "In this area you will be able to change the content of pages, like Terms & Condition and Privacy Policy",
			emailsSubTitle: "Setup email templates used to send emails to users or to manage staff invitation",
			faqSubTitle: "Frequently asked questions",
			appSettings: "App Settings",
			appSettingsTitle: "Change the general settings of your app",
			appConfigurations: "App Configurations",
			primaryColor: "Primary Color",
			gradientStart: "Gradient Color Start",
			gradientEnd: "Gradient Color End",
			pickColor: "Click to pick a color",
			imageColor: "Image Color",
			imageWhite: "Image White",
			splashImage: "Image to use on Splashscreen",
			register: "Allow User Registration",
			socialLogin: "Use Social Login",
			sentry: "App Sentry Link",
			storeLinkAndroid: "Android Store Link",
			storeLinkIos: "iOS Store Link",
			warningVersionsAndroid: "Android Warning Version",
			criticalVersionsAndroid: "Android Critical Version",
			warningVersionsIos: "iOS Warning Version",
			criticalVersionsIos: "iOS Critical Version",
			borderRadius: "Border Radius",
			shippings: "Carriers",
			addShipping: "Add carrier",
			shippingsSubTitle: "Turn on/off and setup providers to make payment available for partners",
			zones: "Zones Configuration",
			zonesSubTitle: "Setup the zones, regions and countries that will be available for partners",
			taxes: "Taxes Configuration",
			taxesSubTitle: "Setup the taxes that will be applied to clients",
			taxesTitle: "Taxes",
			addTax: "Add tax",
			editTax: "Edit tax",
			addZoneTax: "Add new",
			taxNameRequired: "Tax name is a mandatory field.",
			taxValueRequired: "Tax value is a mandatory field.",
			taxValueInvalid: "Tax value is invalid.",
			taxZoneRequired: "Tax zone is a mandatory field.",
			taxEdited: "Tax has been edited successfully.",
			taxAdded: "Tax has been added successfully.",
			taxDeleted: "Tax has been deleted successfully.",
			shipping: "Carrier",
			addShippingMethod: "Add shipping method",
			shippingMethods: "Shipping methods",
			shippingMethod: "Shipping method",
			newShipping: "New carrier",
			newShippingMethod: "New shipping method",
			pickMethod: "Pick a method",
			freeShipping: "Free shipping",
			freeShippingDescription:
				"Create free shipping, all of text information will be shown to the user in the checkout shipping method section",
			fixedShipping: "Fixed shipping",
			fixedShippingDescription:
				"Create a fixed rate shipping, all of text information will be shown to the user in the checkout shipping method section",
			customShipping: "Custom shipping",
			customShippingDescription:
				"Custom rate shipping, all of text information will be shown to the user in the checkout shipping method section",
			methodSettings: "Method Settings",
			nameShownClient: "This name is shown to the customer, have this in mind when you choose the name for this option.",
			descriptionShownClient:
				"The description is a detailed explanation to the customer to understand this shipping method and its specifications and in what",
			descriptionShownClientBold: "conditions you are enabling this discount, also the time the order might take to arrive.",
			pickZone: "Choose a zone or create a new one to set where this shipping should be applied.",
			manageZone: "Manage zones",
			minimumValue: "Minimum value",
			value: "Value",
			tableBasedOn: "Table based on",
			type: "Type",
			freeMethodValueTip: "When the customer reaches this value in checkout, this method will be available to the selected zone.",
			fixedMethodValueTip: "This is the price customers will pay regardless of the order value or weight.",
			subTotalInterval: "Subtotal interval",
			cost: "Cost",
			andAbove: "and above",
			andAboveInfo:
				"This is the price customers will pay when the subtotal is equal or above the previous value set. Click the text to set a max limit.",
			addInterval: "Add interval",
			subTotal: "Subtotal (€)",
			invalidValue: "Invalid value. It must be a number and greater than 0.",
			invalidIntervalValues: "Invalid interval values. The first value must be lower than the second one.",
			invalidCostValue: "Invalid cost value. It must be a number and greater than or equal to 0.",
			videos: "Videos",
			shownToPartnersLevel: "Shown to partners level",
			previewVideo: "Preview video",
			editFile: "Edit file",
			newVideo: "New file",
			filesSubTitle: "Setup the files and its levels to be shown for your partners according to their level",
			url: "URL",
			successLevel: "Success level",
			titleMandatory: "Title is a mandatory field.",
			urlMandatory: "URL is a mandatory field.",
			successLevelMandatory: "Success level is a mandatory field.",
			invalidSuccessLevel: "Invalid success level.",
			level: "Level",
			files: "Help Files",
			newFile: "New file",
			fileType: "File type",
			selectPdf: "Select PDF",
			file: "File",
			invalidUrl: "Invalid video URL.",
			viewFile: "View file",
			youtubeVideo: "Youtube video",
			noFiles: "No files have been added yet.",
			noFilesPartner: "No files available.",
			topic: "Topic",
			help: "Help",
			other: "Others",
			topicMandatory: "Topic is a mandatory field.",
			deleteFile: "Delete file",
			fileDeleted: "File has been deleted successfully.",
			noFaqsAvailable: "No FAQs available.",
			topicProduct: "Product information",
			topicBusiness: "Business Development",
			addFile: "Add",
			bonuses: "Bonuses Configuration",
			bonusesSubTitle: "Set up the bonuses that will be applied to partners",
			bonusesNotificationMessageSuccess: "Bonus was saved successfully.",
			bonusesNotificationMessageError: "An error occurred while saving the bonus.",
			successLadder: "Success Ladder Configuration",
			successLadderSubTitle: "Set up the success ladder requirements that will be applied to partners",
			manuals: "Manuals",
			manualsSubTitle: "Set up the manuals that will be available to partners",
			manualsFullTitle: "Policy and Procedure Manuals",
			viewManual: "View Manual",
			editManual: "Edit Manual",
			deleteManual: "Delete Manual",
			noManuals: "No manuals have been added yet.",
			addManual: "Add Manual",
			newManual: "New Manual",
			noManualsPartner: "No manuals available.",
			ethics: "Code of Ethics",
			ethicsFullTitle: "Code of Ethics",
			ethicsSubTitle: "Set up the code of ethics that will be available to partners",
			viewEthics: "View Code of Ethics",
			editEthics: "Edit Code of Ethics",
			deleteEthics: "Delete Code of Ethics",
			noEthics: "No code of ethics have been added yet.",
			addEthics: "Add Code of Ethics",
			newEthics: "New Code of Ethics",
			noEthicsPartner: "No code of ethics available.",
			incentives: "Extra Incentives",
			incentivesSubTitle: "Set up the incentives that your partners can win",
			trimestralBonus: "Trimestral Bonus",
			groupTrip: "Group Trip",
			addTrimestralIncentive: "Add Trimestral Bonus",
			editTrimestralIncentive: "Edit Trimestral Bonus",
			addGroupTripIncentive: "Add Group Trip",
			editGroupTripIncentive: "Edit Group Trip",
			noIncentives: "No incentives have been added yet."
		},

		pages: {
			single: "Page",
			title: "Title",
			content: "Content",
			new: "New Page",
			isLink: "Redirect"
		},

		templates: {
			single: "Template"
		},

		emails: {
			single: "E-mail",
			tags: "Tags",
			preview: "Preview",
			subject: "Subject",
			cc: "CC",
			templates: "E-mail Templates",
			invite: "Staff Invite",
			edited: "E-mail template saved successfully."
		},

		serverErrors: {
			title: "Internal Server Error",
			subTitle: "It's not you, it's us!",
			refresh: "Go Back to Previous Page",
			backToDashboard: "Go to Dashboard",
			wentWrong: "Something went wrong."
		},

		logs: {
			dateRange: "Date Range",
			userId: "User Id",
			url: "Url",
			token: "Token",
			codes: "Codes",
			methods: "Methods",
			sources: "Sources",
			types: "Types"
		},

		emailTemplates: {
			generalEmail: "Generic E-mail",
			accountConfirmationCode: "User Account Confirmation by Code",
			recoverPasswordCode: "User Password Recovery by Code",
			accountConfirmationLink: "User Account Confirmation by Link",
			recoverPasswordLink: "User Password Recovery by Link",
			reservationEmail: "User Reservation E-mail",
			staffAccountConfirmation: "Staff Account Confirmation for BO",
			staffRecoverPassword: "Staff Recover Password for BO",
			supportEmail: "Support E-mail",
			resetPassword: "Reset Password (BO)",
			generalCleanWithoutButton: "General Clean Without Button",
			generalCleanWithButton: "General Clean With Button",
			generalWithCode: "General With Code",
			confirmAccount: "Confirm Account (BO)",
			welcome: "Welcome E-mail",
			generalWithLink: "General With Link",
			changeEmailLink: "Change E-mail with Link",
			partnerAccountConfirmation: "Partner Account Confirmation",
			deactivateAccount: "Account deactivation",
			welcomePartner: "Partner Invite",
			orderConfirmation: "Order Confirmation"
		},

		notifications: {
			new: "New Notification",
			title: "Notification Title",
			message: "Notification Text",
			email: "Email Content",
			date: "Notification Scheduled Date",
			sendEmail: "Send E-mail",
			created: "Notification has been created successfully.",
			edited: "Notification has been edited successfully.",
			deleted: "Notification has been deleted successfully.",
			missingTitle: "Title is a mandatory field.",
			missingDescription: "Description is a mandatory field.",
			missingDate: "Scheduled Date is a mandatory field.",
			missingEmailContent: "Email content is a mandatory field.",
			notificationText: "Notification Text"
		},

		banners: {
			single: "Banner",
			addBanner: "Add New Banner",
			showLabels: "Show Labels",
			title: "Title",
			subTitle: "Subtitle",
			fullInfo: "Banner Information",
			webSettings: "Web Settings",
			appSettings: "App Settings",
			created: "Banner has been created successfully.",
			updated: "Banner has been updated successfully.",
			deleted: "Banner has been deleted successfully.",
			nameMissing: "Name field is a mandatory field.",
			titleMissing: "Title field is a mandatory field.",
			subTitleMissing: "Subtitle field is a mandatory field.",
			imageMissing: "Banner image is mandatory.",
			showMissing: "You need to enable banner is web or app.",
			shownWeb: "Shown in Web",
			shownApp: "Shown in App",
			showButton: "Show Button",
			buttonText: "Button Text",
			darkOverlay: "Dark filter",
			details: "Banner details"
		},

		highlightedSections: {
			single: "Highlighted Section",
			addHighlightedSection: "Add New highlighted section",
			title: "Title",
			subTitle: "Subtitle",
			fullInfo: "Highlighted Section Information",
			webSettings: "Web Settings",
			appSettings: "App Settings",
			created: "Highlighted section has been created successfully.",
			updated: "Highlighted section has been updated successfully.",
			deleted: "Highlighted section has been deleted successfully.",
			nameMissing: "Name field is a mandatory field.",
			titleMissing: "Title field is a mandatory field.",
			subTitleMissing: "Subtitle field is a mandatory field.",
			imageMissing: "Highlighted section image is mandatory.",
			showMissing: "You need to enable highlighted section is web or app.",
			shownWeb: "Shown in Web",
			shownApp: "Shown in App",
			showButton: "Show Button",
			buttonTitle: "Button Title",
			darkOverlay: "Dark filter",
			details: "Highlighted section details",
			descriptionMissing: "Description field is a mandatory field."
		},

		faqs: {
			single: "Faq",
			showLabels: "Show Labels",
			addFaq: "Add Faq",
			question: "Question",
			answer: "Answer",
			isActive: "IsActive",
			showMissing: "You need to enable faq in web or app.",
			nameMissing: "Name field is a mandatory field.",
			questionMissing: "Question field is a mandatory field.",
			answerMissing: "Answer field is a mandatory field.",
			created: "Faq has been created successfully.",
			updated: "Faq has been updated successfully.",
			deleted: "Faq has been deleted successfully."
		},

		segments: {
			single: "Segment",
			addSegment: "Add Segment",
			isActive: "IsActive",
			showOnHeader: "Show on header",
			showMissing: "You need to enable segment in web or app.",
			nameMissing: "Name field is a mandatory field.",
			created: "Segment has been created successfully.",
			updated: "Segment has been updated successfully.",
			deleted: "Segment has been deleted successfully.",
			new: "New Segment"
		},

		categories: {
			single: "Category",
			addCategoryt: "Add category",
			isActive: "IsActive",
			showMissing: "You need to enable category in web or app.",
			nameMissing: "Name field is a mandatory field.",
			created: "Category has been created successfully.",
			updated: "Category has been updated successfully.",
			deleted: "Category has been deleted successfully.",
			category: "Category information",
			addSubCategory: "Add sub category",
			editSubCategory: "Edit sub category",
			subCategories: "Sub categories",
			nameRequired: "Name is a mandatory field.",
			subCategoryCreated: "Sub category has been created successfully."
		},

		users: {
			header: "Customer",
			fullInfo: "Customer Information",
			fullPartnerInfo: "Partner's Information",
			fullSpouseInfo: "Spouse's Information",
			addUser: "Add customer",
			editUser: "Edit customer",
			title: "Customers",
			kickTitle: "Blocked customer",
			solveKick: "Kick solved",
			session: "Session",
			reason: "Reason",
			deleted: "Customer has been deleted successfully.",
			updated: "Customer has been updated successfully.",
			noImage: "Customer has no image",
			addresses: "Addresses",
			editAddress: "Edit Address",
			addAddress: "Add Address",
			noAddresses: "Customer has no addresses",
			nameRequired: "Name is a mandatory field.",
			fullNameRequired: "Full Name is a mandatory field.",
			birthdayRequired: "Birthday is a mandatory field.",
			emailRequired: "E-mail is a mandatory field.",
			phoneRequired: "Phone is a mandatory field.",
			ibanInvalid: "IBAN is invalid.",
			vatRequired: "VAT is a mandatory field.",
			emailInvalid: "E-mail is invalid.",
			addressRequired: "Address is a mandatory field.",
			cityRequired: "City is a mandatory field.",
			countryRequired: "Country is a mandatory field.",
			stateRequired: "State is a mandatory field.",
			zipRequired: "Zip is a mandatory field.",
			isDefaultBilling: "Default Billing Address",
			isDefaultShipping: "Default Shipping Address",
			clients: "Customers",
			addPartner: "Add Partner",
			partnerNoImage: "Partner has no image",
			partnerCode: "Partner Code",
			partnerCodeInvalid: "Partner code is invalid. It must contain only characters and numbers.",
			partnerTree: "Partner Tree",
			isDefault: "Default Address",
			underBy: "Under by",
			referredBy: "Referred by",
			placementSide: "Placement Side",
			redeemed: "Redeemed",
			redeemedOn: "Redeemed on",
			noCommissions: "No commissions available.",
			noBonus: "No bonus available.",
			wallet: "Wallet",
			commission: "Commission",
			status: "Status",
			selectPartner: "Select a partner",
			leftCarry: "Left Carry",
			rightCarry: "Right Carry",
			personalPV: "Personal PV",
			groupPV: "Group PV",
			badges: {
				specialExecutive: "Special Executive",
				bronze: "Bronze Visionary",
				silver: "Silver Visionary",
				gold: "Gold Visitionary",
				gold30: "Gold 30",
				gold60: "Gold 60",
				gold100: "Gold 100",
				ruby: "Ruby Director",
				emerald: "Emerald Director",
				diamond: "Diamond Director",
				diamond2: "Diamond 2",
				diamond4: "Diamond 4",
				diamond6: "Diamond 6",
				diamond8: "Diamond 8",
				specialDiamond: "Special Diamond"
			},
			userCode: "User Code",
			personalPurchases: "Personal Purchases",
			personalVolume: "Personal Volume",
			personalVolumeTotal: "Personal Total Volume",
			leftGroupVolume: "Left Group Volume",
			rightGroupVolume: "Right Group Volume",
			leftSpillover: "Spillover Left",
			rightSpillover: "Spillover Right",
			leftTotalGroupVolume: "Left Total Group Volume",
			rightTotalGroupVolume: "Right Total Group Volume",
			allowedTgv: "Total Group Volume Available",
			noPartnersFound: "No partners found.",
			referredByRequired: "Referred by is a mandatory field.",
			vatNumberRequired: "VAT Number is a mandatory field.",
			pleaseSelectSide: "Please select a side for partner placement.",
			associatedPartner: "Associated Partner",
			balance: "Balance",
			partnerStatus: "Partner Status",
			suspend: "Suspend Partner",
			unSuspend: "Unsuspend Partner",
			suspended: "Account Suspended",
			id: "ID",
			personalVolumeAccumulated: "Personal Volume Accumulated",
			adquiredLevel: "Adquired Level",
			newPartner: "New Partner",
			newPartners: "New Partners",
			totalNewPartners: "Total new partners",
			workingBinaryPartners: "N.º of active partners",
			totalPartners: "N.º of inactive partners",
			partnerRanking: "Partner success level",
			pendingRegistration: "Pending Registration",
			pendingRegistrationMessage: "Your parnter registration is pending. Please wait for the admin to approve your order payment.",
			suspendedMessage1:
				"You are no longer an Authorized Partner of Essência da Vida, because you have not met the minimum requirements in the last months to remain as such.",
			suspendedMessage2:
				"If you wish to return to work with our Company and Products, with the same or another sponsor, make a new registration.",
			suspendedMessage3: "For more information, contact us at {tel} or by email, {email}."
		},

		zones: {
			title: "Zones",
			zoneName: "Zone name",
			addZone: "Add zone",
			editZone: "Edit zone",
			addANewZoneFirst: "Add a new zone first",
			deleted: "Zone has been deleted successfully.",
			updated: "Zone has been updated successfully.",
			addSelection: "Add selection",
			continent: "Continent",
			selectAContinent: "Select a continent",
			addCountries: "Add countries",
			byContinent: "By continent",
			zonesHelper: "Take into account that the name of the zone will be shown to your partners.",
			zonesList: "Zones list",
			zonesListHelper: "Select the zone you want to edit or create a new one.",
			customZone: "Custom zone",
			fillCountry: "Please pick atleast one country",
			newZoneName: "New zone name",
			selectACountry: "Please select a country",
			allStatesSelected: "All states are selected",
			other: "Other zones",
			zone: "Zone"
		},

		bonuses: {
			bonusProductsTitle: "1st Product Acquisition Discount",
			bonusProductsHeader1: "Position",
			bonusProductsHeader2: "Product Acquisition Discount",
			bonusRecruitmentTitle: "2nd Recruitment Bonus",
			bonusRecruitmentBasic: "Basic Kit Bonus",
			bonusRecruitmentSuperior: "Superior Kit Bonus",
			bonusRecruitmentHeader1: "Bonus",
			bonusRecruitmentHeader2: "Points",
			bonusRepassTitle: "3rd Repass Bonus on Junior Executives",
			bonusRepassHeader1: "Success Ladder Position",
			bonusRepassHeader2: "Percentage",
			bonusPerformanceTitle: "4th Annual Performance Bonus",
			bonusPerformanceHeader1: "Monthly Average",
			bonusPerformanceHeader2: "Annual Total",
			bonusPerformanceHeader3: "Bonus",
			bonusPerformanceButton: "Add Bonus",
			bonusBinaryTitle: "5th Binary Production Bonus",
			bonusBinaryString1:
				"If you qualify as at least a Special Executive Active, and when one of the left or right teams accumulates",
			bonusBinaryString2: "and the other accumulates",
			bonusBinaryString3: ", 1 cycle is closed, and the company pays",
			bonusBinaryString4: "for each cycle.",
			bonusDepthTitle: "6th Depth Bonus",
			bonusDepthHeader1: "Level",
			bonusDepthHeader2: "Percentage",
			bonusDepthButton: "Add Level",
			bonusDiamondTitle: "7th Diamond Prize",
			bonusDiamondMinimumPoints: "Minimum Points Number",
			bonusDiamondHeader1: "Condition",
			bonusDiamondHeader2: "Points",
			bonusSpecialTitle: "8th Special Reward",
			bonusSpecialHeader1: "Qualifies as",
			bonusSpecialHeader2: "Earns",
			bonusExtraIncentiveTitle: "Extra Incentive",
			bonusExtraIncentiveHeader1: "Personal Total Volume",
			bonusExtraIncentiveHeader2: "Bonus",
			bonusExtraIncentiveMoney: "Money",
			bonusExtraIncentivePrize: "Prize",
			bonusTripGroupTitle: "Trip Group",
			bonusTripGroupHeader1: "Monthly Average",
			bonusTripGroupHeader2: "Annual Total",
			bonusTripGroupHeader3: "Reward",
			bonusesNotificationMessageSuccess: "Bonus changed successfully.",
			bonusesNotificationMessageError: "An error occurred while changing the bonus.",
			keyClient: "Client",
			keyJunior: "Junior",
			keyExecutive: "Executive",
			keySpecialExecutive: "Special Executive and higher positions",
			keyDirector: "First time qualifying as Diamond Director",
			keyCycles: "For every 1000 cycles achieved in the binary production bonus during the quarter",
			keyTrimester: "For each personally sponsored member paid as Diamond or higher, in any of the months during that period.",
			keyUnderDiamond2: "For each month paid as Diamond 2 or higher.",
			level: "Level",
			bonusExtraIncentiveTpvError: "Personal Total Volume is empty or invalid.",
			bonusExtraIncentiveTypeError: "Type is a mandatory field.",
			bonusExtraIncentivePrizeError: "Prize field is empty or invalid.",
			bonusTripGroupAverageError: "Monthly Average is empty or invalid.",
			bonusTripGroupAnnualError: "Annual Total is empty or invalid.",
			bonusTripGroupPrizeError: "Prize field is empty or invalid."
		},

		successLadder: {
			qualification: "Qualification",
			monthlyMinimum: "Monthly Minimum",
			qualificationAndMonthlyMinimum: "Qualification and Monthly Minimum",
			or: "OR",
			have: "Have at least",
			activeSpecialExecutives: "active direct Special Executives with at at least",
			activeSpecialExecutivesMaintenance: "active direct Special Executives with at least",
			activeExecutives: "active direct Executives with at least",
			activeExecutivesMaintenance: "active direct Executives with at least",
			eachBinaryLeg: "in each binary leg.",
			accumulateMin: "Accumulate at least",
			keepActive: "Keep active at least",
			specialExecutives1MinOr: "direct Special Executives (minimum 1 in each leg) or",
			specialExecutives2MinOr: "direct Special Executives (minimum 2 in each leg) or",
			executives3Min: "direct Executives (minimum 3 in each leg).",
			specialExecutives3Min: "direct Special Executives (minimum 3 in each leg).",
			accumulate: "Accumulate",
			ciclesBonusBinary: "cycles in the binary production bonus.",
			sponsorshipGold: "sponsorship lines, with one paid member as Gold Visionary in each line.",
			sponsorshipDiamond: "sponsorship lines, each with a Diamond, in the first 7 levels.",
			sgv: "of Sponsored Group Volume (SGV). For each sponsorship line, a maximum of",
			sgv2: "SGV points will be counted.",
			executivesActiveLine: "Active Executives in all their sponsorship lines.",
			minimumPoints: "Minimum Points",
			minimumPointsDescription: "Minimum points to qualify for all ladder potions.",
			havePvPoints: "Have at least",
			pvPoints: "PV points",
			cpPoints: "and CP points",
			messageSuccess: "Success ladder changed successfully.",
			messageError: "An error occurred while changing the success ladder."
		},

		products: {
			title: "Products",
			catalog: "Catalog",
			categories: "Categories",
			segments: "Segments",
			addCategory: "Add category",
			editCategory: "Edit category",
			categoryUpdated: "Category has been updated successfully.",
			categoryDeleted: "Category has been deleted successfully.",
			reference: "Reference",
			ean: "EAN",
			price: "Price",
			specialPrice: "Special Price",
			points: "Points",
			pointsPersonal: "Personal Points",
			pointsShort: "Pts",
			inStock: "In stock",
			stock: "Stock",
			updated: "Product has been updated successfully.",
			deleted: "Product has been deleted successfully.",
			details: "Product details",
			new: "New product",
			information: "Product information",
			segmentation: "Segmentation",
			ingredients: "Ingredients",
			howToUse: "How to use",
			howToStore: "How to store",
			notes: "Notes",
			comments: "Comments",
			showComment: "Show comment",
			hideComment: "Hide comment",
			commentUpdated: "Comment has been updated successfully.",
			commentDeleted: "Comment has been deleted successfully.",
			saved: "Product has been saved successfully.",
			toDelete: "to delete",
			nameRequired: "Name is a mandatory field.",
			descriptionRequired: "Description is a mandatory field.",
			referenceRequired: "Reference is a mandatory field.",
			eanRequired: "EAN is a mandatory field.",
			priceInvalid: "Price is invalid.",
			cannotDeleteFavoriteImage: "Cannot delete favorite image. Please change favorite image first.",
			provenBy: "Proven by",
			stockInvalid: "Stock value is invalid.",
			addProduct: "Add product",
			noResults: "No products found.",
			waitingForInput: "Waiting for input...",
			searching: "Searching...",
			composition: "Composition",
			activePrinciples: "Active principles",
			presentation: "Presentation",
			bibliographic: "Bibliographic references",
			component: "Component",
			dailyValue: "Daily Quantity (adult)",
			dailyValueChildren: "Daily Quantity (children)",
			nrv: "% NRV",
			nrvChildren: "% NRV (children)",
			addComponent: "Add component",
			editComponent: "Edit component",
			componentNameMandatory: "Component name is a mandatory field.",
			componentDailyValueMandatory: "Component daily value is a mandatory field.",
			reviews: "Reviews",
			review: "Review",
			suggestedProducts: "Suggested products",
			categoryRequired: "Category is a mandatory field.",
			favoriteDescription: "Define as favorite image",
			catalogDescription: "Define as catalog image",
			deleteDescription: "Delete image",
			labels: "Labels",
			labelBlood: "Blood",
			labelBones: "Bones",
			labelBrain: "Brain Function",
			labelCelular: "Cell Protection",
			labelEnergy: "Energy",
			labelFertility: "Fertility",
			labelGlucose: "Blood Glucose",
			labelHair: "Hair",
			labelHeart: "Heart",
			labelHepatic: "Liver Function",
			labelImmunity: "Immunity",
			labelMental: "Mental Performance",
			labelMetabolism: "Metabolism",
			labelMuscles: "Muscle Functioning",
			labelNails: "Nails",
			labelNervous: "Nervous System",
			labelSkin: "Skin",
			labelSleep: "Fall Asleep",
			labelStomach: "Stomach",
			labelTeeth: "Teeth",
			labelTestosterone: "Testosterone",
			labelThyroid: "Thyroid",
			labelVision: "Vision",
			taxRequired: "Tax is a mandatory field.",
			qtyOrdered: "Qty ordered",
			qtyShipped: "Qty shipped",
			qtyDelivered: "Qty delivered",
			qtyRefunded: "Qty refunded",
			editReview: "Edit review",
			showCommentInHome: "Show in home",
			isKit: "Partner Kit",
			junior: "Junior Executive",
			executive: "Executive",
			merchandising: "Merchandising",
			barcode: "Barcode",
			weight: "Weight"
		},

		table: {
			areYouSure: "Are you sure?",
			delete: "Delete",
			cancel: "Cancel"
		},

		orders: {
			addOrder: "Add order",
			orderNumber: "Order number",
			paymentStatus: "Payment status",
			shippingStatus: "Shipping status",
			paymentStatuses: {
				waiting_payment: "Waiting payment",
				failed_payment: "Failed payment",
				pending_payment: "Pending payment",
				paid: "Paid",
				cancelled: "Cancelled",
				partially_refunded: "Partially refunded",
				refunded: "Refunded"
			},
			shippingStatuses: {
				pending_processing: "Pending processing",
				processing: "Processing",
				partially_shipped: "Partially shipped",
				shipped: "Shipped",
				delivered: "Delivered",
				returned: "Returned",
				partially_returned: "Partially returned",
				cancelled: "Cancelled",
				partially_pickedup: "Partially picked up",
				awaiting_pickup: "Awaiting pickup",
				partially_created: "Partially created",
				pickup_created: "Pickup created",
				closed: "Closed"
			},
			newOrder: "New order",
			orderDetail: "Order detail",
			close: "Close order",
			clientInformation: "Client information",
			date: "Order date",
			addresses: "Addresses",
			shippingAddress: "Shipping address",
			billingAddress: "Billing address",
			shippingAndPayment: "Shipping and payment",
			paymentMethod: "Payment method",
			bankTransfer: "Bank transfer",
			creditCard: "Credit card",
			cashOnDelivery: "Cash on delivery",
			resume: "Order resume",
			subtotal: "Subtotal",
			shippingCost: "Shipping cost",
			taxes: "Taxes",
			productList: "Product list",
			searchClientLabel: "Search for a client",
			searchClient: "Search for name or email",
			orderAddresses: "Order addresses",
			selectClientFirst: "Select a client first",
			addAddress: "Add new address",
			addProducts: "Add products",
			productListEmpty: "Product list is empty",
			searchProduct: "Search for a product",
			orderProcessing: "Order processing",
			selectProductsFirst: "Select products first",
			shippingMethod: "Shipping method",
			addProduct: "Add",
			outOfStock: "Out of stock",
			free: "Free",
			cashOnDeliveryDescription: "Payment will be made on delivery.",
			bankTransferDescription: "Payment will be made by bank transfer and the order will be processed after payment confirmation.",
			invalidOrder: "Order is invalid. Please select all required fields.",
			totalQuantity: "Total quantity",
			taxVat: "VAT {0}% (included)",
			reopen: "Reopen order",
			shipments: "Shipments",
			prepareShipment: "Prepare shipment",
			noShipments: "No shipments have been created yet.",
			pricePerItem: "Price per item",
			objectNumber: "Object number",
			createShipping: "Create shipment",
			shippingProviderRequired: "Shipping provider is a mandatory field.",
			shippingProductsRequired: "You need to select at least one product.",
			shippingsEmptyFields: "You need to fill all required fields.",
			pickupCreated: "Pickup created",
			cancelled: "Cancelled",
			delivered: "Delivered",
			shipped: "Shipped",
			returned: "Returned",
			submitOrder: "Submit order",
			becomeDistributor: "Become a distributor of Essência da Vida",
			partnerKit: "Partner Kit",
			selectShippingAddressFirst: "Select a shipping address first",
			mbwayDescription: "Enter the phone number associated with your MB Way account.",
			expires: "Expires on",
			noCreditCards: "No credit cards have been added yet.",
			addCreditCard: "Add credit card",
			paymentMethodsInvalidCardNumber: "Invalid card number.",
			paymentMethodsInvalidCVC: "Invalid CVC.",
			paymentMethodsInvalidExpiry: "Invalid expiry date. Expiry date must be posterior to the current date and in the format YYYY.",
			cardEndingWith: "Credit card ending with",
			sales: "Sales",
			confirmation: "Confirmation",
			confirmOrder: "You need to accept the terms and conditions to confirm the order.",
			iConfirm: "I confirm that I have read and accept the terms and conditions and I want to proceed with the order.",
			status: "Order status",
			closed: "Closed",
			pending: "Pending",
			cash: "Cash",
			value: "Order value"
		},

		kits: {
			description:
				"To complete your registration as our distributor, you must choose one of the available kits, each with a set of articles designed to help you get to know the world of opportunities of Essência da Vida.",
			kitLevelRequired: "Kit level is a mandatory field."
		},

		commissions: {
			cp: "Personal Purchases",
			pv: "Personal Volume",
			tpv: "Total Personal Volume",
			leftSgv: "Left Total Referred Group Volume",
			rightSgv: "Right Group Referred Total Volume",
			leftSpillover: "Left Spillover",
			rightSpillover: "Right Spillover",
			leftTgv: "Left Total Group Volume",
			rightTgv: "Right Total Group Volume",
			tgv: "Total Group Volume",
			annualTgv: "Annual Total Group Volume",
			annualCp: "Annual Personal Volume",
			annualPv: "Annual Personal Volume",
			annualTpv: "Annual Total Personal Volume",
			redeemedOn: "Redeemed on",
			expired: "Expired",
			redeemedValue: "Commissions redeemed value",
			redeemedPoints: "Redeemed points",
			pendingValue: "Commissions pending value",
			pendingPoints: "Pending points",
			expiredValue: "Commissions expired value",
			expiredPoints: "Expired points",
			usedBalance: "Used balance",
			sales: "Sales Commissions",
			newPartners: "New Partners",
			repass: "Repass Bonus",
			annual: "Annual Performance Bonus",
			binary: "Binary Production Bonus",
			depth: "Depth Bonus",
			diamond: "Diamond Prize",
			special: "Special Reward",
			incentives: "Incentives",
			adjuncts: "Adjuncts",
			value: "Commission value",
			withThisBonus: "With this bonus you've earned",
			accumulatedTpv: "Accumulated TPV",
			progress: "Current progress",
			totalCycles: "Total cycles",
			cycles: "Cycles",
			earned: "Amount earned in this month",
			eligibility: "Eligibility",
			notEligible:
				"To benefit from this bonus, you must be at least in the position of Special Executive. For more information, see the Success Ladder in the help section.",
			notEligibleJunior:
				"To benefit from this bonus, you must be at least in the position of Executive. For more information, see the Success Ladder in the help section.",
			notEligibleSpecial:
				"To benefit from this bonus, you must be at least in the position of Diamond 4. For more information, see the Success Ladder in the help section.",
			notEligibleBronze:
				"To benefit from this bonus, you must be at least in the position of Bronze Visionary. For more information, see the Success Ladder in the help section.",
			progressTrip: "Current group trip progress",
			progressAdditional: "Current progress for additional incentives",
			createNew: "Create new commission",
			totalTimesDiamond: "Total times achieved Diamond",
			totalMonthsAsDiamond: "Total months as Diamond 4 or higher",
			totalAmountEarned: "Total amount earned",
			specialDescription:
				"This bonus is awarded to partners who have achieved the position of Diamond 4 or higher and only once. For more information, see the Compensation plan in the help section.",
			actualRank: "Actual rank",
			earnedFromClients: "You've earned from clients",
			earnedFromNewPartners: "You've earned from new partners",
			earnedFromRepass: "You've earned from repass"
		},

		wallet: {
			available: "Balance Available",
			spent: "Balance Spent",
			expired: "Balance Expired",
			movements: "Movements",
			balanceUsed: "Used balance in order",
			expiredCommission: "Expired commission",
			redeemFrom: "Commission redeemed from ${0}",
			wallets: "Wallets",
			noPartnerSelected: "No partner selected.",
			noMovements: "No movements available.",
			adjustment: "Adjustment of balance by administrator",
			earned: "Earned",
			totalBilled: "Total billed",
			monthlyControl: "Monthly control",
			earnedPeriod: "Earned in this period: {0}"
		}
	},
	/* cSpell.language:pt,pt_PT */
	pt: {
		// Generic
		generic: {
			askToSave: "Deseja guardar as alterações antes de sair da página?",
			edit: "Editar",
			done: "Concluir",
			accept: "Aceitar",
			cancel: "Cancelar",
			remove: "Remover",
			forget: "Esquecer cliente",
			delete: "Eliminar",
			success: "Sucesso",
			active: "Ativar",
			expand: "Expandir",
			toggle: "Toggle",
			save: "Guardar",
			yes: "Sim",
			no: "Não",
			apply: "Aplicar",
			item: "Artigo",
			items: "Artigos",
			submit: "Submeter",
			continue: "Continuar",
			confirm: "Confirmar",
			finish: "Terminar",
			back: "Voltar",
			oops: "Oops",
			error: "Erro",
			retry: "Tentar novamente",
			reset: "Reset",
			search: "Pesquisar",
			noResults: "Sem Resultados",
			searchByEmailOrName: "Pesquisar por email ou nome",
			searchPartner: "Nome/código do parceiro...",
			clickHereTo: "Clique para {0}",
			requiredField: "{0} é um campo obrigatório",
			duplicateEmail: "Este email já está a ser utilizado. Por favor utilize outro email.",
			emptyList: "Sem {0} na lista",
			leavePage: "Sair da Página?",
			leave: "Sair",
			stay: "Ficar",
			open: "Abrir",
			close: "Fechar",
			change: "Mudar",
			free: "Grátis",
			send: "Enviar",
			selected: "Selecionado",
			selecteds: "Selecionados",
			swalEditTitle: "Cancelar Alteração?",
			swalEditDescription: "Cancelar edição vai apagar toda a informação não guardada",
			leaveDescription: "Ao sair desta página vai apagar toda a informação não guardada",
			swalDeleteTitle: "Apagar {0}?",
			swalDeleteDescription: "Ao sair desta página vai apagar todos os {0} na sua lista",
			genericSelect: "Selecionar {0}",
			select: "Selecionar",
			addGeneric: "Adicionar {0}",
			moreOptions: "Mais Opções",
			all: "Tudo",
			enable: "Ativar",
			newF: "Nova",
			newM: "Novo",
			addNewF: "Adicionar nova {0}",
			addNewM: "Adicionar novo {0}",
			default: "Pré-Definido",
			editGeneric: "Editar {0}",
			infinite: "Infinito",
			details: "Detalhes",
			noItemsSelected: "Nenhum Item Selecionado",
			clearList: "Limpar lista",
			loading: "A Carregar...",
			view: "Ver",
			actions: "Ações",
			invalidUrl: "URL inválido. Por favor remova o prefixo HTTP.",
			shop: "Loja",
			noEntries: "Sem Valores",
			chatWalktroughText1:
				"Adicionámos diversas FAQ que podem ajudar a conhecer e entender como funciona o produto Shop2Grow. Essas FAQ estão disponíveis no ícone de chat no canto inferior direito do ecrã.",
			chatWalktroughText2:
				"Estamos também disponíveis, através de chat, para ajudar na melhor maneira de criar e definir a sua loja.",
			skip: "Saltar Walkthrough",
			walkTrough: "Walkthrough",
			clone: "Clonar",
			download: "Download",
			upload: "Upload",
			template: "Download Template",
			export: "Exportar",
			import: "Importar",
			defaultItem: "um item",
			defaultItemRecipient: "este/esta {0}",
			demo: "Demo",
			previewOnly: "Apenas Preview",
			selectPaymentMethod: "Por favor, selecione um método de pagamento antes de atualizar a sua subscrição.",
			basic: "Básico",
			advanced: "Avançado",
			editMode: "Modo de Edição",
			print: "Imprimir",
			code: "Codigo",
			filters: "Filtros",
			changeImage: "Clique para adicionar uma imagem ou arraste o ficheiro para esta caixa.",
			changeImages: "Clique para adicionar imagens ou arraste os ficheiros para esta caixa.",
			userImage: `Este utilizador ainda não tem imagem`,
			changeVideo: "Clique para adicionar um ficheiro de video ou arraste o ficheiro para esta caixa.",
			changeYoutubeVideo: "Insira um link de youtube",
			changeAudio: "Clique para adicionar um ficheiro de áudio ou arraste o ficheiro para esta caixa.",
			everyF: "Todas",
			uploadImages: "Drop images here or click to select files to upload",
			uploadVideos: "Drop animations here or click to select files to upload",
			none: "Nenhum",
			clickOrDrag: "Clique ou arraste ficheiros para esta área",
			singleOrBulk: "Suporte para um ou mais ficheiros ao mesmo tempo.",
			processingImages: "A processar imagens",
			preview: "Pre-visualizar",
			every: "Todos",
			seoMissing: "SEO é um campo de preenchimento obrigatório",
			visitStore: "Visitar Loja",
			storeLink: "Link da Loja",
			placeholder: "Placeholder",
			left: "Esquerda",
			right: "Direita",
			redeem: "Resgatar",
			bonus: "Bónus",
			from: "Origem",
			bonuses: "Bónus",
			destiny: "Destino",
			expirationDate: "Expira a",
			copy: "Copiar",
			opened: "Aberto",
			closed: "Fechado",
			searchMinimumChars: "Por favor insira pelo menos 3 caracteres",
			searchEmail: "Pesquisar por email",
			inactive: "Inativo"
		},

		fields: {
			name: "Nome",
			firstName: "Primeiro Nome",
			lastName: "Último Nome",
			fullName: "Nome Completo",
			centername: "Nome de Centro",
			address: "Morada",
			address2: "Morada 2",
			age: "Idade",
			birthday: "Data de Nascimento",
			nif: "NIF",
			addresses: "Endereços",
			country: "País",
			state: "Estado / Distrito",
			city: "Cidade",
			zipCode: "Código Postal",
			description: "Descrição",
			phone: "Telefone",
			phoneNumber: "Número de Telefone",
			phoneShort: "Tel.:",
			mobilePhone: "Telemóvel",
			email: "Email",
			request: "Pedido",
			createdAt: "Criado em",
			partner: "Parceiro",
			seoUrl: "SEO Url",
			tags: "Tags",
			ccNumber: "Número do CC",
			billingAddress: "Endereço de Faturação",
			shippingAddress: "Endereço de Envio",
			vat: "VAT",
			vatNumber: "N.º Contribuinte",
			password: "Password",
			message: "Mensagem",
			image: "Imagem",
			images: "Imagens",
			imgPlaceholder: "Tente arrastar imagens para aqui, ou clique para fazer upload",
			filesPlaceholder: "Tente arrastar o ficheiro para aqui ou clique para selecionar o ficheiro",
			subTotal: "Subtotal",
			total: "Total",
			quantity: "Quantidade",
			ordered: "Qtd. Encomendada",
			totalQuantity: "Quantidade Total",
			discount: "Desconto",
			registerDate: "Data Registo",
			invalidEmail: "Email não é valido",
			tax: "Taxa",
			addedOn: "Adicionado",
			startDate: "Data Inicial",
			endDate: "Data Final",
			startHour: "Hora Inicial",
			endHour: "Hora Final",
			filename: "Nome do Ficheiro",
			gender: "Género",
			waitingForResults: "À espera do nome ou SKU...",
			link: "Link",
			date: "Data",
			otherRegions: "Outras Zonas",
			taxValues: "Taxas",
			previewLink: "Link de Pré-Visualização",
			clickToUpload: "Clique para fazer o Upload",
			photo: "Foto",
			autor: "Autor",
			category: "Categoria",
			question: "Questão",
			answer: "Resposta",
			exclusivePartners: "Exclusivo Parceiros",
			exclusiveClients: "Exclusivo Clientes",
			pickOnStore: "Levantamento em Loja",
			recipient: "Entidade",

			// Select
			selectCountry: "Selecionar País",
			selectState: "Selecionar Estado / Distrito",
			selectFilters: "Selecionar Filtros",
			selectDiscount: "Selecionar Desconto",

			countries: "Países",
			states: "Estados",
			selectCountryFirst: "Por favor selecione um país primeiro",

			// Pairs
			valuePair: "{0} / {1}",
			placeHolderPair: "{0} => {1}",

			code: "Abreviatura",
			bccEmail: "Email para receber encomendas",
			article: "Artigo",

			logo: "Logo",
			gif: "GIF",
			iban: "IBAN",
			weight: "Peso (kg)",
			list: "Lista",
			edit: "Editar",
			cardName: "Nome do Titular",
			cardNumber: "Número do Cartão",
			month: "Mês",
			year: "Ano",
			cvc: "CVC"
		},

		language: {
			header: "Idioma",
			defaultLanguage: "Idioma Padrão",
			languageNotActive: "Guarde as atualizações antes de proceder à alteração do idioma padrão.",
			portuguese: "Português",
			english: "Inglês",
			german: "Alemão",
			french: "Francês",
			spanish: "Espanhol"
		},

		sidebar: {
			dashboard: "Dashboard",
			crm: "CRM",
			profile: "Perfil",
			help: "Ajuda",
			information: "Informação",
			staff: "Staff",
			staffLogs: "Staff Logs",
			settings: "Definições",
			logs: "Logs",
			users: "Clientes",
			marketing: "Marketing",
			highlightedSections: "Destaques",
			banners: "Banners",
			faqs: "Faqs",
			segments: "Segmentos",
			notifications: "Notificações",
			partners: "Parceiros",
			categories: "Categorias",
			tree: "Árvore de Parceiros",
			orders: "Encomendas",
			commissions: "Comissões",
			partnerTrees: "Árvores de Parceiros",
			geoTree: "Árvore Genealógica",
			referralTree: "Árvore Patrocinadora",
			bonuses: "Bónus",
			successLadder: "Escada do Sucesso",
			commissionsAndBonuses: "Comissões e Bónus",
			wallet: "Carteira"
		},

		header: {
			daysLeft: "dias restantes de trial",
			upgrade: "Upgrade",
			account: "Conta",
			maintenance: "Manutenção",
			visitStore: "Visitar Loja",
			deleteSuccess: "Dados Temporários apagados com sucesso",
			extend: "Aumentar Trial"
		},

		// Screens
		authentication: {
			welcome: "Bem-vindo",
			header: "Autenticação",
			register: "Registo",
			login: "Entrar",
			logout: "Sair",
			signIn: "Autenticar",
			confirmAccount: "Confirmar Conta",
			name: "Nome",
			namePlaceholder: "O seu nome",
			email: "Email",
			emailPlaceholder: "O seu email",
			password: "Password",
			passwordPlaceholder: "A sua password",
			newPassword: "Nova password",
			newPasswordPlaceholder: "Inserir nova password",
			confirmPassword: "Confirmar Password",
			confirmPasswordPlaceholder: "Repetir a password",
			forgotPassword: "Esqueceu-se da password?",
			loginFooter: "Não tem conta? Registe-se aqui",
			registerFooter: "Já tem conta? Autentique-se aqui.",
			resendCode1: "Não recebeu o seu código de confirmação? ",
			resendCode2: "Reenviar código de ativação",
			sendEmail: "Enviar Email",
			recoverPassword: "Recuperar password",
			changePassword: "Mudar Password",
			changeEmail: "Mudar Email",
			newEmail: "Novo Email",
			newEmailConfirm: "Confirmar Novo Email",
			repeatEmail: "Repetir Email",
			currentEmail: "Email Atual",
			currentPassword: "Password Atual",
			emailSent: "Email Enviado com Sucesso",
			repeatPassword: "Repetir Password",
			rememberedPassword: "Lembrou-se da password?",
			adminPassword: "Password do Administrador",
			notLoggedIn: "Necessita de estar autenticado para ter acesso a esta página",
			noMatchPassword: "As passwords não coincidem",
			acceptSubtitle: "Por favor insira uma password para confirmar a sua conta.",
			confirmInvite: "Por favor insira a sua password para aceitar o convite.",
			goBackToLogin: "Ir para o ecrã de início de sessão",
			resendInviteEmail: "Reenviar email de convite",
			confirmResendInviteEmail: "Reenviar email de convite para {0}?",
			forgetTitle:
				"Tem a certeza que deseja esquecer o utilizador {0}? Esta ação irá deixar o utilizar sem acesso à app, e todos os dados serão removidos.",
			forgetConfirm: "Forget customer",
			enterCode: "Introduza o código authy"
		},

		acceptInvite: {
			header: "Aceitar Convite",
			title: "Olá {0}, {1} convidou-o(a) a pertencer a {2}",
			title2: "Olá {0}, {1} convidou-o(a) para gerir {2}.",
			subTitle: "Insira uma password para confirmar a sua conta",
			expired: "Este convite expirou"
		},

		notFound: {
			header: "Página Inexistente"
		},

		importModal: {
			fileName: "Nome do Ficheiro",
			size: "Tamanho",
			import: "Importar"
		},

		errors: {
			invalidArgs: "Argumentos inválidos ou em falta",
			invalidFields: "Campos inválidos ou em falta",
			emailIsInvalid: "O formato de email inserido não é válido",
			fillEmail: "Insira um email por favor",
			fillPassword: "Insira uma password por favor",
			pleaseFillFormCorrectly: "Por favor preencha o formulário correctamente",
			fillField: "Este campo é de preenchimento obrigatório",
			notSupportedFile: "Tipo de ficheiro não suportado",
			nameIsEmpty: "O campo nome é de preenchimento obrigatório",
			passwordsDontMatch: "Os campos 'Nova password' e 'Confirmar password' não coincidem",
			emailIsEmpty: "O campo email é de preenchimento obrigatório",
			fileTooBigorInvalid: "O tamanho da imagem é superior a 8MB ou tem um formato inválido",
			unsupportedFile: "Ficheiro não suportado",
			fileNotSupported: "O ficheiro que tentou carregar, não é suportado.",
			processingError: "Erro de Processamento",
			imageProcessingError: "{images} não puderam ser processadas.",
			partnerCodeIsInvalid: "O código de parceiro inserido não é válido",
			fillEmailOrCode: "Insira um email ou código de parceiro"
		},

		profile: {
			profilePicture: "Foto de Perfil",
			role: "Cargo",
			editedSuccessfully: "Perfil editado com sucesso",
			recurrence: "Recorrência",
			recurrenceActive: "Ativar Recorrência",
			recurrenceUntil: "Recorrente até",
			recurringEndMissing: "A data final de recorrência é obrigatória.",
			recurrenceSuccess: "Definições de recorrência guardadas com sucesso.",
			deleteRecurrence: "Eliminar recorrência",
			noThankYou: "Não Obrigado(a)",
			recurrenceTooltip: "Criar recorrência",
			deleteRecurrenceDescription: "Pretende eliminar a recorrência do dia {date}?",
			personalPoints: "Pontos Pessoais",
			pvPoints: "Pontos PV"
		},

		placeholders: {
			name: "O seu nome",
			email: "o_seu@email.com",
			phoneNumber: "+351265000000",
			language: "Selecione uma língua",
			role: "Selecione um cargo",
			partner: "Partner",
			currentPassword: "password actual",
			newPassword: "nova password",
			confirmPassword: "nova password",
			newEmail: "novo@email.com",
			page: "pagina",
			titlePage: "Título da Página",
			link: "https://www.google.com/"
		},

		staff: {
			owner: "Dono",
			owners: "Donos",
			admin: "Administrador",
			marketing: "Marketing",
			partner: "Parceiro",
			editedSuccessfully: "Membro da staff alterado com sucesso",
			passwordChangedSuccessfully: "Password alterada com sucesso",
			emailChangedSuccessfully: "Email alterado com sucesso",
			wrongPassword: "Password errada.",
			emailInUse: "E-mail em uso",
			status: "Estado da conta",
			confirmed: "Confirmado",
			pending: "Pendente",
			createStaff: "Criar staff",
			createdSuccessfully: "Staff criado com sucesso.",
			sysAdmin: "Administrador do sistema",
			addStaff: "Adicionar staff",
			editStaff: "Editar staff",
			addOwner: "Adicionar dono",
			editOwner: "Editar dono",
			user: "Cliente",
			removeStaff: "Remover staff",
			removed: "Staff removido com sucesso.",
			disableStaff: "Desativar membro de staff",
			enableStaff: "Ativar membro de staff",
			disabled: "Staff desativado com sucesso.",
			enabled: "Staff ativado com sucesso.",
			addNew: "Adicionar novo",
			partnerSearch: "Pesquisar parceiro"
		},

		staffLogs: {
			logTypes: {
				staff: "Staff",
				user: "User",
				product: "Produto",
				order: "Encomenda"
			},
			changeType: "Tipo de alteração"
		},

		crm: {
			single: "CRM",
			name: "Name",
			email: "Email",
			phone: "Phone",
			createdAt: "Created At",
			partner: "Parceiro",
			request: "Request",
			editedSuccessfully: "Membro da CRM alterado com sucesso",
			confirmed: "Confirmado",
			pending: "Pendente",
			createCrm: "Criar CRM",
			createdSuccessfully: "CRM criado com sucesso.",
			addCrm: "Adicionar CRM",
			editCrm: "Editar CRM",
			user: "Utilizador",
			removeCrm: "Remover CRM",
			removed: "Crm removido com sucesso.",
			disableCrm: "Desativar membro de CRM",
			enableCrm: "Ativar membro de CRM",
			disabled: "CRM desativado com sucesso.",
			enabled: "CRM ativado com sucesso.",
			addNew: "Adicionar novo",
			created: "O CRM foi criado com sucesso.",
			updated: "O CRM foi editado com sucesso.",
			deleted: "O CRM foi apagado com sucesso.",
			removeCRM: "Remover CRM",
			disableCRM: "Desativar CRM",
			emailMissing: "O email é um campo de preenchimento obrigatório.",
			phoneMissing: "O telefone é um campo de preenchimento obrigatório.",
			enableCRM: "Ativar CRM",
			nameMissing: "O nome é um campo de preenchimento obrigatório.",
			partnerMissing: "O parceiro é um campo de preenchimento obrigatório.",
			requestMissing: "O pedido é um campo de preenchimento obrigatório.",
			sendToAdmin: "Enviar para Admin",
			sendToPartner: "Enviar para Parceiro",
			close: "Fechar CRM",
			open: "Reabrir CRM"
		},

		ranges: {
			today: "Hoje",
			thisMonth: "Este mês",
			lastMonth: "Último mês",
			thisYear: "Este ano",
			lastYear: "Último ano"
		},

		dashboard: {
			period: "Período",
			active: "Ativo",
			homepage: "Homepage",
			inStock: "Em Stock",
			daily: "Diário",
			monthly: "Mensal",
			annually: "Anual",
			newUsers: "Novos Utilizadores",
			newUsersContent: "Novos Utilizadores Conteúdo",
			uses: "Utilizações",
			androidUse: "Utilização Android",
			androidUseContent: "Utilização Android Conteúdo",
			iOSUse: "Utilização iOS",
			iOSUseContent: "Utilização iOS Conteúdo",
			webUse: "Utilização Web",
			webUseContent: "Utilização Web Conteúdo",
			copyStoreLink: "Copiar Link da Loja",
			storeLinkCopied: "Link da loja copiado com sucesso",
			yourStoreLink: "O link da sua loja"
		},

		settings: {
			pages: "Páginas",
			emailTemplates: "Templates de E-mails",
			faqs: "Questões Frequentes",
			pagesSubTitle:
				"Nesta área poderá configurar as páginas do seu site como por exemplo, termos e condições ou política de privacidade",
			emailsSubTitle: "Configure a forma e imagem dos emails enviados aos seus clientes ou convites para pertencer ao staff",
			faqSubTitle: "Configure usa série de questões e respostas que estarão disponíveis para os seus parceiros",
			appSettings: "Definições da Aplicação",
			appSettingsTitle: "Defina as configurações gerais da sua aplicação",
			appConfigurations: "Configurações",
			primaryColor: "Cor primária",
			gradientStart: "Cor inícial do gradiente",
			gradientEnd: "Cor final do gradiente",
			pickColor: "Clique para escolher cor",
			imageColor: "Cor da imagem",
			imageWhite: "Imagem branca",
			splashImage: "Imagem a ser usar como splashscreen",
			register: "Permitir registo de utilizadores",
			socialLogin: "Usar redes sociais como autenticação",
			sentry: "Hiperligação do Sentry",
			storeLinkAndroid: "Hiperligação da loja para Androids",
			storeLinkIos: "Hiperligação da loja para iPhones",
			warningVersionsAndroid: "Versão de aviso para Androids",
			criticalVersionsAndroid: "Versão crítica para Androids",
			warningVersionsIos: "Versão de aviso para iPhones",
			criticalVersionsIos: "Versão crítica para iPhones",
			borderRadius: "Curvatura da borda",
			shippings: "Transportadoras",
			addShipping: "Adicionar Transportadora",
			shippingsSubTitle: "Ative e configure os métodos de envio que irão estar disponíveis para os seus parceiros",
			zones: "Definições de Zonas",
			zonesSubTitle: "Defina as zonas de envio, regiões e países para os seus parceiros",
			taxes: "Definições de Taxas",
			taxesSubTitle: "Defina os impostos que irão ser aplicados aos seus clientes",
			taxesTitle: "Taxas",
			addTax: "Adicionar taxa",
			editTax: "Editar taxa",
			addZoneTax: "Adicionar nova",
			taxNameRequired: "Nome da taxa é um campo obrigatório.",
			taxValueInvalid: "O valor da taxa para outras zonas é inválido.",
			taxValueRequired: "O valor da taxa para outras zonas é um campo obrigatório.",
			taxZoneRequired: "A zona é um campo obrigatório.",
			taxAdded: "Taxa adicionada com sucesso.",
			taxEdited: "Taxa editada com sucesso.",
			taxDeleted: "Taxa removida com sucesso.",
			addShippingMethod: "Adicionar método de envio",
			shipping: "Transportadora",
			shippingMethods: "Métodos de envio",
			shippingMethod: "Método de envio",
			newShipping: "Nova transportadora",
			newShippingMethod: "Novo método de envio",
			pickMethod: "Escolha o método de envio",
			freeShipping: "Envio grátis",
			freeShippingDescription: "Criar envio grátis, toda a informação textual irá ser apresentada ao utilizador no checkout",
			fixedShipping: "Envio fixo",
			fixedShippingDescription: "Criar envio com custo fixo, toda a informação textual irá ser apresentada ao utilizador no checkout",
			customShipping: "Envio personalizado",
			customShippingDescription:
				"Criar envio com custo variável, toda a informação textual irá ser apresentada ao utilizador no checkout",
			methodSettings: "Definições do método de envio",
			nameShownClient: "Este é o nome que é mostrado ao cliente, mantenha isto em mente aquando da escolha do mesmo.",
			descriptionShownClient:
				"A descrição é uma explicação detalhada para o cliente entender este método de envio, as especificações e que",
			descriptionShownClientBold: "condições ativam este desconto e o tempo em que a encomenda pode demorar até chegar ao destino.",
			pickZone: "Escolha ou crie uma nova zone a onde o envio deva ser aplicado.",
			manageZone: "Gerir zonas",
			minimumValue: "Valor mínimo",
			value: "Valor",
			tableBasedOn: "Tabela baseada em",
			type: "Tipo",
			freeMethodValueTip: "Quando o cliente, atinge este valor no checkout. Este método vai ficar disponível nas zonas selecionadas.",
			fixedMethodValueTip: "Este é o valor que o cliente irá pagar independentemente do valor ou peso da encomenda.",
			subTotalInterval: "Intervalo de Subtotal",
			cost: "Custo",
			andAbove: "e acima",
			andAboveInfo:
				"Este é o valor que o cliente erá pagar quando o subtotal é igual ou superior ao valor anterior. Clique no texto para definir um limite máximo.",
			addInterval: "Adicionar intervalo",
			subTotal: "Subtotal (€)",
			invalidValue: "Valor inválido. Tem de ser um número acima de 0.",
			invalidIntervalValues: "Intervalo inválido. O valor mínimo tem de ser menor que o valor máximo.",
			invalidCostValue: "Valor de custo inválido. Tem de ser um número igual ou acima de 0.",
			videos: "Videos",
			shownToPartnersLevel: "Mostrado a parceiros de nível",
			previewVideo: "Pré-visualizar vídeo",
			editFile: "Editar ficheiro",
			newVideo: "Novo ficheiro",
			filesSubTitle: "Adicione ficheiros para os seus parceiros verem e aprenderem mais sobre a sua empresa",
			url: "URL",
			successLevel: "Nível de sucesso",
			titleMandatory: "Título é um campo de preenchimento obrigatório.",
			urlMandatory: "URL é um campo de preenchimento obrigatório.",
			successLevelMandatory: "Nível de sucesso é um campo de preenchimento obrigatório.",
			invalidSuccessLevel: "Nível de sucesso inválido.",
			level: "Nível",
			files: "Ficheiros de Ajuda",
			newFile: "Novo ficheiro",
			fileType: "Tipo de ficheiro",
			selectPdf: "Selecionar PDF",
			file: "Ficheiro",
			invalidUrl: "URL de vídeo inválido.",
			viewFile: "Ver ficheiro",
			youtubeVideo: "Vídeo do Youtube",
			noFiles: "Ainda não foi inserido nenhum ficheiro.",
			noFilesPartner: "Não existem ficheiros disponíveis.",
			topic: "Tema",
			help: "Ajuda",
			other: "Outros",
			topicMandatory: "Tema é um campo de preenchimento obrigatório.",
			deleteFile: "Apagar ficheiro",
			fileDeleted: "Ficheiro apagado com sucesso.",
			noFaqsAvailable: "Ainda não existem FAQs.",
			topicProduct: "Informação de Produto",
			topicBusiness: "Desenvolvimento de Negócio",
			addFile: "Adicionar",
			bonuses: "Definições de Bónus",
			bonusesSubTitle: "Defina os bónus que os seus parceiros podem ganhar",
			bonusesNotificationMessageSuccess: "O seu bónus foi atualizado com sucesso.",
			bonusesNotificationMessageError: "Ocorreu um erro ao atualizar o seu bónus.",
			successLadder: "Definições da Escada do Sucesso",
			successLadderSubTitle: "Defina os requisitos dos níveis da escada do sucesso",
			manuals: "Manuais",
			manualsSubTitle: "Defina os manuais que os seus parceiros podem consultar",
			manualsFullTitle: "Manuais de políticas e procedimentos",
			viewManual: "Ver manual",
			editManual: "Editar manual",
			deleteManual: "Apagar manual",
			noManuals: "Ainda não foi inserido nenhum manual.",
			addManual: "Adicionar manual",
			newManual: "Novo manual",
			noManualsPartner: "Não existem manuais disponíveis.",
			ethics: "Código de Ética",
			ethicsFullTitle: "Código de Ética",
			ethicsSubTitle: "Defina o código de ética que os seus parceiros podem consultar",
			viewEthics: "Ver código de ética",
			editEthics: "Editar código de ética",
			deleteEthics: "Apagar código de ética",
			noEthics: "Ainda não foi inserido nenhum código de ética.",
			addEthics: "Adicionar código de ética",
			newEthics: "Novo código de ética",
			noEthicsPartner: "Não existem códigos de ética disponíveis.",
			incentives: "Incentivos Extra",
			incentivesSubTitle: "Defina os incentivos que os seus parceiros podem ganhar",
			trimestralBonus: "Bónus Trimestral",
			groupTrip: "Viagem de Grupo",
			addTrimestralIncentive: "Adicionar Bónus Trimestral",
			editTrimestralIncentive: "Editar Bónus Trimestral",
			addGroupTripIncentive: "Adicionar Viagem de Grupo",
			editGroupTripIncentive: "Editar Viagem de Grupo",
			noIncentives: "Ainda não foi inserido nenhum incentivo."
		},

		pages: {
			single: "Página",
			title: "Título",
			content: "Conteúdo",
			new: "Nova Página",
			isLink: "Redirecionamento"
		},

		templates: {
			single: "Template"
		},

		emails: {
			single: "E-mail",
			tags: "Tags",
			preview: "Pré-visualização",
			subject: "Assunto",
			cc: "CC",
			templates: "Templates de E-mail",
			invite: "Convidar Staff",
			edited: "Template de E-mail editado com sucesso."
		},

		serverErrors: {
			title: "Erro de Servidor",
			subTitle: "Não é você, somos nós!",
			refresh: "Voltar à Página Anterior",
			backToDashboard: "Ir para o Dashboard",
			wentWrong: "Algo correu mal."
		},

		logs: {
			dateRange: "Date Range",
			userId: "User Id",
			url: "Url",
			token: "Token",
			codes: "Codes",
			methods: "Methods",
			sources: "Sources",
			types: "Types"
		},

		emailTemplates: {
			generalEmail: "E-mail Genérico",
			accountConfirmationCode: "Confirmação de Conta Utilizador por Código",
			recoverPasswordCode: "Recuperação de Password por Código",
			accountConfirmationLink: "Confirmação de Conta Utilizador por Link",
			recoverPasswordLink: "Recuperação de Password por Link",
			reservationEmail: "E-mail de Confirmação de Reserva",
			staffAccountConfirmation: "Confirmação de Conta de Acesso ao BO",
			staffRecoverPassword: "Recuperação de Password de Acesso ao BO",
			supportEmail: "Support E-mail",
			resetPassword: "Reset Password (BO)",
			generalCleanWithoutButton: "General Clean Without Button",
			generalCleanWithButton: "General Clean With Button",
			generalWithCode: "General With Code",
			confirmAccount: "Confirm Account (BO)",
			welcome: "Welcome E-mail",
			generalWithLink: "General With Link",
			changeEmailLink: "Mudança de E-mail com Link",
			partnerAccountConfirmation: "Confirmação de Conta de Parceiro",
			deactivateAccount: "Desativação de Conta",
			welcomePartner: "Convite de Parceiro",
			orderConfirmation: "Confirmação de Encomenda"
		},

		notifications: {
			new: "Nova Notificação",
			title: "Título da Notificação",
			message: "Texto da Notificação",
			email: "Conteúdo do E-mail",
			date: "Date de Envio",
			sendEmail: "Enviar E-mail",
			created: "A notificação foi criada com sucesso.",
			edited: "A notificação foi editada com sucesso.",
			deleted: "A notificação foi apagada com sucesso.",
			missingTitle: "Título é um campo de preenchimento obrigatório.",
			missingDescription: "Descrição é um campo de preenchimento obrigatório.",
			missingDate: "Date de Envio é um campo de preenchimento obrigatório.",
			missingEmailContent: "Conteúdo do E-mail é um campo de preenchimento obrigatório.",
			notificationText: "Notification Text"
		},

		banners: {
			single: "Banner",
			addBanner: "Adicionar Banner",
			showLabels: "Mostrar Etiquetas",
			title: "Título",
			subTitle: "Sub-título",
			fullInfo: "Informação do Banner",
			webSettings: "Definições de Web",
			appSettings: "Definições de App",
			created: "O banner foi criado com sucesso.",
			updated: "O banner foi editado com sucesso.",
			deleted: "O banner foi apagado com sucesso.",
			nameMissing: "O nome é um campo de preenchimento obrigatório.",
			titleMissing: "O título é um campo de preenchimento obrigatório.",
			subTitleMissing: "O sub-título é um campo de preenchimento obrigatório.",
			imageMissing: "A imagem do banner é obrigatória.",
			showMissing: "O banner deverá estar disponível em web ou app.",
			shownWeb: "Visível em Web",
			shownApp: "Visível em App",
			showButton: "Mostrar botão",
			buttonText: "Texto do botão",
			darkOverlay: "Filtro escuro",
			details: "Detalhes do banner"
		},

		highlightedSections: {
			single: "Destaques",
			addHighlightedSection: "Adicionar destaque",
			title: "Título",
			subTitle: "Sub-título",
			fullInfo: "Informação do destaque",
			webSettings: "Definições de Web",
			appSettings: "Definições de App",
			created: "O destaque foi criado com sucesso.",
			updated: "O destaque foi editado com sucesso.",
			deleted: "O destaque foi apagado com sucesso.",
			nameMissing: "O nome é um campo de preenchimento obrigatório.",
			titleMissing: "O título é um campo de preenchimento obrigatório.",
			subTitleMissing: "O sub-título é um campo de preenchimento obrigatório.",
			imageMissing: "A imagem do destaque é obrigatória.",
			showMissing: "O destaque deverá estar disponível em web ou app.",
			shownWeb: "Visível em Web",
			shownApp: "Visível em App",
			showButton: "Mostrar botão",
			buttonTitle: "Título do botão",
			darkOverlay: "Filtro escuro",
			details: "Detalhes do destaque",
			descriptionMissing: "A descrição é um campo de preenchimento obrigatório."
		},

		faqs: {
			single: "Questão Frequente",
			showLabels: "Mostrar Etiquetas",
			addFaq: "Adicionar Questão Frequente",
			question: "Questão",
			answer: "Resposta",
			isActive: "Ativa",
			showMissing: "O faq deverá estar disponível em web ou app.",
			nameMissing: "O nome é um campo de preenchimento obrigatório.",
			questionMissing: "A questão é um campo de preenchimento obrigatório.",
			answerMissing: "A resposta é um campo de preenchimento obrigatório.",
			created: "A questão frequente foi criada com sucesso.",
			updated: "A questão frequente foi edita com sucesso.",
			deleted: "A questão frequente foi apagada com sucesso."
		},

		segments: {
			single: "Segmento",
			addSegment: "Adicionar segmento",
			isActive: "IsActive",
			showOnHeader: "Mostrar no cabeçalho",
			showMissing: "O segmento deverá estar disponível em web ou app.",
			nameMissing: "O nome é um campo de preenchimento obrigatório.",
			created: "O segmento foi criado com sucesso.",
			updated: "O segmento foi editado com sucesso.",
			deleted: "O segmento foi apagado com sucesso.",
			new: "Novo Segmento"
		},

		categories: {
			single: "Categoria",
			addCategoryt: "Adicionar categoria",
			isActive: "IsActive",
			showMissing: "O categoria deverá estar disponível em web ou app.",
			nameMissing: "O nome é um campo de preenchimento obrigatório.",
			created: "O categoria foi criado com sucesso.",
			updated: "O categoria foi editado com sucesso.",
			deleted: "O categoria foi apagado com sucesso.",
			category: "Informação da categoria",
			addSubCategory: "Adicionar sub-categoria",
			editSubCategory: "Editar sub-categoria",
			subCategories: "Sub-categorias",
			nameRequired: "O nome é um campo de preenchimento obrigatório.",
			subCategoryCreated: "A sub-categoria foi criada com sucesso."
		},

		users: {
			header: "Utilizador",
			fullInfo: "Informação do cliente",
			fullPartnerInfo: "Informação do parceiro",
			fullSpouseInfo: "Informação do cônjuge",
			addUser: "Adicionar um cliente",
			editUser: "Editar um cliente",
			title: "Clientes",
			kickTitle: "Clientes expulsos",
			solveKick: "Resolução da Expulsão",
			session: "Sessão",
			reason: "Reason",
			deleted: "O cliente foi apagado com sucesso.",
			updated: "O cliente foi editado com sucesso.",
			noImage: "O cliente não tem imagem",
			addresses: "Endereços",
			addAddress: "Adicionar Endereço",
			editAddress: "Editar Endereço",
			noAddresses: "O cliente não tem endereços",
			emailRequired: "O e-mail é um campo de preenchimento obrigatório.",
			nameRequired: "O nome é um campo de preenchimento obrigatório.",
			fullNameRequired: "O nome completo é um campo de preenchimento obrigatório.",
			birthdayRequired: "A data de nascimento é um campo de preenchimento obrigatório.",
			phoneRequired: "O telefone é um campo de preenchimento obrigatório.",
			ibanInvalid: "O IBAN é inválido.",
			vatRequired: "O NIF é um campo de preenchimento obrigatório.",
			emailInvalid: "O e-mail é inválido.",
			addressRequired: "O endereço é um campo de preenchimento obrigatório.",
			cityRequired: "A cidade é um campo de preenchimento obrigatório.",
			countryRequired: "O país é um campo de preenchimento obrigatório.",
			stateRequired: "O estado/distrito é um campo de preenchimento obrigatório.",
			zipRequired: "O código postal é um campo de preenchimento obrigatório.",
			isDefaultBilling: "Endereço de Faturação Principal",
			isDefaultShipping: "Endereço de Entrega Principal",
			clients: "Clientes",
			addPartner: "Adicionar Parceiro",
			partnerNoImage: "O parceiro não tem imagem",
			partnerCode: "Código de Parceiro",
			partnerCodeInvalid: "O código do parceiro é inválido. Deverá conter apenas letras e números.",
			partnerTree: "Árvore de Parceiros",
			isDefault: "Endereço Principal",
			underBy: "Sobreposição",
			referredBy: "Patrocionador",
			placementSide: "Lado de colocação",
			redeemed: "Resgatado",
			redeemedOn: "Resgatado em",
			noCommissions: "O parceiro não tem comissões registadas",
			noBonus: "O parceiro não tem bónus registados",
			wallet: "Carteira",
			commission: "Comissão",
			status: "Estado",
			selectPartner: "Selecione um parceiro",
			leftCarry: "Acumulado Esquerda",
			rightCarry: "Acumulado Direita",
			personalPV: "PV Pessoal",
			groupPV: "PV de Grupo",
			badges: {
				specialExecutive: "Special Executive",
				bronze: "Bronze Visionary",
				silver: "Silver Visionary",
				gold: "Gold Visitionary",
				gold30: "Gold 30",
				gold60: "Gold 60",
				gold100: "Gold 100",
				ruby: "Ruby Director",
				emerald: "Emerald Director",
				diamond: "Diamond Director",
				diamond2: "Diamond 2",
				diamond4: "Diamond 4",
				diamond6: "Diamond 6",
				diamond8: "Diamond 8",
				specialDiamond: "Special Diamond"
			},
			userCode: "Código de Utilizador",
			personalPurchases: "Compras Próprias",
			personalVolume: "Volume Pessoal",
			personalVolumeTotal: "Volume Pessoal Total",
			leftGroupVolume: "Volume de Grupo Pratocinador Esquerda",
			rightGroupVolume: "Volume de Grupo Patrocinador Direita",
			leftSpillover: "Spillover Esquerda",
			rightSpillover: "Spillover Direita",
			leftTotalGroupVolume: "Volume de Grupo Total Esquerda",
			rightTotalGroupVolume: "Volume de Grupo Total Direita",
			allowedTgv: "Volume de Grupo Total Disponível",
			noPartnersFound: "Não foram encontrados parceiros.",
			referredByRequired: "O patrocionador é um campo de preenchimento obrigatório.",
			vatNumberRequired: "O NIF é um campo de preenchimento obrigatório.",
			pleaseSelectSide: "Por favor, selecione um lado de colocação para o parceiro.",
			associatedPartner: "Parceiro Associado",
			balance: "Saldo",
			partnerStatus: "Estado do Parceiro",
			suspend: "Suspender Parceiro",
			unSuspend: "Ativar Parceiro",
			suspended: "Conta Suspensa",
			id: "ID",
			personalVolumeAccumulated: "Volume Pessoal Acumulado",
			adquiredLevel: "Posição Adquirida",
			newPartner: "Novo Parceiro",
			newPartners: "Novos Parceiros",
			totalNewPartners: "N.º de novos parceiros",
			workingBinaryPartners: "N.º de parceiros ativos",
			totalPartners: "N.º parceiros inátivos",
			partnerRanking: "Nível de sucesso do parceiro",
			pendingRegistration: "Registo pendente",
			pendingRegistrationMessage:
				"A sua conta de parceiro está pendente de confirmação do pagamento da encomenda por parte da administração. Por favor, aguarde.",
			suspendedMessage1:
				"Deixou de ser Parceiro Autorizado da Essência da Vida pelo facto de, nos últimos meses, não cumprir os requisitos mínimos necessários para se manter como tal.",
			suspendedMessage2:
				"Caso pretenda voltar a trabalhar com a nossa Empresa e Produtos, com o mesmo ou com outro patrocinador, faça um novo registo.",
			suspendedMessage3: "Para mais esclarecimentos, contacte-nos pelo {tel} ou através do nosso e-mail, {email}."
		},

		zones: {
			title: "Zonas",
			zoneName: "Nome da zona",
			addZone: "Adicionar zona",
			editZone: "Editar zona",
			addANewZoneFirst: "Adicione uma nova zona primeiro.",
			deleted: "A zona foi apagada com sucesso.",
			updated: "A zona foi editada com sucesso.",
			addSelection: "Adicionar seleção",
			continent: "Continente",
			selectAContinent: "Selecione um continente",
			addCountries: "Adicionar países",
			byContinent: "Por continente",
			zonesHelper: "Tenha em consideração que o nome da zona será mostrado aos seus parceiros.",
			zonesList: "Lista de zonas",
			zonesListHelper: "Escolha uma zona para editar, ou crie uma nova zona",
			customZone: "Zona personalizada",
			fillCountry: "Por favor, escolha pelo menos um país",
			newZoneName: "Nome da nova zona",
			selectACountry: "Por favor, selecione um país",
			allStatesSelected: "Todos os estados/distritos estão selecionados",
			other: "Outras zonas",
			zone: "Zona"
		},

		bonuses: {
			bonusProductsTitle: "1º Desconto na Aquisição de Produto",
			bonusProductsHeader1: "Posição",
			bonusProductsHeader2: "Desconto na Aquisição do Produto",
			bonusRecruitmentTitle: "2º Bónus de Recrutamento",
			bonusRecruitmentBasic: "Bónus Kit Básico",
			bonusRecruitmentSuperior: "Bónus Kit Superior",
			bonusRecruitmentHeader1: "Bónus",
			bonusRecruitmentHeader2: "Pontos",
			bonusRepassTitle: "3º Bónus de Repasse sobre os Junior Executives",
			bonusRepassHeader1: "Posição na Escada de Sucesso",
			bonusRepassHeader2: "Percentagem",
			bonusPerformanceTitle: "4º Bónus de Desempenho Anual",
			bonusPerformanceHeader1: "Média Mensal",
			bonusPerformanceHeader2: "Total Anual",
			bonusPerformanceHeader3: "Bónus",
			bonusPerformanceButton: "Adicionar Bónus",
			bonusBinaryTitle: "5º Bónus de Produção do Binário",
			bonusBinaryString1:
				"Se classificar-se como no mínimo Special Executive Ativo, e quando umas das equipas da esquerda ou da direita acumular",
			bonusBinaryString2: "e a outra acumular",
			bonusBinaryString3: ", fecha-se 1 clico e a empresa paga",
			bonusBinaryString4: "por cada ciclo.",
			bonusDepthTitle: "6º Bónus de Profundidade",
			bonusDepthHeader1: "Nível",
			bonusDepthHeader2: "Percentagem",
			bonusDepthButton: "Adicionar Nível",
			bonusDiamondTitle: "7º Prémio de Diamante",
			bonusDiamondMinimumPoints: "Número mínimo de pontos",
			bonusDiamondHeader1: "Condição",
			bonusDiamondHeader2: "Pontos",
			bonusSpecialTitle: "8º Recompensa Especial",
			bonusSpecialHeader1: "Qualifica-se como",
			bonusSpecialHeader2: "Ganha",
			bonusExtraIncentiveTitle: "Incentivo Extra",
			bonusExtraIncentiveHeader1: "Volume Pessoal Total (TPV)",
			bonusExtraIncentiveHeader2: "Bónus",
			bonusExtraIncentiveMoney: "Dinheiro",
			bonusExtraIncentivePrize: "Recompensa",
			bonusTripGroupTitle: "Viagem de Grupo",
			bonusTripGroupHeader1: "Média Mensal",
			bonusTripGroupHeader2: "Total Anual",
			bonusTripGroupHeader3: "Prémio",
			bonusesNotificationMessageSuccess: "Bonus alterado com sucesso.",
			bonusesNotificationMessageError: "Ocorreu um erro ao alterar o bonus.",
			keyClient: "Cliente",
			keyJunior: "Junior",
			keyExecutive: "Executive",
			keySpecialExecutive: "Special Executive e posições superiores",
			keyDirector: "Primeira vez que se qualifica como Diamond Director",
			keyCycles: "Cada 1000 ciclos alcançados no bónus de produção do binário, durante o trimestre",
			keyTrimester:
				"Por cada membro patrocionado pessoalmente por si, que seja pago como Diamond ou nivel superior, em qualquer um dos meses desse periodo.",
			keyUnderDiamond2: "Por cada mês em que é pago como Diamond 2 ou como nível superior.",
			level: "Nível",
			bonusExtraIncentiveTpvError: "O campo de Volume Pessoal Total está vazio ou é inválido.",
			bonusExtraIncentiveTypeError: "O tipo de incentivo é um campo de preenchimento obrigatório.",
			bonusExtraIncentivePrizeError: "O campo de recompensa está vazio ou é inválido.",
			bonusTripGroupAverageError: "O campo de média mensal está vazio ou é inválido.",
			bonusTripGroupAnnualError: "O campo de total anual está vazio ou é inválido.",
			bonusTripGroupPrizeError: "O campo de prémio está vazio ou é inválido."
		},

		successLadder: {
			qualification: "Qualificação",
			monthlyMinimum: "Mínimo Mensal",
			qualificationAndMonthlyMinimum: "Qualificação e Mínimo Mensal",
			or: "OU",
			have: "Ter pelo menos",
			activeSpecialExecutives: "Special Executives diretos e ativos com o mínimo de",
			activeSpecialExecutivesMaintenance: "Special Executives diretos e ativos com o mínimo de",
			activeExecutives: "Executives diretos e ativos com o mínimo de",
			activeExecutivesMaintenance: "Executives diretos e ativos com o mínimo de",
			eachBinaryLeg: "em cada perna do binário.",
			accumulateMin: "Acumular no mínimo",
			keepActive: "Manter ativos pelo menos",
			specialExecutives1MinOr: "Special Executives diretos (mínimo 1 em cada perna) ou",
			specialExecutives2MinOr: "Special Executives diretos (mínimo 2 em cada perna) ou",
			executives3Min: "Executives diretos (mínimo 3 em cada perna).",
			specialExecutives3Min: "Special Executives diretos (mínimo 3 em cada perna).",
			accumulate: "Acumular",
			ciclesBonusBinary: "ciclos no bónus de produção do binário.",
			sponsorshipGold: "linhas de patrocínio, com um membro pago como Gold Visionary em cada linha.",
			sponsorshipDiamond: "linhas de patrocínio, cada uma com um Diamond, nos primeiros 7 níveis.",
			sgv: "de Volume de Grupo Patrocionado (SGV). Por cada linha de patrocínio será contabilizado, no máximo,",
			sgv2: "pontos de SGV.",
			executivesActiveLine: "Executives ativos, em todas as suas linhas de patrocínio.",
			minimumPoints: "Pontos mínimos",
			minimumPointsDescription: "Pontos mínimos para todos os níveis",
			havePvPoints: "Ter no mínimo",
			pvPoints: "pontos no PV e",
			cpPoints: "pontos no CP.",
			messageSuccess: "A escada do sucesso foi alterada com sucesso.",
			messageError: "Ocorreu um erro ao alterar a escada do sucesso."
		},

		products: {
			title: "Produtos",
			catalog: "Catálogo",
			categories: "Categorias",
			segments: "Segmentos",
			addCategory: "Adicionar Categoria",
			editCategory: "Editar Categoria",
			categoryUpdated: "A categoria foi editada com sucesso.",
			categoryDeleted: "A categoria foi apagada com sucesso.",
			reference: "Referência",
			ean: "EAN",
			price: "Preço",
			specialPrice: "Preço Promocional",
			points: "Pontos",
			pointsPersonal: "Pontos Pessoais",
			pointsShort: "Pts",
			inStock: "Em stock",
			stock: "Stock",
			updated: "O produto foi editado com sucesso.",
			deleted: "O produto foi apagado com sucesso.",
			details: "Detalhes do produto",
			new: "Novo produto",
			information: "Informação do produto",
			segmentation: "Segmentação",
			ingredients: "Ingredientes",
			howToUse: "Como usar",
			howToStore: "Como armazenar",
			notes: "Notas",
			comments: "Comentários",
			showComment: "Mostrar comentário",
			hideComment: "Esconder comentário",
			commentUpdated: "O comentário foi editado com sucesso.",
			commentDeleted: "O comentário foi apagado com sucesso.",
			saved: "O produto foi guardado com sucesso.",
			toDelete: "para apagar",
			nameRequired: "O nome é um campo de preenchimento obrigatório.",
			descriptionRequired: "A descrição é um campo de preenchimento obrigatório.",
			referenceRequired: "A referência é um campo de preenchimento obrigatório.",
			eanRequired: "O EAN é um campo de preenchimento obrigatório.",
			priceInvalid: "O preço é inválido.",
			cannotDeleteFavoriteImage: "Não pode apagar a imagem favorita. Por favor mude a imagem favorita primeiro.",
			provenBy: "Comprovado por",
			stockInvalid: "O valor de stock não é válido.",
			addProduct: "Adicionar produto",
			noResults: "Não foram encontrados produtos.",
			waitingForInput: "À espera de pesquisa",
			searching: "A procurar...",
			composition: "Composição",
			activePrinciples: "Princípios ativos",
			presentation: "Apresentação",
			bibliographic: "Referências bibliográficas",
			component: "Componente",
			dailyValue: "Quantidade diária",
			dailyValueChildren: "Quantidade diária (criança)",
			nrv: "% VRN",
			nrvChildren: "% VRN (criança)",

			addComponent: "Adicionar componente",
			editComponent: "Editar componente",
			componentNameMandatory: "O nome do componente é um campo de preenchimento obrigatório.",
			componentDailyValueMandatory: "A quantidade diária é um campo de preenchimento obrigatório.",
			reviews: "Avaliações",
			review: "Avaliação",
			suggestedProducts: "Produtos sugeridos",
			categoryRequired: "A categoria é um campo de preenchimento obrigatório.",
			favoriteDescription: "Definir como imagem favorita",
			catalogDescription: "Definir como imagem de catálogo",
			deleteDescription: "Apagar imagem",
			labels: "Etiquetas",
			labelBlood: "Sangue",
			labelBones: "Ossos",
			labelBrain: "Função Cerebral",
			labelCelular: "Protecção das Células",
			labelEnergy: "Energia",
			labelFertility: "Fertilidade",
			labelGlucose: "Glicose no Sangue",
			labelHeart: "Coração",
			labelHair: "Cabelo",
			labelHepatic: "Função Hepática",
			labelImmunity: "Imunidade",
			labelMental: "Desempenho Mental",
			labelMetabolism: "Metabolismo",
			labelMuscles: "Funcionamento Muscular",
			labelNails: "Unhas",
			labelSkin: "Pele",
			labelNervous: "Sistema Nervoso",
			labelSleep: "Adormecer",
			labelStomach: "Estômago",
			labelVision: "Visão",
			labelTeeth: "Dentes",
			labelTestosterone: "Testosterona",
			labelThyroid: "Tiroide",
			taxRequired: "A taxa é um campo de preenchimento obrigatório.",
			qtyOrdered: "Qtd. encomendada",
			qtyShipped: "Qtd. enviada",
			qtyDelivered: "Qtd. entregue",
			qtyRefunded: "Qtd. reembolsada",
			editReview: "Editar avaliação",
			showCommentInHome: "Mostrar na home",
			isKit: "Kit Parceiro",
			junior: "Junior Executive",
			executive: "Executive",
			merchandising: "Merchandising",
			barcode: "Código de barras",
			weight: "Peso"
		},

		table: {
			areYouSure: "Tem a certeza que deseja apagar?",
			delete: "Apagar",
			cancel: "Cancelar"
		},

		orders: {
			addOrder: "Adicionar encomenda",
			orderNumber: "N.º da encomenda",
			paymentStatus: "Estado do pagamento",
			shippingStatus: "Estado do envio",
			paymentStatuses: {
				waiting_payment: "Pendente",
				failed_payment: "Falha no pagamento",
				pending_payment: "Pendente",
				paid: "Pago",
				cancelled: "Cancelado",
				partially_refunded: "Parcialmente reembolsado",
				refunded: "Reembolsado"
			},
			shippingStatuses: {
				pending_processing: "Pendente",
				processing: "Em processamento",
				partially_shipped: "Parcialmente enviado",
				shipped: "Enviado",
				delivered: "Entregue",
				returned: "Devolvido",
				partially_returned: "Parcialmente devolvido",
				cancelled: "Cancelado",
				partially_pickedup: "Parcialmente levantado",
				awaiting_pickup: "Aguarda levantamento",
				partially_created: "Parcialmente criado",
				pickup_created: "Levantamento criado",
				closed: "Fechado"
			},
			newOrder: "Nova encomenda",
			orderDetail: "Detalhes da Encomenda",
			close: "Fechar encomenda",
			clientInformation: "Informação do cliente",
			date: "Data da encomenda",
			addresses: "Endereços",
			shippingAddress: "Endereço de envio",
			billingAddress: "Endereço de faturação",
			shippingAndPayment: "Envio e pagamento",
			paymentMethod: "Método de pagamento",
			bankTransfer: "Transferência bancária",
			creditCard: "Cartão de crédito",
			cashOnDelivery: "Contra reembolso",
			resume: "Resumo da encomenda",
			subtotal: "Subtotal",
			shippingCost: "Custo de envio",
			taxes: "Taxas",
			productList: "Lista de produtos",
			searchClientLabel: "Procurar cliente",
			searchClient: "Procurar por um nome ou email",
			orderAddresses: "Endereços da encomenda",
			selectClientFirst: "Por favor selecione um cliente primeiro.",
			addAddress: "Adicionar novo endereço",
			addProducts: "Adicionar produtos",
			productListEmpty: "Sem produtos na lista",
			searchProduct: "Procurar por um nome ou referência",
			orderProcessing: "Processamento da encomenda",
			selectProductsFirst: "Por favor selecione produtos primeiro.",
			shippingMethod: "Método de envio",
			addProduct: "Adicionar",
			outOfStock: "Sem stock",
			free: "Grátis",
			cashOnDeliveryDescription: "Pagamento no ato de entrega",
			bankTransferDescription: "Pagamento por transferência bancária e envio após confirmação do pagamento",
			invalidOrder: "Encomenda inválida. Por favor verifique os dados.",
			totalQuantity: "Quantidade total",
			taxVat: "IVA {0}% (incluído)",
			reopen: "Reabrir encomenda",
			shipments: "Envios",
			prepareShipment: "Preparar envio",
			noShipments: "Sem registo de envios",
			pricePerItem: "Preço por item",
			objectNumber: "Número do objeto",
			createShipping: "Criar envio",
			shippingProviderRequired: "A transportadora é um campo de preenchimento obrigatório.",
			shippingProductsRequired: "É necessário selecionar pelo menos um produto.",
			shippingsEmptyFields: "Existem campos obrigatórios por preencher.",
			pickupCreated: "Criado",
			cancelled: "Cancelado",
			delivered: "Entregue",
			shipped: "Enviado",
			returned: "Devolvido",
			submitOrder: "Concluir encomenda",
			becomeDistributor: "Ser distribuidor Essência da Vida",
			partnerKit: "Kit de Parceiro",
			selectShippingAddressFirst: "Selecione um endereço de envio primeiro.",
			mbwayDescription: "Introduza o número de telefone associado ao serviço MB WAY.",
			expires: "Expira em",
			noCreditCards: "Sem cartões de crédito",
			addCreditCard: "Adicionar cartão de crédito",
			paymentMethodsInvalidCardNumber: "Número de cartão inválido.",
			paymentMethodsInvalidCVC: "CVC inválido.",
			paymentMethodsInvalidExpiry: "Data de validade inválida. A data de validade deve ser posterior à data atual no formato AAAA.",
			cardEndingWith: "Cartão de crédito que termina em",
			sales: "Vendas",
			confirmation: "Confirmação",
			confirmOrder: "Necessita de concordar com os termos e condições para concluir a encomenda.",
			iConfirm: "Confirmo que li e aceito os termos e condições e pretendo concluir a encomenda.",
			status: "Estado da encomenda",
			closed: "Fechada",
			pending: "Pendente",
			cash: "Dinheiro",
			value: "Valor da encomenda"
		},

		kits: {
			description:
				"Para concluir a sua inscrição como nosso distribuidor, deverá escolher um dos kit’s disponíveis, cada um com um conjunto de artigos pensados  para o ajudar a conhecer o mundo de oportunidades Essência da Vida.",
			kitLevelRequired: "O nível do kit é um campo de preenchimento obrigatório."
		},

		commissions: {
			cp: "Compras Próprias",
			pv: "Volume Pessoal",
			tpv: "Volume Pessoal Total",
			leftSgv: "Volume de Grupo Patrocinado Esquerdo",
			rightSgv: "Volume de Grupo Patrocinado Direito",
			leftSpillover: "Spillover Esquerdo",
			rightSpillover: "Spillover Direito",
			leftTgv: "Volume de Grupo Total Esquerdo",
			rightTgv: "Volume de Grupo Total Direito",
			tgv: "Volume de Grupo Total",
			annualTgv: "Volume de Grupo Total Anual",
			annualCp: "Compras Próprias Anuais",
			annualPv: "Volume Pessoal Anual",
			annualTpv: "Volume Pessoal Total Anual",
			redeemedOn: "Resgatado em",
			expired: "Expirado",
			redeemedValue: "Comissões resgatadas",
			redeemedPoints: "Pontos resgatados",
			pendingValue: "Comissões pendentes",
			pendingPoints: "Pontos pendentes",
			expiredValue: "Comissões expiradadas",
			expiredPoints: "Pontos expirados",
			usedBalance: "Saldo utilizado",
			sales: "Commissões das Vendas",
			newPartners: "Bónus de Novos Parceiros",
			repass: "Bónus de Repasse",
			annual: "Bónus de Desempenho Anual",
			binary: "Bónus de Produção do Binário",
			depth: "Bónus de Profundidade",
			diamond: "Prémio de Diamante",
			special: "Recompensa Especial",
			incentives: "Incentivos Extra",
			adjuncts: "Aditamentos",
			value: "Valor da comissão",
			withThisBonus: "Com este bónus já ganhou",
			accumulatedTpv: "Volume Pessoal Total Acumulado",
			progress: "Progresso atual",
			totalCycles: "Total de ciclos",
			cycles: "Ciclos",
			earned: "Valor ganho este mês",
			eligibility: "Elegibilidade",
			notEligible:
				"Para que possa beneficiar deste bónus tem que estar, no mínimo, na posição de Special Executive. Para mais informações consolte a Escada de Sucesso, na secção de ajuda.",
			notEligibleJunior:
				"Para que possa beneficiar deste bónus tem que estar, no mínimo, na posição de Executive. Para mais informações consolte a Escada de Sucesso, na secção de ajuda.",
			notEligibleSpecial:
				"Para que possa beneficiar deste bónus tem que estar, no mínimo, na posição de Diamond 4. Para mais informações consolte a Escada de Sucesso, na secção de ajuda.",
			notEligibleBronze:
				"Para que possa beneficiar deste bónus tem que estar, no mínimo, na posição de Bronze Visionary. Para mais informações consolte a Escada de Sucesso, na secção de ajuda.",
			progressTrip: "Progresso atual para viagem de grupo",
			progressAdditional: "Progresso atual para incentivo extra",
			createNew: "Criar nova comissão",
			totalTimesDiamond: "N.º de vezes que se qualificou",
			totalMonthsAsDiamond: "N.º de meses de permanência como Diamond 4 ou superior",
			totalAmountEarned: "Valor total ganho",
			specialDescription:
				"Este bónus é atribuído a todos os parceiros que se qualificam como Diamond 4 ou superior e pago apenas uma vez. Para mais informações consulte o Plano de Compnesação na secção de ajuda.",
			actualRank: "Nível atual",
			earnedFromClients: "Nas compras dos seus clientes, já ganhou",
			earnedFromNewPartners: "Com o convite de novos parceiros, já ganhou",
			earnedFromRepass: "Com o bónus de repasse, já ganhou"
		},

		wallet: {
			available: "Saldo disponível",
			spent: "Saldo utilizado",
			expired: "Saldo expirado",
			movements: "Movimentos",
			balanceUsed: "Saldo utilizado na encomenda",
			expiredCommission: "Comissão expirada",
			redeemFrom: "Comissão proveniente de {0}",
			wallets: "Carteiras",
			noPartnerSelected: "Nenhum parceiro selecionado",
			noMovements: "Sem movimentos",
			adjustment: "Ajuste de saldo pela administração",
			earned: "Já ganhou",
			totalBilled: "Total faturado",
			monthlyControl: "Controlo mensal",
			earnedPeriod: "Neste período já ganhou: {0}"
		}
	}
});
