import React, { Component } from "react";
import { Col, Table, Row, notification } from "antd";
import { ContentWrapper, Icon } from "components";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { setLoader, setTitle } from "store/actions";
import { API, Endpoints } from "utils/api";
import strings from "utils/strings";
import { formatPrice } from "utils/utils";
import moment from "moment";
import { Area } from "@ant-design/charts";

class Binary extends Component<any, any> {
	searchTimeout: NodeJS.Timeout | undefined;

	constructor(props: any) {
		super(props);

		this.state = {
			data: [],
			startDate: moment().add({ months: -5 }).startOf("month"),
			endDate: moment().endOf("month")
		};
	}

	async componentDidMount(): Promise<void> {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		await this.getData();
	}

	async getData() {
		const { startDate, endDate } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		const body = {
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
			type: "binary"
		};

		try {
			const response = await API.post({
				url: Endpoints.uriCommissions("search-by-type"),
				data: body
			});

			if (response.ok) {
				const { commissions = [], cards, commissionsChart, currentCards, eligible = false } = response.data.results;

				this.setState({
					data: commissions,
					cards,
					commissionsChart,
					currentCards,
					eligible
				});
			} else {
				notification.error({
					message: strings.commissions.sales,
					description: response?.data?.message || strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: strings.serverErrors.title,
				description: err as string,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	renderMovements() {
		const { data = [] } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader --multi">
					<div className="ScreenHeaderLeft">
						<Icon name="refresh" />
						<h2>{strings.wallet.movements}</h2>
					</div>
				</div>
				<Table
					style={{ marginTop: 20 }}
					columns={[
						{
							title: strings.fields.date,
							dataIndex: "date",
							key: "date",
							render: (date: string) => DateTime.fromISO(date).toFormat("dd/MM/yyyy HH:mm"),
							defaultSortOrder: "descend",
							sorter: (a, b) => DateTime.fromISO(a.date).toMillis() - DateTime.fromISO(b.date).toMillis(),
							width: 150
						},
						{
							title: strings.commissions.cycles,
							dataIndex: "cycles",
							key: "cycles",
							render: (cycles: number) => cycles || "-",
							sorter: (a, b) => a.cycles - b.cycles,
							width: 100
						},
						{
							title: strings.commissions.value,
							dataIndex: "value",
							key: "value",
							render: (value: number) => formatPrice(value),
							sorter: (a, b) => a.value - b.value,
							width: 150
						},
						{
							title: strings.generic.expirationDate,
							dataIndex: "expirationDate",
							key: "expirationDate",
							render: (value: string) => (value ? DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm") : "-"),
							sorter: (a, b) => DateTime.fromISO(a._created).toMillis() - DateTime.fromISO(b._created).toMillis(),
							width: 150
						}
					]}
					dataSource={data}
					rowKey={(record) => record._id}
				/>
			</ContentWrapper>
		);
	}

	renderChart() {
		const { commissionsChart = [] } = this.state;

		const config = {
			data: commissionsChart,
			xField: "month",
			yField: "cycles",
			appendPadding: 10,
			smooth: true,
			animation: {
				appear: {
					animation: "path-in",
					duration: 0
				}
			},
			meta: {
				total: {
					alias: strings.commissions.cycles
				}
			},
			tooltip: {
				formatter: (datum: any) => {
					return { name: strings.commissions.cycles, value: datum.cycles };
				}
			}
		};

		return (
			<ContentWrapper extraStyle={{ marginBottom: 0 }}>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="refresh" />
						<h2>{strings.commissions.cycles}</h2>
					</div>
				</div>
				<Area style={{ marginTop: 20 }} {...config} />
			</ContentWrapper>
		);
	}

	renderCards() {
		const { cards } = this.state;
		const { cycles = 0, available = 0, spent = 0, expired = 0, total = 0 } = cards || {};

		const CARDS = [
			{
				icon: "refresh",
				value: cycles,
				description: strings.commissions.totalCycles
			},
			{
				icon: "wallet",
				value: total,
				description: strings.commissions.withThisBonus,
				type: "currency"
			},
			{
				icon: "wallet",
				value: available,
				description: strings.wallet.available,
				type: "currency"
			},
			{
				icon: "wallet",
				value: spent,
				description: strings.wallet.spent,
				type: "currency"
			},
			{
				icon: "wallet",
				value: expired,
				description: strings.wallet.expired,
				type: "currency"
			}
		];

		return CARDS.map((card, index) => (
			<Col xs={24} md={6} key={index}>
				<div className="IconCardOuterContainer">
					<div className="IconCard">
						<div className="IconCardHeader">
							<div className="IconCardHeaderMask" />
							<div className="IconCardType">
								<Icon name={card.icon} />
							</div>
						</div>
						<div className="IconCardBody">
							<span className="IconCardValue">{card.type === "currency" ? formatPrice(card.value) : card.value}</span>
							<h3 className="IconCardDescription">{card.description}</h3>
						</div>
					</div>
				</div>
			</Col>
		));
	}

	renderBinaryCards() {
		const { currentCards } = this.state;
		const { cycles = 0, leftTgv = 0, rightTgv = 0, total = 0 } = currentCards || {};

		const CARDS = [
			{
				icon: "points",
				value: leftTgv,
				description: strings.commissions.leftTgv
			},
			{
				icon: "points",
				value: rightTgv,
				description: strings.commissions.rightTgv
			},
			{
				icon: "refresh",
				value: cycles,
				description: strings.commissions.cycles
			},
			{
				icon: "wallet",
				value: total,
				description: strings.commissions.earned,
				type: "currency"
			}
		];

		return CARDS.map((card, index) => (
			<Col xs={24} md={6} key={index}>
				<div className="IconCardOuterContainer">
					<div className="IconCard">
						<div className="IconCardHeader">
							<div className="IconCardHeaderMask" />
							<div className="IconCardType">
								<Icon name={card.icon} />
							</div>
						</div>
						<div className="IconCardBody">
							<span className="IconCardValue">{card.type === "currency" ? formatPrice(card.value) : card.value}</span>
							<h3 className="IconCardDescription">{card.description}</h3>
						</div>
					</div>
				</div>
			</Col>
		));
	}

	render() {
		const { eligible } = this.state;

		return (
			<div className="CommissionPartners">
				<Helmet>
					<title>{strings.commissions.binary}</title>
					<meta name="description" content="Description of commission of binary cycles" />
				</Helmet>
				<div className="IconCards">
					<Row gutter={[20, 20]}>
						{!eligible && (
							<Col xs={24}>
								<ContentWrapper>
									<div className="ScreenHeader">
										<div className="ScreenHeaderLeft">
											<Icon name="user" />
											<h2>{strings.commissions.eligibility}</h2>
										</div>
									</div>
									<p className="CommissionsPartnersAttention">{strings.commissions.notEligible}</p>
								</ContentWrapper>
							</Col>
						)}
						{this.renderCards()}
						<Col xs={24}>{this.renderChart()}</Col>
						{this.renderBinaryCards()}
						<Col xs={24}>{this.renderMovements()}</Col>
					</Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	countries: state.countries
});

export default connect(mapStateToProps)(Binary);
